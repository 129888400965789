<template>

                
    <div class="search-container" @click="onSearchContainerClick">
        <form autocomplete="off" > 
            <div class="p-inputgroup" >
                <i class='pi pi-search'></i>
                <AutoComplete id="secQuickSearch" ref="searchInput" style="width:100%;text-indent:0rem !important;" v-model="selectedItem" :field="getDisplayText" :suggestions="filteredItems" 
                    @complete="search($event)"  @item-select="itemSelected($event)" :placeholder="placeHolder" @click="onInputBarClick"
                     @keydown="onInputKeydown" optionGroupLabel="label" optionGroupChildren="items" scrollHeight="calc(90vh - 7.125rem)" class='auto-search-bar' panel-class="app-search-panel"> 
                    <template #item="slotProps" v-if="isUserSearch">
                       <div class="user-type" :key="slotProps.item.userId">
                                <!-- <img class="profile-image" :src="(slotProps.item.avatar && slotProps.item.avatar !== undefined) ? slotProps.item.avatar : '../Default-Avatar.jpeg'" /> -->
                            <UserAvatar :imageSrc="imageProcessor(slotProps.item)" size="md" :shape="slotProps.item.userTypeId === USER_TYPE_GROUP_ID ? 'roundedSquare' : 'circle'"/> 
                            <div class="text-container">
                                <span class='symbol'>@{{slotProps.item.name}}</span>
                                <span v-if="hasFullName(slotProps.item) && slotProps.item.userTypeId == USER_TYPE_USER_ID" class="full-name">
                                    ({{getFullName(slotProps.item)}})
                                </span>
                            </div>
                            <img class='group-symbol' v-if="slotProps.item.userTypeId === USER_TYPE_GROUP_ID" src="/assets/images/group_mobile.png"/>
                        </div>
                        
                       
                    </template>
                    <template #item="slotProps" v-else-if="isMarketAndUsersSearch">
                        <div class='security-type' v-if="slotProps.item.type === SECURITY_FLAG">
                            <span class='symbol'>${{stringUtils.convertXigniteToQmCompatibleSymbol(slotProps.item.symbol)}}</span><br>
                            <small>
                                {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                                {{ (slotProps.item.exchangeSymbol ? " - "+getExchange(slotProps.item.exchangeSymbol): "")}}
                            </small>
                        </div>
                         <div class='tag-type' v-else-if="slotProps.item.type === TAG_FLAG">
                            <span class='symbol'>#{{slotProps.item.name}}</span> <small>{{(slotProps.item.sectorId ? '(Sector/Industry)': '')}}</small>
                            
                        </div>
                        <div class='user-type' :key="slotProps.item.userId" v-else>
                                <!-- <img class="profile-image" :src="(slotProps.item.avatar && slotProps.item.avatar !== undefined) ? slotProps.item.avatar : '../Default-Avatar.jpeg'" /> -->
                            <UserAvatar :imageSrc="imageProcessor(slotProps.item)" size="md" :shape="slotProps.item.userTypeId === USER_TYPE_GROUP_ID ? 'roundedSquare' : 'circle'" /> 
                            <div class="text-container">
                                <span class='symbol'>@{{slotProps.item.name}}</span>
                                <span v-if="hasFullName(slotProps.item) && slotProps.item.userTypeId == USER_TYPE_USER_ID" class="full-name">
                                    ({{getFullName(slotProps.item)}})
                                </span>
                            </div>
                            <img class='group-symbol' v-if="slotProps.item.userTypeId === USER_TYPE_GROUP_ID" src="/assets/images/group_mobile.png"/>
                        </div>
                    </template>
                    <template #item="slotProps" v-else-if="isTagSearch">
                        <div class='tag-type'>
                            <span class='symbol'>#{{slotProps.item.name}}</span><small>{{(slotProps.item.sectorId ? '(Sector/Industry)': '')}}</small>
                            
                        </div>
                    </template>
                    <template #item="slotProps" v-else>
                        
                        <span class='symbol'>{{stringUtils.convertXigniteToQmCompatibleSymbol(slotProps.item.symbol)}}</span><br>
                        <small>
                            {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                            {{ (slotProps.item.exchangeSymbol ? " - "+getExchange(slotProps.item.exchangeSymbol): "")}}
                        </small>
                    </template>
                </AutoComplete>
                <Button icon="pi pi-angle-down"  class="p-inputgroup-addon" @click="toggleFilterMenu"/> 
                <Menu class='filter-dropdown search-filter' ref="menu" :model="searchTypes" :popup="true" >
                    <template #item="{ item }">
                        <template v-if="item.label == 'Filters'">
                            <span class="p-submenu-header">{{ item.label }}</span>
                        </template>
                        <template v-else>
                            <a class="p-menuitem-link" @click="selectFilter(item, $event)">
                                <div class='menuitem-row'>
                                    <span class="p-menuitem-text">{{(item.prefix ? item.prefix: '')}}{{ item.label }}</span>
                                    <span class='select-icon' v-if="item.selected"><i class="pi pi-check-circle"></i></span>
                                </div>
                            </a>
                        </template>
                    </template>
                </Menu>
            </div>
            
        </form>
    </div>
</template>

<script>
import UserAvatar from './components/profile/UserAvatar';
import AutoComplete from 'primevue/autocomplete';

import GroupService from '@/service/GroupService.js';
import ResearchService from '@/service/ResearchService.js';
import UserService from '@/service/UserService.js';

import StringUtils from '@/utilities/StringUtils.js';

import { USER_TYPE_GROUP_ID, USER_TYPE_USER_ID } from '@/common/constants';
import { generateGroupAvatar } from './components/groups/';
import EventBus from './event-bus';
import { mapState } from 'vuex';
import Mark from 'mark.js';

const USERS_LABEL = 'Users';
const EQUITIES_LABEL = 'Equities';
const FUNDS_LABEL = 'Funds';
const TAGS_LABEL = 'Tags/Sectors/Industries';
const INDICIES_LABEL = 'Indices';
const MARKET_AND_USERS_LABEL = 'All';

const USERS_PREFIX = '@';
const SECURITIES_PREFIX = '$';
// const FUNDS_PREFIX = '$';
const TAG_PREFIX = '#'
// const INDICIES_PREFIX = '!';


// For differenciating user and security search results
const USER_FLAG = 'User';
const SECURITY_FLAG = 'Security'
const TAG_FLAG = 'Tag';

export default {
    name: "AppSearch",
    components: {
        AutoComplete,
        UserAvatar
    },

    data() {
        return {
            //CONSTANTS
            USER_FLAG,
            SECURITY_FLAG,
            TAG_FLAG,
            USER_TYPE_USER_ID,
            USER_TYPE_GROUP_ID,

            stringUtils: StringUtils,

            selectedItem: {displayText:""},
            filteredItems: null,
           
            searchTypes: [
                {
                    label: 'Filters',
                    items: [
                        {label: USERS_LABEL, code: 'O4', selected: false, prefix: USERS_PREFIX},
                        {label: EQUITIES_LABEL, code: 'O1', selected: false, prefix: SECURITIES_PREFIX},
                        {label: FUNDS_LABEL, code: 'O2', selected: false, prefix: SECURITIES_PREFIX},
                        {label: TAGS_LABEL, code: 'O5', selected: false, prefix: TAG_PREFIX},
                        {label: INDICIES_LABEL, code: '03', selected: false},
                        {label: MARKET_AND_USERS_LABEL, code: 'O0', selected: true},
                        
                    ]
                }
			],
				searchType: null,
				searchQuery: null,
                searchPrefix: null,
           
        }
    },
    emits: ["search-click", "search-hide"],
    props: {
        searchActive: {
            type: Boolean,
            default: false,
        }
    },
    created() {
        this.searchType = this.searchTypes[0].items[5];
    }, 
    mounted() {
        EventBus.on('before-search-hide', this.beforeSearchHide);
        EventBus.on('before-search-show', this.beforeSearchShow);
        EventBus.on('populate-search-input', this.populateSearchInput);
        
        this.selectedItem = { displayText: this.searchBarText};
    },
    computed: {
        ...mapState(['selectedFund']),
        ...mapState(['selectedEquity']),
        ...mapState(['selectedMarketIndex']),
        ...mapState(['searchBarText']),
        placeHolder() {
            if( this.isUserSearch){
                return 'Search by username';
            } else if (this.isMarketAndUsersSearch) {
                return 'Search markets and users';
            } else {
                return 'Search by symbol or name';
            }
        },

        isUserSearch() {
            if (this.searchPrefix) {
               return this.searchPrefix == USERS_PREFIX;

            }else {
                return  ( this.searchType && this.searchType.label == USERS_LABEL);
            }
        },
        isMarketAndUsersSearch() {
            return (!this.searchPrefix && this.searchType && this.searchType.label == MARKET_AND_USERS_LABEL);
        },
        isTagSearch() {
            if(this.searchPrefix ) {
                return this.searchPrefix == TAG_PREFIX;
            }
            else {
                return (this.searchType && this.searchType.label == TAGS_LABEL);
            }
        }

    },
    watch: {
        $route(to, from) { //eslint-disable-line
            if (to.path.includes('/equity')) {
                this.$store.commit('SET_SEARCH_BAR_TEXT', `${this.selectedEquity.symbol} - ${this.selectedEquity.company}`);
            } else if (to.path.includes('/fund')) {
                this.$store.commit('SET_SEARCH_BAR_TEXT', `${this.selectedFund.symbol} - ${this.selectedFund.name}`);
            } else if (to.path.includes('/marketIndex')) {
                this.$store.commit('SET_SEARCH_BAR_TEXT', `${this.selectedMarketIndex.symbol} - ${this.selectedMarketIndex.name}`);
            } else {
                this.$store.commit('SET_SEARCH_BAR_TEXT', null);
            }

            this.populateSearchInput();
        },

        searchBarText(newVal, oldVal) {
            if( newVal && newVal != oldVal) {
                this.selectedItem = { displayText: newVal};
            }
        }
    },
    methods: {
        generateGroupAvatar,

        imageProcessor(user) {
            if (user.userTypeId === USER_TYPE_GROUP_ID) {
                if (user.avatar) {
                    return user.avatar;
                } else {
                    return generateGroupAvatar(user.name);
                }
            } else {
                return user.avatar;
            }
        },

        highlightSearchMatches(query) {
            if (this.isUserSearch) {
                let instance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item .user-type .text-container'));

                const regex = new RegExp(`\\b${query}|(?<=[${USERS_PREFIX}(])\\b${query}`, 'gi');

                instance.unmark();
                instance.markRegExp(regex, {
                    'className': 'search-match-highlight',
                });
            } else if (this.isMarketAndUsersSearch) {
               let marketInstance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item .security-type'));
               let userInstance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item .user-type .text-container'));
               let tagInstance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item .tag-type'));

                const userRegex = new RegExp(`\\b${query}|(?<=[${USERS_PREFIX}(])\\b${query}`, 'gi');
                const marketRegex = new RegExp(`^${query}`, 'i');
                const tagRegex = new RegExp(`\\b${query}|(?<=[${TAG_PREFIX}(])\\b${query}`, 'gi');

                marketInstance.unmark();
                userInstance.unmark();
                marketInstance.markRegExp(marketRegex, {
                    'className': 'search-match-highlight',
                });
                userInstance.markRegExp(userRegex, {
                    'className': 'search-match-highlight',
                });

                tagInstance.markRegExp(tagRegex, {
                    'className': 'search-match-highlight',
                });

            }
            else if(this.isTagSearch ){
                 let instance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item .tag-type'));

                const regex = new RegExp(`\\b${query}|(?<=[${TAG_PREFIX}(])\\b${query}`, 'gi');

                instance.unmark();
                instance.markRegExp(regex, {
                    'className': 'search-match-highlight',
                });
            } else {
                let instance = new Mark(document.querySelectorAll('.app-search-panel .p-autocomplete-item'));

                const regex = new RegExp(`^${query}`, 'i');

                instance.unmark();
                instance.markRegExp(regex, {
                    'className': 'search-match-highlight',
                });
            }
        },

        hasFullName(analyst){
            return analyst.firstName || analyst.lastName;
        },
        getFullName(analyst){
            let fullName = analyst.firstName;
            if( analyst.middleName != null ){
                fullName += ' '+analyst.middleName;
            }
            if( analyst.lastName != null ) {
                fullName += ' '+analyst.lastName;
            }
            return fullName;
        },
        getDisplayText(){
            return this.selectedItem.displayText ? this.selectedItem.displayText : '';
        },
        getExchange(exchangeSymbol) {
            if( exchangeSymbol == 'XNAS') {
				return "NASDAQ";
			}
			else if( exchangeSymbol == 'XNYS') {
				return "NYSE";
			}
			else if( exchangeSymbol == 'OOTC') {
				return "OTC";
			}
			else if( exchangeSymbol == 'XTSE') {
				return "TSX";
			}
			else if( exchangeSymbol == 'XTSX') {
				return "TSXV";
			}
			else if( exchangeSymbol == 'XCNQ') {
				return "CSE"
			}
            else if( exchangeSymbol == 'ARCX'){
                return "NYSE ARCA"
            }
			else {
				return exchangeSymbol;
			}
        },

        beforeSearchHide() {
              //this.$refs.email.$el.focus();
        },
        beforeSearchShow() {
            this.selectedItem = null;
        },

        // This function will handle populating the search input bar depending on if the user is on a single security page or away from it (Navigating through side menu item buttons)
        populateSearchInput() {
            /* Essentially, if user is currently not on a single security page - We empty the input bar completely. Otherwise,
            populate the input bar with the custom text of showing security symbol and name (selectionTracker)  or username*/
           //console.log("setting search bar tesxt to "+ this.searchBarText);
            this.selectedItem = { displayText: this.searchBarText};
        },
       
        afterEnter() {
            
            this.$refs.searchInput.$el.focus();
            document.getElementById('secQuickSearch').focus();

        },
        onSearchContainerClick(event) {
            this.$emit("search-click", event);
        },
        onInputBarClick() {
           
            this.clearInput();

        },
        onInputKeydown(event) {
            const key = event.which;
           
            if( key === 13 ) {
                 
                 if( this.filteredItems && this.filteredItems.length == 1) {
                    
                     this.$refs.searchInput.selectItem(event,this.filteredItems[0]);
                 }
                 event.preventDefault();
            }
           
            //escape, tab and enter
           
        },

        search(event){
            //console.log(event);
            if( event.query.startsWith(USERS_PREFIX) || event.query.startsWith(TAG_PREFIX) || event.query.startsWith(SECURITIES_PREFIX) ) {
                
                if( event.query.length > 1) {
                    this.searchPrefix = event.query.substring(0, 1);
                    this.searchQuery = event.query.substring(1);
                    this.doSearch(event);
                }
                else {
                    this.searchPrefix = null;
                }
            }
            else {
                this.searchPrefix = null;
                this.searchQuery = event.query;
                this.doSearch(event);
            }
            
        },

        doSearch(event) {
            
            
            if (this.isUserSearch || event.query.startsWith(USERS_PREFIX)) {
                this.searchUsers(event);
            } else {
                this.searchMarketAndUsers(event);
            }
        },

        searchUsers(event) {
            let query = event.query;
            if( query.length > 1) {
                if( query.startsWith(USERS_PREFIX)  ){
                   
                    query = event.query.substring(1);
                }
            }
            UserService.queryAnalysts(query, 10, 0).then(resp => {

              if(this.searchQuery == query){

                this.filteredItems = [{
                    label: USERS_LABEL, code: 'U', 
                    items: resp.data.analysts
                }];

                // Highlighting of matches in results
                this.$nextTick(() => {
                    this.highlightSearchMatches(query);
                })

              }

            });
        },
        searchMarketAndUsers(event) {
            let type = null;
            
            if( this.searchType.label === MARKET_AND_USERS_LABEL ) {
                type = 'All';
                
            }
            else if( this.searchType.label === TAGS_LABEL ) {
                type = 'Tags';
            }
            else {
                type = this.searchType.label;
            }
            let query = event.query;
            if( query.length > 1) {
                if( query.startsWith(TAG_PREFIX)  ){
                    type = 'Tags';
                    query = event.query.substring(1);
                }
                else if( query.startsWith(SECURITIES_PREFIX) ){
                    type = 'Secs';
                    query = event.query.substring(1);
                }
            }

            
            //console.log("Searching by "+ type)
            ResearchService.queryMarket(type, query, 10, 0).then(response => {

              if(this.searchQuery == query){

                let queryResults = response.data;
                if( this.searchPrefix != null ) {
                    if( this.searchPrefix == SECURITIES_PREFIX ) {
                        for(const prop in queryResults) {
                            const securityPropNames = ['securities', 'funds', 'indices'];
                            
                            let arr = queryResults[prop];

                       
                            if (arr.length > 0) {
                                if (securityPropNames.includes(prop)) {
                                    queryResults[prop] = arr.map((obj) => {
                                        obj['type'] = SECURITY_FLAG;

                                        return obj;
                                    });
                                }
                                
                            }
                        }

                        this.filteredItems = [
                       
                            {
                                label: EQUITIES_LABEL, code: 'EQ', 
                                items: queryResults.securities,
                            },
                            {
                                label: FUNDS_LABEL, code: 'F', 
                                items: queryResults.funds,
                            }
                       ]
                    }
                    else if( this.searchPrefix == TAG_PREFIX) {
                        for(const prop in queryResults) {
                           
                            let arr = queryResults[prop];
                            if (arr.length > 0) {
                                queryResults[prop] = arr.map((obj) => {
                                    obj['type'] = TAG_FLAG;

                                    return obj;
                                });
                            }
                        }
                        this.filteredItems = [
                        
                            {
                                label: TAGS_LABEL, code: 'T', 
                                items: queryResults.tags,
                            }
                        ];
                    }
                    else {
                         for(const prop in queryResults) {
                             
                            let arr = queryResults[prop];
                            if (arr.length > 0) {
                                queryResults[prop] = arr.map((obj) => {
                                    obj['type'] = USER_FLAG;

                                    return obj;
                                });
                            }
                         }
                        this.filteredItems = [
                            {
                                label: USERS_LABEL, code: 'U', 
                                items: queryResults.analysts,
                            }
                        ];
                    }
                       
                }
                else {
                    if (this.searchType.label == MARKET_AND_USERS_LABEL) {
                        // Adding in a 'type' flag so that it's able to differentiate between a security and user when selecting a result in the default search
                        for(const prop in queryResults) {
                            const securityPropNames = ['securities', 'funds', 'indices'];
                            
                            let arr = queryResults[prop];

                            if (arr.length > 0) {
                                if (securityPropNames.includes(prop)) {
                                    queryResults[prop] = arr.map((obj) => {
                                        obj['type'] = SECURITY_FLAG;

                                        return obj;
                                    });
                                }
                                else if( prop === 'tags') {
                                    queryResults[prop] = arr.map((obj) => {
                                        obj['type'] = TAG_FLAG;

                                        return obj;
                                    });
                                    
                                } else {
                                    queryResults[prop] = arr.map((obj) => {
                                        obj['type'] = USER_FLAG;

                                        return obj;
                                    })
                                }
                            }
                        }

                        this.filteredItems = [
                        {
                            label: USERS_LABEL, code: 'U', 
                            items: queryResults.analysts,
                        },
                        {
                            label: EQUITIES_LABEL, code: 'EQ', 
                            items: queryResults.securities,
                        },
                        {
                            label: FUNDS_LABEL, code: 'F', 
                            items: queryResults.funds,
                        },
                        {
                            label: TAGS_LABEL, code: 'T', 
                            items: queryResults.tags,
                        },
                        {
                            label: INDICIES_LABEL, code: 'I', 
                            items: queryResults.indices,
                        }]
                    }
                    else if( this.searchType.label == EQUITIES_LABEL){
                        this.filteredItems = [{
                            label: EQUITIES_LABEL, code: 'EQ', 
                            items: queryResults.securities,
                        }]
                    }
                    else if( this.searchType.label == FUNDS_LABEL){
                        this.filteredItems = [{
                            label: FUNDS_LABEL, code: 'F', 
                            items: queryResults.funds,
                        }];
                    }
                    else if( this.searchType.label == TAGS_LABEL){
                        this.filteredItems = [{
                            label: TAGS_LABEL, code: 'T', 
                            items: queryResults.tags,
                        }];
                    }
                    else if( this.searchType.label == INDICIES_LABEL){
                        this.filteredItems = [{
                            label: INDICIES_LABEL, code: 'I', 
                            items: queryResults.indices
                        }];
                    } 
                }

                // Highlighting of matches in results
                this.$nextTick(() => {
                    EventBus.emit('return-search-results', query)

                    this.highlightSearchMatches(query);
                })

              }

          })
          .catch(error => {
              this.filteredItems = []
              
            console.debug('There was an error:', error.response)
          })
		},
        itemSelected(event){
            // this.selectedItem = event.value;
           if( this.searchPrefix == null ) {
                if( this.isUserSearch ){
                    this.userSelected(event);
                } else if (this.isMarketAndUsersSearch) {
                    this.userOrSecuritySelected(event);

                } else if( this.isTagSearch ){
                    this.tagSelected(event);
                
                } else {
                    this.securitySelected(event);
                }
           }
           else {

            if( this.searchPrefix == USERS_PREFIX) {
                this.userSelected(event);
            }
            else if( this.searchPrefix == SECURITIES_PREFIX) {
                this.securitySelected(event);
            }
            else {
                 this.tagSelected(event);
            }
           }

            this.$nextTick(() => {
                this.$refs.searchInput.$el.blur();
                document.getElementById('secQuickSearch').blur();
            })
        },
        userOrSecuritySelected(event) {
            if (event.value) {
                switch(event.value.type) {
                    case USER_FLAG:
                        this.userSelected(event);
                        break;
                    case SECURITY_FLAG:
                        this.securitySelected(event);
                        break;
                     case TAG_FLAG:
                        this.tagSelected(event);
                        break;
                    default:
                        break;
                }
            }
        },
        userSelected(event) {
            if (event.value) {
                if (event.value.userTypeId === USER_TYPE_USER_ID) {
                    if( event.value.name ){
                        // this.$store.commit('SET_SEARCH_BAR_TEXT', event.value.name);
                        // this.selectedItem = { displayText: this.searchBarText};
                        
                        this.$router.push({ path: '/analysts/profile/'+event.value.name });
                    }
                } else if (event.value.userTypeId === USER_TYPE_GROUP_ID) {
                    if (event.value.userId) {
                        GroupService.getGroupByGroupUserId(event.value.userId).then(resp => {
                            if (resp.data.status === 'success') {
                                this.$router.push({path: '/groups/profile/'+resp.data.group.groupId});
                            } else {
                                console.log("error getting group: "+ resp.data.message);
                            }
                        })
                    }
                }
            }

            try {
                EventBus.emit('track-user-search')
            } catch (err) {
                return;
            }
            
        },
        securitySelected(event) {
            this.setSelectedSecurity(event.value);

           
        },

        tagSelected(event) {
            let tag = event.value;
           
            this.$router.push({ path: '/tag/profile/'+tag.tagId });

            this.$store.commit('SET_SEARCH_BAR_TEXT', `${tag.name}`); // need for when user queries another stock from fund screen - route doesn't change
            
        }, 
        selectFilter(filter, event) {
            this.searchTypes[0].items.forEach((filterItem) => {
                if (filterItem === filter) {
                    filterItem.selected = true;
                    this.searchType = filterItem;
                } else {
                    filterItem.selected = false;
                }
            });

            this.$refs.menu.toggle(event);
            document.getElementById('secQuickSearch').focus();
        },
        setSelectedSecurity(sec) {
            sec.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(sec.symbol);//IN-256 convert xignite symbol to qm compatible symbol
            if( sec.securityId ) {
                ResearchService.setSelectedEquity(sec.exchangeSymbol, sec.symbol, () => {
                    this.$router.push({ path: '/equity' });
                    this.$store.commit('SET_SEARCH_BAR_TEXT', `${sec.symbol} - ${sec.company}`); // need for when user queries another stock from equity screen - route doesn't change
                });
                // this.$store.commit('SET_SELECTED_EQUITY', sec);
            }
            else if( sec.fundId ) {
                ResearchService.setSelectedFund(sec.exchangeSymbol, sec.symbol, () => {
                    this.$router.push({ path: '/fund' });
                    this.$store.commit('SET_SEARCH_BAR_TEXT', `${sec.symbol} - ${sec.name}`); // need for when user queries another stock from fund screen - route doesn't change
                });
            }
           
            else {
                this.$store.commit('SET_SELECTED_MARKET_INDEX', sec);
                this.$router.push({ path: '/marketIndex' });

                this.$store.commit('SET_SEARCH_BAR_TEXT', `${sec.symbol} - ${sec.name}`);// // need for when user queries another index from index screen - route doesn't change
            }

            this.selectedItem = { displayText: this.searchBarText}; // need for when user queries another stock/fund/index from equity/fund/index screen - route doesn't change

            try {
                EventBus.emit('track-security-search')
            } catch (err) {
                return;
            }
        },
        toggleFilterMenu(event) {
            this.$refs.menu.toggle(event);
        },
        clearInput() {
            this.selectedItem = { displayText: ""};
            document.getElementById('secQuickSearch').focus();
        }
        
    }
};
</script>
<style>
    .p-autocomplete-panel.p-component {
        font-family: 'Trebuchet MS', 'Verdana';
        box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
        /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
        border-radius: 8px;
    }
    .p-autocomplete-panel.p-component::-webkit-scrollbar {
        /* width */
        width: 10px;
    }
    .p-autocomplete-panel.p-component::-webkit-scrollbar-track {
        background-color: #F2F2F2;
        opacity: 1;
        transition: background-color .2s;
    } 
    .p-autocomplete-panel.p-component::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background: #BFBFBF;    /* color of the scroll thumb */
        border-radius: 10px;       /* roundness of the scroll thumb */
    }
    .p-autocomplete-panel.p-component .p-autocomplete-items {
        padding: 0;
    }
    .p-autocomplete-panel.p-component .p-autocomplete-item-group {
        color: #32364e;
        padding: 0.5rem 1rem;
        font-weight: bold;
        font-size: 16px;
    }
     .p-autocomplete-panel.p-component .p-autocomplete-item .symbol {
         color: black;
     }
    .p-autocomplete-panel.p-component .p-autocomplete-item:hover .symbol {
        color: #FFFFFF;
     }
    .p-autocomplete-panel.p-component .p-autocomplete-item:hover {
        color: #ddd;
        background-color: #32364e;
    }
    .p-autocomplete-panel.p-component .p-autocomplete-item:not(:last-child),
    .p-autocomplete-panel.p-component .p-autocomplete-item-group:not(:last-child) {
        border-bottom: 1px solid #ddd;;
    }

.p-autocomplete-panel.p-component .p-autocomplete-item .names-container {
  display: flex;
  flex-direction: column;
}
.p-autocomplete-panel.p-component .p-autocomplete-item .names-container .full-name {
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
  line-height: 1.2;
}
.p-autocomplete-panel.p-component .p-autocomplete-item .names-container .username {
  color: #a6a6a6;
  line-height: 1.2;
  margin-left: 5px;
}
.p-autocomplete-panel.p-component .p-autocomplete-item .names-container > span
 {
  color: #FFFFFF;
}

@media (max-width: 760px) {
    .p-autocomplete-panel.p-component.app-search-panel {
        width: 100%;
        border-radius: 0px;
    }
    .search-container {
       flex: 0 1 auto;
    }
}

div.search-filter{
    width: 16rem !important;
}

</style>
<style scoped>


.search-container {
    position: relative;
    display:block;
    width: 800px;
}

::v-deep(.p-autocomplete > input ){
   
    text-indent: 0rem;
    /* width: 11rem; */
}

::v-deep( .p-button:focus ) {
    border-radius: none;
    
    border: none !important;
   

}

::v-deep(.p-inputgroup .pi-search) {
    line-height: 0.8;
}

::v-deep(.p-inputgroup .p-inputgroup-addon) {
    padding: 1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border: 2px solid #32364e;
    background-color: #F2F4FA;
}
::v-deep(.p-inputgroup .p-inputgroup-addon:focus),
::v-deep(.p-inputgroup .p-inputgroup-addon:enabled:focus),
::v-deep(.p-inputgroup .p-inputgroup-addon:enabled:hover) {
    border: 2px solid #33CC99 !important;
    box-shadow: none;
}

::v-deep(.p-inputgroup .p-inputgroup-addon .pi-angle-down) {
    color: #32364e;
}


::v-deep(.pi-search) {
    position: absolute;
    z-index: 2;
    top: 11px;
    left: 11px;
    color: #BFBFBF;
    font-weight: 900;
    font-size: 16px;
}

::v-deep(.p-inputgroup) {
    height: 36px;
    position: relative;
}
::v-deep(.p-inputgroup .auto-search-bar .p-autocomplete-input.p-inputtext) {
    font-family: 'Trebuchet MS', 'Verdana';
    font-size: 16px;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border: 2px solid #32364e;
    border-right-style: none;
    background-color: #F2F4FA;
    color: black;
    padding-left: 35px;
    text-overflow: ellipsis;
}
/* Controls the styling for placeholder text within the search bar*/
::v-deep(.p-inputgroup .auto-search-bar .p-autocomplete-input.p-inputtext::placeholder) { 
    color: #BFBFBF; 
}
::v-deep(.p-inputgroup .auto-search-bar .p-autocomplete-input.p-inputtext:enabled:hover),
::v-deep(.p-inputgroup .auto-search-bar .p-autocomplete-input.p-inputtext:enabled:focus) {
    border: 2px solid #33CC99;
    box-shadow: none;
}

.user-type {
    display: flex;
    align-items: center;
}
.user-type > * {
    margin-right: 8px;
}
.user-type > *:last-child {
    margin-right: 0;
}

@media screen and (max-width: 1920px) and (min-width: 1092px) {
    .search-container {
        width: calc(41vw);
    }
} 

@media  screen and (max-width: 1091px) and (min-width: 761px) {
    .search-container {
        width: calc(33vw);
    }
}
</style>