import INVRS from '../../main.js';

export default class FundOverviewTour {

    tour = INVRS.app.config.globalProperties.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'invrs-onboarding-tour',
            cancelIcon: {
                'enabled': true
            }
        }
    });
    
    steps = this.createSteps();

    createSteps() {
        return [
        {
            title: "Welcome to the Fund Overview page",
            text: "Anytime you search for an ETF or Fund, you will be greeted by this page. Now let's look at what's featured:",
            buttons: [
                {
                 classes: 'green-button',
                 text: 'Next',
                 action: this.tour.next   
                }
            ]
        },
        {
            title: 'Price Series Chart',
            attachTo: {element: document.querySelector('.fund-overview-chart-card'), on: 'right'},
            text: "This is an interactive view of the company's price performance. This tool presents the daily stock price and associated volume in a chosen date range.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                 classes: 'green-button',
                 text: 'Next',
                 action: this.tour.next   
                }
            ]
        },
        {
            title: 'Sector Allocation',
            attachTo: {element: document.querySelector('.fund-sector-allocation-chart-container'), on: 'left'},
            scrollTo: {
                behavior: 'smooth',
                block: 'start'
            },
            text: "The allocation breakdown shows how much asset weight is allocated to each industry sector in this fund.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                 classes: 'green-button',
                 text: 'Next',
                 action: this.tour.next   
                }
            ]
        },
        {
            title: 'Posts',
            attachTo: {element: () => {
                return document.querySelector('.fund-overview-container .social-feed .activity-cards-container')}
                , on: 'left'},
            canClickOnTarget: false,
            text: "This is an aggregate view of all community Posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations.",
            scrollTo: {
                behavior: 'smooth',
                block: 'center'
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Overview',
            attachTo: {element: document.querySelector('.fund-overview-container .profile-container'), on: 'right'},
            text: "Check out relevant statistics and administration information.",
            scrollTo: {behavior: 'smooth', block: 'center'},
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Relative Performance',
            attachTo: {element: document.querySelector('.fund-overview-container .relative-performance-chart-container'), on: 'right'},
            text: "This tool compares the price performance of the fund against related peers.",
            scrollTo: {behavior: 'smooth', block: 'center'},
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Holdings Tab',
            attachTo: {element: document.querySelector('.pill-menu-bar .navigation button:nth-of-type(2)'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "This tool presents the top 10 portfolio holdings and performance for a given fund. Use this to gain insight into what holdings are driving Fund performance.",
            scrollTo: {behavior: 'smooth', block: 'start'},
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Charting Tab',
            attachTo: {element: document.querySelector('.pill-menu-bar .navigation button:nth-of-type(3)'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "This Advanced Chart contains all the most popular upper & lower indicators, as well as other complementary functions to enhance your technical analysis.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Dividends Tab',
            attachTo: {element: document.querySelector('.pill-menu-bar .navigation button:nth-of-type(4)'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "This tab tracks all historical dividends declared by a company and provides insight into future dividends once they have been formally declared.",
            scrollTo: {behavior: 'smooth', block: 'start'},
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Posts Tab',
            attachTo: {element: document.querySelector('.pill-menu-bar .navigation button:last-of-type'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "This tab contains an aggregate view of all the posts shared on INVRS that include this company.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Subscribe Alert Button (Premium)',
            attachTo: {element: document.querySelector('.pill-menu-bar .optional-buttons-container .subscribe-alert-button'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "Keep up to date with custom triggers and alerts.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Follow Fund Button',
            attachTo: {element: document.querySelector('.pill-menu-bar .optional-buttons-container .follow-button'), on: 'bottom'},
            classes: 'pill-menu-bar-button',
            text: "Make sure you get relevant news by Following your favorite companies.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                }
            ]
        },
        {
            title: 'Summary',
            text: "This page is helpful when you want to initially familiarize yourself with a new fund.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back   
                },
                {
                 classes: 'green-button',
                 text: 'OK',
                 action: this.tour.complete 
                }
            ]
        }
        ]
    }
  }