<template>
    <LoadingSpinner :showLoading="loading || initLoading"/> 
   
    <infinite-scroll @infinite-scroll="loadMoreChannels" message="" :noResult="noMoreResults" :style='scrollStyle' v-observe-visibility="visibilityChanged" v-if="!initLoading ">
        
        <DMChannelListItem v-for='channel in channels' :key='channel.id' @channel-clicked="onChannelClick" :channel="channel" :sideMenuWidth="sideMenuWidth"/>
        
    </infinite-scroll>
</template>

<script>
import ChatService from '../../service/ChatService';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import LoadingSpinner from '../common/LoadingSpinner.vue';
//import ChatService from '../../service/ChatService';

import Mark from 'mark.js';
import DMChannelListItem from './DMChannelListItem.vue';

import EventBus from '../../event-bus';

export default {
    name: 'DMChannelList',
    emits: ['channel-selected'],
    props: {
        filter: {
            type: String,
            required: false
        },
        includeHidden: {
            type: Boolean,
            required: true,
            default: false
        },
       
        sideMenuWidth: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            channels: [],
            noMoreResults: false,
            selectingChannel: false,
            selectedChannelForAction: null,
            totalMatches: 0,
            header: '',
            selectedMenuItem: null,
            scrollStyle: "width: 100%; max-height: 100%; height: calc(100vh - 200px);",
            currentlyVisible : false,
            mentionNotificationHandler: null,
            messageNotificationHandler: null,
            refreshHandler: null,
           
        }
    },
    components: {
        InfiniteScroll, //LoadingSpinner, 
        DMChannelListItem,LoadingSpinner
    },

    errorCaptured(err, vm, info) {// eslint-disable-line
        console.log("capturing error : "+err)
        return false;
    },
   watch: {
        includeHidden(newVal, oldVal) {
            if( newVal != oldVal) {
                this.$store.commit('MARK_CHANNELS_STALE_DM')
                console.log("include hidden changed from "+oldVal +" to "+ newVal)
                this.refreshList();
            }
        },

        filter(newVal, oldVal) {
            console.log("filter changed from "+oldVal +" to "+ newVal)
            this.$store.commit('MARK_CHANNELS_STALE_DM')
            if( newVal && newVal != oldVal ) {
                
                this.channels = [];
                this.fetchDMChannelsFromSearch(this.filter, 20, 0);
            }
            else {
                this.initalFetchDMChannels(this.includeHidden);
            }
        }
   },

    mounted() {
       

        EventBus.off('mark-dm-messages-read');
        EventBus.on('mark-dm-messages-read', (channelId) => {
            this.markChannelRead(channelId);
        })

       

        if( !this.refreshHandler ){
            this.refreshHandler = (notification) => {//eslint-disable-line

                this.$store.commit('MARK_CHANNELS_STALE_DM')
                if( this.currentlyVisible ) {
                    this.refreshList();
                }
                else {
                    console.log("recived refresh my dm channel list event but not visible")
                }

            };
        }
        EventBus.off('refresh-my-dm-channel-list', this.refreshHandler);
        EventBus.on('refresh-my-dm-channel-list', this.refreshHandler);
    },

    unmounted() {
        this.currentlyVisible = false;
      
        
    },
   

    methods: {

         visibilityChanged (isVisible, entry) { // eslint-disable-line
            this.currentlyVisible = isVisible;
            if(isVisible ) {
                
               this.initalFetchDMChannels();
                
            }
            
            
            
        },
        

        markChannelRead(channelId) {
            for( var i = 0; i < this.channels.length; ++i ) {
                let c = this.channels[i];
                if (c.data.id == channelId ) {
                    c.state.unreadCount = 0;
                    if( c['mentionCount']) {
                        c['mentionCount'] = 0;
                    }
                    break;
                }
            }
        },

       refreshList() {
            if( this.currentlyVisible ) {
                this.channels = [];
                this.initLoading = true;
                this.initalFetchDMChannels(this.includeHidden);
            }
       },

        initalFetchDMChannels(includeHidden) {
            // console.log("initalFetchDMChannels");
            this.initLoading = true;
            let chatStateDM = this.$store.state.chatStateDM;
            if( chatStateDM.channelsAreStale ) {
                console.log("my dm channels are stale reloading")
                this.noMoreResults = false;
                this.fetchDMChannels(20, 0, includeHidden);
            }
            else {
                console.log("my dm channels are not stale - resusing existing list")
                this.channels = chatStateDM.myChannels;
                this.initLoading = false;
            }
        },

        fetchDMChannels(limit, offset, includeHidden, cids) {
            ChatService.waitForClient().then(() => {
                ChatService.getMyDMChannels(limit, offset, null, null, null, includeHidden, cids).then(  resp => {
                    if (this.initLoading) {
                        this.initLoading = false;
                    }

                    this.loading = false;
                    //TODO handle paging - depends on how we're going to implement ui
                    if( resp.status == "success") {
                        
                        let chatStateDM = { channelsAreStale: false, myChannels: resp.channels};
                        this.$store.commit("SET_CHAT_STATE_DM", chatStateDM)
                        if (chatStateDM.myChannels.length > 0) {
                            this.channels = this.channels.concat(chatStateDM.myChannels); 
                        
                            this.noMoreResults = false;
                            
                        
                        } else {
                            
                            this.noMoreResults = true;
                        }
                        if( this.filter) {
                            this.$nextTick(() => {
                                let instance = new Mark(document.querySelectorAll('.search-channels-scrollpanel .channel-item'));

                                let regex = new RegExp(`^${this.filter}`, 'i');

                                instance.unmark();
                                instance.markRegExp(regex, {
                                    'className': 'search-match-highlight'
                                });
                            })
                        }
                    }
                    else {
                        console.error("error getting my dm channels :" + resp.message)
                    }
                    
                });
            });
        },

        fetchSearchResultDMChannels(includeHidden, cids) {
            ChatService.waitForClient().then(() => {
                ChatService.getMyDMChannels(20, 0, null, null, null, includeHidden, cids).then(  resp => {
                    
                    this.loading = false;
                    //TODO handle paging - depends on how we're going to implement ui
                    if( resp.status == "success") {
                        
                        this.channels = resp.channels;
                        this.noMoreResults = true;
                        
                        if( this.filter) {
                            this.$nextTick(() => {
                                let instance = new Mark(document.querySelectorAll('.search-channels-scrollpanel .channel-item'));

                                let regex = new RegExp(`^${this.filter}`, 'i');

                                instance.unmark();
                                instance.markRegExp(regex, {
                                    'className': 'search-match-highlight'
                                });
                            })
                        }
                    }
                    else {
                        console.error("error searching my dm channels :" + resp.message)
                    }
                    
                });
            });
        },

        
        fetchDMChannelsFromSearch(query, limit, offset) {
            this.loading = true;
            ChatService.searchDMChannels(query, limit, offset, null, null).then( resp => {
                
                
                const cids = resp.data.cids;//.filter(this.isNotMemberOrOwner);
                if( cids.length > 0 ) {
                    this.fetchSearchResultDMChannels( this.includeHidden,  cids);
                }
                else {
                    this.loading = false;
                }
            })
        },


        async loadMoreChannels() {
            if (!this.loading && this.channels.length >= 20) {
                this.loading = true;

                if (!this.noMoreResults) {
                    this.fetchChannels(20, this.channels.length);
                } else {
                    this.loading = false;
                }
            }
            
        },

        onChannelClick(channel) {
            
            this.$emit('channel-selected', channel);

        },

    }
}
</script>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.p-scrollpanel-content) {
    padding: 0px !important;
}

/* 
::v-deep(.channel-item) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.channel-item:hover) {
    background: #32364e;
}
::v-deep(.channel-item:hover .channel-title) {
    color: #FFFFFF;
}

::v-deep(.channel-item .channel-title) {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep(.channel-item .more-options) {
    margin-left: auto;
} */
</style>