<template>
<SearchBar @search-input="searchInput" @loading="onLoading" :placeholderText="placeholderText">
    <template #afterInput>
        <div class="field-checkbox" style="display: inline-block;margin-left: 3px;">
            <Checkbox v-model="includeHidden" :binary="true" l inputId="hiddenControl" style="margin-right: 3px;position:relative;top:5px;"/>
            <label for="hiddenControl" style="font-size:12px;">Show Hidden</label>
        </div>
    </template>
</SearchBar>
    <!-- <LoadingSpinner :showLoading="initLoading" />
    <infinite-scroll @infinite-scroll="loadMoreResults" message="" :noResult="noMoreSearchResults" :style='scrollStyle' v-if="currentText" class='search-channels-scrollpanel'>
        <LoadingSpinner :showLoading="loading"/>
        <DMChannelSearchListItem v-for='channel in searchResults' :key='channel.chatChannelId' @channel-clicked="onClick" :channel="channel" />
    </infinite-scroll> -->
</template>

<script>
// import LoadingSpinner from '../common/LoadingSpinner.vue';
// import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
//import DMChannelSearchListItem from './DMChannelSearchListItem.vue';
import SearchBar from '../query/SearchBar.vue';
import Checkbox from 'primevue/checkbox';

//import ChatService from '../../service/ChatService';

//import Mark from 'mark.js';
//import {onChannelClick} from '.';

export default {
    name: 'ChannelSearch',
    emits: ['search-input', 'hidden-toggled'],
    components: {
        //LoadingSpinner, InfiniteScroll, DMChannelSearchListItem, 
        SearchBar,Checkbox
    },

    data() {
        return {
            placeholderText: "Search Your DM's",
            loading: false,
            initLoading: false,
            currentText: '',
            searchResults: [],
            noMoreSearchResults: false,
            scrollStyle: "width: 100%; max-height: 100%; height: 100%;",
            includeHidden: false,
        }
    },

    watch: {
        includeHidden(newVal, oldVal) {
            if( newVal != oldVal) {
                this.$emit('hidden-toggled',this.includeHidden);
            }
        }
    },

    methods: {
        onLoading() {
            this.searchResults = [];
            this.initLoading = true;
            this.loading = false;
        },

        searchInput(val) {
            this.currentText = val;

            this.$emit('search-input', val);

            //this.fetchDMChannelsFromSearch(val, 20, 0);
        },

       
    }

}
</script>

<style >
#appChatDrawer > div.p-sidebar-content > span > i{
    top:15px;
}
</style>

<style scoped>
.field-checkbox, .field-radiobutton {
    
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
::v-deep(.search-bar) {
    width: 70%;
    height: 30px;
    font-size: 12px;
}


::v-deep(.channel-item) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.channel-item:hover) {
    background: #32364e;
    cursor: pointer;
}
::v-deep(.channel-item:hover .channel-title) {
    color: #FFFFFF;
}

::v-deep(.channel-item .channel-title) {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep(.channel-item .more-options) {
    margin-left: auto;
}
</style>