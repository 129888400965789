<template>
    <Dialog :header="headerLabel" v-model:visible="display" :modal="true" :draggable="false" class="confirmation-modal">
        <div class="body-text" v-html="bodyText">
        </div>

        <template #footer>
            <Button label="OK" class="ok-button green-button__secondary" @click="onClose"/>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';


export default {
    name: 'ConfirmationModal',
    components: {
        // Checkbox,
        Dialog
    },
    props: {
        headerLabel: {
            type: String
        },
        bodyText: {
            type: String
        }
    },

    data() {
        return {
            display: false
        }
    },


    methods: {
        open() {
            this.display = true;
        },

        onClose() {
            this.display = false;
        }
    },

}
</script>

<style>
.confirmation-modal .p-dialog-header {
    justify-content: center;
    padding: 16px 16px 16px 0;
}
.confirmation-modal .p-dialog-header .p-dialog-title {
    margin: 0px auto;    
}

.confirmation-modal .p-dialog-content {
    padding: 16px;
    text-align: center;
}

@media (max-width: 760px) {
    .confirmation-modal {
        width: 100%;
    }

    .confirmation-modal .p-dialog-header {
        border-top-right-radius: 0;
        border-top-left-radius: 0;
    }

    .confirmation-modal .p-dialog-content {
        padding-top: 16px !important;
    }

    .confirmation-modal .p-dialog-footer {
        padding: 16px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi, .material-symbols-outlined),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}
.body-text {
    white-space: pre-wrap;
    font-size: 16px;
    color: black;
}

.ok-button {
    border-radius: 16px;
    font-weight: bold;
}
</style>