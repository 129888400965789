<template>
    <div class="layout-topbar" :class="{'home-page': $route.fullPath == '/home'}" id='top-bar'>
        <div class="topbar-left">
            <a tabindex="0" class="menu-button show-on-desktop" @click="onMenuButtonClick">
                <i class="pi pi-chevron-left"></i>
            </a>
            <ul class="topbar-menu show-on-mobile" v-if="isMobile()">
                    

                    <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }" style="margin-left:0; width: 56px; height: 48px;">
                   
                        <UserAvatar :imageSrc="users.user.analyst.avatar" size='md' customStyle="border: 3px solid #32364e;" @click="onTopbarUserMenuButtonClick"/>
                         <Badge :value="allNotifications()"  v-if="allNotifications()" class="mobile-badge"></Badge>
                        
                        <ul class="profile-menu fade-in-up">
                            <!-- <li >
                                <div class='menu-header'>
                                    <span class='full-name'>{{fullName}}</span>
                                    <span class='username'>@{{users.user.analyst.name}}</span>
                                </div>
                            </li> -->
                            <li class="notification-menu-item">
                                <a href="#" @click="onTopbarNotificationMenuButtonClick">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/bell.png" />
                                        <span>Notifications</span>
                                        <Badge :value="getNumFeedNotifications()"  v-if="getNumFeedNotifications()" class="mobile-notification-badge"></Badge>
                                    </div>
                                </a>
                            </li>
                            <li class="chat-menu-item" v-if="mobileChatEnabled()">
                                <a @click="onChatButtonClick($event)">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/chat.png" />
                                        <span>Chat</span>
                                        <Badge :value="chatNotifications()"  v-if="chatNotifications()" class="mobile-chat-badge"></Badge>
                                        <Badge :value="mentionNotifications()"  v-if="mentionNotifications()" class="mobile-mention-badge"></Badge>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#/profile" @click="userMenuButtonClick()">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/profile.png" />
                                        <span>Profile</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#/profile/settings" @click="userMenuButtonClick()">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/settings.png" />
                                        <span>Settings</span>
                                    </div>
                                </a>
                            </li>
                             <li v-if="hasAdminModRights()">
                                <a href="#" @click="sessionCountClick">
                                    <div class='menuitem-text'>
                                        <i class="pi pi-user"></i>
                                        <span>Session Count</span>
                                    </div>
                                </a>
                            </li>
                             <li>
                                 <a href="#/billing">
                                    <div class='menuitem-text logout-mobile'>
                                        <img src="/assets/images/plans.png" />
                                        <span>Plans & Billing</span>
                                    </div>
                                </a>
                            </li>

                            <!-- <li>
                                <a href="#" >
                                    <div class='menuitem-text' style="opacity:0.5;">
                                        <i class="pi pi-cog"></i>
                                        <span>Settings</span>
                                    </div>
                                </a>
                            </li> -->
                            <li class="support-menu-item">
                                <a href="#" @click="supportClick">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/question.png">
                                        <span>Support</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#"  @click="onLogoutClick">
                                    <div class='menuitem-text logout-mobile'>
                                        <img src="/assets/images/poweroff.png" />
                                        <span>Logout</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>

                
                </ul>

            <span class="topbar-separator"></span>
           
            <div class="layout-breadcrumb viewname" style="text-transform: uppercase;display:flex;" >
                
                <AppSearch ref='appSearch' @click='onSearchClick'/> 
                <div class="right-buttons-container show-on-mobile">
                    <Button  v-if="paperAccountsEnabled()" class="p-button-raised green-button__secondary paper-accounts-trading-item"  @click="onTopbarPaperAccountsTradingButtonClick" v-tooltip.bottom="'Place a Trade Order'">
                        <img src="/assets/images/trade.png" />
                    </Button>
                    <Button class="p-button-raised green-button__secondary investment-calculator-button" @click="onInvestmentCalculatorButtonClick" v-tooltip.bottom="'Investment Calculator'">
                        <img src="/assets/images/calculator.png" />
                    </Button>
                </div>
            </div>

        </div>

        <div class="topbar-bottom-mobile" v-if="isMobile() && $route.fullPath.endsWith('/home')">
            <FeedTypeMenu />
            <!-- <Button :class="{'status-form-action-button': true, 'selected': showNotesOnly}"
                v-tooltip.bottom="'Notes Only'" icon='pi pi-pencil' @click="toggleShowNotesOnly"/> -->
            <div class="playdough-balances-container-mobile" @click="openCashAccounts()">
                <img style="width: 20px;" src="assets/images/playd-icon.png"/>
                <span :class="playdoughBalancesBlinkingActivated ? 'playdough-balances-blink' : ''">Playdough Balance: {{this.formatter.format(cashAccounts[CANADIAN_CURRENCY_TYPE.ID], '$0,000.00')}} CAD | {{this.formatter.format(cashAccounts[US_CURRENCY_TYPE.ID], '$0,000.00')}} USD</span>
            </div>
        </div>

        <div class="topbar-right show-on-desktop" v-if="!isMobile()">
            <div class='user-bar-container'>

                <ul class="topbar-menu">
                    <li class="profile-item">
                        <div @click="openCashAccounts()" class="playdough-balances-container">
                            <img style="width: 30px;" src="assets/images/playd-icon.png"/>
                            <div>
                                <span>Playdough Balance:</span>
                                <div class="playdough-balances" :class="playdoughBalancesBlinkingActivated ? 'playdough-balances-blink' : ''">
                                    <span>{{this.formatter.format(cashAccounts[1], '$0,000.00')}} CAD</span>
                                    |
                                    <span>{{this.formatter.format(cashAccounts[2], '$0,000.00')}} USD</span>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
                
                <ul class="topbar-menu">
                    <li class="profile-item" >
                        <Button class="p-button-raised p-button-rounded green-button__secondary investment-calculator-button" @click="onTopbarCalculatorButtonClick" v-tooltip.bottom="'Investment Calculator'">
                            <img src="/assets/images/calculator.png" />
                        </Button>
                    </li>
                </ul>

                <ul class="topbar-menu">
                    <li class="profile-item paper-accounts-trading-item" v-if="paperAccountsEnabled()">
                       
                        <Button class="p-button-raised p-button-rounded green-button__secondary"  @click="onTopbarPaperAccountsTradingButtonClick" v-tooltip.bottom="'Place a Trade Order'">
                            <img src="/assets/images/trade.png" />
                            <span>&nbsp;Buy/Sell</span>
                        </Button>
                        
                    </li>
                </ul>

                <ul class="topbar-menu">
                    <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarTourMenuActive }" >
                        <div class='tour-button' @click="onTopbarTourMenuButtonClick">
                            <img src="/assets/images/question.png"/>
                        </div>
                        <ul class="tour-menu fade-in-up">
                            <li v-for="(item, i) in tourMenuItems" :key="i">
                                <div @click="item.click($event)">
                                    {{item.text}}
                                </div>
                            </li>
                            <li >
                                <div @click="watchGroupVideo()">
                                    What are Groups?
                                </div>
                            </li>
                             <li >
                                <div @click="watchCreateGroupVideo()">
                                    How do I create a Group?
                                </div>
                            </li>
                             <li >
                                <div @click="watchCreateCourseVideo()">
                                    How do I create a Course?
                                </div>
                            </li>
                            <li >
                                <div @click="watchCourseResourcesVideo()">
                                    How to manage and use Course Resources?
                                </div>
                            </li>
                            <li >
                                <div @click="showWithdrawFundsFAQ()">
                                   Can I withdraw funds?
                                </div>
                            </li>
                            <li >
                                <div @click="showWhatIsPlaydoughFAQ()">
                                    What is Playdough?
                                </div>
                            </li>
                            <li >
                                <div @click="showMarketOrderNotFilledFAQ()">
                                    Why didn't my Pending Market order get filled?
                                </div>
                            </li>
                            <li >
                                <div @click="showLevel2CompDataFAQ()">
                                    What is Level 2 Competition Trading Data?
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                
                 <ul class="topbar-menu">
                    <li class="profile-item" id='notification-bell' :class="{ 'notification-button' : true, 'active-menuitem fadeInDown': topbarNotificationMenuActive }" >
                        <div v-badge.info="getNumFeedNotifications()" v-if="getNumFeedNotifications()>0">
                            <span class="material-symbols-outlined note" id="note1">music_note</span>
                            <span class="material-symbols-outlined note" id="note2">music_note</span>
                            <span class="material-symbols-outlined note" id="note3">music_note</span>
                            <a href="#" @click="onTopbarNotificationMenuButtonClick">
                                <img src="/assets/images/bell.png"  />
                            </a>
                        </div>
                        <div v-else>
                            <span class="material-symbols-outlined note" id="note1">music_note</span>
                            <span class="material-symbols-outlined note" id="note2">music_note</span>
                            <span class="material-symbols-outlined note" id="note3">music_note</span>
                            <a href="#" @click="onTopbarNotificationMenuButtonClick">
                                <img src="/assets/images/bell.png"  />
                            </a>
                        </div>
                        <ul class="notify-menu fade-in-up">
                            <template v-if='notifications.length > 0'>
                                <infinite-scroll id='infiniteScrollingForNotifications' @infinite-scroll="loadMoreNotifications" message="" :noResult="noMoreResults" :style='scrollStyle'>
                                    <li v-for="notification in notifications.filter((n,i) => n.verb !== 'groupPost')" :key="notification">
                                        <div :class="{'notification-row': true, 'unread': !notification.is_read}" @click='clickNotification(notification)'>
                                            <UserAvatar class="avatar" :imageSrc="(notification.activities[0].actor.data ? notification.activities[0].actor.data.avatar : '')" size='sm-md' 
                                                customStyle='width: 48px; height: 48px;min-width: 48px; min-height: 48px;'  v-if="!notification.verb.startsWith('alert') && !notification.verb.startsWith('trade') && !notification.verb.startsWith('motion') && !notification.verb.startsWith('actionReward') && !notification.verb.startsWith('pollReward') && !notification.verb.startsWith('fundsTransferred')&& !notification.verb.startsWith('contestPost')"/>
                                            <div class='notification-user-info' v-if="!notification.verb.startsWith('alert') && !notification.verb.startsWith('trade') && !notification.verb.startsWith('motion') && !notification.verb.startsWith('actionReward') && !notification.verb.startsWith('pollReward') && !notification.verb.startsWith('fundsTransferred')&& !notification.verb.startsWith('contestPost')">
                                                <div class='full-name'>{{(notification.activities[0].actor.data ? stringUtils.fullName(notification.activities[0].actor.data) : '')}}</div>
                                                <div>
                                                    <span class='username'> @{{(notification.activities[0].actor.data ? notification.activities[0].actor.data.name : '')}}</span>
                                                    <span class='time-posted'> | {{getCorrectTime(notification)}}</span>
                                                </div>
                                                <p class='notification-text'>{{templateStringViaVerb(notification)}}</p>
                                                <div class='group-profile-button-container' v-if="notification.verb == 'joinedGroup'">
                                                    <Button class="green-button__primary" @click='goToGroupProfile($event, notification)'>
                                                        {{ notification.activities[0].group.groupTypeId === 1 ? 'Go to Community' : 'Go to Team' }}
                                                    </Button>
                                                </div>
                                            </div>
                                            <div class='follow-button-container' v-if="notification.verb.startsWith('follow')">
                                                <Button :label='followButtonLabel(notification.activities[0].actor)' :class='followButtonClass(notification.activities[0].actor)' @click='toggleFollowing($event, notification.activities[0].actor)' />
                                            </div>
                                             <div class='follow-button-container' v-if="notification.verb.startsWith('groupInvite')">
                                                <Button :label='acceptButtonLabel(notification)' :class="acceptButtonClass(notification)" @click='acceptInvite($event, notification)' />
                                                <Button :label='declineButtonLabel(notification)' :class="declineButtonClass(notification)" @click='declineInvite($event, notification)' />
                                            </div>
                                            <div class='follow-button-container' v-if="notification.verb.startsWith('dmRequest')">
                                                <Button :label='acceptButtonLabel(notification)' :class="acceptButtonClass(notification)" @click='acceptDmInvite($event, notification)' />
                                                <Button :label='declineButtonLabel(notification)' :class="declineButtonClass(notification)" @click='openDeclineDmInviteModal($event, notification)' />
                                            </div>
                                            <div class='follow-button-container' v-if="notification.verb.startsWith('dmAcceptance')">
                                                <Button label='Go to DM' class="accept-button" @click='viewDmChannel($event, notification)' />
                                            </div>
                                            <div class='notification-user-info' v-if="notification.verb.startsWith('alert')">
                                                <div class='symbol'>{{JSON.parse(notification.activities[0].alertNotification.payload).symbol}}</div>
                                                <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                                                <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                                            </div>
                                            <div class='notification-user-info' v-if="notification.verb =='tradeOrderFilled'">
                                                <div class='symbol'>Order Filled</div>
                                                <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                                                <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                                            </div>
                                            <div class='notification-user-info' v-if="notification.verb == 'tradeOrderCancelled'">
                                                <div class='symbol'>Order Cancelled</div>
                                                <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                                                <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                                            </div>
                                            <div class="notification-user-info" v-if="notification.verb.startsWith('motion')">
                                                <img style="width: 20px;" src="assets/images/motion_icon.png"/>
                                                <span class='notification-text' v-if="(notification.verb === 'motionSubmitted' || notification.verb === 'motion')">New Motion Submitted {{ notification.activities[0].motion?.submittedBy ? ` by @${notification.activities[0].motion.submittedBy}` : ''}}</span>
                                                <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                                            </div>
                                            <div class="notification-user-info notification-user-info-row" v-if="notification.verb.startsWith('actionReward')">
                                                <img style="width: 24px;" src="assets/images/reward_icon.png"/>
                                                <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                                            </div>
                                            <div class="notification-user-info notification-user-info-row" v-if="notification.verb.startsWith('pollReward')">
                                                <img style="width: 24px;" src="assets/images/reward_icon.png"/>
                                                <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                                            </div>
                                            <div class="notification-user-info notification-user-info-row funds-transferred-notification" v-if="notification.verb.startsWith('fundsTransferred')">
                                                <img src="assets/images/playd-icon.png"/>
                                                <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                                            </div>
                                            <div class="notification-user-info notification-user-info-row contest-post-notification" v-if="notification.verb.startsWith('contestPost')">
                                                <img style="width: 24px;" src="assets/images/contest.png"/>
                                                <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                                            </div>
                                        </div>
                                    </li>
                                </infinite-scroll>
                            </template>
                        </ul>
                    </li>
                </ul>


                <!-- <i class="pi pi-bookmark" style="opacity: 0.5;" v-tooltip.bottom="{value: 'Coming soon!'}"></i> -->
                <ul class="topbar-menu">
                    

                    <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
                        <UserAvatar :imageSrc="users.user.analyst.avatar" size='sm-md' customStyle="border: 3px solid #32364e;" @click="onTopbarUserMenuButtonClick" />
                        <ul class="profile-menu fade-in-up">
                            <li >
                                <div class='menu-header'>
                                    <span class='full-name'>{{fullName}}</span>
                                    <span class='username'>@{{users.user.analyst.name}}</span>
                                </div>
                            </li>
                            <li>
                                <a href="#/profile" @click="userMenuButtonClick()">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/profile.png" />
                                        <span>Profile</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#/profile/settings" @click="userMenuButtonClick()">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/settings.png" />
                                        <span>Settings</span>
                                    </div>
                                </a>
                            </li>
                            <!-- <li>
                                <a href="#" >
                                    <div class='menuitem-text' style="opacity:0.5;">
                                        <i class="pi pi-cog"></i>
                                        <span>Settings</span>
                                    </div>
                                </a>
                            </li> -->
                            <li v-if="hasAdminModRights()">
                                <a href="#" @click="sessionCountClick">
                                    <div class='menuitem-text'>
                                        <i class="pi pi-user"></i>
                                        <span>Session Count</span>
                                    </div>
                                </a>
                            </li>
                            <li v-if="hasAdminRights()">
                                <a href="#" @click="clearCacheClick">
                                    <div class='menuitem-text'>
                                        <i class="pi pi-trash"></i>
                                        <span>Clear Cache</span>
                                    </div>
                                </a>
                            </li>
                            <li v-if="hasAdminRights()">
                                <a href="#" @click="rescheduleMotionsClick">
                                    <div class='menuitem-text'>
                                        <i class="pi pi-refresh"></i>
                                        <span>Reschedule Motions</span>
                                    </div>
                                </a>
                            </li>
                            <li v-if="hasAdminRights()">
                                <a href="#" @click="rescheduleJobsClick">
                                    <div class='menuitem-text'>
                                        <i class="pi pi-refresh"></i>
                                        <span>Reschedule Jobs</span>
                                    </div>
                                </a>
                            </li>
                            <li v-if="hasAdminRights()">
                                <a @click="depositPlaydoughClick">
                                    <div class='menuitem-text'>
                                        <span>Deposit Playdough</span>
                                    </div>
                                </a>
                            </li>

                            <li v-if="userIsAdvertising()">
                                <a href="#/advertising">
                                    <div class='menuitem-text logout-mobile'>
                                        <span>Advertising</span>
                                    </div>
                                </a>
                            </li>

                            <li v-if="featureSettings.premiumSubscriptionEnabled">
                                <a href="#/billing">
                                    <div class='menuitem-text logout-mobile'>
                                        <img src="/assets/images/plans.png" />
                                        <span>Plans & Billing</span>
                                    </div>
                                </a>
                            </li>

                            <li>
                                <a href="#"  @click="onLogoutClick">
                                    <div class='menuitem-text'>
                                        <img src="/assets/images/poweroff.png" />
                                        <span>Logout</span>
                                    </div>
                                </a>
                            </li>
                        </ul>
                    </li>

                
                </ul>
            </div>
        </div>
    </div>
    <!-- <template v-if="mobileChatEnabled">
    <div v-if="showChat" class="chat-container" v-click-away="onClickAway">
        <Chat />
        <div style="text-align: center;margin-bottom:1rem;"><Button class="p-button-raised p-button-rounded" @click="showChat = false">Close</Button></div>
    </div>
    </template>  -->
    <AppChatDrawer ref='chatDrawer' v-if='mobileChatEnabled'/>

    <!-- <div v-if="showChat" class="chat-container" v-click-away="onClickAway">
        <Chat />
        <div style="text-align: center;margin-bottom:1rem;"><Button class="p-button-raised p-button-rounded" @click="showChat = false">Close</Button></div>
    </div> -->
    <div v-if="showNotification && isMobile() " style="width:100%;height:100%;position:absolute;top:0;right:0;bottom:0;left:0;z-index:999;">
    <ul class="notify-menu p-d-md-none" >
        <li class="close-notify-button"><h4>Notifications</h4> <Button style="margin-left:auto;background-color: #ffff;color: black;border: 0;box-shadow: none;" class="p-button-raised p-button-rounded" icon="pi pi-times" @click="showNotification = false"/></li>
        <template v-if='notifications.length > 0'>
            <infinite-scroll id='infiniteScrollingForNotifications' @infinite-scroll="loadMoreNotifications" message="" :noResult="noMoreResults" :style='scrollStyle'>
                <li v-for="notification in notifications.filter((n,i) => n.verb !== 'groupPost')" :key="notification">
                    <div :class="{'notification-row': true, 'unread': !notification.is_read}" @click='clickNotification(notification)'>
                        <UserAvatar :imageSrc="(notification.activities[0].actor.data ? notification.activities[0].actor.data.avatar : '')" size='sm-md' 
                        customStyle='width: 40px; height: 40px;min-width: 40px; min-height: 40px;' 
                        v-if="!notification.verb.startsWith('alert') && !notification.verb.startsWith('trade') && !notification.verb.startsWith('motion') && !notification.verb.startsWith('actionReward') && !notification.verb.startsWith('pollReward') && !notification.verb.startsWith('fundsTransferred')"/>
                        <div class='notification-user-info'  v-if="!notification.verb.startsWith('alert') && !notification.verb.startsWith('trade') && !notification.verb.startsWith('motion') && !notification.verb.startsWith('actionReward') && !notification.verb.startsWith('pollReward') && !notification.verb.startsWith('fundsTransferred')">
                            <div class='full-name'>{{(notification.activities[0].actor.data? stringUtils.fullName(notification.activities[0].actor.data): '')}}</div>
                            <div>
                            <span class='username'> @{{(notification.activities[0].actor.data? notification.activities[0].actor.data.name : '')}}</span>
                                <span class='time-posted'> | 
                                    {{getCorrectTime(notification)}}
                                </span>
                            </div>
                            <p class='notification-text'>{{templateStringViaVerb(notification)}}</p>
                            <Button class="green-button__primary" @click='goToGroupProfile($event, notification)' v-if="notification.verb == 'joinedGroup'">
                                {{ notification.activities[0].group.groupTypeId === 1 ? 'Go to Community' : 'Go to Team' }}
                            </Button>
                        </div>
                        <div class='follow-button-container' v-if="notification.verb.startsWith('follow')">
                            <Button :label='followButtonLabel(notification.activities[0].actor)' :class='followButtonClass(notification.activities[0].actor)' @click='toggleFollowing($event, notification.activities[0].actor)' />
                        </div>
                        <div class='follow-button-container' v-if="notification.verb.startsWith('groupInvite')">
                            <!-- <Button label='Accept' @click='acceptInvite($event, notification)' /> -->
                            <Button :label='acceptButtonLabel(notification)' :class="acceptButtonClass(notification)" @click='acceptInvite($event, notification)' />
                                                
                            <Button :label='declineButtonLabel(notification)' :class="declineButtonClass(notification)" @click='declineInvite($event, notification)' />
                        </div>
                        <div class='follow-button-container' v-if="notification.verb.startsWith('dmRequest')">
                            <Button :label='acceptButtonLabel(notification)' :class="acceptButtonClass(notification)" @click='acceptDmInvite($event, notification)' />
                            <Button :label='declineButtonLabel(notification)' :class="declineButtonClass(notification)" @click='openDeclineDmInviteModal($event, notification)' />
                        </div>
                        <div class='follow-button-container' v-if="notification.verb.startsWith('dmAcceptance')">
                            <Button label='Go to DM' class="accept-button" @click='viewDmChannel($event, notification)' />
                        </div>
                        <div class='notification-user-info' v-if="notification.verb.startsWith('alert')">
                            <div class='symbol'>{{JSON.parse(notification.activities[0].alertNotification.payload).symbol}}</div>
                            <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                            <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                        </div>
                        <div class='notification-user-info' v-if="notification.verb =='tradeOrderFilled'">
                            <div class='symbol'>Order Filled
                            <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                            </div>
                            <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                        </div>
                        <div class='notification-user-info' v-if="notification.verb == 'tradeOrderCancelled'">
                            <div class='symbol'>Order Cancelled
                            <span class='time-posted'>{{getCorrectTime(notification)}}</span>
                            </div>
                            <span class='notification-text'>{{templateStringViaVerb(notification)}}</span>
                        </div>
                        <div class="notification-user-info" v-if="notification.verb.startsWith('motion')">
                            <img style="width: 20px;" src="assets/images/motion_icon.png"/>
                            <span class="notification-text" v-if="notification.verb === 'motionSubmitted' || notification.verb === 'motion'">New Motion Submitted by @{{ notification.activities[0].motion.submittedBy}}</span>
                            <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                        </div>
                        <div class=" notification-user-info " v-if="notification.verb.startsWith('actionReward')">
                            <img style="width: 24px;" src="assets/images/reward_icon.png"/>
                            <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                        </div>
                        <div class="notification-user-info " v-if="notification.verb.startsWith('pollReward')">
                            <img style="width: 24px;" src="assets/images/reward_icon.png"/>
                            <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                        </div>
                        <div class="notification-user-info " v-if="notification.verb.startsWith('fundsTransferred')">
                            <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                        </div>
                        <div class="notification-user-info " v-if="notification.verb.startsWith('contestPost')">
                           
                            <span class="notification-text">{{ templateStringViaVerb(notification) }}</span>
                        </div>
                    </div>
                </li>
                
            </infinite-scroll>
        </template>
    </ul>
    </div>

    <DepositPlaydoughModal ref="depositPlaydoughModal" :isAdmin="hasAdminRights()" />
    <SupportTicketModal ref="supportTicketModal"/>
    <DeclineDmInviteModal ref='declineDmInviteModal'/>
    <ViewMediaModal ref="videoPlayer"/>
    <Dialog id="faqDialog" v-model:visible="showFaq"  :header="faqQuestion"  :position="'topright'">
    
    
        <div class="faq-content" v-html="faqAnswer">
        
        </div>
        <template #footer>
            </template>
    
    </Dialog>
</template>

<script>
import { defineAsyncComponent } from 'vue';
//import InfiniteScroll from './components/common/scroll/infinite-scroll-vue3';
import EventBus from './event-bus';
import FeedService from './service/FeedService';
import UserService from './service/UserService';
import ChatService from './service/ChatService';

import StringUtils from './utilities/StringUtils';
import PostUtils from './utilities/PostUtils';
import TimeUtils from './utilities/TimeUtils';
import AppChatDrawer from './AppChatDrawer.vue';

import AppSearch from './AppSearch';
import UserAvatar from './components/profile/UserAvatar';
import FeedTypeMenu from './components/home/FeedTypeMenu';
//import DeclineDmInviteModal from './components/modal/DeclineDmInviteModal.vue';
//import DepositPlaydoughModal from './components/modal/DepositPlaydoughModal.vue';

import { mapState } from 'vuex';
import BrowserUtils from './utilities/BrowserUtils';
//import SupportTicketModal from './components/modal/SupportTicketModal';
import Badge from 'primevue/badge';
import FormattingUtils from './utilities/FormattingUtils';
import GroupService from './service/GroupService';
import ResearchService from './service/ResearchService';
import TourService from './service/TourService';
import UserUtils from './utilities/UserUtils';
import {ALERT_TYPES_WITH_VALUES, ALERT_PRICE_TYPES, TOUR_ROUTE_MAP, HOME_TOUR_NAME, EQUITY_OVERVIEW_TOUR_NAME, FUND_OVERVIEW_TOUR_NAME, PORTFOLIO_TOUR_NAME, ACTIONS_ID, YESNO_POLL, CANADIAN_CURRENCY_TYPE, US_CURRENCY_TYPE,
PAPER_CASH_ACCOUNT_TYPE, USER_TYPE_GROUP_ID } from './common/constants';
import MotionService from './service/MotionService';
import AdminService from './service/AdminService';
import PortfolioService from './service/PortfolioService';
import CookieUtils from './utilities/CookieUtils';

import Dialog from 'primevue/dialog';

const CASH_ACCOUNTS_COOKIE_NAME = "cash.accounts";

const withdrawFAQ = "<b>No</b>.  There is no real cash on the INVRS platform.  All cash balances etc are in <b>Playdough</b>, our in-app currency.  Playdough has <b>NO VALUE</b> outside of the application.";
const playdoughFAQ = "<b>Playdough</b> is an in-app currency used to place trades within the application and can also be used to purchase Investor Passes.<br><br> "+
" You can earn Playdough by performing certain tasks on the app and of course by making good investment decisions.<br><br>Note that Playdough has <b>NO VALUE</b> outside of the application and cannot be withdrawn from the platform.";

export default {
    name: "AppTopbar",
    emits: ["menu-button-click", "search-click", "topbar-notification", "topbar-user-menu", "right-menubutton-click", 
    "logout-click", "tour-button-click", "paper-accounts-trading-button-click", "calculator-button-click"],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean,
        topbarTourMenuActive: Boolean
    },
    components: {
        DepositPlaydoughModal: defineAsyncComponent(() =>
            import('./components/modal/DepositPlaydoughModal.vue')
            ),
        AppChatDrawer,
        AppSearch,
        InfiniteScroll: defineAsyncComponent(() =>
        import('./components/common/scroll/infinite-scroll-vue3')
        ),
        UserAvatar,
        SupportTicketModal: defineAsyncComponent(() =>
            import('./components/modal/SupportTicketModal')
            ),
        Badge,
        DeclineDmInviteModal: defineAsyncComponent(() =>
            import('./components/modal/DeclineDmInviteModal.vue')
            ),
        FeedTypeMenu,
        ViewMediaModal: defineAsyncComponent(() =>
            import('./components/modal/ViewMediaModal.vue')
            ),
        Dialog,
    },

    data() {
        return {
            //CONSTANTS
            ALERT_TYPES_WITH_VALUES,
            CANADIAN_CURRENCY_TYPE, 
            US_CURRENCY_TYPE,

            formatter: new FormattingUtils(),

            playdoughBalancesBlinkingActivated: false,
            cashAccounts: [],
            items: [],
            notifications: [],
            showChat: false,
            showNotification: false,
            loading: false,
            postUtils: null,
            stringUtils: null,
            noMoreResults: false,
            
            tourService: null,
            tour: null,
            loggingOut: false,
            showFaq: false,
            faqQuestion: null,
            faqAnswer: null,
            
        };
    },

    created() {
        this.postUtils = PostUtils;
        this.timeUtils = TimeUtils;
        this.stringUtils = StringUtils;

        

        console.log("AppTopbar created");

        

        EventBus.off('refresh-cash-accounts-balance');
        EventBus.on('refresh-cash-accounts-balance', this.refreshCashAccountsBalance);
    },
    computed: {
        ...mapState(['users','feedNotificationCounts','featureSettings', 'showNotesOnly' ,'selectedGroupConduit']),


        scrollStyle() {
           
            if( this.isMobile() ){
                return 'width: 100%; height: calc(100vh - 50px);';
            }
            else {
                return 'width: 100%; max-height: 350px;';
            }

        },

        fullName() {
            const user = this.users.user;
            let full = user.analyst.firstName;
           
            if( user.analyst.lastName) {
                full += " "+user.analyst.lastName;
            }
            if( full ) {
                return full;
            }
            else {
                return user.analyst.name
            }
            // return `${user.analyst.firstName} ${user.analyst.lastName}`;
        },

        tourMenuItems() {
            let items = [{
                text: 'Support',
                click: this.supportClick
            }];

            const clickFunc = () => {
                this.startTour(TOUR_ROUTE_MAP[this.$route.path]);
            }

            if (TOUR_ROUTE_MAP[this.$route.path] === HOME_TOUR_NAME) {
                items.push(
                    {
                        text: 'Take the Home Overview Tour',
                        click: clickFunc
                    }
                )
            } else if (TOUR_ROUTE_MAP[this.$route.path] === FUND_OVERVIEW_TOUR_NAME) {
                items.push(
                    {
                        text: 'Take the Fund Overview Tour',
                        click: clickFunc
                    }
                )
            } else if (TOUR_ROUTE_MAP[this.$route.path] === EQUITY_OVERVIEW_TOUR_NAME) {
                items.push(
                    {
                        text: 'Take the Equity Overview Tour',
                        click: clickFunc
                    }
                )
            } else if (this.paperAccountsEnabled() && TOUR_ROUTE_MAP[this.$route.path] === PORTFOLIO_TOUR_NAME) {
                items.push(
                    {
                        text: 'Take the Portfolio Overview Tour',
                        click: clickFunc
                    }
                )
            }

            return items;
        },

        onGroupHome() {
            return this.$route.fullPath.startsWith('/groups');
        }
    },

    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },

    mounted() {
        console.log("mounting top bar and loggingOUt = "+this.loggingOut)
        if( !this.loggingOut ){
            this.refreshCashAccountsBalance();
        }
    },


    watch: {
        topbarNotificationMenuActive(newVal, oldVal) { //eslint-disable-line
            if( !newVal ){
                EventBus.emit("feed-notifications-seen");
            } else {
                this.notifications = [];

                this.fetchNotifications();
            }
           
        }
    },
    methods: {

    showWithdrawFundsFAQ() {
        
        this.faqQuestion = 'Can I withdraw funds?';
        this.faqAnswer = withdrawFAQ;
        this.showFaq  = true;
        //this.$toast.add({severity:'info', summary: 'Can I withdraw funds?', detail: withdrawFAQ,   group: 'tr'});
    },

    showWhatIsPlaydoughFAQ() {
         this.faqQuestion = 'What is Playdough?';
        this.faqAnswer = playdoughFAQ;
        this.showFaq  = true;
        //this.$toast.add({severity:'info', summary: 'What is Playdough?', detail:playdoughFAQ,  group: 'tr'});
                
    },

    showLevel2CompDataFAQ() {
         this.faqQuestion = 'What is Level 2 Competition Trading Data?';
        this.faqAnswer = 
        `Level 2 Competition Trading data includes all the competition orders that are currently pending as well as historical orders.
        This is meant to mimic the real world where investors can pay for access to Level 2 trading data which includes similar information.
        Note that just as in the real world, no information about who is making the trades is included.
        `;
        this.showFaq  = true;
    },

    showMarketOrderNotFilledFAQ() {
         this.faqQuestion = 'Pending Market Order Not Filled?';
        this.faqAnswer = 
        `Market orders that are pending may not always get filled.<br><br>
        This can happen when the equity or fund is thinly traded and has no current trading activity (usually on an OTC exchange).<br><br>
        In this case we cannot fill the order until there is some trading activity.
        `;
        this.showFaq  = true;
        //this.$toast.add({severity:'info', summary: 'What is Playdough?', detail:playdoughFAQ,  group: 'tr'});
                
    },
       
    watchGroupVideo(){
        this.$refs.videoPlayer.open('video/mp4', 'https://docs.invrs.com/public/groups.mp4');
    },

    watchCreateGroupVideo(){
        this.$refs.videoPlayer.open('video/mp4', 'https://docs.invrs.com/public/createGroupFinal-comp.mp4', 'https://docs.invrs.com/public/creategroupthumb.png');
    },

    watchCreateCourseVideo(){
        this.$refs.videoPlayer.open('video/mp4', 'https://docs.invrs.com/public/createcoursefinal-comp.mp4', 'https://docs.invrs.com/public/createcoursethumb.png');
    },
     watchCourseResourcesVideo(){
        this.$refs.videoPlayer.open('video/mp4', 'https://docs.invrs.com/public/courseresources.mp4', 'https://docs.invrs.com/public/couresresources-thumb.png');
    },



        allNotifications(){
            let count = (this.getChatNotificationCount() | 0) + (this.getMentionNotificationCount() | 0) + (this.getNumFeedNotifications() | 0);
           if( count == 0 ) {
                return null;
            }
            else if( count > 10) {
                return "10+";
            }
            else {
                return count;
            }
        },

        getNumFeedNotifications(){
            if( this.feedNotificationCounts){
                return this.feedNotificationCounts.unseen.numLikes 
                    + this.feedNotificationCounts.unseen.numComments
                    + this.feedNotificationCounts.unseen.numReposts
                    + this.feedNotificationCounts.unseen.numMentions
                    + this.feedNotificationCounts.unseen.numFollows 
                    + this.feedNotificationCounts.unseen.numGroupInvites
                    + this.feedNotificationCounts.unseen.numDmRequests 
                    + this.feedNotificationCounts.unseen.numDmAcceptances
                    + this.feedNotificationCounts.unseen.numGroupJoins
                    + this.feedNotificationCounts.unseen.numAlerts
                    + this.feedNotificationCounts.unseen.numTrades
                    + this.feedNotificationCounts.unseen.numJoinedGroup
                    + this.feedNotificationCounts.unseen.numMotionsSubmitted
                    + this.feedNotificationCounts.unseen.numMotionsPassed
                    + this.feedNotificationCounts.unseen.numMotionsFailed
                    + this.feedNotificationCounts.unseen.numMotionsWithdrawn
                    + this.feedNotificationCounts.unseen.numMotionsVetoed
                    + this.feedNotificationCounts.unseen.numActionRewards
                    + this.feedNotificationCounts.unseen.numPollRewards
                    + this.feedNotificationCounts.unseen.numFundsTransferred
                    + this.feedNotificationCounts.unseen.numContestPosts;
            }
            else {
                return 0;
            }
        },

        chatNotifications() {
            let count = this.getChatNotificationCount();
            if( count == 0 ) {
                return null;
            }
            else if( count > 10) {
                return "10+";
            }
            else {
                return count;
            }
           
        },

        mentionNotifications() {
            let count = this.getMentionNotificationCount();
            if( count == 0 ) {
                return null;
            }
            else if( count > 10) {
                return "10+";
            }
            else {
                return count;
            }
           
        },

        toggleShowNotesOnly() {
            this.$store.commit("SET_SHOW_NOTES_ONLY", !this.showNotesOnly);
            EventBus.emit('update-show-notes-only-pref');
        },

        paperAccountsEnabled() {
            return UserUtils.isFeatureEnabled('paperAccountsEnabled');
        },
        hasAdminModRights() {
                return UserUtils.userIsAdmin() || UserUtils.userIsFeedModerator() || UserUtils.userIsChatModerator();
        },

         hasAdminRights() {
                return UserUtils.userIsAdmin() ;
        },

        userIsAdvertising() {
            return UserUtils.userIsAdvertising() ;
        },
        
        clearCacheClick() {
            ResearchService.clearCache().then( resp => {
                if( resp.data.status == 'success'){
                    this.$toast.add({severity:'info', summary: 'Cache Cleared '+ resp.data.cacheCount + ' entries.', detail:'',  group: 'tc'});
                }
                else {
                    console.log("Error clearching cache: "+ resp.data.message);
                }
            });
        },

        openCashAccounts() {
            this.refreshCashAccountsBalance();// just in case we're stale
            this.$router.push('/portfolios/cash')
        },

        getCashAccountsFromCookies(){
            const cashAccountsCookieStr = CookieUtils.getCookie(CASH_ACCOUNTS_COOKIE_NAME);
            if(cashAccountsCookieStr){
                return JSON.parse(cashAccountsCookieStr);
            } else {
                return [];
            }
        },

        saveCashAccountsToCookies(cashAccounts){
            CookieUtils.setCookie(CASH_ACCOUNTS_COOKIE_NAME, JSON.stringify(cashAccounts));
        },

        refreshCashAccountsBalance() {
            PortfolioService.getCashAccounts().then(resp => {
                if (resp.data.status == 'success') {
                    const cashAccountsCookie = this.getCashAccountsFromCookies();
                    let balanceIncreaseDetected = false;

                    resp.data.cashAccounts.forEach(acc => {
                        this.cashAccounts[acc.currencyId] = acc.totalValue;

                        if(!balanceIncreaseDetected && this.cashAccounts[acc.currencyId] > cashAccountsCookie[acc.currencyId]){
                            balanceIncreaseDetected = true;
                        }
                    });

                    if(!this.playdoughBalancesBlinkingActivated && balanceIncreaseDetected) {
                        // console.log("refreshCashAccountsBalance2 blink");
                        this.playdoughBalancesBlinkingActivated = true;
                        setTimeout(() => {
                            this.playdoughBalancesBlinkingActivated = false;
                        }, 5000);
                    }

                    this.saveCashAccountsToCookies(this.cashAccounts);
                    // console.log("refreshCashAccountsBalance3 cashAccounts",this.cashAccounts);
                }
                else {
                    console.log("error getting cash accounts " + resp.data.message);
                }
            });
        },

        rescheduleMotionsClick() {
            MotionService.rescheduleMotions().then(resp => {
               
                if( resp.data.status == 'success') {
                    this.$toast.add({severity:'info', summary: 'Successfully rescheduled ' + resp.data.total+' Motions.', detail:'',  group: 'tc'});
                }
                else {
                    console.log("Error rescheduling motions: "+ resp.data.message);
                }
            })
        },

        rescheduleJobsClick() {
            AdminService.rescheduleJobs().then(resp => {
               
                if( resp.data.status == 'success') {
                    this.$toast.add({severity:'info', summary: 'Successfully rescheduled Jobs.', detail:'',  group: 'tc'});
                }
                else {
                    console.log("Error rescheduling jobs: "+ resp.data.message);
                }
            })
        },

        sessionCountClick() {
            UserService.getSessionCount().then(resp => {
                if( resp.data.status == 'success'){
                    this.$toast.add({severity:'info', summary: 'There is currently aprox. ' + resp.data.count+" active sessions.", detail:'',  group: 'tc'});
                }
                else {
                    console.log("Error getting session count: "+ resp.data.message);
                }
            });
        },

        depositPlaydoughClick() {
            this.$refs.depositPlaydoughModal.open();
        },

        getCorrectTime(notification) {
            if (notification.verb === 'repost') {
                return TimeUtils.getTimeFromNow(notification.created_at);
            } else {
                return TimeUtils.getTimeFromNow(notification.activities[0].time);
            }
        },

        getChatNotificationCount() {
            if (this.$store.state.chatNotificationCounts) {
                if (this.$store.state.chatNotificationCounts.numUnread > 0) {
                   
                    return this.$store.state.chatNotificationCounts.numUnread;
                    
               
                }
            } else {
                return 0;
            }
        },

        getMentionNotificationCount() {
            if (this.$store.state.chatNotificationCounts) {
                if (this.$store.state.chatNotificationCounts.numMentions > 0) {
                    return this.$store.state.chatNotificationCounts.numMentions;
                
                }
            } else {
                return 0;
            }
        },



        supportClick(e) {
            e.stopPropagation();
            e.preventDefault();
           
            this.$refs.supportTicketModal.open();
        },

        isMobile() {
            return BrowserUtils.isMobile();
        },

        mobileChatEnabled() {
            return ChatService.isChatEnabled() && this.isMobile();
        
        },
        onClickAway() {
            this.showNotification =false;
            this.showChat = false;
        },
        onChatButtonClick() {
            // this.showChat = !this.showChat;
            this.$refs.chatDrawer.open();
            this.$store.commit('CLEAR_CHAT_COUNTS');
        },
        onInvestmentCalculatorButtonClick() {
            EventBus.emit('open-investment-calculator-modal');
        },

        followButtonLabel(analyst) {
            return this.postUtils.alreadyFollowsUser(analyst.id) ? 'Following' : 'Follow';
        },
        followButtonClass(analyst) {
			return {
                'follow-button': true,
				'following': this.postUtils.alreadyFollowsUser(analyst.id)
			}
		},

        viewDmChannel(e, notification) {
            e.stopPropagation();
            this.showNotification = false;
            
            if(!notification.is_read){
                this.$store.commit('MARK_CHANNELS_STALE_DM');
            }

            //console.log("notification.activities[0]",JSON.stringify(notification.activities[0]));
            ChatService.getDMChannelIfExists(notification.activities[0].actor.id).then((resp) => {
                if (resp.status == 'success' && resp.channels) {
                  //console.log("getDMChannelIfExists", JSON.stringify(notification.activities[0].actor));
                   let channel = resp.channels[0];
                   channel["type"] = "privateMessaging";
                   channel["channelTitle"] = "@"+notification.activities[0].actor.data.name;
                   this.EventBus.emit('open-dm-channel', channel);
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Unexpected error during opening chat', detail: '', life: 3000, group: 'tc' });
                }
            });
            FeedService.markNotificationRead(notification.id);
        },

        goToGroupProfile(e, notification) {
            //e.stopPropagation();
            this.showNotification = false;

            this.$router.push('/groups/profile/' + notification.activities[0].group.groupId);
            FeedService.markNotificationRead(notification.id);
        },

        acceptButtonLabel(notification) {
            if( notification.activities[0].accepted) {
                return "Accepted";
            }
            else {
                return "Accept";
            }
        },

        acceptButtonClass(notification) {
            if( notification.activities[0].declined) {
                return "hidden-accept-button";
            }
            else if( notification.activities[0].accepted) {
                return "accepted-button";
            }
            else {
                return "accept-button";
            }
        },

        declineButtonLabel(notification) {
            
            if( notification.activities[0].declined) {
                return "Declined";
            }
            else {
                return "Decline";
            }
        },

        declineButtonClass(notification) {
            if( notification.activities[0].accepted) {
                return "hidden-decline-button";
            }
            else if( notification.activities[0].declined ) {
                return "declined-button"
            }
            else {
                return "decline-button";
            }
        },

        toggleFollowing(e, analyst) {
            e.stopPropagation();
            
            if (!this.postUtils.alreadyFollowsUser(analyst.id)) {
                UserService.getAnalystByUsername(analyst.data.name).then((resp)=>{
                    if (resp.data.status == 'success') {
                        this.follow(resp.data.analyst);
                    }
                });
			}
			else {
				this.unfollow(analyst);
			}
        },

        acceptInvite(e, notification) {
            e.stopPropagation();
            
            let groupInvite = notification.activities[0].groupInvite;
            //console.log("accepting invite for groupInvite ", groupInvite);
            GroupService.acceptGroupInvite(groupInvite.groupInviteId).then(resp=> {
                if( resp.data.status === "registration-required") {
                    notification.activities[0].accepted = true;
                    this.$router.push( '/groups/profile/'+resp.data.group.groupId+'?joinGroupByInvite=true&inviteCode='+groupInvite.inviteCode);
                }
                else if( resp.data.status === "success") {
                    notification.activities[0].accepted = true;
                    this.$toast.add({severity:'success', summary: 'You have successfully joined '+groupInvite.groupName, detail:'', life: 3000, group: 'tc'});
                    this.$store.commit('MARK_CHANNELS_STALE');
                    EventBus.emit('refresh-group-list');
                    EventBus.emit('refresh-app-group-menu');
                }
                else {
                    console.log("Error accepting invite."+ resp.data.message);

                    if(resp.data.message && resp.data.message.startsWith("Membership Full.")){
                        this.$toast.add({severity:'info', summary: "Oops! This group's membership is currently full.", detail:'', life: 4000, group: 'center'});
                    }
                }
            });
            FeedService.markNotificationRead(notification.id);
        },

        declineInvite(e, notification) {
            e.stopPropagation();
            
            let groupInvite = notification.activities[0].groupInvite;
            //console.log("declining invite for groupInvite "+ groupInvite.groupInviteId);
            GroupService.declineGroupInvite(groupInvite.groupInviteId).then(resp=> {
                if( resp.data.status === "success") {
                    notification.activities[0].declined = true;
                   
                }
                // else {
                //     console.log("Error declining invite."+ resp.data.message);
                // }
            });
            FeedService.markNotificationRead(notification.id);
        },

        acceptDmInvite(e, notification) {
            e.stopPropagation();
            
            let dmRequest = notification.activities[0].request;
            // console.log("accepting invite for dm", dmRequest);
            ChatService.acceptOrRejectDMRequest(dmRequest.dmRequestId, true).then(resp=> {
                if( resp.data.status === "success") {
                    notification.activities[0].accepted = true;
                    this.$toast.add({severity:'success', summary: 'You have successfully joined ', detail:'', life: 3000, group: 'tc'});
                    this.$store.commit('MARK_CHANNELS_STALE_DM');
                }
                else {
                    console.log("Error accepting invite."+ resp.data.message);
                                        
                    if(resp.data.message.includes("expired")){
                        this.$toast.add({severity:'info', summary: 'Invite request has expired', detail:'', life: 3000, group: 'tc'});
                        
                        ChatService.acceptOrRejectDMRequest(dmRequest.dmRequestId, false).then(resp=> {
                            if( resp.data.status === "success") {
                                notification.activities[0].declined = true;
                                //this.$toast.add({severity:'success', summary: 'Declined invite', detail:'', life: 2000, group: 'tc'});
                            }
                        });
                    }
                }
            });
            FeedService.markNotificationRead(notification.id);
        },

        openDeclineDmInviteModal(e, notification) {
            e.stopPropagation();

            let dmRequest = notification.activities[0].request;

            const declineDMRequest = () => {
                ChatService.acceptOrRejectDMRequest(dmRequest.dmRequestId, false).then(resp=> {
                    if( resp.data.status === "success") {
                        notification.activities[0].declined = true;
                        //this.$toast.add({severity:'success', summary: 'Declined invite', detail:'', life: 2000, group: 'tc'});
                    }
                    else {
                        console.log("Error declining invite."+ resp.data.message);
                        this.$toast.add({severity:'error', summary: 'Error declining invite', detail:'', life: 2000, group: 'tc'});
                    }
                });
            };
            const blockUserRequest = () => {
                ChatService.blockUser(dmRequest.requesterId).then(resp=> {
                    if( resp.data.status === "success") {
                        this.$toast.add({severity:'success', summary: 'The user is blocked', detail:'', life: 2000, group: 'tc'});
                    }
                    else {
                        console.log("Error blocking user."+ resp.data.message);
                        this.$toast.add({severity:'error', summary: 'Error blocking the user', detail:'', life: 2000, group: 'tc'});
                    }
                });
            };
            
            this.$refs.declineDmInviteModal.open(declineDMRequest, blockUserRequest);

            FeedService.markNotificationRead(notification.id);
        },

        unfollow(analyst) {
            const username = analyst.data.name;

			FeedService.unfollowUser(username).then((resp) => {
				if( resp.data.status == 'success' ) {
					this.$toast.add({severity:'success', summary: 'You are no longer following '+username, detail:'', life: 2000, group: 'tc'});
					delete this.users.user.analyst.followed.followedAnalysts[analyst.id];
				}

			});
		},
		follow(analyst){
            const username = analyst.name;

			FeedService.followUser(username).then((resp) => {
				if( resp.data.status == 'success' ) {
					this.$toast.add({severity:'success', summary: 'You are now following '+username, detail:'', life: 2000, group: 'tc'});
					this.users.user.analyst.followed.followedAnalysts[analyst.analystId] = analyst;
                }
			})
		},

        templateStringViaVerb(notification) {
            const noteTitle = notification.activities[0].object.title || notification.activities[0].note_title;
            const verb = notification.verb;

            if (verb === 'follow') {
                return 'Followed You';
            } else if (verb === 'mention') {
                return notification.activities[0].src_reaction_id ? 'Mentioned you in a comment' : noteTitle ? `Mentioned you in a note "${noteTitle}"` : 'Mentioned you in a post';
            } else if (verb === 'comment') {
                if (notification.activities[0].reaction.data.related_feed_comment_id && notification.activities[0].reaction.data.related_reaction_id) {
                    return noteTitle ? `Replied to your comment on note "${noteTitle}"` : 'Replied to your comment on a post';
                } else {
                    return noteTitle ? `Commented on your note "${noteTitle}"` : 'Commented on your post';
                }
            } else if (verb === 'like') {
                return noteTitle ? `Liked your note "${noteTitle}"` : 'Liked your post';
            } else if (verb === 'repost') {
                return noteTitle? `Reposted your note "${noteTitle}"` : 'Reposted your post'
            } else if( verb === 'groupInvite'){
                let groupInvite = notification.activities[0].groupInvite;
                //console.log(JSON.stringify(groupInvite));
                if( groupInvite ) {
                    return "@"+groupInvite.inviterName + ' has invited you to join '+groupInvite.groupName;
                }
                else {
                    "You have been invited to a group"
                }
            
            } else if( verb === 'groupJoinAcceptance'){
                let group = notification.activities[0].group;
                return 'Welcome!  You are now a member of '+group.name;
                
            } else if( verb === 'dmRequest') {
                return 'Is inviting you to chat' + (notification.activities[0].request.message ? '\nMessage: '+ notification.activities[0].request.message:'');
            } else if( verb === 'dmAcceptance') {
                return ('Has accepted your invite to chat');
            } else if (verb === 'alert') {
                let text = '';
                const alert = notification.activities[0];
                const payload = JSON.parse(alert.alertNotification.payload);
                const type = alert.alertType;//Number(payload.alertType);

                //if (ALERT_TYPES_WITH_VALUES.includes(type)) {
                    let comparatorString = '';

                    if (type === "Price Change" || type === "Percent Change") {
                        comparatorString = Number(payload.comparator) === 1 ? 'increased' : 'decreased';

                        if (type === "Price Change") {
                            text = `Price ${comparatorString} by ${this.formatter.format(payload.userValue, '$0.00')}`;
                        } else {
                            text = `Price Percent ${comparatorString} by ${this.formatter.format(payload.userValue, '0.00')}`;
                        }
                    } else if (type === "Volume" || type === "Price" || type === "Bid Price" || type === "Ask Price") {
                        comparatorString = Number(payload.comparator) === 1 ? 'greater' : 'less';

                        if (type === "Volume") {
                            text = `Volume was ${comparatorString} than ${this.formatter.format(payload.userValue, '0.00')}`;
                        } else if (type === "Price") {
                            text = `Price quote was ${comparatorString} than ${this.formatter.format(payload.userValue, '$0.00')}`;
                        } else if (type === "Bid Price") {
                            text = `Bid price was ${comparatorString} than ${this.formatter.format(payload.userValue, '$0.00')}`;
                        } else {
                            text = `Ask price was ${comparatorString} than ${this.formatter.format(payload.userValue, '$0.00')}`;
                        }
                    }
                    else {
                         text = type;
                    }
                
                //} else {
                //    text = alert.alertType;
                //}
               
                return text;
            }
            else if (verb === 'tradeOrderCancelled') {//eslint-disable-line
              let tradeOrder = notification.activities[0].tradeOrder;
                let text = "Your order to "+(tradeOrder.tradeTypeId == 1 ? 'Buy' : 'Sell')+" "+tradeOrder.quantity+" unit(s) of "
                    + (tradeOrder.securityListing ? tradeOrder.securityListing.symbol : tradeOrder.fund.symbol)+ " has been cancelled"+(tradeOrder.statusNote ? tradeOrder.statusNote+'.' : '.');
               
                return text;
            }else if (verb === 'tradeOrderFilled'){
                let tradeOrder = notification.activities[0].tradeOrder;
                let text = "Your order to "+(tradeOrder.tradeTypeId == 1 ? 'Buy' : 'Sell')+" "+tradeOrder.quantity+" unit(s) of "
                    + (tradeOrder.securityListing ? tradeOrder.securityListing.symbol : tradeOrder.fund.symbol)+ " has been filled at "+ this.formatter.format(tradeOrder.executedPrice, '$0.00')+" per unit.";
               
                return text;
            } else if (verb === 'joinedGroup') {
                const group = notification.activities[0].group;
                const groupType = group.groupTypeId === 1 ? 'Community' : 'Team';

                const text = `You have a new ${groupType} member.  @${notification.activities[0].actor.data.name} has joined ${group.name}`
            
                return text;
            } else if (verb.startsWith('motion')) {
                const motion = notification.activities[0].motion;

                const baseText = motion.title;

                const MOTION_TEXT_MAP = {
                    'motionWithdrawn': `${baseText} was withdrawn.`,
                    'motionVetoed': `${baseText} was vetoed.`,
                    'motionFailed': `${baseText} was not passed.`,
                    'motionPassed': `${baseText} Passed!`,
                    'motionSubmitted': baseText,
                    'motion': baseText
                }

                return MOTION_TEXT_MAP[verb];
            } else if (verb.startsWith('actionReward')) {
                const actionRewardHistory = notification.activities[0].actionRewardHistory;
                const actionId = JSON.parse(notification.activities[0].actor).actionId;

                const baseText = 'You have received a $' + actionRewardHistory.rewardAmount + ' Playdough reward for ';

                const ACTIONS_TEXT_MAP = {
                    [ACTIONS_ID.submitMotion]: baseText + 'submitting a motion.',
                    [ACTIONS_ID.voteOnMotion]: baseText + 'voting on a motion.',
                    [ACTIONS_ID.motionPassed]: baseText + 'a motion passing.',
                    [ACTIONS_ID.createYesNoQuestion]: baseText + 'creating a poll.',
                    [ACTIONS_ID.answerYesNoQuestion]: baseText + 'answering a poll.',
                    [ACTIONS_ID.createPoll]: baseText + 'creating a poll.',
                    [ACTIONS_ID.answerPoll]: baseText + 'answering a poll.',
                    [ACTIONS_ID.createGeneralQuestion]: baseText + 'asking a question.',
                    [ACTIONS_ID.answerGeneralQuestion]: baseText + 'answering a question.',
                    [ACTIONS_ID.answerLiked]: baseText + 'a helpful answer.'
                }

                return ACTIONS_TEXT_MAP[actionId];
            } else if (verb.startsWith('pollReward')) {
                const poll = notification.activities[0].poll;

                const baseText = 'You have received a $' + poll.correctAnswerReward + ' Playdough reward for answering the ';

                return baseText + (poll.pollTypeId == YESNO_POLL.pollTypeId ? 'question correctly.' : 'poll correctly.');
            } else if (verb.startsWith('fundsTransferred')) {
                const activity = notification.activities[0];
                const transaction = activity.transaction;
                let baseText = "";

                if (activity.sender) {
                    baseText = `${activity.sender.analyst.name} has transferred ${this.formatter.format(transaction.amount, '$0.00')} ${transaction.currencyCode} Playdough to`
                    
                    if (activity.sender.userTypeId === USER_TYPE_GROUP_ID) {
                        baseText = 'Group ' + baseText;
                    } else {
                        baseText = 'User @' + baseText;
                    }
                    
                } else {
                    baseText = `${this.formatter.format(transaction.amount, '$0.00')} ${transaction.currencyCode} Playdough was deposited to`
                }

                if (activity.group) {
                    return `${baseText} ${activity.group.name}(${activity?.destAccount?.name})`;
                } else {
                    return `${baseText} your ${activity?.destAccount?.name}`;
                }
            }
            else if( verb.startsWith('contestPost')){
                const activity = notification.activities[0];
                //console.log("activity for contest post notification: ", activity);
                const feedPost = activity.feedPost;
                const contest = feedPost.contest;
                return contest.name+' has a new announcement.';

            }
            
        },

        async fetchNotifications(id = null) {
            await FeedService.getCurrentNotifications(id).then((resp) => {
                this.loading = false;


                if (resp.length > 0) {
                    const filteredResults = resp.filter((r) => {
                        if (!r.verb.startsWith('motion') && !r.verb.startsWith('fundsTransferred')) {
                            return !this.postUtils.isOwnPost(r.activities[0].actor.id);
                        } else {
                            return true;
                        }
                    })

                    const motionPassedResults = filteredResults.filter((r => r.verb === 'motionPassed'));

                    if (motionPassedResults.length > 0 && !motionPassedResults[0].is_seen) {
                        EventBus.emit('motion-passed', motionPassedResults[0].activities[0].motion);
                    }

                    const results = this.notifications.concat(filteredResults);
                   //console.log("fetchNotifications",results);

                    this.notifications = results;
                    this.noMoreResults = false;
                } else {
                    this.noMoreResults = true;
                }

                
                // console.log(this.notifications);
            });
        },
        clickNotification(notification) {
            FeedService.markNotificationRead(notification.id);

            if (notification.verb.startsWith('follow')) {
                this.$router.push('/analysts/profile/' + notification.activities[0].actor.data.name);
            
            }else if( notification.verb.startsWith('groupInvite')){
                    // todo?
                this.$router.push('/groups');
            } else if (notification.verb.startsWith('joinedGroup')) {
                this.$router.push(`/groups/profile/${notification.activities[0].group.groupId}`);
            } else if( notification.verb.startsWith('dmRequest') || notification.verb.startsWith('dmAcceptance')){

                this.$router.push('/analysts/profile/' + notification.activities[0].actor.data.name);

            }else if( notification.verb.startsWith('groupJoinAcceptance')){
                    // todo?
                let group = notification.activities[0].group;
                group.isMember = true;
                this.$store.commit('SET_SELECTED_GROUP', group);
                this.$router.push('/groups/profile/' + group.groupId);
            } else if ( notification.verb.startsWith('alert')) {
                let payload = JSON.parse(notification.activities[0].alertNotification.payload);

                let notificationData = payload;
                notificationData.actualAlertType = notification.activities[0].alertType;
                notificationData.alertType = this.toAlertTypeId(notificationData.actualAlertType);
                notificationData.percentChange = `${this.formatter.format(payload.percentChange * 100, '0.00')}%`;
                notificationData.volume = this.formatter.format(payload.volume, '0.00');

                if (payload.alertType === 9) {
                    notificationData.userValue = `${this.formatter.format(payload.userValue, '0.00')}%`;
                } else if (payload.alertType === 3) {
                    notificationData.userValue = this.formatter.format(payload.userValue, '0.00');
                } else if (ALERT_PRICE_TYPES.includes(payload.alertType)) {
                    notificationData.low52Week = this.formatter.format(payload.low52Week, '$0.00');
                    notificationData.high52Week = this.formatter.format(payload.high52Week, '$0.00');
                    notificationData.userValue = this.formatter.format(payload.userValue, '$0.00');
                    if ([11,12].includes(payload.alertType)) {
                        notificationData.askPrice = this.formatter.format(payload.askPrice, '$0.00');
                        notificationData.bidPrice = this.formatter.format(payload.bidPrice, '$0.00');
                    } else {
                        notificationData.price = this.formatter.format(payload.price, '$0.00');
                    }
                }

                EventBus.emit('alert-notification', notificationData);
            } else if (notification.verb.startsWith('motion')) {
                const motion = notification.activities[0].motion;
                // EventBus.emit('motion-passed', motion);

                const isOnGroupsRoute = this.$route.path.includes('groups');
                const isOnSameGroupProfile = this.selectedGroupConduit?.groupId === motion.groupId;
                const isNotOnMotionsTab = this.$store.state.selectedGroupConduit?.tab === undefined || (this.selectedGroupConduit?.tab !== undefined && this.selectedGroupConduit?.tab !== 'Motions');

                /*
                Logic for navigating to motions screen:

                1. Has groupId
                2. Is not on the same group as the one from the selected motion
                3. Is on the same group, but is not on the portfolio section and motions tab
                4. Is not on the group route

                */

                if (motion.groupId && (((isOnGroupsRoute && !isOnSameGroupProfile) || (isOnGroupsRoute && isOnSameGroupProfile && this.selectedGroupConduit?.type !== 'portfolio' && isNotOnMotionsTab)) || !isOnGroupsRoute)) {
                    GroupService.getCollaborativeGroupPortfolios(motion.groupId).then(resp => {
                        if (resp.data.status === 'success') {

                            const portfolio = resp.data.groupPortfolios.filter(portfolio => portfolio.groupId === motion.groupId)[0];

                            const actualPortfolio = {
                                ...portfolio,
                                accountTypeId: -1
                            }

                            if (!isOnGroupsRoute || (isOnGroupsRoute && !isOnSameGroupProfile)) {
                                this.$store.commit("SET_GROUP_ACCESS_HIST", 
                                { 
                                    groupId: motion.groupId, 
                                    conduit: {
                                        groupId: motion.groupId,
                                        type: "portfolio",
                                        tab: 'Motions',
                                        obj: actualPortfolio
                                    }
                                });

                                this.$router.push('/groups/profile/' + motion.groupId);
                            } else {
                                EventBus.emit("select-group-portfolio", {portfolio: actualPortfolio, tab: 'Motions'});
                            }
                        }
                    })
                }

            } else if (notification.verb.startsWith('actionReward')) {
                this.$router.push('/portfolios/cash');
            } else if (notification.verb.startsWith('fundsTransferred')) {
                const activity = notification.activities[0];

                if (activity.group) {
                    const group = activity.group;
                    const destAccount = activity.destAccount;
                    const isOnGroupsRoute = this.$route.path.includes('groups');
                    const isOnSameGroupProfile = this.selectedGroupConduit?.groupId === group.groupId;
                    const isNotOnPortfolioOrCashAccount = this.selectedGroupConduit?.type !== 'portfolio' && this.selectedGroupConduit?.type !== 'cashAccount';
                    const isOnSameGroupAccount = this.selectedGroupConduit?.obj?.investmentAccountId === activity.destAccount.investmentAccountId;

                    /*
                    Logic for navigating to group account screen:

                    2. Is not on the same group as the one from the transferred to account
                    3. Is on the same group, but is not on portfolio or cash account screen
                    4. Is on the same group, but is on an account different from transferred to account
                    5. Is not on the group route

                    */

                    if ((isOnGroupsRoute && !isOnSameGroupProfile) 
                    || (isOnGroupsRoute && isOnSameGroupProfile && isNotOnPortfolioOrCashAccount)
                    || (isOnGroupsRoute && isOnSameGroupProfile && (!isNotOnPortfolioOrCashAccount && !isOnSameGroupAccount))
                    || !isOnGroupsRoute) {

                        if (activity.destAccount.accountTypeId === PAPER_CASH_ACCOUNT_TYPE['ID']) {
                            if (!isOnGroupsRoute || (isOnGroupsRoute && !isOnSameGroupProfile)) {
                                this.$store.commit("SET_GROUP_ACCESS_HIST", 
                                { 
                                    groupId: group.groupId, 
                                    conduit: {
                                        groupId: group.groupId,
                                        type: "cashAccount",
                                    }
                                });

                                this.$router.push('/groups/profile/' + group.groupId);
                            } else if (this.selectedGroupConduit?.type !== 'cashAccount') {
                                EventBus.emit('select-cash-account');
                            }
                        } else {
                            GroupService.getCollaborativeGroupPortfolios(group.groupId).then(resp => {
                                if (resp.data.status === 'success') {

                                    let account = null;

                                    resp.data.groupPortfolios.forEach(port => {
                                        account = port.accounts.filter(acct => acct.investmentAccountId === destAccount.investmentAccountId)[0];

                                        if (account) {
                                            account['groupId'] = group.groupId;
                                            account['accountTypeId'] = destAccount.accountTypeId;
                                            account['totalValue'] = destAccount.totalValue;
                                            account['dayReturn'] = destAccount.dayReturn;
                                            account['dayReturnAmount'] = destAccount.dayReturnAmount;
                                            account['cumReturn'] = destAccount.cumReturn;
                                            account['cumReturnAmount'] = destAccount.cumReturnAmount;
                                            account['totalBorrowed'] = destAccount.totalBorrowed;
                                            account['cashPositions'] = destAccount.cashPositions;
                                            account['currentBuyingPower'] = destAccount.currentBuyingPower;
                                            account['collaborative'] = destAccount.collaborative;
                                            return;
                                        }
                                    });        

                                    if (!isOnGroupsRoute || (isOnGroupsRoute && !isOnSameGroupProfile)) {
                                        this.$store.commit("SET_GROUP_ACCESS_HIST", 
                                            { 
                                                groupId: group.groupId, 
                                                conduit: {
                                                    groupId: group.groupId,
                                                    type: "portfolio",
                                                    tab: 'Overview',
                                                    obj: account
                                                }
                                            });

                                        this.$router.push('/groups/profile/' + group.groupId);
                                    } else if (this.selectedGroupConduit?.obj?.investmentAccountId !== destAccount.investmentAccountId) {
                                        EventBus.emit("select-group-portfolio", {portfolio: account});
                                    }

                                }
                            })
                        }
                    }
                } else {
                    this.$router.push('/portfolios/cash');
                }

            } else if (notification.verb.startsWith('contestPost')) {
                try {
                    const activity = notification.activities[0];
                
                    const feedPost = activity.feedPost;
                    const contest = feedPost.contest;
                    if( contest ) {
                        this.$router.push('/contests/competitions/'+contest.contestId);
                    }
                }catch( err){
                    console.log("error on contest post notification click", err);
                }

            } else {
                // All notifications except mention types will have 'id' field within the 'object' from 'activities' that will contain the id of it's respective activity post
                const activityId = notification.verb.startsWith('mention') ? notification.activities[0].src_activity_id : notification.activities[0].object.id;

                FeedService.getSingleActivity(activityId).then((result) => {
                    if (result) {
                        EventBus.emit('trigger-dialog-for-single-activity', result[0])
                    } else {
                        this.$toast.add({ severity: 'error', detail: 'Error with displaying post. Contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
                    }
                });
            }
        },

        toAlertTypeId(title) {
            if( title == "52 Week High") {
                return 1;
            }
            else if( title == "52 Week Low") {
                return 2;
            }
            else if( title ==  "Volume") {
                return 3;
            }
            else if( title == "Price") {
                return 4;
            }
            else if( title == "End of Day Summary") {
                return 7;
            }
            else if( title == "End of Day Price") {
                return 13;
            }
            else if( title == "Price Change") {
                return 8;
            }
            else if( title == "Percent Change") {
                return 9;
            }
            else if( title == "Bid Price") {
                return 11;
            }
            else if( title == "Ask Price") {
                return 12;
            }
            else{
                return 0;
            } 

            
        },

        loadMoreNotifications() {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreResults) {
                    this.fetchNotifications(this.notifications[this.notifications.length - 1].id);
                } else {
                    this.loading = false;
                }
            }
        },

        onTopbarPaperAccountsTradingButtonClick() {
            EventBus.emit('open-trading-paper-accounts-modal');
        },

        userMenuButtonClick() {
            this.$store.commit('SET_SEARCH_BAR_TEXT', null);
            EventBus.emit('populate-search-input');
        },

        onMenuButtonClick(event) {
            this.$emit("menu-button-click", event);
        },
        onSearchClick(event) {
            this.$emit("search-click", event);
        },
        onTopbarCalculatorButtonClick() {
            this.$emit("calculator-button-click");
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.showNotification = !this.showNotification;
            this.$emit("topbar-notification", event);
        },
        onTopbarTourMenuButtonClick(event) {
            this.$emit("tour-button-click", event);
        },
        onTopbarUserMenuButtonClick(event) {
            this.$emit("topbar-user-menu", event);
        },
        onRightMenuClick(event) {
            this.$emit("right-menubutton-click", event);
        },
        onLogoutClick() {
            this.loggingOut = true;
            EventBus.emit("logout-click");

            try {
                EventBus.emit('track-logout')
            } catch (err) {
                return;
            }
        },

        startTour(name) {
            this.tourService = new TourService(name);
            this.tourService.start();
        }
    }
};
</script>
<style >
#faqDialog {
    width: 25rem !important; 
    border-radius:16px !important;
}

#faqDialog > .p-dialog-header > .p-dialog-title {
    font-size: 18px;
}
</style>

<style scoped>

 *:not(.pi, .material-icons-outlined, .material-symbols-outlined), ::v-deep(.p-component) {
    font-family: 'Trebuchet MS', 'Verdana';
} 



.layout-topbar {
    padding: 0px 8px;
    height: 3.5rem;
}

.layout-topbar .topbar-right .user-bar-container > * {
    margin-right: 12px;
}
.layout-topbar .topbar-right .user-bar-container > *:last-child {
    margin-right: 0px;
}

.layout-topbar .topbar-menu > li {
    margin-left: 0;
}
.layout-topbar .topbar-menu > li.profile-item {
    border:0;
    padding: 0;
}
.layout-topbar .topbar-menu > li.profile-item:hover {
    cursor: pointer;
}
.layout-topbar .user-bar-container {
    display: flex;
    align-items: center;
}
.layout-topbar .user-bar-container .topbar-menu:not(:last-child) {
    height: 29.4px !important;
}
.layout-topbar .user-bar-container .topbar-menu .tour-button {

    display: flex;
    border-radius: 50px;
    width: 30px;
    height: 30px;
    overflow: hidden;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
.layout-topbar .user-bar-container .topbar-menu .tour-button:hover {
    animation: ripple 0.7s linear infinite;
}

.layout-topbar .user-bar-container .topbar-menu .buy-sell:hover {
    animation: ripple 0.7s linear infinite;
}
.layout-topbar .user-bar-container .paper-accounts-trading-item button {
    height: 2.1rem;
    font-size: 14px;
}
.paper-accounts-trading-item img {
    width: 20px;
}
.layout-topbar .user-bar-container .paper-accounts-trading-item .item-circle-container {
    padding: 4px;
    border-radius: 50%;
    border: 2px solid #33CC99;
}
.layout-topbar .user-bar-container .paper-accounts-trading-item .item-circle-container:hover {
    color: #FFFFFF;
    background: #33CC99;
}
.layout-topbar .user-bar-container .pi-bookmark {
    font-size: 30px;
    color: #32364e;
}

.layout-topbar .playdough-balances-blink {
    animation: blink 2s linear infinite;
    color: #33CC99 !important;
}

.layout-topbar .user-bar-container .playdough-balances {
    display: flex;
    gap: 4px;
}
.layout-topbar .topbar-bottom-mobile .playdough-balances-container-mobile {
    font-size: 12px;
    color: #32364e;
    display: flex;
    align-items: center;
    height: 20px;
    /* width: 100%;
    justify-content: center; */
}
.layout-topbar .user-bar-container .playdough-balances-container {
    font-size: 13px;
    color: #32364e;
    display: flex;
    flex-direction: row;
    /* margin-left: 8px; */
}
.layout-topbar .user-bar-container .playdough-balances-container:hover {
    color: #33CC99;
}
.layout-topbar .user-bar-container .notification-button {
    position: relative;
    top: 2px;
}
.layout-topbar .user-bar-container .notification-button img {
    width: 25px;
}
.layout-topbar .user-bar-container .notification-button > div {
    position: relative;
}
.layout-topbar .user-bar-container .notification-button .note {
    position: absolute;
    font-size: 28px;
    opacity: 0;
    right: 20px;
    bottom: -12px;
}

.layout-topbar .user-bar-container .notification-button:hover > div a > img {
    transform:rotate(0.2deg);
    transition:transform 0.25s cubic-bezier(0.5,400,0.5,-400);
}
.layout-topbar .user-bar-container .notification-button:hover > div #note1 {
    -webkit-animation: note1 1s ease-out;
    animation: note1 1s ease-out;
}
.layout-topbar .user-bar-container .notification-button:hover > div #note2 {
    -webkit-animation: note2 1s ease-out 0.25s;
    animation: note2 1s ease-out 0.25s;
}
.layout-topbar .user-bar-container .notification-button:hover > div #note3 {
    -webkit-animation: note3 1s ease-out 0.5s;
    animation: note3 1s ease-out 0.5s;
}
.layout-topbar .user-bar-container .notification-button ::v-deep(.p-badge.p-badge-info) {
    background: #e63e3e;
}


.topbar-menu .profile-menu {
    padding: 0;
    min-width: 200px;
    border-radius: 8px;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    z-index: 3333;
}
.topbar-menu .profile-menu > li:not(:last-child) {
    border-bottom: 1px solid #ddd;
}
.topbar-menu .profile-menu > li > a:hover {
    background-color: #32364e;
}
.topbar-menu .profile-menu >li > a:hover .menuitem-text {
    color: #ddd;
}
.topbar-menu .profile-menu .menu-header,
.topbar-menu .profile-menu > li > a {
    padding: 10px;
}
.topbar-menu .profile-menu >li .menuitem-text {
    display: flex;
    align-items: center;
}
.topbar-menu .profile-menu >li .menuitem-text > img {
    width: 24px;
}
.topbar-menu .profile-menu .menu-header .full-name {
    display: block;
    color: #32364e;
    font-size: 18px;
    font-weight: bold;
}
.topbar-menu .profile-menu .menu-header .username {
    display: block;
    color: #a6a6a6;
}


.topbar-menu .notify-menu {
    padding: 0;
    width: 400px;
    border-radius: 8px;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    top: 2.25rem;
    overflow: hidden;
}
.topbar-menu .notify-menu .notification-row {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ddd;
    padding: 16px 16px 16px 8px;
    align-items: center;
}
.topbar-menu .notify-menu .notification-row.unread:hover,
.topbar-menu .notify-menu .notification-row:hover {
    cursor: pointer;
    background: #32364e;
}
.topbar-menu .notify-menu .notification-row:hover .p-avatar {
    border-color: #FFFFFF;
}
.topbar-menu .notify-menu .notification-row:hover .notification-user-info .material-symbols-outlined,
.topbar-menu .notify-menu .notification-row:hover .notification-user-info .symbol,
.topbar-menu .notify-menu .notification-row:hover .notification-user-info .full-name,
.topbar-menu .notify-menu .notification-row:hover .notification-user-info .notification-text {
    color: #FFFFFF;
}
.topbar-menu .notify-menu .notification-row.unread {
    background: #F2F4FA;
}
.topbar-menu .notify-menu .p-avatar {
    width: 48px;
    height: 48px;
    border: 1px solid #32364e;
    margin-right: 8px;
}
.topbar-menu .notify-menu .notification-user-info {
    display: flex;
    flex-direction: column;
}

.notification-user-info-row {
    
    flex-direction: row !important;
    flex-shrink: 0 !important;
}

.topbar-menu .notify-menu .notification-user-info .symbol,
.topbar-menu .notify-menu .notification-user-info .full-name {
    font-size: 16px;
    font-weight: bold;
    color: #32364e;
}
.topbar-menu .notify-menu .notification-user-info .username,
.topbar-menu .notify-menu .notification-user-info .time-posted {
    color: #a6a6a6;
    font-size: 11px;
}

.topbar-menu .notify-menu .notification-user-info .notification-text {
    color: black;
    font-size: 12px;
    margin-top: 4px;
    white-space: pre-line;
}
.topbar-menu .notify-menu .funds-transferred-notification img {
    position: relative;
    left: -5px;
}

.topbar-menu .notify-menu .contest-post-notification img {
    position: relative;
    left: -5px;
}
.topbar-menu .notify-menu .follow-button-container {
    display: flex;
    justify-content: flex-end;
    flex: 1;
}
.topbar-menu .notify-menu .follow-button {
    height: 25px;
    padding: 16px;
    border-radius: 10px;
    color: #32364e;
    border: 1px solid #33CC99;
    background: #FFFFFF;
}
.topbar-menu .notify-menu .follow-button.following,
.topbar-menu .notify-menu .follow-button:hover {
    border-color: #33CC99;
    background: #33CC99;
    color: #FFFFFF;
}
.topbar-menu .notify-menu .follow-button:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.topbar-menu .notify-menu > li:last-child .notification-row {
    border-bottom: none;
}

.topbar-menu .tour-menu {
    padding: 0;
    border-radius: 8px;
    box-shadow: 0px 12px 17px rgba(0, 0, 0, 0.14), 0px 5px 22px rgba(0, 0, 0, 0.12), 0px 7px 8px rgba(0, 0, 0, 0.2);
    top: 2.25rem;
    overflow: hidden;
}

.topbar-menu .tour-menu div {
    padding: 8px;
    color: #32364e;
    background: #FFFFFF;
}
.topbar-menu .tour-menu div:hover {
    color: #FFFFFF;
    background: #32364e;
}

.investment-calculator-button {
    height: 2.1rem !important;
    width: 2.1rem !important;
    justify-content: center;
}
.investment-calculator-button img {
    width: 25px;
}

.accept-button{
    font-size:.8rem;
    padding: 0.5rem 1rem;
    margin-right: 3px;
   
}
.decline-button {
    font-size: .8rem;
    padding: 0.5rem 1rem;
    background-color: #fff;
    border-color: #e63e3e;
    color: #32364e;
}

.decline-button:hover, .decline-button:active {
    background-color: #e63e3e;
    color: #fff;
    border-color: #e63e3e;
}

.accepted-button {
        pointer-events: none;
        opacity: 0.5;
    }

.declined-button {
        pointer-events: none;
        opacity: 0.5;
        background-color: #e63e3e;
        color: #fff;
        border-color: #e63e3e;
    }

.hidden-accept-button {
       
        display: none;
    }

.hidden-decline-button {
       display: none;
    }

.topbar-bottom-mobile {
    width: 100%;
    /* height: 25px; */
    margin-top: 4px;
    display: flex;
    flex-direction: column;
}

::v-deep(.status-form-action-button) {
	border-radius: 22px;
	border: 1px solid #32364e;
	background: #FFFFFF;
	color: #32364e;
    width: 22px;
    height: 22px;
    margin: 2px 0px 2px 4px;
    padding: 0.5rem !important;
}
::v-deep(.status-form-action-button span) {
    font-size: 11px !important;
}
::v-deep(.status-form-action-button:enabled:hover),
::v-deep(.status-form-action-button.selected) {
	background: #32364e;
	color: #FFFFFF;
	border: 1px solid #32364e;
 }
 ::v-deep(.status-form-action-button:focus) {
	border-color: #32364e;
	box-shadow: none;
 }

@media (max-width: 760px) {
    .layout-wrapper .layout-topbar {
        height: auto;
        padding-top: 0.65rem;
        padding-bottom: 0.65rem;
    }
    .layout-wrapper .layout-topbar .viewname {
        width: calc(100% - 56px);
    }

    .right-buttons-container {
        display: flex;
        align-items: center;
        margin-left: 8px;
        flex: 1 0 auto;
    }
    .right-buttons-container > * {
        padding: 0.4rem;
        height: 30px;
        width: 30px;
        border-radius: 50%;
        margin-right: 8px;
        justify-content: center;
    }
    .right-buttons-container > button span,
    .right-buttons-container > button ::v-deep(.pi) {
        font-size: 18px;
    } 
    .right-buttons-container > *:last-child {
        margin-right: 0;
    }
    .layout-wrapper .layout-topbar.home-page {
        padding-bottom: 0 !important;
    }
    .logout-mobile {
        font-size: 0.9em;
    }
    .profile-menu {
        margin-left: 0.5rem !important;
        margin-top: -2rem !important;
        width: 40vw !important;
    }
    .chat-container {
        width: 100%;
        z-index: 99;
        background: #fff;
    }

    .topbar-menu .profile-menu .notification-menu-item img {
        width: 16px;
    }

    .topbar-menu .profile-menu .chat-menu-item img {
        width: 18px;
    }

    .topbar-menu .profile-menu .support-menu-item img {
        width: 18px;
    }

    .notify-menu {
        height: 100%;
        left: 0;
        top: 0 !important;
        background: #fff;
        width: 100%;
        padding-left: 0;
        z-index: 999;
        margin:0;
    }
    .notification-row {
        display: flex;
        width: 100%;
        padding: 1rem;
        border-top: #BFBFBF solid 1px;
    }
    .notification-user-info {
        padding-left: 10px;
    }
    .notification-user-info img {
        margin-right: 2px;
    }
    .follow-button-container {
        /* margin-left: 2rem; */
        margin-left: auto; 
        margin-right: 1rem;
        align-self: center;
    }
    .username, .time-posted {
        color: #BFBFBF;
    }
    .full-name {
        font-size: 1.2rem;
        font-weight: 700;
    }
    .close-notify-button {
        padding: 0.5rem;
        display:flex;
    }
    .topbar-left {
        border-bottom: none !important;
        padding: unset !important;
        justify-content: unset !important;
    }

    .mobile-badge {
        position: relative; 
        bottom: 50px; 
        left: 35px;
        background-color: #e63e3e;
    }

    .mobile-notification-badge {
        background-color: #e63e3e;
    }

    .mobile-chat-badge {
        background-color: #e63e3e;
    }

    .mobile-mention-badge {
       
        background-color: #693BF5;
    }

    .accept-button{
        font-size:.8rem;
        padding: 0.5rem 1rem;
        margin-bottom: 5px;
        margin-right:0;
            width: 100%;
    
    }
    .decline-button {
        font-size: .8rem;
        padding: 0.5rem 1rem;
            width: 100%;
    }
}

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(51, 204, 153, 0.3),
                0 0 0 2px rgba(51, 204, 153, 0.3),
                0 0 0 8px rgba(51, 204, 153, 0.3),
                0 0 0 14px rgba(51, 204, 153, 0.3);
  }
  100% {
    box-shadow: 0 0 0 2px rgba(51, 204, 153, 0.3),
                0 0 0 8px rgba(51, 204, 153, 0.3),
                0 0 0 14px rgba(51, 204, 153, 0.3),
                0 0 0 20px rgba(51, 204, 153, 0);
  }
}

@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes note1 {
    0% {
        transform: translateY(0) translateX(0) scale(0.25);
        opacity: 1;
    }
    100% {
        transform: translateY(20px) translateX(-5px) scale(1);
        opacity: 0;
    }
}
@keyframes note2 {
    0% {
        transform: translateY(0px) translateX(5px) scale(0.25);
        opacity: 1;
    }
    100% {
        transform: translateY(20px) translateX(5px) scale(1);
        opacity: 0;
    }
}
@keyframes note3 {
    0% {
        transform: translateY(0px) translateX(10px) scale(0.25);
        opacity: 1;
    }
    100% {
        transform: translateY(20px) translateX(15px) scale(1);
        opacity: 0;
    }
}

</style>