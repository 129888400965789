<template>
    <div class="layout-sidebar" @click="onMenuClick">
      
        <div class="logo" style="margin: 0; padding: .5rem;">
            <img id="app-logo" class="logo-image" src="assets/layout/images/Invrs-Symbol-Green_Blue.svg" alt="invrs" style="width:2.5rem;height:2.5rem;"/>
        </div>
           
        <div class='navigation-menu'>
            <div class="layout-menu-container" style="padding-left:7px;padding-right:7px;">
                <AppSubmenu :items="model" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="active" :mobileMenuActive="mobileMenuActive" :root="true" />
            </div>
            <div class="layout-menu-container" style="padding-left:7px;padding-right:7px;margin-top:auto;">
                <AppGroupMenu :items="model" :layoutMode="layoutMode" :parentMenuItemActive="true" :menuActive="active" :mobileMenuActive="mobileMenuActive" :root="true" />
            </div>
        </div>
    </div>
    <!-- <Sidebar v-model:visible="showNotes" position="full" class="p-sidebar-notes" id="notesSidebar"> 
        <h3>Notes</h3>
        <NotesPanel />
    </Sidebar> -->

</template>

<script>
import AppSubmenu from "./AppSubmenu";
import AppGroupMenu from "./AppGroupMenu";
// import Sidebar from 'primevue/sidebar';
// import NotesPanel from './components/notes/NotesPanel';

export default {
    name: "AppMenu",
    props: {
        model: Array,
        layoutMode: String,
        active: Boolean,
        mobileMenuActive: Boolean
    },
    data() {
        return {
            showNotes:false
        }
    },

    components: { AppSubmenu,   AppGroupMenu },
};
</script>
<style >
#notesSidebar {
    width: 92%;
    height: 90%;
    top: 5rem;
    left: 8rem;
}
</style>

<style scoped>
.layout-sidebar {
    width: 5rem !important;
}

.layout-sidebar .layout-menu-container {
    padding: 0 !important;
    flex: 0;
    overflow: visible;
}

::v-deep(.layout-menu) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.p-badge) {
    position: absolute;
    top: 0px;
    right: 2px;
    padding: 6px 6px;
    font-size: 8px;
    line-height: 0.6;
    height: 16px;
    background: #33CC99;
}

::v-deep(.layout-menuitem) {
    justify-content: center;
    display: flex;
    padding: 7px 0;
    cursor: pointer;
    position: relative;
}

::v-deep(.layout-menuitem:hover) {
    background: #e9ecef !important;
}

::v-deep(.layout-menuitem .layout-menuitem-icon) {
    font-size: 24px !important;
    margin: 6px !important;
}

::v-deep(.menu-active) {
    font-size: 19px !important;
    position: absolute;
    top: 18px;
    left: 54px;
    color: #32364e;
    display: none;
}

::v-deep(a.active-subroute .menu-active),
::v-deep(a.active-route .menu-active) {
    display: inline-block;
}

::v-deep(.layout-menuitem:hover .layout-menuitem-image),
::v-deep(.layout-root-menuitem:hover .layout-menuitem-image),
::v-deep(a.active-subroute .layout-menuitem-image),
::v-deep(a.active-route .layout-menuitem-image) {
    border-radius: 16px !important;
    box-shadow: 0px 1px 1px rgb(0 0 0 / 14%), 0px 2px 1px rgb(0 0 0 / 12%), 0px 1px 3px rgb(0 0 0 / 20%);
    background: #32364e !important;
    color: white !important;
}
::v-deep(.layout-menuitem .layout-menuitem-image),
::v-deep(.layout-root-menuitem .layout-menuitem-image){
    color: #32364e !important;
    background: white !important;
}

::v-deep(.layout-menuitem .layout-menuitem-image .motions-menuitem-icon),
::v-deep(.layout-root-menuitem .layout-menuitem-image .motions-menuitem-icon){
    font-size: 28px !important;
}

::v-deep(.layout-menuitem-image) {
    border: 2px solid #32364e;
    background: white;
    min-width: 40px;
    min-height: 40px;
    max-width: 40px;
    max-height: 40px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.navigation-menu {
    display: flex;
    flex-direction: column;
    height: 100%;
}
</style>