<template>
<!-- -->
    <Dialog v-model:visible="display" :header='tour.headerText' :modal="true" class='tour-prompt-modal' :closable="false" @hide="onHide">
        {{tour.bodyText}}

        <div class="popup-checkbox-container">
            <Checkbox v-model="value" inputId="popup-checkbox" :binary="true" inputClass="green-button__primary"/>
            <label for="popup-checkbox" class="popup-checkbox-label">Don't ask again</label>
        </div>

        <template #footer>
            <Button label="No" class='cancel-button red-button__primary' @click="closeAndShowTourButton"/>
			<Button label="Yes" class='confirm-button green-button__primary' @click="closeAndStartTour"/>
		</template>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';
import Checkbox from 'primevue/checkbox';

import { TOUR_ROUTE_MAP,  HOME_TOUR_NAME, PORTFOLIO_TOUR_NAME, SHOW_OVERVIEW_PREFERENCE, SHOW_PORTFOLIO_OVERVIEW_PREFERENCE } from '../../common/constants';
import TourService from '../../service/TourService';
import UserService from '../../service/UserService';

const TOURS = [
    {
        name: HOME_TOUR_NAME,
        userPreferenceId: SHOW_OVERVIEW_PREFERENCE,
        headerText: 'Overview Tour',
        bodyText: 'Would you like to take a brief overview tour of the application?',
    },
    {
        name: PORTFOLIO_TOUR_NAME,
        userPreferenceId: SHOW_PORTFOLIO_OVERVIEW_PREFERENCE,
        headerText: 'Welcome to the Portfolio section of the app!',
        bodyText: `This is where you can create Paper Trading and Manual accounts.\n\nWould you like to take a brief tour?`,
    }
];

export default {
    name: 'TourPromptModal',
    data() {
        return {
            display: false,

            tour: {
                name: '',
                userPreferenceId: 1,
                headerText: '',
                bodyText: ''
            },
            tourService: null,
            value: false
        }
    },


    components: {
        Dialog,
        Checkbox
    },

    methods: {
        open(tourName) {
            this.tour = TOURS.find(tour => tour.name == tourName);
            console.log("TourPrompt",this.tour);
            this.display = true;
        },

        close() {
            this.display = false;

            if (this.value) {
                UserService.updateUserPreference( this.tour.userPreferenceId, false ).then((res) => {
                    if (res.data.status === 'error') {
                        this.$toast.add({ severity: 'error', summary: res.data.message, life: 3000, group: 'center' });
                    } else {
                        const actualValue = 'false';
                        const obj = {
                            'id': this.tour.userPreferenceId,
                            'value': actualValue
                        };

                        this.$store.dispatch("users/setSetting", obj);
                        console.log(this.$store.getters['users/settingsList']);
                        // this.$toast.add({severity: 'success', summary: 'Preference changed!', life: 3000, group: 'center'});
                    }
                })
                .catch((err) => {
                    console.error(err);
                    this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                })
            }
        },

        onHide() {
            this.tourService = null;
        },

        initializeTour() {
            this.tourService = new TourService(TOUR_ROUTE_MAP[this.$route.path]);
        },

        closeAndShowTourButton() {
            this.close();
            this.initializeTour();
            this.tourService.startHelpButtonTour();
        },

        closeAndStartTour() {
            this.close();
            this.initializeTour();
            this.tourService.start();
        }


    }
    
}
</script>

<style>

.tour-prompt-modal {
    font-family: "Trebuchet MS", "Verdana";
	box-shadow: none;
}

.tour-prompt-modal .p-dialog-header {
    padding: 16px;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
    color: #32364E;
    justify-content: center;
}

.tour-prompt-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
    margin-right: auto;
    margin-left: auto;
}

.tour-prompt-modal .p-dialog-content {
    color: black;
	padding: 0px 25px 30px;
    text-align: center;
    font-size: 16px;
    white-space: pre-line;
}

.tour-prompt-modal .p-dialog-footer {
    padding: 0px 16px 16px;
    border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
}

p {
    margin-top: 8px;
}
@media (max-width: 760px) {
    .tour-prompt-modal {
        width: 100%;
        height: 100%;
    }
}
</style>

<style scoped>
.p-dialog-footer > button {
    font-weight: bold;
    border-radius: 50px;
}

.popup-checkbox-container {
    margin-top: 12px;
}
.p-checkbox {
    margin-right: 8px;
}

::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box:hover {
    border-color: #33CC99;
}
::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #00C083;;
    background: #00C083;
}
::v-deep(.p-checkbox):not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
    border-color: #00C083;
    box-shadow: none;
}

</style>