<template>
    <Dialog v-model:visible="display" :modal="true" class='alert-notification-modal'>
        <template #header>
            <span class='title'>Alert: {{notification.actualAlertType}}</span>
        </template>
        <div class='body-text'>
            <span>Symbol: {{notification.symbol}}</span>
            <template v-if='[1,2,4,8,13].includes(notification.alertType)'>
                <span v-if="[4,8].includes(notification.alertType)">Alert Price: {{notification.userValue}}</span>
                <span>Current Price: {{notification.price}}</span>
                <span>52-Week High: {{notification.high52Week}}</span>
                <span>52-Week Low: {{notification.low52Week}}</span>
            </template>
            <template v-else-if="notification.alertType === 9">
                <span>Alert Percent Change: {{notification.userValue}}</span>
                <span>Current Percent Change: {{notification.percentChange}}</span>
            </template>
            <template v-else-if="notification.alertType === 3">
                <span>Alert Volume: {{notification.userValue}}</span>
                <span>Current Volume: {{notification.volume}}</span>
            </template>
            <template v-else-if="notification.alertType === 7">
                <span>Percent Change: {{notification.percentChange}}</span>
                <span>Volume: {{notification.volume}}</span>
                <span>Current Price: {{notification.price}}</span>
                <span>52-Week High: {{notification.high52Week}}</span>
                <span>52-Week Low: {{notification.low52Week}}</span>
            </template>
            <template v-else-if="notification.alertType === 11">
                <span>Alert Bid Price: {{notification.userValue}}</span>
                <span>Current Bid Price: {{notification.bidPrice}}</span>
            </template>
            <template v-else-if="notification.alertType === 12">
                <span>Alert Ask Price: {{notification.userValue}}</span>
                <span>Current Ask Price: {{notification.askPrice}}</span>
            </template>
        </div>
		<template #footer>
			<Button label="Dismiss" class='dismiss-button green-button__primary' @click="onClose"/>
		</template>
	</Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';

export default {
    name: 'AlertNotificationModal',
    components: {
        Dialog
    },

    data() {
        return {
            display: false,
            notification: {}
        }
    },


    methods: {
        open(data) {
            this.notification = data;
            this.display = true;
        },

        onClose() {
            this.display = false;
        }
    }
}
</script>

<style>
.alert-notification-modal {
    width: 430px;
}

.alert-notification-modal .p-dialog-header {
    padding: 16px;
}

.alert-notification-modal .p-dialog-content {
    padding: 0px 16px;
}

.alert-notification-modal .p-dialog-footer {
    padding: 16px 0px;
    text-align: center;
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.dismiss-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}
</style>