<template>
    <Toast style="z-index:30000;" :autoZIndex="false" />
    <Toast position="top-left" group="tl" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="top-center" group="tc" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="top-right" group="tr" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="center" group="center" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="bottom-left" group="bl" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="bottom-center" group="bc" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="bottom-right" group="br" style="z-index:30000;" :autoZIndex="false" />
    <Toast position="center" group="center-html" style="z-index:30000;" :autoZIndex="false">
        <template #message="slotProps" >
            <span :class="toastIconClass(slotProps.message)"></span>
            <div class="p-toast-message-text">
                <span class="p-toast-summary">{{slotProps.message.summary}}</span>
                
                <div class="p-toast-detail" v-html="slotProps.message.detail" />
            </div>
            
        </template>
    </Toast>

    <div class="loader-container" v-if="!authorized" style="background:#17212f">
        <div class="loader">
            <div class="bottom-section">
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
            </div>
            <div class="top-section">
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
                <div class="bottom"></div>
            </div>
            <div class="middle"></div>
        </div>
    </div>


    <div :class="containerClass" :data-theme="colorScheme" @click="onDocumentClick($event)" v-if="authorized">
        <TabMenu :model="menuMobile" :activeIndex="activeTab" class="p-d-md-none tab-menu">
            <template #item="{item}">
                <SpeedDial v-if="item.label == 'Tools'" :model="toolboxSpeedDialMenu" direction="up" :transitionDelay="10" :showIcon="item.icon" :hideIcon="item.icon"/>
                <button v-else @click="item.action" class="tab-container mobile-bottom-menu-item" >
                    <img v-if="item.image" :src="item.image" />
                    <i v-else :class="item.icon"></i>
                    <p>{{ item.label }}</p>
                    <Badge :value="getGroupsNotificationCount()" class='menuitem-notification-badge' v-if="item.label === 'Groups' && getGroupsNotificationCount()"></Badge>
                </button>
            </template>
        </TabMenu>
        <div class="layout-content-wrapper" :class="{'home-page': $route.fullPath == '/home'}">
       
            <AppTopBar ref='topBar'  
                :topbarNotificationMenuActive="topbarNotificationMenuActive"
                :topbarUserMenuActive="topbarUserMenuActive"
                :topbarTourMenuActive="topbarTourMenuActive"
                @menu-button-click="onMenuButtonClick"
                @search-click="onSearchClick"
                @topbar-notification="onTopbarNotificationMenuButtonClick"
                @tour-button-click="onTopbarTourMenuButtonClick"
                @topbar-user-menu="onTopbarUserMenuButtonClick"
                @right-menubutton-click="onRightMenuButtonClick"
                @calculator-button-click="onTopbarCalculatorMenuButtonClick"
            ></AppTopBar>
        

            <div class="layout-content" v-if="!loggingOut">
                 <transition-group name="p-message" tag="div">
                    
                    <Message v-for="msg of notificationMessages" :severity="msg.severity" :key="msg.id"  :sticky="true" >
                       <span v-html="msg.content"></span>
                    </Message>
                </transition-group>

                <router-view v-slot="{ Component }">
                    <keep-alive><component :is="Component"/></keep-alive>
                </router-view>
            </div>
            <template v-if="chatEnabled()">
                <Badge :value="notifications" class="chat-floating-badge show-on-desktop" v-if="notifications"></Badge>
                <!-- <Badge :value="chatMentions" class="chat-floating-mention-badge show-on-desktop" v-if="chatMentions"></Badge> -->
                <a class="chat-floating-button show-on-desktop" @click="onChatButtonClick($event)" v-show="!showChat">
                    <img src="/assets/images/chat.png"/>
                </a>
            </template>
            <!-- <a class="paper-accounts-floating-button" @click="onPaperAccountsButtonClick" v-show="$route.path === '/equity'">
                <i class="pi pi-dollar"></i>
                <span class='text'>Buy/Sell</span>
            </a> -->

            <AppChatDrawer ref='chatDrawer' @close-chat-drawer="onCloseChatDrawer"/>
            
        </div>

        <AppMenu :model="menu" :layoutMode="layoutMode" :active="menuActive" :mobileMenuActive="staticMenuMobileActive"></AppMenu>

        <div class="layout-mask modal-in"></div>
    </div>
    <AlertNotificationModal ref="alertNotificationModal"/>
    <RegistrationModal ref="registrationModal" :display="shouldShowRegForm()" />

    <LoginRequiredModal ref="loginReqModal" />
    <teleport to="body" v-if="loggingOut">
        <Sidebar v-model:visible="loggingOut" :autoZIndex="false" style="z-index:2147483003;display:inline;" position="full" :showCloseIcon="false" :dismissable="false">
            <div class="loader-container" style="background:#17212f">
                <div class="logging-out-overlay">Logging you out...</div>
                <div class="loader">
                    <div class="bottom-section">
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                    </div>
                    <div class="top-section">
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                        <div class="bottom"></div>
                    </div>
                    <div class="middle"></div>
                </div>
            </div>
        </Sidebar>
    </teleport>
    <StatusEditorDialog ref="statusEditorModal" />
    <TourPromptModal ref="tourPromptModal" />
    <AddPaperAccountModal ref='addPaperAccountModal' @created-account="createdAccountCallback"/>
    <PlaceTradeOrderModal ref='placeTradeOrderModal' :tradingAccounts="tradingAccounts" @create-account="createAccountCallback"/>
    <CreateMotionModal ref="createMotionModal" />
    <InvestmentCalculator ref='investmentCalculatorModal'/>
    <SubmitReportModal ref='submitReportModal'/>

    <ConfirmationModal :headerLabel="motionPassedHeaderLabel" :bodyText="motionPassedBodyText" ref="motionPassedConfirmationModal"/>
</template>

<script>
import { mapState } from 'vuex';
import AppTopBar from './AppTopbar';
import AppMenu from './AppMenu';
//import Dashboard from "./views/Dashboard";

// import Chat from './views/Chat';
import ConfirmationModal from './components/modal/ConfirmationModal.vue';
import SubmitReportModal from './components/modal/SubmitReportModal.vue';
import LoginRequiredModal from './components/errors/LoginRequiredModal';
import AlertNotificationModal from './components/QuoteMedia/alerts/modal/AlertNotificationModal.vue';
import AddPaperAccountModal from './components/portfolio/modal/AddPaperAccountModal.vue';
import PlaceTradeOrderModal from './components/portfolio/modal/PlaceTradeOrderModal.vue';
import CreateMotionModal from './components/motion/CreateMotionModal.vue';
import InvestmentCalculator from './components/QuoteMedia/InvestmentCalculator.vue';
import AppChatDrawer from './AppChatDrawer.vue';
import Sidebar from 'primevue/sidebar';
import RegistrationModal from './components/QuoteMedia/RegistrationModal';
import StatusEditorDialog from './components/feed/StatusEditorDialog.vue';
import TourPromptModal from './components/modal/TourPromptModal.vue';
import UserService from './service/UserService';
import AuthService from './service/AuthService';

import { BroadcastChannel } from 'broadcast-channel';
import BrowserUtils from './utilities/BrowserUtils';
import ChatService from './service/ChatService';
import GroupService from './service/GroupService';
import PortfolioService from './service/PortfolioService';
import LocalStorageService from "./service/LocalStorageService";
import AudioUtils from './utilities/AudioUtils';
import SessionStorageUtils from './utilities/SessionStorageUtils';
import UserUtils from './utilities/UserUtils';

import Badge from 'primevue/badge';
import Message from 'primevue/message';
import TabMenu from 'primevue/tabmenu';
import SpeedDial from 'primevue/speeddial';

import {SHOW_OVERVIEW_PREFERENCE, PAPER_TRADING_ACCOUNT_TYPE, EDUCATIONAL_ACCOUNT_TYPE, HOME_TOUR_NAME, SHOW_PORTFOLIO_OVERVIEW_PREFERENCE, PORTFOLIO_TOUR_NAME,
ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE, ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE, 
ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE, SHOW_NOTES_ONLY, DEFAULT_FEED, COMPETITION_ACCOUNT_TYPE, COOKIE_SIGN_UP_CONTEST_ID, COOKIE_COURSE_REG_CODE} from './common/constants.js';
import EventBus from './event-bus';
import CookieUtils from './utilities/CookieUtils';
import {EDUCATION_GROUP_ID} from './common/config';

const CONTESTS_BUTTON_PULSATING_MAX_ITERATIONS = 3;

export default {
    data() {
        return {
            tvChannel: null,
            wealthicaChannel: null,
            wealthMsgHandler: null,
            tvMsgHandler: null,
            menuActive: false,
            layoutMode: 'slim', //"static",
            colorScheme: 'light',
            menuTheme: 'layout-sidebar-white',
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            menuClick: false,
            rootMenuItemClick: false,
            searchActive: false,
            searchClick: false,
            userMenuClick: false,
            topbarUserMenuActive: false,
            tourMenuClick: false,
            topbarTourMenuActive: false,
            notificationMenuClick: false,
            topbarNotificationMenuActive: false,
            rightMenuClick: false,
            rightMenuActive: false,
            configActive: false,
            configClick: false,

            justMounted: false,

            tradingAccounts: [],
            selectedSecurity: null,

            showChat: false,
            loggingOut: false,

            notificationMessages: [],

            menuMobile: [
                {
                    label: 'Home',
                    image: "/assets/images/home.png",
                    action: this.viewHomeFeed,
                },
                {
                    label: 'Contests',
                    image: "/assets/images/contest.png",
                    action: this.viewContestsFeed,
                },
                {
                    label: 'Education',
                    image: "/assets/images/ed.png",
                    action: this.viewEducationGroup,
                },
                {
                    label: 'Portfolio',
                    image: '/assets/images/portfolio.png',
                    action: this.viewPortfolioFeed,
                },
                {
                    label: 'Tools',
                    icon: 'material-icons icon-troubleshoot',
                },
                {
                    label: "Groups", 
                    image: "/assets/images/group_mobile.png",
                    action: this.viewGroups,
                    disabled: !this.groupsEnabled()
                }
            ],
            
            toolboxSpeedDialMenu: [
                {
                    label: 'News',
                    icon: 'pi pi-fw pi-book',
                    command: this.viewNewsFeed,
                },
                {
                    label: 'Discover',
                    icon: 'pi pi-fw pi-search',
                    command: this.viewDiscoverFeed,
                },
                {
                    label: 'Markets',
                    icon: 'pi pi-fw pi-dollar',
                    command: this.viewMarketsFeed,
                },
                {
                    label: 'Economy',
                    icon: 'pi pi-fw pi-chart-bar',
                    command: this.viewEconomyFeed,
                },
            ],

            activeTab: null,

            appChannel: null,
            appMsgHandler: null,
            securityToTrade: null,

            dmNotificationsAudioPlayer: new AudioUtils(),
            generalNotificationsAudioPlayer: new AudioUtils(),
            groupActivityNotificationsAudioPlayer: new AudioUtils(),

            motionPassedHeaderLabel: 'Motion Passed!',
            motionPassedBodyText: null,

            contestsButtonPulsatingActivated: null,
            contestsButtonPulsatingIteration: 0,
        }
    },
    created() {
        this.initSelectedFeedTypeSettings();

        EventBus.off('logout-click', this.showLogout);
        EventBus.on('logout-click', this.showLogout);
        
        this.EventBus.on('registration-complete', () => {
            this.completeRegistration();
        });
        

        this.EventBus.on('system-notification', msg => {
            this.handleSystemNotification(msg);
        });

        console.log("creating App");
        //console.trace();
    },
    mounted() {
        console.log("mouting App");
        //console.trace();
       // console.log(" this.$route.query.reg="+ this.$route.query.reg);
        const isReg =  this.$route.query.reg;
        const storedIsReg = LocalStorageService.getString('reg');
        if( isReg == 'true' || storedIsReg == 'true'){
            LocalStorageService.removeString('reg');
           this.completeRegistrationFirst();
        }
        else {
            this.doMountedRoutine();
        }
        
       
        
    },
    unmounted() {
        this.loggingOut = false;
    },
    
    computed: {
        ...mapState(['authorized', 'registered', 'selectedEquity', 'selectedFund', 'currentlySelectedPortfolio', 'selectedGroupConduit', 
        'groupChannelsUnreadCounts', 'feedNotificationCounts', 'selectedFeedType', 'showNotesOnly']),

        containerClass() {
            return [
                'layout-wrapper',
                {
                    'layout-overlay': this.layoutMode === 'overlay',
                    'layout-static': this.layoutMode === 'static',
                    'layout-slim': this.layoutMode === 'slim',
                    'layout-sidebar-dim': this.colorScheme === 'light',
                    'layout-sidebar-dark': this.colorScheme === 'light',
                    'layout-overlay-active': this.overlayMenuActive,
                    'layout-mobile-active': this.staticMenuMobileActive,
                    'layout-static-inactive': this.staticMenuDesktopInactive && this.layoutMode === 'static',
                    'p-input-filled': this.$appState.inputStyle === 'filled',
                    'p-ripple-disabled': !this.$primevue.ripple
                },
                this.colorScheme === 'light' ? this.menuTheme : '',
            ];
        },

        notifications() {
            let count = this.getNotificationCount();
            if( count == 0 ) {
                return null;
            }
            else if( count > 10) {
                return "10+";
            }
            else {
                return count;
            }
           
        },

        chatMentions() {
             let count = this.getMentionCount();
            if( count == 0 ) {
                return null;
            }
            else if( count > 10) {
                return "10+";
            }
            else {
                return count;
            }
        },

        shouldShowTourPrompt() {
            return this.$store.getters['users/getSetting'](SHOW_OVERVIEW_PREFERENCE)?.value === "true" && 
                SessionStorageUtils.getParsedValue('showTourPrompt');
        },

        menu() { 
            
            return [
                {label: "Home", image: "/assets/images/home.png",  to: "/home" },

                {label: "Contests", image: "/assets/images/contest.png", to: "/contests/competitions" , disabled: !UserUtils.isFeatureEnabled('contestsEnabled'), class:  this.contestsButtonClass},

               
               {
                    label: 'Education',
                    image: "/assets/images/ed.png",
                    to: "/education"
                },
               {label: "Portfolio", image: "/assets/images/portfolio.png", to: "/portfolios", disabled: !this.portfoliosSettingsEnabled() || !this.portfoliosFeatureEnabled() },
                
                {label: "Tools", image: "/assets/images/tools.png", to: "/sandbox/lists" , disabled: false}, 

                {label: "Notes", image: "/assets/images/notes.png", to: "/notes" , disabled: false},
            ]
        },

        contestsButtonClass() {
            return this.contestsButtonPulsatingActivated ? 'contests-menu-button pulsating' : 'contests-menu-button'
        }
    },
    components: {
        AppTopBar,
        AppChatDrawer,
        SpeedDial,
        AppMenu,
        AlertNotificationModal,
        LoginRequiredModal,
        ConfirmationModal,
        // Chat,
        //Dashboard,
        // Dialog
        Sidebar,
        RegistrationModal,
        TourPromptModal,
        TabMenu,
        Badge,
        Message,
        StatusEditorDialog,
        AddPaperAccountModal,
        PlaceTradeOrderModal,
        InvestmentCalculator,
        SubmitReportModal,
        CreateMotionModal
    },
    watch: {
        $route(newRoute) {
            this.menuActive = false;
            this.$toast.removeAllGroups();
            this.navigateMobile();

            this.selectSecurityProcessor();

            if (newRoute.path !== '/' && newRoute.path !== '/home') {
                this.contestsButtonPulsatingActivated = null;
            }
        },

        loggingOut(newVal) {
            if (newVal && !SessionStorageUtils.getParsedValue('loggedInAlready')) {
                sessionStorage.setItem('loggedInAlready', true);
            }
        },

        contestsButtonPulsatingActivated(newVal) {
            if (newVal !== null) {
                if (newVal) {
                    if (this.contestsButtonPulsatingIteration < CONTESTS_BUTTON_PULSATING_MAX_ITERATIONS) {
                        this.contestsButtonPulsatingIteration++;
                        setTimeout(() => {
                            this.contestsButtonPulsatingActivated = false;
                        }, 4000);
                    } else {
                        this.contestsButtonPulsatingActivated = false;
                    }
                } else {
                    setTimeout(() => {
                        this.contestsButtonPulsatingActivated = true;
                    }, 10000)
                }
            }
        }
    },
    methods: {

        viewEducationGroup() {
            this.$router.push('/education');
        },

        activateContestsButtonPulsating() {
            //console.log(this.authorized);
            if (!SessionStorageUtils.getParsedValue('loggedInAlready') && UserUtils.isFeatureEnabled('contestsEnabled')) {
                setTimeout(() => {
                    this.contestsButtonPulsatingActivated = true;
                }, 10000);
            }
        },

        doMountedRoutine() {
            //console.log("ehere")
            this.setupNotificationSounds();
            this.initAppChannel();
            this.navigateMobile();

            this.checkFreeTrialStatus();

            if( this.$store.state.systemNotifications) {
                this.$store.state.systemNotifications.forEach(msg => {
                    this.handleSystemNotification(msg);
                });
            }

            if( !this.shouldShowRegForm() && !this.loggingOut) {
                this.postLogin();
            }

            EventBus.off('portfolio-imported');
            EventBus.off('portfolio-import-failed');
            EventBus.off('payment-complete-notification');
            EventBus.off('subscription-cancelled-notification');
            EventBus.off('open-dm-channel');
            EventBus.off('open-create-dm-channel-modal');
            EventBus.off('alert-notification');
            EventBus.off('open-paper-accounts-modal');
            EventBus.off('open-investment-calculator-modal');
            EventBus.on('portfolio-imported', this.importCompleteHandler);
            EventBus.on('portfolio-import-failed', this.importFailedHandler);
            EventBus.on("payment-complete-notification", this.paymentCompleteHandler);
            EventBus.on('subscription-cancelled-notification', this.subscriptionCancelledHandler);
            EventBus.on('open-dm-channel', this.openDmChannelHandler);
            EventBus.on('open-create-dm-channel-modal', this.openCreateDmChannelModalHandler);
            EventBus.on('alert-notification', this.alertNotificationHandler);
            EventBus.off("app-menu-click");
            EventBus.on("app-menu-click", this.onMenuClick);
            EventBus.off("app-menuitem-click");
            EventBus.on("app-menuitem-click", this.onMenuItemClick);
            EventBus.off("app-root-menuitem-click");
            EventBus.on("app-root-menuitem-click", this.onRootMenuItemClick);
            EventBus.on('open-trading-paper-accounts-modal', this.tradingPaperAccountsModalOpenHandler);
            EventBus.off("portfolio-opened");
            EventBus.on("portfolio-opened", () =>{
                if (!BrowserUtils.isMobile()) {
                    this.handleTourPrompt();
                }
            });
            EventBus.on('open-investment-calculator-modal', this.onTopbarCalculatorMenuButtonClick);
            EventBus.off('post-reported');
            EventBus.on('post-reported',this.onPostReported);
            EventBus.off('user-reported');
            EventBus.on('user-reported',this.onUserReported);
            EventBus.off('play-notification-sound');
            EventBus.on('play-notification-sound', this.playNotificationSound);
            EventBus.off('update-feed-type-pref');
            EventBus.on('update-feed-type-pref', this.updateFeedTypePref);
            EventBus.off('update-show-notes-only-pref');
            EventBus.on('update-show-notes-only-pref', this.updateShowNotesOnlyPref);

            EventBus.off('open-create-motion-modal');
            EventBus.on('open-create-motion-modal', this.createMotionModalOpenHandler);

            EventBus.off('motion-passed');
            EventBus.on('motion-passed', this.motionPassedHandler)

            if(  window.innerWidth > 760 && window.innerWidth < 991){
                this.$nextTick(() => {
                    //this.$toast.add({severity: 'warn', summary: "Switch to landscape mode.", detail:"Based on your screen size you should change your device orientation to landscape mode.",  life:20000, group: 'center'});
                    this.notificationMessages.push({ content: "Based on your screen size you should change your device orientation to landscape mode.", severity: 'warn', id: '2' });
                })
                
            }
        },

        onUserReported(report) {
            this.$refs.submitReportModal.open(report);
        },
        onPostReported(report) {
           this.$refs.submitReportModal.open(report);
        },
        toastIconClass(message) {
            if( message.severity === 'info' ) {
                return "pi pi-info-circle";

            }
            else if( message.severity === 'warn' ){
                return 'pi pi-exclamation-triangle';
            }
            else if(message.severity === 'error') {
                return 'pi pi-times';
            }
            else {
                  return 'pi pi-check';
            }
        },

        getGroupsNotificationCount(){
            let count = this.groupChannelsUnreadCounts?.unreadCount ? this.groupChannelsUnreadCounts.unreadCount : 0;
            count += this.feedNotificationCounts?.unseen?.numGroupPosts ? this.feedNotificationCounts.unseen.numGroupPosts : 0;

            if(count > 10){
                return "10+";
            }

            return count;
        },

        showPortfolioTourPrompt() {
            // console.log("showPortfolioTourPrompt", this.$store.getters['users/getSetting'](SHOW_PORTFOLIO_OVERVIEW_PREFERENCE)?.value, 
            //     SessionStorageUtils.getParsedValue('showPortfolioTourPrompt'));
            return this.$store.getters['users/getSetting'](SHOW_PORTFOLIO_OVERVIEW_PREFERENCE)?.value !== "false" && 
                SessionStorageUtils.getParsedValue('showPortfolioTourPrompt');
        },

        paperAccountsEnabled() {
            return UserUtils.isFeatureEnabled('paperAccountsEnabled');
        },

        selectSecurityProcessor() {
            if (this.$route.fullPath.startsWith('/equity')) {
                this.selectedSecurity = this.selectedEquity;
            } else if (this.$route.fullPath.startsWith('/fund')) {
                this.selectedSecurity = this.selectedFund;
            }
        },

        initAppChannel() {
            this.appChannel = new BroadcastChannel('app_channel');
            this.appMsgHandler = (messageEvent) => {
                const data = JSON.parse(messageEvent);
                if (data.type === 'navigate') {
                    this.$router.push('/' + data.route);
                } else {
                    console.error("Unknow broadcast message received : " + JSON.stringify(data));
                }
            };

            this.appChannel.addEventListener('message', this.appMsgHandler);
         },

        initSelectedFeedTypeSettings(){
            try {
                if(this.$store && this.$store.getters['users/getSetting']) {
                    const goToQAFeed = SessionStorageUtils.getParsedValue('goToQAFeed');

                    if (goToQAFeed) {
                        sessionStorage.removeItem('goToQAFeed');
                        this.$store.commit("SET_SELECTED_FEED_TYPE", "questions");
                        this.updateUserPreference(DEFAULT_FEED, "questions");
                    } else if( !this.$store.state.registered && this.$store.state.featuredGroupFeed) {
                        this.$store.commit("SET_SELECTED_FEED_TYPE", "featuredFeed");
                        this.updateUserPreference(DEFAULT_FEED, "featuredFeed");
                    } else {
                        const defaultFeedPref = this.$store.getters['users/getSetting'](DEFAULT_FEED)?.value;
                        this.$store.commit("SET_SELECTED_FEED_TYPE", defaultFeedPref);
                    }

                    const showNotesOnlyPref = this.$store.getters['users/getSetting'](SHOW_NOTES_ONLY)?.value === 'true';
                    this.$store.commit("SET_SHOW_NOTES_ONLY", showNotesOnlyPref);
                }
            } catch (error) {
                console.error("error initSelectedFeedTypeSettings", error);
            }
        },

        updateShowNotesOnlyPref(){
            this.updateUserPreference(SHOW_NOTES_ONLY, this.showNotesOnly, 'Show Notes Only preference changed!');
        },

        updateFeedTypePref() {
            this.updateUserPreference(DEFAULT_FEED, this.selectedFeedType);
        },

        updateUserPreference(preferenceId, value, toastMsg){
            //.log("updateUserPreference", preferenceId, value);
            UserService.updateUserPreference( preferenceId, value ).then((res) => {
                if (res.data.status === 'error') {
                    // this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
                } else {
                    const obj = {
                        'id': preferenceId,
                        'value': value
                    };

                    this.$store.dispatch("users/setSetting", obj);
                    //console.log(this.$store.getters['users/settingsList']);
                    if(toastMsg){
                        this.$toast.add({severity: 'success', summary: toastMsg, life: 3000, group: 'center'});
                    }
                }
            })
            .catch((err) => {
                console.error(err);
                // this.$toast.add({ severity: 'error', summary: 'Sorry an unexpected error occurred. Please try again or contact support@invrs.com if the problem persists.', life: 3000, group: 'center' });
            });
        },

        navigateMobile() {
          if (BrowserUtils.isMobile() && this.$store.state.authorized) {
              const ROUTE_MAP = {
                  '/home': 0,
                  '/contests/competitions': 1,
                  '/education': 2,
                  '/portfolios': 3,
                  '/home?feed=markets': 4,
                  '/economy': 4,
                  '/home?feed=discover': 4,
                  '/home?feed=news': 4,
                  '/groups': 5,
              }

              // It will be empty at first when you first log in onto the app, so we manually 'click' on the home button to navigate there
              if (this.$route.path === '/') {
                  this.viewHomeFeed();
                  this.activeTab = 0;
              } else if (ROUTE_MAP[this.$route.fullPath] !== undefined) {
                  this.activeTab = ROUTE_MAP[this.$route.fullPath];
              }else if( this.$route.fullPath === '/groups/profile/'+EDUCATION_GROUP_ID ) {
                this.activeTab = 2;
              }
              else if (this.$route.fullPath.startsWith('/groups/profile')) {
                    this.activeTab = 5;
              }
          }
        },

        chatEnabled() {
            return ChatService.isChatEnabled();
        },

        groupsEnabled() {
            return GroupService.groupsEnabled();
        },

        portfoliosSettingsEnabled() {
            return PortfolioService.portfoliosSettingsEnabled();
        },

        portfoliosFeatureEnabled() {
            return PortfolioService.portfoliosFeatureEnabled();
        },

        handleSystemNotification(systemNotification) {
            
            if( systemNotification.priority == 2) {
               
                this.showAllClearNotification( systemNotification.message);
            }
            else if(  systemNotification.priority == 1 ){
                this.showWarningNotification(systemNotification.message);
            }
            else  {
                this.showInfoNotification(systemNotification.message);
            }
        },

        showAllClearNotification(msg) {
            this.notificationMessages.push({ content: msg, severity: 'success', id: '1' });
        },

        showInfoNotification(msg) {
            this.notificationMessages.push({ content: msg, severity: 'info', id: '3' });
        },

        showWarningNotification(msg) {
            this.notificationMessages.push({ content: msg, severity: 'warn', id: '2' });
        },

        onNotificationClose() {
            this.notificationMessages = [];
        },

        viewMarketsFeed() {
            this.$router.push('/home?feed=markets');
        },
         viewEconomyFeed() {
            this.$router.push('/economy');
        },
         viewPortfolioFeed() {
            this.$router.push('/portfolios');
        },
        viewDiscoverFeed() {
            this.$router.push('/home?feed=discover');
        },
        viewHomeFeed() {
            this.$router.push('/home');
        },
        viewContestsFeed() {
            this.$router.push('/contests/competitions');
        },
        viewNewsFeed() {
            this.$router.push('/home?feed=news');
        },
        viewGroups() {
            this.$router.push('/groups');
        },

        getNotificationCount() {
            if (this.$store.state.chatNotificationCounts) {
                if (this.$store.state.chatNotificationCounts.numUnread > 0) {
                   
                    return this.$store.state.chatNotificationCounts.numUnread;
                    
                
                }
            } else {
                return 0;
            }
        },

        getMentionCount() {
            if (this.$store.state.chatNotificationCounts) {
                if (this.$store.state.chatNotificationCounts.numMentions > 0) {
                    return this.$store.state.chatNotificationCounts.numMentions;
               
                }
            } else {
                return 0;
            }
        },

        shouldShowRegForm() {
            return !this.registered && this.authorized;
        },
        completeRegistration() {
            //console.log("completing registration");

            UserService.completeRegistration(this.$store.state.users.user.userId).then(resp => {
                if (resp.data.status == 'success') {
                    this.$store.commit('SET_REGISTERED', true);
                   
                    
                    this.postSignup();
                    this.notifyDependantComponents();
                }
            });
        },

        async completeRegistrationFirst() {
            

            let resp = await UserService.completeRegistration(this.$store.state.users.user.userId);
            if (resp.data.status == 'success') {
                this.$store.commit('SET_REGISTERED', true);
                //console.log("completing registration now!");
                


                this.postSignup();
                this.notifyDependantComponents();
            }

            this.doMountedRoutine();
        },

        notifyDependantComponents() {
            EventBus.emit('registration-completed');
           
  
        },

        joinGroupIfPendingInvite(inviteCode) {
            GroupService.getGroupInvitePreview(inviteCode).then(resp=> {

                if( resp.data.status === "success") {
                    LocalStorageService.removeGroupInviteCode();
                    this.$store.commit('SET_SELECTED_GROUP', resp.data.group);
                    this.$router.push('/groups/profile/' + resp.data.group.groupId + "?joinGroupByInvite=true&inviteCode=" + inviteCode);
                } else {
                    // console.log("error joining group "+ resp.data.message)
                }

            });
        },

        postSignup() {
            try {
                if(window.investmentCalculatorQMod) {
                    window.investmentCalculatorQMod.loadTools();
                }

                let groupInviteCode = LocalStorageService.getGroupInviteCode();
                if( groupInviteCode) {
                    this.joinGroupIfPendingInvite(groupInviteCode);
                }

                const signUpContestId = CookieUtils.getCookie(COOKIE_SIGN_UP_CONTEST_ID);
                if(signUpContestId) {
                    this.contestRegistrationHandler(signUpContestId);
                }

                const courseRegCode = CookieUtils.getCookie(COOKIE_COURSE_REG_CODE);
                if(courseRegCode) {
                    CookieUtils.deleteCookie(COOKIE_COURSE_REG_CODE);
                    this.courseRegistrationHandler(courseRegCode);
                }

                this.$gtag.event('conversion', {
                    method: 'Google',
                    send_to: 'AW-10876880460/0kSRCMvGs7EDEMyMwMIo'
                    });
            }catch( error ) {
                console.warn("Error encountered during postLogin call most likely logout issue " );
                console.warn(error);
            }
        },

        postLogin() {
            try {
                if(window.investmentCalculatorQMod) {
                    window.investmentCalculatorQMod.loadTools();
                }

                //  try {
                //     console.log("*********word is " + UserService.decrypt(this.$store.state.checksum));
                // } catch( error) {
                //     console.log("error revealing "+ error);
                // }

                this.activateContestsButtonPulsating();

                const signUpContestId = CookieUtils.getCookie(COOKIE_SIGN_UP_CONTEST_ID);
                const groupInviteCode = LocalStorageService.getGroupInviteCode();
                const groupProfileId = SessionStorageUtils.getParsedValue('goToGroup');
                const courseModuleId = SessionStorageUtils.getParsedValue('goToCourseModule');
                const goingToOwnProfile = SessionStorageUtils.getParsedValue('goToOwnProfile');
                const contestId = SessionStorageUtils.getParsedValue('goToContest');
                const registerForContestId = SessionStorageUtils.getParsedValue('registerForContest');
                const puzzleId = SessionStorageUtils.getParsedValue('goToPuzzle');
                const courseRegCode = SessionStorageUtils.getRawValue('courseRegCode');

                if(signUpContestId) {
                    this.contestRegistrationHandler(signUpContestId);
                } else if( groupInviteCode) {
                    this.joinGroupIfPendingInvite(groupInviteCode);
                } else if (groupProfileId) {
                    sessionStorage.removeItem('goToGroup');
                    sessionStorage.removeItem('goToCourseModule');

                    if (courseModuleId) {
                        GroupService.getGroupCourseFeeds(groupProfileId, courseModuleId).then(resp => {
                            if (resp.data.status === 'success') {
                                const courseFeed = resp.data.groupFeeds[0];

                                this.$store.commit("SET_GROUP_ACCESS_HIST", 
                                    { 
                                        groupId: groupProfileId, 
                                        conduit: {
                                            groupId: groupProfileId,
                                            type: "courseFeed", 
                                            obj: courseFeed
                                        }
                                    });

                                    this.$router.push(`/groups/profile/${groupProfileId}`);
                            } else {
                                console.log('Error trying to find course feed: ' + resp.data.message);
                            }

                        });
                    } else {
                        this.$router.push(`/groups/profile/${groupProfileId}`);
                    }
                } else if (contestId) {
                    sessionStorage.removeItem('goToContest');
                    this.$router.push(`/contests/competitions/${contestId}`);
                } else if (registerForContestId) {
                    sessionStorage.removeItem('registerForContest');
                    UserService.getDecodedGroupContestId(registerForContestId).then(resp => {
                        if( resp.data.status == 'success'){
                            this.$router.push(`/contests/competitions/${resp.data.contestId}?register=true`);
                        }
                    });
                     
                   
                } else if (puzzleId) {
                    sessionStorage.removeItem('goToPuzzle');
                    this.$router.push(`/contests/${puzzleId}`);

                } else if ( courseRegCode ){
                    sessionStorage.removeItem('courseRegCode');
                    this.courseRegistrationHandler(courseRegCode);

                    
                } else if (!BrowserUtils.isMobile()) {
                    if (goingToOwnProfile) {
                        sessionStorage.removeItem('goToOwnProfile');
                        this.$router.push('/profile');
                    } else {
                        this.handleTourPrompt();
                    }
                }

            }catch( error ) {
                console.warn("Error encountered during postLogin call most likely logout issue " );
                console.warn(error);
            }
        },

        displayRegistrationModal() {},

        contestRegistrationHandler(signUpContestId) {
            CookieUtils.deleteCookie(COOKIE_SIGN_UP_CONTEST_ID);

            this.$router.push(`/contests/competitions/${signUpContestId}?register=true`);
            
            
        },

         courseRegistrationHandler(courseRegCode) {
            
            GroupService.decodeGroupCourseRegCode(courseRegCode).then(resp => {
                if( resp.data.status == 'success'){
                     

                    this.$router.push(`/groups/profile/${resp.data.regParams}`);
                }
                else {
                    console.log("error decoding course reg code", resp.data);
                }
            }).catch(err => {
                console.log("error decoding course reg code", err);
            })
           
            
            
        },

        showLogout() {
            //console.log("logging out!");
            this.loggingOut = true;
            EventBus.emit('remove-tour');
        },
        onChatButtonClick() {
            this.showChat = true;

            this.$refs.chatDrawer.open();
            this.$store.commit('CLEAR_CHAT_COUNTS');
        },
        // onPaperAccountsButtonClick() {
        //     EventBus.emit('open-paper-accounts-modal');
        // },
        openStatusEditorDialog() {
            this.$refs.statusEditorModal.open();
        },
        getINVRSExchange(exchangeSymbol) {
            if (exchangeSymbol == 'NASDAQ') {
                return 'XNAS';
            } else if (exchangeSymbol == 'NYSE') {
                return 'XNYS';
            } else if (exchangeSymbol == 'OTC') {
                return 'OOTC';
            } else if (exchangeSymbol == 'TSX') {
                return 'XTSE';
            } else if (exchangeSymbol == 'TSXV') {
                return 'XTSX';
            } else if (exchangeSymbol == 'CSE') {
                return 'XCNQ';
            } else if (exchangeSymbol == 'AMEX') {
                return 'ARCX';
            } else if (exchangeSymbol == 'AQL') {
                return 'AQL';
            } else {
                return null;
            }
        },
        onDocumentClick() {
            if (!this.searchClick && this.searchActive) {
                this.onSearchHide();
            }

            if (!this.userMenuClick) {
                this.topbarUserMenuActive = false;
            }

            if (!this.notificationMenuClick) {
                this.topbarNotificationMenuActive = false;
            }

            if (!this.tourMenuClick) {
                this.topbarTourMenuActive = false;
            }

            if (!this.rightMenuClick) {
                this.rightMenuActive = false;
            }

            if (!this.menuClick) {
                if (this.isSlim()) {
                    this.EventBus.emit('reset-active-index');
                    this.menuActive = false;
                }

                if (this.overlayMenuActive || this.staticMenuMobileActive) {
                    this.hideOverlayMenu();
                }

                this.unblockBodyScroll();
            }

            if (this.configActive && !this.configClick) {
                this.configActive = false;
            }

            if (!this.rootMenuItemClick && !this.searchClick) {
                /* SCENARIO:  we want to repopulate the search input bar everytime with the current security's symbol and 
                company name when we click anywhere except for the search bar and side menu bar items*/

                this.EventBus.emit('populate-search-input');
            }

            this.searchClick = false;
            this.rootMenuItemClick = false;
            this.configClick = false;
            this.userMenuClick = false;
            this.rightMenuClick = false;
            this.notificationMenuClick = false;
            this.tourMenuClick = false;
            this.menuClick = false;
        },
        onMenuClick() {
            this.menuClick = true;
        },
        onMenuButtonClick(event) {
            this.menuClick = true;
            this.topbarUserMenuActive = false;
            this.topbarNotificationMenuActive = false;
            this.topbarTourMenuActive = false;
            this.rightMenuActive = false;

            if (this.isOverlay()) {
                this.overlayMenuActive = !this.overlayMenuActive;
            }

            if (this.isDesktop()) {
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            } else {
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
                if (this.staticMenuMobileActive) {
                    this.blockBodyScroll();
                } else {
                    this.unblockBodyScroll();
                }
            }

            event.preventDefault();
        },
        onMenuItemClick(event) {
            if (!event.item.items) {
                this.EventBus.emit('reset-active-index');
                this.hideOverlayMenu();
            }
            if (!event.item.items && this.isSlim()) {
                this.menuActive = false;
            }

            if (event.item.label === "Groups") {
                EventBus.emit("groups-click");
            }
        },
        onRootMenuItemClick() {
            this.menuActive = !this.menuActive;
            this.rootMenuItemClick = true;
            // this.$store.commit('SET_SEARCH_BAR_TEXT', null);
            // this.EventBus.emit('populate-search-input'); // SCENARIO: Calling this will empty the input bar completely whenever we click on any of the side menu items
        },
        onTopbarUserMenuButtonClick(event) {
            this.userMenuClick = true;
            this.topbarUserMenuActive = !this.topbarUserMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.notificationMenuClick = true;
            this.topbarNotificationMenuActive = !this.topbarNotificationMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarTourMenuButtonClick(event) {
            this.tourMenuClick = true;
            this.topbarTourMenuActive = !this.topbarTourMenuActive;

            this.hideOverlayMenu();

            event.preventDefault();
        },
        onTopbarCalculatorMenuButtonClick() {
            this.$refs.investmentCalculatorModal.open();
        },
        toggleSearch() {
            if (this.searchActive) {
                this.onSearchHide();
            } else {
                this.EventBus.emit('before-search-show');
                this.searchActive = true;
                this.searchClick = true;
            }
        },
        onSearchClick() {
            this.searchClick = true;
        },
        onSearchHide() {
            this.EventBus.emit('before-search-hide');
            this.searchActive = false;

            this.searchClick = false;
        },
        onRightMenuClick() {
            this.rightMenuClick = true;
        },
        onRightMenuButtonClick(event) {
            this.rightMenuClick = true;
            this.rightMenuActive = !this.rightMenuActive;
            this.hideOverlayMenu();
            event.preventDefault();
        },
        onConfigClick() {
            this.configClick = true;
        },
        onConfigButtonClick() {
            this.configActive = !this.configActive;
            this.configClick = true;
        },
        onCloseChatDrawer() {
            this.showChat = false;
            this.$store.commit('CLEAR_CHAT_COUNTS');
            
        },
        hideOverlayMenu() {
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
            this.unblockBodyScroll();
        },
        blockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.add('blocked-scroll');
            } else {
                document.body.className += ' blocked-scroll';
            }
        },
        unblockBodyScroll() {
            if (document.body.classList) {
                document.body.classList.remove('blocked-scroll');
            } else {
                document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
            }
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },
        isOverlay() {
            return this.layoutMode === 'overlay';
        },
        isDesktop() {
            return window.innerWidth > 991;
        },

        importCompleteHandler(importJob) {
            const {accountName, accountNumber, institutionName} = importJob;

            this.$store.dispatch({
                type: 'clearPortfolioState',
            });
            
            this.$toast.add({ severity: 'success', summary: `${institutionName} account "${accountName}(${accountNumber})" connected.`, life: 7000, group: 'center' });

        },

        importFailedHandler(importJob) {
            const {accountName, institutionName} = importJob;

            this.$toast.add({ severity: 'error', summary: `Error connecting ${institutionName} account "${accountName}".`, detail: 'Please try again or contact INVRS support for help by clicking your profile picture.', life: 4000, group: 'center' });
        },

        paymentCompleteHandler(data) {
            const payload = data.payload;
            if (payload.status === 'paid') {
                this.$store.dispatch({
                    type: 'users/setUser',
                    user: payload.user,
                })

                AuthService.changeUserObject(payload.user);

                this.$toast.add({ severity: 'success', summary: `${payload.subscription.name} has been added to your account.`, life: 4000, group: 'center' });

                EventBus.emit('refresh-cash-accounts-balance');
            }
        },

        subscriptionCancelledHandler(data) {
            const user = data.payload;
            this.$store.dispatch({
                type: 'users/setUser',
                user,
            })
            
            this.$toast.add({ severity: 'success', summary: `Your subscription has been cancelled.`, life: 4000, group: 'center' });
        },

        openDmChannelHandler(channel) {
            this.$refs.chatDrawer.open(channel);
        },
        
        openCreateDmChannelModalHandler(user) {
            this.$refs.chatDrawer.openInviteUserModal(user);
        },

        alertNotificationHandler(data) {
            this.$refs.alertNotificationModal.open(data);
        },

        checkFreeTrialStatus() {
            const trial = this.$store.state.users.user?.subscriptions.find(sub => sub.subscriptionId === 5);
            const premium = this.$store.state.users.user?.subscriptions.find(sub => sub.subscriptionId === 4 || sub.subscriptionId === 3);
            let daysLeft;

            if (trial && !premium) {
                const today = new Date();
                const trialEnd = new Date(trial.stopDate);
                daysLeft = Math.floor((trialEnd - today) / (1000 * 60 * 60 * 24));
            }

            if (daysLeft === 0) {
                this.$toast.add({ severity: 'info', summary: `Your free trial is ending today. Upgrade to Premium to continue using its features.`, life: 4000 });
                return;
            }

            if (daysLeft === 1) {
                this.$toast.add({ severity: 'info', summary: `Your free trial is ending tomorrow. Upgrade to Premium to continue using its features.`, life: 4000 });
                return;
            }

            if (daysLeft <= 7) {
                this.$toast.add({ severity: 'info', summary: `Your free trial is ending in ${daysLeft} days. Upgrade to Premium to continue using its features.`, life: 4000 });
                return;
            }
        },

        handleTourPrompt() {

            // Since anything in session storage lasts throughout refresh, this will exist until we close the tab or log out
            if (SessionStorageUtils.getParsedValue('showTourPrompt') === null) {
                sessionStorage.setItem('showTourPrompt', true);
            }  
            if (SessionStorageUtils.getParsedValue('showPortfolioTourPrompt') === null) {
                sessionStorage.setItem('showPortfolioTourPrompt', true);
            }

            if ((this.$route.fullPath == '/' || this.$route.fullPath == '/home') && this.shouldShowTourPrompt) {
                this.$refs.tourPromptModal.open(HOME_TOUR_NAME);
                sessionStorage.setItem('showTourPrompt', false);
            } else if (this.paperAccountsEnabled() && this.$route.fullPath == '/portfolios' && this.showPortfolioTourPrompt()) {
                this.$refs.tourPromptModal.open(PORTFOLIO_TOUR_NAME);
                sessionStorage.setItem('showPortfolioTourPrompt', false);
            }

            // console.log("handleTourPrompt", this.paperAccountsEnabled(), this.$route.fullPath == '/portfolios', this.$route.fullPath, this.showPortfolioTourPrompt());
        },

        tradingPaperAccountsModalOpenHandler(data = null) {
            PortfolioService.getListOfAccounts().then((resp) => {
                if (resp.data.status === 'success') {
                    this.tradingAccounts = resp.data.accounts.filter((acct) => 
                        acct.accountTypeId === PAPER_TRADING_ACCOUNT_TYPE['ID'] || acct.accountTypeId === COMPETITION_ACCOUNT_TYPE['ID']  || acct.accountTypeId === EDUCATIONAL_ACCOUNT_TYPE['ID']);
                }

                let account = null;
                let security = null;
                let fromGroup = false;
                let fromUserProfile = false;

                if (this.$route.fullPath.startsWith('/portfolios') || this.$route.fullPath.startsWith('/groups') || this.$route.fullPath.includes('profile')) {
                    security = data?.security;
                    const isPaperTradingOrCompAccount = () => {
                        if (this.currentlySelectedPortfolio && [PAPER_TRADING_ACCOUNT_TYPE['ID'], COMPETITION_ACCOUNT_TYPE['ID'], EDUCATIONAL_ACCOUNT_TYPE['ID']].includes(this.currentlySelectedPortfolio.accountTypeId) 
                            && this.currentlySelectedPortfolio.userId == this.$store.state.users.user.userId) {
                            account = this.currentlySelectedPortfolio;
                        } 
                    }

                    if (this.$route.fullPath.startsWith('/groups'))  {
                        if (this.selectedGroupConduit && this.selectedGroupConduit.type === 'portfolio') {
                            fromGroup = true;
                            isPaperTradingOrCompAccount();
                        }
                    } else if (this.$route.fullPath.includes('profile')) { 
                        fromUserProfile = true;
                        isPaperTradingOrCompAccount();
                    } else {
                        isPaperTradingOrCompAccount();
                    }
                } else if (this.$route.fullPath.startsWith('/equity') || this.$route.fullPath.startsWith('/fund')) {
                    security = this.selectedSecurity;
                }

                if (this.tradingAccounts.length > 0) {

                    const inputData = {
                        security: security,
                        account: account,
                        fromGroup: fromGroup,
                        fromUserProfile: fromUserProfile
                    };

                    this.$refs.placeTradeOrderModal.open(inputData);
                } else {
                    this.$refs.addPaperAccountModal.openFromBuySellButton();
                }
            });
        },

        createdAccountCallback(data) {
            this.tradingAccounts.push(data.account);

            if (this.$route.fullPath.startsWith('/portfolios')) {
                EventBus.emit('get-accounts');
            }

            if (data.openPlaceTradeOrderModal) {    
                this.$refs.placeTradeOrderModal.open(data)
            }
        },

        createAccountCallback() {

            this.$refs.addPaperAccountModal.openFromPlaceTradeOrderModal();
        },

        createMotionModalOpenHandler(params) {
            this.$refs.createMotionModal.open(params);
        },

        setupNotificationSounds() {
            this.dmNotificationsAudioPlayer.loadAudio('/assets/sounds/dm_notification_sound.mp3');
            this.groupActivityNotificationsAudioPlayer.loadAudio('/assets/sounds/group_activity_notification_sound.mp3');
            this.generalNotificationsAudioPlayer.loadAudio('/assets/sounds/general_notification_sound.mp3');
        },

        playNotificationSound(type) {
            if (type === ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE) {
                if (this.$store.getters['users/getSetting'](ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE).value === "true" && 
                    this.$store.getters['users/getSetting'](ENABLE_GENERAL_NOTIFICATION_SOUNDS_PREFERENCE).value === "true") {
                    this.generalNotificationsAudioPlayer.playAudio();
                }
            } else if (type === ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE) {
                const onGroupProfile = this.$route.fullPath.startsWith('/groups/profile');
                if ((!onGroupProfile || (onGroupProfile && this.selectedGroupConduit && this.selectedGroupConduit.type !== 'channel')) && (this.$store.getters['users/getSetting'](ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE).value === "true" && 
                    this.$store.getters['users/getSetting'](ENABLE_GROUP_ACTIVITY_NOTIFICATION_SOUNDS_PREFERENCE).value === "true")) {
                    this.groupActivityNotificationsAudioPlayer.playAudio();
                }
            } else if (type === ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE) {
                if (!this.showChat && (this.$store.getters['users/getSetting'](ENABLE_ALL_NOTIFICATION_SOUNDS_PREFERENCE).value === "true" && 
                this.$store.getters['users/getSetting'](ENABLE_DM_NOTIFICATION_SOUNDS_PREFERENCE).value === "true")) {
                    this.dmNotificationsAudioPlayer.playAudio();
                }
            }
        },

        motionPassedHandler(motion) {
            if (UserUtils.isUser(motion.userId)) {
                this.motionPassedBodyText = `Congratulations! <b>${motion.groupName}</b> has passed your motion, <b>${motion.title}</b>.`
            } else {
                this.motionPassedBodyText = `<b>${motion.groupName}</b> has passed <b>${motion.title}</b>.`
            }

            this.$refs.motionPassedConfirmationModal.open();

            this.$confetti.start({
                particlesPerFrame: 5,
                windSpeedMax: 0
            });

            setTimeout(() => {
                this.$confetti.stop();
            }, 2500)


        }
    },
};
</script>

<style lang="scss">
@import 'App.scss';
</style>

<style scoped>

/* tab-menu speeddial css start */

::v-deep(.tab-menu .p-speeddial-list li:nth-child(1) a::before) {
    content: "News";
    background: #32364e;
    border-radius: 10px;
    padding: 6px;
}
::v-deep(.tab-menu .p-speeddial-list li:nth-child(2) a::before) {
    content: "Discover";
    background: #32364e;
    border-radius: 10px;
    padding: 6px;
}
::v-deep(.tab-menu .p-speeddial-list li:nth-child(3) a::before) {
    content: "Markets";
    background: #32364e;
    border-radius: 10px;
    padding: 6px;
}
::v-deep(.tab-menu .p-speeddial-list li:nth-child(4) a::before) {
    content: "Economy";
    background: #32364e;
    border-radius: 10px;
    padding: 6px;
}
::v-deep(.tab-menu .p-speeddial-list li) {
    display: flex;
    align-items: center;
    gap:4px;
}

::v-deep(.tab-menu .p-speeddial-list) {
    align-items: end;
    margin-bottom:18px;
}

::v-deep(.tab-menu .p-highlight .p-speeddial .p-speeddial-button) {
    color: #33cc99 !important;
}

::v-deep(.tab-menu .p-speeddial) {
    position: fixed;
    bottom:12px;
    width: 20%;
}

::v-deep(.tab-menu .p-speeddial .p-speeddial-item) {
    margin: 4px 0;
}

::v-deep(.tab-menu .p-speeddial button) {
    display: flex;
    flex-direction: column;
    border: 0;
    color: #32364E;
    background: #fff;
    overflow: visible;
    justify-content:center;
    width: 100%;
}

::v-deep(.tab-menu .p-speeddial .p-button-label) {
    visibility: visible;
    width:100%;
    font-size: 0.7rem;
}

::v-deep(.tab-menu .p-speeddial .p-button-label::after) {
    content: "Tools";
}

.tab-menu .p-speeddial button:not(.p-disabled).p-focus,
::v-deep(.tab-menu .p-speeddial button:not(.p-disabled):focus),
::v-deep(.tab-menu .p-speeddial button:focus:not(.focus-visible)) {
    outline: none !important;
    box-shadow: none !important;
}
::v-deep(.tab-menu .p-speeddial-list) {
    display: none;
}
::v-deep(.tab-menu .p-speeddial-opened .p-speeddial-list) {
    display: flex;
}
::v-deep(.tab-menu .p-speeddial-list a) {
    border-radius: unset;
    color: white;
    font-size: 12px;
}
::v-deep(.tab-menu .p-speeddial-list a span) {
    background:#32364e;
    border-radius: 30px;
    padding: 6px;
    width:unset;
    margin-left:4px;
}

/* tab-menu speeddial css end */

::v-deep(.tab-menu .icon-troubleshoot) {
    position:relative;
    display:inline-block;
    height: 20px;
    content : url(/assets/images/tools.png);
}

::v-deep(.tab-menu .material-symbols-outlined),
::v-deep(.tab-menu .material-icons) {
    font-size: 16px !important;
    margin-bottom: -1px;
    text-align: center;
}

.mobile-bottom-menu-item {
    font-size:0.7rem;
    position: relative;
}
.mobile-bottom-menu-item img {
    width: 20px;
}

.menuitem-notification-badge {
    min-width: 18px;
    width: 18px;
    height: 18px;
    position: absolute;
    right: 2px;
    top: -5px;
    font-size: 10px;
    line-height: 1.25rem;
    background: #e63e3e;
    text-align: center;
    padding: 0;
}

::v-deep(.tab-menu) {
    position: fixed;
    bottom: 0;
    left: 0;
    height: 54px;
    width: 100%;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

::v-deep(.tab-menu .p-tabmenu-nav) {
    height: 100%;
    display: flex;
}

::v-deep(.tab-menu .p-tabmenuitem) {
    width: 33.33%;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
}

::v-deep(.tab-menu .p-tabmenuitem > button) {
    display: flex;
    height: 100%;
    width: 100%;
    border: 0;
    color: #32364E;
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::v-deep(.tab-menu .p-highlight > button) {
    color: #33cc99;
}

::v-deep(.tab-menu .tab-container p) {
    margin: 0px;
}

.tab-div {
    padding: 0px !important;
}

::v-deep(.pi-team-dollar::before) {
    content: '\e941 ';
    font-size: 1rem;
}
::v-deep(.pi-team-dollar:after) {
    content: '\e96b ';
    font-size: 0.7rem;
    margin-left: -2px;

    vertical-align: super;
}

::v-deep(.pi-feed::before) {
    content: '\e941 ';
    font-size: 1rem;
}
::v-deep(.pi-feed::after) {
    content: '\e967';
    font-size: 0.8rem;
    margin-left: -3px;
    vertical-align: super;
}

::v-deep(.pi-pick-list::before) {
    content: '\e967';
}
::v-deep(.pi-pick-list::after) {
    content: '\e937';
    font-size: 0.8rem;
    margin-left: -2px;
    vertical-align: super;
}

::v-deep(.pi-screener::before) {
    content: '\e967';
}
::v-deep(.pi-screener::after) {
    content: '\e94c';
    font-size: 0.8rem;
    margin-left: -3px;
    vertical-align: super;
}
::v-deep(.pi-analyze::before) {
    content: '\e96d';
}
::v-deep(.pi-analyze::after) {
    content: '\e908';
    font-size: 1rem;
    margin-left: -3px;
    vertical-align: super;
}

::v-deep(.pi-sandbox::before) {
    content: '\e98b';
    /* \e98b"; */
    font-size: 1.4rem;

    vertical-align: -15px;
}
::v-deep(.pi-sandbox::after) {
    content: '\e942';
    font-size: 1rem;
    margin-left: -15px;
    /* vertical-align:super; */
}

::v-deep(.pi-notes::before) {
    content: '\e958';
}
::v-deep(.pi-notes::after) {
    content: '\e942';
    font-size: 1rem;
    margin-left: -3px;
    vertical-align: super;
}

::v-deep(.pi-forum::before) {
    /* content: "\e967 "; */
    content: '\e97e ';
    font-size: 1rem;
}
::v-deep(.pi-forum:after) {
    /* content: "\e97f "; */
    content: '\e967 ';
    font-size: 0.7rem;
    margin-left: -2px;

    vertical-align: super;
}

::v-deep(.layout-content) {
    background-color: #f2f4fa;
}

.chat-floating-badge {
    bottom: 3rem;
    right: 45px;
    background-color: #e63e3e;
    color: #ffffff;
    width: 21px;
    height: 21px;
    line-height: 1.6rem;
    z-index: 1100;

    display: block;
    position: fixed;

    text-align: center;

    transition: background-color 0.2s;
    overflow: hidden;
    cursor: pointer;

    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    border-radius: 50%;

    transform: rotate(0deg);
    transition: transform 1s;
    padding: 0;
}

.chat-floating-button:hover {
    animation: chatbuttonhover 1s infinite;
}

@keyframes chatbuttonhover {
    0% {
        transform: scale(1,1);
        background: none;
    }
    50% {
        transform: scale(1.3,1.3);
        background: rgba(166, 166, 166, 0.6);
    }
    100% {
        transform: scale(1,1);
        background: none;
    }
}


.chat-floating-mention-badge {
    
    background-color: #693BF5;
    bottom: 3rem;
    right: 5px;
    color: #ffffff;
    width: 21px;
    height: 21px;
    line-height: 1.6rem;
    z-index: 1100;

    display: block;
    position: fixed;

    text-align: center;

    transition: background-color 0.2s;
    overflow: hidden;
    cursor: pointer;

    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);
    border-radius: 50%;

    transform: rotate(0deg);
    transition: transform 1s;
    padding: 0;
   
}

.chat-floating-button {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    width: 4rem;
    height: 4rem;

    right: 0;
    overflow: hidden;
    cursor: pointer;

    z-index: 1099;
    bottom: 1rem;
    right: 10px;

    border-radius: 50%;
}

.chat-floating-button img{
    width: 3rem;
}

.paper-accounts-floating-button {
    display: block;
    position: fixed;
    width: 3rem;
    height: 3rem;

    color: #ffffff;
    text-align: center;

    right: 0;

    transition: background-color 0.2s;
    overflow: hidden;
    cursor: pointer;

    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%);

    z-index: 1099;
    bottom: 4.5rem;
    right: 10px;

    border-radius: 50%;
    background: #32364e; /*#33cc99;*/
}
.paper-accounts-floating-button i {
    font-size: 11px;
    line-height: inherit;
    transform: rotate(0deg);
    transition: transform 1s;
    /* color: #32364e; */
}
.paper-accounts-floating-button .text {
    display: block;
    font-size: 9px;
    /* color: #32364e; */
}

.layout-config {
    width: 30rem;
    z-index: 2147483003;
    bottom: 1rem;
    top: unset;
    height: auto;
    right: 1rem;
    padding: 0;
}

.layout-content-wrapper {
    margin-left: 5rem !important;
}

::v-deep(.layout-sidebar .navigation-menu .layout-menu-container:first-of-type .contests-menu-button.pulsating .layout-menuitem-image) {
    animation: contestsbuttonpulsating 1s ease infinite;
}
/* ::v-deep(.layout-menuitem-image > img) {
    max-width:34px;
} */


@keyframes contestsbuttonpulsating {
    0% {
        transform: scale(1,1);
        box-shadow: none;
    }
    40% {
        box-shadow: 0 0 0 0 rgba(51, 204, 153, 0.3),
                0 0 0 2px rgba(51, 204, 153, 0.3),
                0 0 0 8px rgba(51, 204, 153, 0.3),
                0 0 0 14px rgba(51, 204, 153, 0.3);
    }
    50% {
        transform: scale(1.3,1.3);
        box-shadow: 0 0 0 2px rgba(51, 204, 153, 0.3),
                0 0 0 8px rgba(51, 204, 153, 0.3),
                0 0 0 14px rgba(51, 204, 153, 0.3),
                0 0 0 20px rgba(51, 204, 153, 0);
    }
    60% {
        box-shadow: 0 0 0 4px rgba(51, 204, 153, 0.3),
                0 0 0 14px rgba(51, 204, 153, 0.3),
                0 0 0 20px rgba(51, 204, 153, 0),
                0 0 0 26px rgba(51, 204, 153, 0);
    }
    100% {
        transform: scale(1,1);
        box-shadow: none;
    }
}
/* .chat-floating-button > i {
        font-size: 30px !important;
    color: #32364e;
} */
@media (max-width: 990px) {
    .layout-content-wrapper {
        margin-left: 1rem !important;
    }
}

@media (max-width: 760px) {
    .layout-content-wrapper {
        padding-top: 4.8rem;
        margin-left: 0 !important;
    }

    .layout-content-wrapper.home-page {
        padding-top: 8.2rem;
    }

    .logging-out-overlay{
        font-size: 2rem !important;
        left:20% !important;
        top:30% !important;
        margin-left: 0 !important;
    }
}

.logging-out-overlay{
    position:absolute;
    left:50%;top:30%;
    margin-left: -12rem;
    font-size: 3rem;
}
</style>
