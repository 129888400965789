<template>
    <template>
        <Dialog :header='headerLabel' v-model:visible="display" :modal="true" :draggable="false" class='place-trade-order-modal' @hide="onHide">
            <template v-if='!loading'>
                <Dropdown class="account-dropdown dropdown" :options="[...tradingAccounts, {name: '- Create New Account -'}]" 
                :placeholder="accountDropdownPlaceholder" panelClass="account-dropdown-menu" >
                    <template #option="slotProps">
                        <div :disabled="accountDisabled(slotProps.option)" @click='selectAccount(slotProps.option)' v-if='slotProps.option.investmentAccountId !== undefined'>{{slotProps.option.name}} <span class='account-type'>({{accountTypeLabel(slotProps.option.accountTypeId)}} {{currencyUtils.getCurrencySymbolViaId(slotProps.option.currencyId)}})</span></div>
                        <div @click='onCreateAccountClick' v-else>{{slotProps.option.name}}</div>
                    </template>
                </Dropdown>

                <div class='order-section' v-if='account'>
                    <div class='header-content'>
                        <span>Account: <b>{{account.accountName || account.name}}</b></span>
                        <template v-if="selectedSecurity?.symbol && position">
                            <div class="position-summary" v-if="!isMobile">
                                <span>Current Position: 
                                <span :class="accountPositionClass">{{position}}</span></span> 
                                <template v-if="position !== 'None'">
                                    <span>Quantity: <b>{{ positionQuantity }}</b></span>  
                                    <span>Avg Cost Per Unit: <b>{{ positionAvgCostPerUnit  }}</b></span>
                                </template>
                            </div>
                            <template v-else>
                                <span>Current Position: 
                                <span :class="accountPositionClass">{{position }}</span></span> 
                                <template v-if="position !== 'None'">
                                    <span>Quantity: <b>{{ positionQuantity }}</b></span>  
                                    <span>Avg Cost Per Unit: <b>{{ positionAvgCostPerUnit }}</b></span>
                                </template>
                            </template>
                        </template>
                        <span>Currency: <b>{{currency}}</b></span>
                    </div>

                    <div class='form-section'>
                        <!-- Security search bar will only appear if we're creating an order from the actual orders page from Portfolios -->
                        <div class='input-section'>
                            <span class="p-float-label p-input-icon-left security-search-bar-container" v-click-away="onSecuritySearchClickAway">
                                <AutoComplete id="securitySearchBarForTrades" ref='securitySearchBar' placeholder="Select a security" v-model="selectedSecurity" 
                                    :suggestions="securityList" @complete="searchSecurities($event)" field="symbol" optionGroupLabel="label" optionGroupChildren="items"
                                    panelClass="security-search-trade-order-panel" @item-select="onSecuritySelected($event)" >
                                    <template #item="slotProps">

                                        <span class='symbol'>{{slotProps.item.symbol.replace("/", ".")}}</span><br>
                                        <small>
                                            {{(slotProps.item.company ? slotProps.item.company : slotProps.item.name)}}
                                            {{ (slotProps.item.exchangeSymbol ? " - "+securityFormatter.getFormattedExchange(slotProps.item.exchangeSymbol): "")}}
                                        </small>
                                    </template>
                                </AutoComplete>
                                <i class="pi pi-search" />
                                <label for="securitySearchBarForTrades" class='input-label'>Security</label>
                            </span>

                            <div class='quote-data-section' v-if='currentSecurity'>
                                <QuoteFeedTable :askPrice="askPrice" :askSize="askSize" :bidPrice="bidPrice" :bidSize="bidSize"
                                :highValue="highValue" :lowValue="lowValue" :openValue="openValue" :volume="volume" />
                            </div>
                        </div>



                        <div class='input-section'>
                            <div class="p-float-label">
                                <Dropdown id="orderTypeDropdown" class="order-type-dropdown dropdown" v-model="orderTypeOption" optionLabel="name" :options="orderTypeOptions" 
                                optionDisabled="disabled" panelClass='order-type-dropdown-panel' @change="orderTypeSelected">
                                    <template #value="slotProps">
                                        <div v-if="slotProps.value" class="flex align-items-center">
                                            <span>{{getOrderTypeName(slotProps.value)}}</span>
                                        </div>
                                        <span v-else>
                                            {{ slotProps.placeholder }}
                                        </span>
                                    </template>
                                    <template #option="slotProps">
                                        <div class="order-type-item-row">
                                            <span>{{getOrderTypeName(slotProps.option)}}</span>
                                            <Button icon='pi pi-info' class='order-type-info-button blue-button' v-if='slotProps.option.description !== undefined' v-tooltip.right="{value: slotProps.option.description}"/>
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="orderTypeDropdown" class='input-label'>Order Type</label>
                            </div>
                            <div class='selected-order-type-description' v-if="orderTypeOption">{{orderTypeOption.description}}</div>
                        </div>
                        
                        <template v-if='isNonMarketOrderType(orderTypeOption?.name)'>
                            <div class='input-section' v-if="STOP_ORDER_TYPES.includes(orderTypeOption?.name)">
                                <span class="p-float-label p-input-icon-right">
                                    <i class="pi pi-dollar" />
                                    <InputNumber id="stopPriceInput" class='price-input' v-model="stopPrice" mode="decimal" :minFractionDigits="2" :min="0"/>
                                    <label for="stopPriceInput" class='input-label'>Stop Price</label>
                                </span>
                            </div>
                            <div class='input-section' v-if="LIMIT_ORDER_TYPES.includes(orderTypeOption?.name)">
                                <span class="p-float-label p-input-icon-right">
                                    <i class="pi pi-dollar" />
                                    <InputNumber id="limitPriceInput" class='price-input' v-model="limitPrice" mode="decimal" :minFractionDigits="2" :min="0"/>
                                    <label for="limitPriceInput" class='input-label'>Limit Price</label>
                                </span>
                            </div>
                        </template>

                        <div class='input-section'>
                            <div :class="{'p-float-label': true, 'input-error': v$.quantity.$error }">
                                <InputNumber id='quantityInput' :class="{'quantity-input': true, 'input-error': v$.quantity.$error}" v-model="v$.quantity.$model" :min="0" @input="resetError('quantity')"/>
                                <label for="quantityInput" class='input-label'>Quantity</label>
                            </div>
                            <span class='error-message' v-if="v$.quantity.$error">{{v$.quantity.grossAmountTooLarge.$message}}</span>
                        </div>  
                        
                        <div class='input-section'>
                            <div class="p-float-label">
                                <Dropdown id='durationTypeDropdown' :class="durationTypeDropdownClass" v-model="durationTypeOption" :options="durationTypeOptions" 
                                optionLabel="name" panelClass="duration-type-dropdown-panel" :disabled="durationTypeDropdownDisabled" :placeholder="durationTypeDropdownPlaceholder"/>
                                <label for="durationTypeDropdown" class='input-label'>Duration</label>
                            </div>
                        </div>  
                    </div>
                </div>
            </template>
            <template v-else>
                <div class='loading-logo-container'>
                    <span class="material-symbols-outlined loading-logo">currency_exchange</span>
                    <span>{{loadingText}}</span>
                </div>
            </template>

            <template #footer>
                <Button label="Place Order" class='place-order-button green-button__primary' @click="openConfirmModal" :loading="loading" :disabled="disablePlaceOrderButton"/>
            </template>
        </Dialog>

        <ConfirmModal ref='confirmOrderModal' headerText="Please Confirm Your Order" @confirmed="onConfirmPlaceOrderCallback" @cancelled="onPlaceOrderCancelledCallback" :disableConfirmButton="false" :confirmButtonGreen="true">
            <template #custom-header>
                <img class='confirm-order-icon' src="/assets/images/tocm.png" />
            </template>
            <template #content>
                <div class='estimate-order-content'>
                    <div class='header'>Account: <b>{{account.name}}</b></div>

                    <div class="p-grid">
                        <div class='p-sm-12 only-cell'>
                            <span class='header'>Trade</span>
                        </div> 
                        <div class='p-sm-6 left-col'>
                            <table class='trade-order-table'>
                                <tr>
                                    <td class='left-col'>Buy/Sell:</td>
                                    <td class='right-col'><span class='order-value'>{{orderTypeOption.tradeType === BUY_TRADE_TYPE['ID'] ? BUY_TRADE_TYPE['NAME']: SELL_TRADE_TYPE['NAME']}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Security:</td>
                                    <td class='right-col'><span class='order-value'>{{selectedSecurity.symbol}} 
                                        <span v-if="!isMobile"> ({{selectedSecurity.companyName}})</span>
                                    </span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Quantity:</td>
                                    <td class='right-col'><span class='order-value'>{{quantity}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>{{isMobile ? 'Type': 'Order Type'}}:</td>
                                    <td class='right-col'><span class='order-value'>{{orderTypeOption.name}}</span></td>
                                </tr>
                                <tr v-if="NON_MARKET_ORDER_TYPES.includes(orderTypeOption.name)">
                                    <template v-if="STOP_ORDER_TYPES.includes(orderTypeOption.name)">
                                        <td class='left-col'>Stop Price:</td>
                                        <td class='right-col'><span class='order-value'>{{formatter.format(stopPrice, '$0.00')}}</span></td>
                                    </template>
                                    <template v-else>
                                        <td class='left-col'>Limit Price:</td>
                                        <td class='right-col'><span class='order-value'>{{formatter.format(limitPrice, '$0.00')}}</span></td>
                                    </template>
                                </tr>
                            </table>
                        </div>
                        <div class='p-sm-6 right-col'>
                            <table class='estimated-trade-order-table'>
                                <tr>
                                    <td class='left-col'>{{isMobile ? 'Est. Price': 'Estimated Price'}}: <i class="pi pi-info-circle" v-tooltip.top="basedOnText"></i></td>
                                    <td class='right-col'><span class='price-value'>{{formatter.format(estimatedPrice, '$0.00')}}</span> <b v-if="exchangeRate">{{estimatedPriceCurrency}}</b></td>
                                </tr>
                                 <tr>
                                    <td class='left-col'>{{isMobile ? 'Est. Sub-Total': 'Estimated Sub-Total'}}:</td>
                                    <td class='right-col'><span class='price-value'>{{formatter.format(estimatedTotalPrice, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Fee:</td>
                                    <td class='right-col'>
                                        <span class='price-value' v-if="orderTypeOption.tradeType === BUY_TRADE_TYPE['ID']">{{formatter.format(fee, '$0.00')}}</span>
                                        <span class='price-value negative' v-else>-{{formatter.format(fee, '$0.00')}}</span>
                                    </td>
                                </tr>
                                <tr v-if="exchangeRate">
                                    <td class='left-col'>Exchange Rate:</td>
                                    <td class='right-col'><span class='price-value'>{{exchangeRate}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>{{isMobile ? 'Est. Total': 'Estimated Total Amount'}}:</td>
                                    <td class='right-col'><span class='price-value'>{{formatter.format(estimatedNetAmount, '$0.00')}}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div class='p-sm-12 only-cell'>
                            <span class='header'>Account Balances</span>
                        </div>
                        <div class='p-sm-6 left-col'>
                            <table class='before-balance-table'>
                                <tr>
                                    <th>
                                        Before Trade:
                                    </th>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Value:</td>
                                    <td class='right-col'><span :class="priceValueClass(totalValue)">{{formatter.format(totalValue, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Cash:</td>
                                    <td class='right-col'><span :class="priceValueClass(totalCash)">{{formatter.format(totalCash, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Borrowed:</td>
                                    <td class='right-col'><span class='red price-value'>{{formatter.format(totalBorrowed, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Buying Power:</td>
                                    <td class='right-col'><span :class="priceValueClass(currentBuyingPower)">{{formatter.format(currentBuyingPower, '$0.00')}}</span></td>
                                </tr>
                            </table>
                        </div>
                        <div class='p-sm-6 right-col'>
                            <table class='before-balance-table'>
                                <tr>
                                    <th>
                                        After Trade:
                                    </th>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Value:</td>
                                    <td class='right-col'><span :class="priceValueClass(accountEstimate.totalValue)">{{formatter.format(accountEstimate.totalValue, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Cash:</td>
                                    <td class='right-col'>
                                        <span class="price-value positive" v-if="numberUtils.isPositive(accountEstimate.cashPositions[0].marketValue)">{{formatter.format(accountEstimate.cashPositions[0].marketValue, '$0.00')}}</span>
                                        <span class="price-value positive" v-else>$0.00</span>    
                                    </td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Total Borrowed:</td>
                                    <td class='right-col'><span class='red price-value'>{{formatter.format(accountEstimate.totalBorrowed, '$0.00')}}</span></td>
                                </tr>
                                <tr>
                                    <td class='left-col'>Buying Power:</td>
                                    <td class='right-col'><span :class="priceValueClass(accountEstimate.currentBuyingPower)">{{formatter.format(accountEstimate.currentBuyingPower, '$0.00')}}</span></td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </template>
        </ConfirmModal >
        <OrderConfirmationPopup ref="orderConfirmationPopup" :isNotOnAccount="!onAccountProfile"/>

        <UsageLimitModal ref='usageLimitModal' :accessCheck="accessCheck"/>
    </template>

    
</template>

<script>
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import ConfirmModal from '../../modal/ConfirmModal.vue';
import OrderConfirmationPopup from './OrderConfirmationPopup.vue';
import QuoteFeedTable from '../../QuoteMedia/QuoteFeedTable.vue';

import {PAPER_ANALYSIS_ACCOUNT_TYPE, PAPER_TRADING_ACCOUNT_TYPE,
    ASSET_TYPES, MARKET_CLOSE_DURATION_ID, UNTIL_CANCELLED_DURATION_ID, FILL_OR_KILL_DURATION_ID, BUY_TRADE_TYPE, SELL_TRADE_TYPE,
    MARKET_ORDER_TYPE_ID, LIMIT_ORDER_TYPE_ID, STOP_LOSS_ORDER_TYPE_ID, STOP_ENTRY_ORDER_TYPE_ID, STOP_LIMIT_ORDER_TYPE_ID } from '../../../common/constants';
import { getTotalCash} from '../index.js';
import DataService from '../../../service/DataService';
import PortfolioService from '../../../service/PortfolioService';
import ResearchService from '../../../service/ResearchService';
import TradingService from '../../../service/TradingService';
import SecurityFormatter from '../../../common/SecurityFormatter';
import BrowserUtils from '../../../utilities/BrowserUtils';
import CustomValidationUtils from '../../../utilities/CustomValidationUtils';
import CurrencyUtils from '../../../utilities/CurrencyUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import NumberUtils from '../../../utilities/NumberUtils';

import useVuelidate from '@vuelidate/core'
import {helpers} from '@vuelidate/validators';
import EventBus from '../../../event-bus';

import UsageLimitModal from '../../modal/UsageLimitModal.vue';

const BUY_MARKET_ORDER_NAME = 'Buy Market';
const SELL_MARKET_ORDER_NAME = 'Sell Market';
const BUY_LIMIT_ORDER_NAME = 'Buy Limit';
const SELL_LIMIT_ORDER_NAME = 'Sell Limit';
const BUY_STOP_LOSS_ORDER_NAME = 'Buy Stop Loss';
const SELL_STOP_LOSS_ORDER_NAME = 'Sell Stop Loss';
const BUY_STOP_ENTRY_ORDER_NAME = 'Buy Stop Entry';
const SELL_STOP_ENTRY_ORDER_NAME = 'Sell Stop Entry';
const BUY_STOP_LIMIT_ORDER_NAME = 'Buy Stop Limit';
const SELL_STOP_LIMIT_ORDER_NAME = 'Sell Stop Limit';

const MARKET_ORDER_TYPES = [BUY_MARKET_ORDER_NAME, SELL_MARKET_ORDER_NAME];
const NON_MARKET_ORDER_TYPES = [BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME, BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME,
BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const STOP_ORDER_TYPES = [BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME,
BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const LIMIT_ORDER_TYPES = [BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const STOP_LIMIT_ORDER_TYPES = [BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];

const SHORTABLE_SELL_ORDER_TYPES = [SELL_MARKET_ORDER_NAME, SELL_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME];

const POLLING_PRICE_INTERVAL = 10000;

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'PlaceTradeOrderModal',
    emits: ['create-account'],
    components: {
        Dialog,
        Dropdown,
        InputNumber,
        ConfirmModal,
        OrderConfirmationPopup,
        QuoteFeedTable,
        UsageLimitModal
    },
    props: {
        tradingAccounts: {
            type: Array,
            required: true
        }
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        grossAmount() {
            return this.quantity * this.askPrice;
        },

        headerLabel() {
            if (this.loading) {
                if (this.placingOrder) {
                    return 'Placing Order';
                } else {
                    return 'Estimating Order';
                }

            } else {
                return 'Place an Order';
            }
        },

        loadingText() {
            let text = '';

            if (this.loading) {
                if (this.placingOrder) {
                    text = 'Order is being placed...';
                } else {
                    text = 'Order is being estimated...';
                }
            }

            return text;
        },

        accountDropdownPlaceholder() {
            return this.account ? 'Change Account' : 'Select an Account';
        },

        accountPositionClass() {
            return {
                'position': true,
                'long': this.position === 'Long',
                'none': this.position === 'None',
                'short': this.position === 'Short'
            }
        },

        durationTypeDropdownClass() {
            return {
                'duration-type-dropdown': true,
                'dropdown': true,
                'market-order-selected': this.marketOrderTypeSelected
            };
        },

        durationTypeDropdownPlaceholder() {
            return !this.marketOrderTypeSelected ? null : 'Fill Or Kill'
        },

        disablePlaceOrderButton() {
            let value = null;
            const orderName = this.orderTypeOption && this.orderTypeOption.name;

            const baseCondition = !this.selectedSecurity?.symbol || !this.quantity || !this.durationTypeOption || !orderName;

            if (!orderName) {
                value = baseCondition;
            } else {
                if (this.isNonMarketOrderType(orderName)) {
                    if (LIMIT_ORDER_TYPES.includes(orderName)) {
                        const isLimitPriceEmpty = !this.limitPrice;

                        if ([BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME].includes(orderName)) {
                            value = baseCondition || isLimitPriceEmpty;
                        } else if (STOP_LIMIT_ORDER_TYPES.includes(orderName)) {
                            value = baseCondition || !this.stopPrice || isLimitPriceEmpty;
                        }
                    } else if ([BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME].includes(orderName)) {
                        value = baseCondition || !this.stopPrice;
                    }
                } else {
                    value = baseCondition;
                }
            }

            return value || this.v$.$errors.length > 0;
        },

        basedOnText() {
            let value = null;

            if (MARKET_ORDER_TYPES.includes(this.orderTypeOption.name)) {
                value = this.orderTypeOption.name === BUY_MARKET_ORDER_NAME ? 'Based on Delayed Ask Price' : 'Based on Delayed Bid Price';
            } else if ([BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME].includes(this.orderTypeOption.name)) {
                value = 'Based on Limit Price';
            } else if (STOP_ORDER_TYPES.includes(this.orderTypeOption.name)) {
                value = 'Based on Stop Price';
            }

            return value;
        },

        // If we're doing an foreign exchange, we have to make sure the estimated total price is the same as the foreign currency
        trueTotalPrice() {
            return this.exchangeRate ? this.estimatedTotalPrice * this.exchangeRate : this.estimatedTotalPrice;
        }
    },
    watch: {
        account(val) {
            // Get cash positions every time the account changes (Basically when we select another account)
            if (val) {
                this.fetchAccountBalance(val.investmentAccountId);
            }
        },

        $route(to, from) { //eslint-disable-line
            if ( to.path.includes('/billing')) {
                if( this.display)  {
                    this.onClose();// moving to billing due to max accts error
                }
            }
        }
    },
    data() {
        return {
            //CONSTANTS
            MARKET_ORDER_TYPES,
            NON_MARKET_ORDER_TYPES,
            STOP_ORDER_TYPES,
            LIMIT_ORDER_TYPES,
            BUY_TRADE_TYPE,
            SELL_TRADE_TYPE,
            BUY_MARKET_ORDER_NAME,
            SELL_MARKET_ORDER_NAME,

            securityFormatter: SecurityFormatter,
            currencyUtils: CurrencyUtils,
            numberUtils: NumberUtils,
            formatter: new FormattingUtils(),

            display: false,
            loading: false,
            placingOrder: false,
            refetcher: false,
            marketOrderTypeSelected: null,

            account: null,
            selectedAccount: null,
            accountEstimate: null,
            position: null,
            positionQuantity: null,
            positionAvgCostPerUnit: null,
            currency: null,
            fromGroup: false,
            fromUserProfile: false,
            onAccountProfile: false,

            securitySearchBarPlaceholder: 'Add a security',
            securityList: [],
            currentSecurity: null,
            selectedSecurity: {
                symbol: "",
                assetTypeId: null,
                assetId: null,
                exchangeSymbol: null,
                companyName: null
            },

            askPrice: null,
            bidPrice: null,
            openValue: null,
            highValue: null,
            lowValue: null,
            volume: null,
            askSize: null,
            bidSize: null,

            actionTypeOption: null,
            actionTypeOptions: ['Buy', 'Sell'],
            actionTypeDropdownPlaceholder: 'Select an Action Type',

            priceTypeOption: null,
            priceTypeOptions: ['US Dollar'],

            orderTypeOption: null,
            orderTypeOptions: [],
            orderTypeDropdownPlaceholder: 'Select an Order Type',

            stopPrice: null,
            stopPricePlaceholder: 'Enter in value for Stop Price',

            limitPrice: null,
            limitPricePlaceholder: 'Enter in value for Limit Price',

            quantity: null,
            quantityPlaceholder: 'Enter in value for quantity',

            durationTypeOption: null,
            durationTypeOptions: [],
            durationTypeDropdownDisabled: true,


            totalValue: 0,
            totalCash: 0,
            totalBorrowed: 0,
            currentBuyingPower: 0,
            fee: null,
            estimatedPrice: null,
            estimatedTotalPrice: null,
            exchangeRate: null,
            estimatedNetAmount: null,
            estimatedPriceCurrency: null,
            accessCheck: null,
        }
    },


    methods: {

        getOrderTypeName(orderType) {
            if( this.positionQuantity > 0 ) {
                return orderType.name
            }
            else {
                if(SHORTABLE_SELL_ORDER_TYPES.includes(orderType.name)) {
                    return "Short "+orderType.name;
                }
                else {
                    return orderType.name;
                }
                
            }
        },
       
        //IN-COMPONENT METHODS
        accountDisabled(account) {
            return account.investmentAccountId
        },

        priceValueClass(price) {
            return {
                'price-value': true,
                'positive': NumberUtils.isPositive(price),
                'negative': NumberUtils.isNegative(price)
            }
        },

        accountTypeLabel(typeId) {
            if (typeId === 73) {
                return PAPER_ANALYSIS_ACCOUNT_TYPE['NAME'];
            } else if (typeId === 74) {
                return PAPER_TRADING_ACCOUNT_TYPE['NAME'];
            } else if (typeId === 76) {
                return "Comp";
            }
        },

        open(data) {
            this.selectedAccount = null;
            this.account = null;
            this.fromGroup = data.fromGroup;
            this.fromUserProfile = data.fromUserProfile;
            this.accessCheck = null;

            if (data.account) {
                this.selectedAccount = data.account;
                this.account = data.account;

                this.fetchAccountBalance(this.account.investmentAccountId);
                this.currency = CurrencyUtils.getCurrencyNameViaId(this.account.currencyId);

                if (data.security) {
                    this.initSelectedSecurity(data.security);

                    this.startPollingPrices();

                    this.fetchAvailableOrderTypes().then((types) => {
                        this.orderTypeOptions = types;
                    });
                }
            } else {
                this.onAccountProfile = false;
                if (data.security) {
                    this.initSelectedSecurity(data.security);

                    this.startPollingPrices();
                }
            }
            
            
            this.display = true;
        },

        onClose() {
            this.display = false;
        },

        fetchAccountBalance(investmentAccountId) {
            PortfolioService.getInvestmentAccount(investmentAccountId).then((resp) => {
                if (resp.data.status === 'success') {
                    const account = resp.data.account;

                    this.totalValue = account.totalValue;
                    this.totalCash = getTotalCash(account.cashPositions);
                    this.totalBorrowed = account.totalBorrowed;
                    this.currentBuyingPower = account.currentBuyingPower;
                }
            });
        },
        
        selectAccount(account) {
            if (!this.account) {

                this.account = account;
                this.currency = CurrencyUtils.getCurrencyNameViaId(this.account.currencyId);

                if (this.currentSecurity) {
                    this.fetchAvailableOrderTypes().then((types) => {
                        this.orderTypeOptions = types;
                    });
                }
            } else {
                this.account = account;
                this.currency = CurrencyUtils.getCurrencyNameViaId(this.account.currencyId);
                this.resetOrderSubmission();
            }
        },
        
        onCreateAccountClick() {
            this.$emit('create-account');
        },

        onSecuritySearchClickAway(event) {
            if (this.selectedSecurity.symbol === undefined) {
                if (this.currentSecurity) {
                    this.selectedSecurity = {...this.currentSecurity};

                    this.$nextTick(() => {
                        this.$refs.securitySearchBar.$emit('hide', event);
                    });
                } else {
                    this.resetSelectedSecurity();
                }
            }
        },

        resetSelectedSecurity() {
            this.selectedSecurity = {symbol: "", assetTypeId: null, assetId: null, exchangeSymbol: null, companyName: null};
            this.currentSecurity = null;
        },

        initSelectedSecurity(security) {
            const isSecurity = security.securityId !== undefined;

            this.selectedSecurity.symbol = security.symbol;
            this.selectedSecurity.assetTypeId = isSecurity ? ASSET_TYPES['EQUITY'] : ASSET_TYPES['FUND'];
            this.selectedSecurity.assetId = isSecurity ? security.securityId : security.fundId;
            this.selectedSecurity.exchangeSymbol = security.exchangeSymbol;
            this.selectedSecurity.companyName = isSecurity ? security.company : security.name;

            this.currentSecurity = {...this.selectedSecurity};
        },

        onHide() {
            this.placingOrder = false;
            this.loading = false;
            this.currency = null;
            this.fee = null;
            this.estimatedPrice = null;
            this.estimatedTotalPrice = null;
            this.exchangeRate = null;
            this.estimatedNetAmount = null;

            this.resetOrderSubmission();
        },

        resetOrderSubmission() {
            this.stopPollingPrices();
            this.resetSelectedSecurity();
            this.resetOrderFieldValues();
        },

        resetOrderFieldValues() {
            this.recordTradePrice = null;
            this.priceTypeOption = null;
            this.limitPrice = null;
            this.stopPrice = null;
            this.orderTypeOption = null;
            this.orderTypeOptions = [];
            this.durationTypeOption = null;
            this.durationTypeOptions = [];
            this.durationTypeDropdownDisabled = true;
            this.marketOrderTypeSelected = false;
            this.quantity = null;
            this.position = null;
            this.positionQuantity = null;
            this.positionAvgCostPerUnit = null;
        },

        resetQuoteDataValues() {
            this.askPrice = null;
            this.bidPrice = null;
            this.openValue = null;
            this.highValue = null;
            this.lowValue = null;
            this.volume = null;
            this.askSize = null;
            this.bidSize = null;
        },

        startPollingPrices() {
            if (this.refetcher) {
                this.resetQuoteDataValues();
                clearInterval(this.refetcher);
            }
            this.fetchPrices();

            this.refetcher = setInterval(() => {
                this.fetchPrices();
            }, POLLING_PRICE_INTERVAL);
        },

        stopPollingPrices(reset = true) {
            if (this.refetcher) {
                clearInterval(this.refetcher);

                if (reset) {
                    this.resetQuoteDataValues();
                }
                this.refetcher = null;
            }
        },

        fetchPrices() {
            DataService.getSnapQuotes(SecurityFormatter.getSymbolViaCdnExchanges(this.selectedSecurity)).then((resp) => {
                const quoteData = resp.data.results.quote[0];
                if( quoteData && quoteData.pricedata) {
                    this.askPrice = quoteData.pricedata.ask;
                    this.bidPrice = quoteData.pricedata.bid;
                    this.openValue = quoteData.pricedata.open;
                    this.highValue = quoteData.pricedata.high;
                    this.lowValue = quoteData.pricedata.low;
                    this.volume = quoteData.pricedata.tradevolume;
                    this.askSize = quoteData.pricedata.asksize;
                    this.bidSize = quoteData.pricedata.bidsize;
                }
            });
        },

        searchSecurities(event){
            ResearchService.queryMarket('All', event.query, 10, 0).then(response => {
				
                let queryResults = response.data;

                this.securityList = [
                {
                    label: 'Equities', code: 'EQ', 
                    items: queryResults.securities
                },
                {
                    label: 'Funds', code: 'F', 
                    items: queryResults.funds
                }];
            })
            .catch(error => {
                this.securityList = []
                
                console.debug('There was an error:', error.response)
            })
        },

        onSecuritySelected(event) {

            // ResearchService.getSecurity(event.value.exchangeSymbol, event.value.symbol).then((resp) => {
            //     if( resp.data ) {
            //         resp.data.symbol = StringUtils.convertXigniteToQmCompatibleSymbol(resp.data.symbol);

            //         this.$refs.securitySearchBar.$el.blur();

            //         this.resetOrderFieldValues();
            //         this.initSelectedSecurity(resp.data);
            //         this.startPollingPrices();

            //         this.fetchAvailableOrderTypes().then((types) => {
            //             this.orderTypeOptions = types;
            //         });
            //     }
            // });

            this.$refs.securitySearchBar.$el.blur();

            this.resetOrderFieldValues();
            this.initSelectedSecurity(event.value);
            this.startPollingPrices();

            this.fetchAvailableOrderTypes().then((types) => {
                this.orderTypeOptions = types;
            });
		},

        async fetchAvailableOrderTypes() {
            return PortfolioService.getPosition(this.account.investmentAccountId, this.selectedSecurity.assetTypeId, this.selectedSecurity.assetId).then((resp) => {

                if (resp.data.status === 'success') {
                    const positionSummary = resp.data.positionSummary;

                    this.position = positionSummary.position;
                    this.positionQuantity = positionSummary.quantity;
                    this.positionAvgCostPerUnit = positionSummary.avgCostPerUnit

                    const ORDER_TYPES = [
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_MARKET_ORDER_NAME, tradeOrderTypeId: MARKET_ORDER_TYPE_ID}, 
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_MARKET_ORDER_NAME, tradeOrderTypeId: MARKET_ORDER_TYPE_ID}, 
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_LIMIT_ORDER_NAME, tradeOrderTypeId: LIMIT_ORDER_TYPE_ID}, 
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_LIMIT_ORDER_NAME, tradeOrderTypeId: LIMIT_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_LOSS_ORDER_NAME, tradeOrderTypeId: STOP_LOSS_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_LOSS_ORDER_NAME, tradeOrderTypeId: STOP_LOSS_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_ENTRY_ORDER_NAME, tradeOrderTypeId: STOP_ENTRY_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_ENTRY_ORDER_NAME, tradeOrderTypeId: STOP_ENTRY_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_LIMIT_ORDER_NAME, tradeOrderTypeId: STOP_LIMIT_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_LIMIT_ORDER_NAME, tradeOrderTypeId: STOP_LIMIT_ORDER_TYPE_ID}];

                    const SUPPORTED_TRADE_COMBINATIONS_MAP = {
                        'Buy': {
                            'StopEntry': BUY_STOP_ENTRY_ORDER_NAME,
                            'StopLoss': BUY_STOP_LOSS_ORDER_NAME,
                            'Limit': BUY_LIMIT_ORDER_NAME,
                            'StopLimit': BUY_STOP_LIMIT_ORDER_NAME,
                            'Market': BUY_MARKET_ORDER_NAME
                        },
                        'Sell': {
                            'StopEntry': SELL_STOP_ENTRY_ORDER_NAME,
                            'StopLoss': SELL_STOP_LOSS_ORDER_NAME,
                            'Limit': SELL_LIMIT_ORDER_NAME,
                            'StopLimit': SELL_STOP_LIMIT_ORDER_NAME,
                            'Market': SELL_MARKET_ORDER_NAME
                        }
                    };

                    const types = ORDER_TYPES.map((type) => {

                        let obj = {tradeType: type.tradeTypeId, tradeOrderType: type.tradeOrderTypeId, name: type.name, disabled: false};

                        const match = resp.data.supportedTradeCombinations.filter((combo) => {
                            return SUPPORTED_TRADE_COMBINATIONS_MAP[combo.tradeType][combo.tradeOrderType] === type.name
                        })[0];

                        if (match) {
                            obj['description'] = match.description;
                        } else {
                            obj.disabled = true;
                        }

                        return obj;
                    });

                    const nonDisabledTypes = types.filter(type => !type.disabled);
                    const disabledTypes = types.filter(type => type.disabled);
                    const sortedTypes = nonDisabledTypes.concat(disabledTypes);

                    return sortedTypes;

                } else {
                    this.$toast.add({ severity: 'error', summary: 'You are not authorized to get current position.', life: 3000, group: 'center' });
                    console.log("unexpected error getting position: "+resp.data.message);

                    return [];
                }
            })
            .catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                console.log("unexpected error getting position: "+err);

                return [];
            })
        },

        orderTypeSelected(event) {
            this.limitPrice = null;
            this.stopPrice = null;
            this.quantity = null;

            if (this.isNonMarketOrderType(event.value.name)) {
                this.durationTypeOptions = [
                    {id: UNTIL_CANCELLED_DURATION_ID, name: 'Until Cancelled (Max 30 Days)'},
                    {id: MARKET_CLOSE_DURATION_ID, name: 'Market Close'}
                ];
                this.marketOrderTypeSelected = false;
                this.durationTypeOption = null;
                this.durationTypeDropdownDisabled = false;
            } else {
                this.marketOrderTypeSelected = true;
                this.durationTypeOptions = [];
                this.durationTypeOption = {id: FILL_OR_KILL_DURATION_ID, name: 'Fill Or Kill'};
            }
        },

        isNonMarketOrderType(type) {
            return NON_MARKET_ORDER_TYPES.includes(type);
        },

        onConfirmPlaceOrderCallback() {
            this.placingOrder = true;

            const tradeOrderRequest = {
                assetTypeId: this.selectedSecurity.assetTypeId,
                limitPrice: this.limitPrice,
                durationTypeId: this.durationTypeOption.id,
                orderTypeId: this.orderTypeOption.tradeOrderType,
                quantity: this.quantity,
                stopPrice: this.stopPrice,
                tradeTypeId: this.orderTypeOption.tradeType,
                assetId: this.selectedSecurity.assetId,
                investmentAccountId: this.account.investmentAccountId
            };

            TradingService.placeOrder(tradeOrderRequest).then(async (resp) => {
                
                if (resp.data.status === 'success') {
                    if (this.selectedAccount?.investmentAccountId === this.account.investmentAccountId) {
                        this.onAccountProfile = true;
                    }

                    if (!this.fromGroup) {
                        if (!this.fromUserProfile) {
                            EventBus.emit('reload-account');
                        } else {
                            EventBus.emit('refresh-profile-portfolio-overview');
                        }
                    } else {
                        EventBus.emit('reload-account-from-groups');
                    }

                    const updatedAccountResp = await PortfolioService.getInvestmentAccount(this.account.investmentAccountId);
                    this.placingOrder = false;
                    this.loading = false;

                    if (updatedAccountResp.data.status === 'success') {
                        const updatedAccount = updatedAccountResp.data.account;

                        const data = {
                            security: this.selectedSecurity,
                            askPrice: this.askPrice,
                            currency: this.estimatedPriceCurrency,
                            quantity: this.quantity,
                            totalCost: this.estimatedTotalPrice,
                            stopPrice: this.stopPrice,
                            limitPrice: this.limitPrice,
                            tradeType: this.orderTypeOption.tradeType,
                            orderType: this.orderTypeOption.tradeOrderType,
                            durationType: this.durationTypeOption.id,
                            executionDate: resp.data.tradeOrder?.executionDate,
                            executedPrice: resp.data.tradeOrder?.executedPrice,
                            updatedAccount: updatedAccount,
                            effectiveDate : resp.data.tradeOrder?.effectiveDate,
                            tradingDelay: resp.data.tradeOrder?.tradingDelay,
                            createdDate: resp.data.tradeOrder?.createdDate,
                            lastChecked: resp.data.tradeOrder?.lastChecked
                        };

                        this.$refs.orderConfirmationPopup.open(data);

                        this.onClose();

                        EventBus.emit('track-place-trade');
                        
                    }
                } else {
                    this.placingOrder = false;
                    this.loading = false;
                    this.startPollingPrices();
                    console.error('error placing order: ', + resp.data.message);
                    let errorMsg = '';
                    let summary = '';

                    if( resp.data.message == 'Limit Reached.') {
                        this.accessCheck = resp.data.check;
                        this.$refs.usageLimitModal.open();
                        this.onClose();
                    }
                    else {

                        if (resp.data.message === 'NSF') {
                            summary = resp.data.message;
                            errorMsg = "You don't have enough funds for placing this order.";
                            
                        } else if (resp.data.message === 'Account Suspended: MARGIN CALL') {
                            summary = resp.data.message;
                            errorMsg = "You have used up all your borrowing power and your total buying power has gone negative."
                        } else if (resp.data.message.startsWith('Trading Error')) {
                            summary = 'Trading Error';
                            errorMsg = resp.data.message;
                        }
                        else if( resp.data.message === 'Contest not started.'){
                            summary = 'Competion Not Started.';
                            errorMsg = 'The competition associated with this account has not yet started.  You can only place trades with this account once the competition has begun.'
                        }
                        else if( resp.data.message == 'Contest is over.'){
                            summary = 'Competion Finished.';
                            errorMsg = 'The competition associated with this account has finished.  You can only place trades with this account while the competition is running.'
                        }else if (resp.data.message === 'Below minimum price.') {
                            summary = "Price too low.";
                            errorMsg = "The competition associated with this account has a minimum price trade restriction.  The minimum allowed price is " + this.formatter.format(resp.data.minPrice, '$0.00')+".";
                        }else if( resp.data.message == 'Course Cohort has not started.'){
                            summary = 'Cohort not started.';
                            errorMsg = 'Your cohort for the course associated with this account has not started.'
                        }else if( resp.data.message == 'Course has not started.'){
                            summary = 'Course not started.';
                            errorMsg = 'The course associated with this account has not started.'
                        } else {
                            summary = "Error placing order"
                            errorMsg = "Please review the order details and try again or contact support if the problem persists."
                        }

                        this.$toast.add({ severity: 'error', summary: summary, detail:errorMsg, life: 12000, group: 'center'});
                    }
                    
                }
            })
            .catch((err) => {
                this.startPollingPrices();
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                console.log("unexpected error placing order: "+err);
                this.loading = false;
                this.placingOrder = false;
                this.onClose();
            });
        },

        onPlaceOrderCancelledCallback() {
            this.startPollingPrices();
            this.loading = false;
        },

        openConfirmModal() {
            this.loading = true;

            const estimateOrderRequest = {
                assetTypeId: this.selectedSecurity.assetTypeId,
                limitPrice: this.limitPrice,
                durationTypeId: this.durationTypeOption.id,
                orderTypeId: this.orderTypeOption.tradeOrderType,
                quantity: this.quantity,
                stopPrice: this.stopPrice,
                tradeTypeId: this.orderTypeOption.tradeType,
                assetId: this.selectedSecurity.assetId,
                investmentAccountId: this.account.investmentAccountId
            };

            TradingService.estimateOrder(estimateOrderRequest).then((resp) => {
                if (resp.data.status === 'success') {
                    const tradeOrder = resp.data.estimate.tradeOrder;
                    this.accountEstimate = resp.data.estimate.account;

                    const countryId = tradeOrder?.securityListing ? tradeOrder.securityListing.exchangeCountryId : tradeOrder.fund.countryId;

                    this.fee = tradeOrder.fee;
                    this.exchangeRate = tradeOrder.exchangeRate;
                    this.estimatedPriceCurrency = CurrencyUtils.getCurrencySymbolViaId(CurrencyUtils.getCurrencyViaCountry(countryId));
                    this.estimatedPrice = tradeOrder.executedPrice;
                    this.estimatedTotalPrice = tradeOrder.grossAmount;
                    this.estimatedNetAmount = tradeOrder.netAmount;

                    this.stopPollingPrices(false);

                    this.$refs.confirmOrderModal.open();
                }
            });

        },

        resetError(field) {
            this.v$[field].$reset();
        },
    },

    validations() {
        return {
            quantity: {
                grossAmountTooLarge: helpers.withMessage(`Quantity is too big, gross amount is over ${this.formatter.format(1000000000000, '$000,000,000,000.00')}`, CustomValidationUtils.grossAmountTooLarge(this.grossAmount))
            }
        }
    }

}
</script>

<style>
.place-trade-order-modal {
    width: 600px;
}

.place-trade-order-modal .p-dialog-header {
    padding: 16px;
}

.place-trade-order-modal .p-dialog-content {
    padding: 0 16px 16px;
    /* overflow-y: visible; */
}

.place-trade-order-modal .p-dialog-content::-webkit-scrollbar {
    /* width */
    width: 3px;

}
.place-trade-order-modal .p-dialog-content::-webkit-scrollbar-track {
    margin-right: 3px;
}
.place-trade-order-modal .p-dialog-content::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #BFBFBF;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}

.duration-type-dropdown-panel .p-dropdown-items,
.order-type-dropdown-panel .p-dropdown-items {
    padding: 0px;
    border-radius: 4px;
}

.place-trade-order-modal .p-dialog-footer {
    padding: 16px 0px;
    text-align: center;
}

.account-dropdown-menu .p-dropdown-items {
    padding: 0px;
}
.account-dropdown-menu .p-dropdown-items .p-dropdown-item {
    padding: 0px;
}
.account-dropdown-menu .p-dropdown-items .p-dropdown-item:last-child {
    color: black;
    background: rgba(255, 189, 83, 0.4);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    font-weight: bold;
}
.account-dropdown-menu .p-dropdown-items .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover:last-child {
    color: black;
    background: rgba(255, 189, 83, 0.8);
}
.account-dropdown-menu .p-dropdown-items .p-dropdown-item > div {
    padding: 8px;
    width: 100%;
}

@media (max-width: 760px) {
    

    .place-trade-order-modal {
    
        height: 100vh!important;
        
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    .place-trade-order-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .place-trade-order-modal .p-dialog-content {
        padding: 0 16px 0;
        overflow-y: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .place-trade-order-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-autocomplete-panel.p-component.security-search-trade-order-panel {
        width: 100%;
        border-radius: 0px;
    }
}
</style>
<style scoped>
*:not(.pi, .material-symbols-outlined),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.body-text {
    color: black;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.place-order-button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}

.input-section {
    display: flex;
    flex-direction: column;
    width: 75%;
    margin-bottom: 32px;
}
.input-section:last-of-type {
    margin-bottom: 8px;
}
.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 12px;
}
.p-float-label ::v-deep(.p-placeholder) {
    opacity: 1;
}

.dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.dropdown:hover, .dropdown:active {
    border-color: #33CC99;
}
.dropdown:focus, .dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}
.dropdown.market-order-selected {
    pointer-events: none;
}
.account-dropdown {
    margin-bottom: 12px;
}

.p-input-icon-right,
.p-inputnumber {
    width: 100%;
}
.p-inputnumber > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
}
.p-inputnumber > ::v-deep(.p-inputtext),
.input {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.p-inputnumber:hover > ::v-deep(.p-inputtext),
.input:hover {
    border-color: #33CC99;
}
.p-inputnumber:focus > ::v-deep(.p-inputtext),
.input:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}
.p-inputnumber.input-error > ::v-deep(.p-inputtext),
.p-inputnumber.input-error > ::v-deep(.p-inputtext:focus-within) {
    border-color: #E63E3E;
} 
.input-error .input-label {
    color: #E63E3E;
}
.error-message {
    margin-top: 10px;
    color: #E63E3E;
}


.security-search-bar-container {
    width: 100%;
    margin-bottom: 12px;
}
.p-autocomplete {
    width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 36px;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component::placeholder) {
    font-size: 16px;
    color: #BFBFBF;
}

.order-section {
    overflow-y: auto;
    max-height: calc(100% - 60px);
}

.header-content {
    font-size: 14px;
    color: black;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.position-summary {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.order-value,
.price-value,
.position {
    color: black;
    font-weight: bold;
}
.positive {
    color: #33CC99;
}
.none {
    color: black;
}
.negative {
    color: #E63E3E;
}

.quote-data-section {
    padding: 16px 16px 0;
}

.selected-order-type-description {
    color: black;
    margin-top: 12px;
}

.order-type-item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order-type-item-row.disabled {
    color: #bfbfbf;
    pointer-events: none;
}
.order-type-info-button {
    border-radius: 50px;
    padding: 2px 8px;
    height: 20px;
    width: 20px;
}

.order-type-item-row > span {
    white-space: normal;
    max-width: 80%;
}

.estimate-order-content {
    padding: 16px;
    width: 700px;
}
.trade-order-table {
    margin-bottom: 24px;
}
table {
    border-spacing: 0;
}
.estimate-order-content .header,
table th {
    color: black;
    font-weight: bold;
    text-align: left;
}
.estimate-order-content .p-grid {
    padding: 0px 12px;
    margin-top: 24px;
}
.estimate-order-content .p-grid .only-cell {
    padding: 0;
}
.estimate-order-content .p-grid > .left-col {
    padding: 0px 8px 0px 0px;
}
.estimate-order-content .p-grid > .right-col {
    padding: 0px 0px 0px 8px;
}
.estimate-order-content table {
    width: 100%;
}
.estimate-order-content table .left-col {
    text-align: left;
}
.estimate-order-content table .right-col {
    text-align: right;
}

.green {
    color: #33CC99;
}
.red {
    color: #E63E3E;
}

.pi-info-circle {
    vertical-align: super;
}

.loading-logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.loading-logo {
    color: #33CC99;
    animation-duration: 3s;
    -webkit-animation-duration: 3s;
    animation-fill-mode: none;
    -webkit-animation-fill-mode: none;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    font-size: 60px;
    margin-bottom: 8px;
    animation-name: rotate;
    -webkit-animation-name: rotate;
}

.confirm-modal .p-dialog-header {
    position: relative;
}
.confirm-modal .p-dialog-header .confirm-order-icon {
    position: absolute;
    right: 0;
    top: 5px;
    width: 80px;
}
@keyframes rotate {
  0% {
    /*transform: scale(1);*/
    transform-origin: center center;
    transform: rotate(-360deg) scale(1);
  }
  50% {
    /*transform: scale(1.1);*/
    transform-origin: center center;
    transform: rotate(-180deg) scale(0.7);
  }
  100% {
    /*transform: scale(1);*/
    transform-origin: center center;
    transform: rotate(0) scale(1);
  }
}

@media (max-width: 760px) {

    .order-section {
        padding-bottom: 12px;
    }

    .input-section {
        width: 100%;
        margin-bottom: 36px;
    }

    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
        top: -1rem;
        font-size: 16px;
    }

    .security-search-bar-container {
        margin-bottom: 16px;
    }

   

    .estimate-order-content {
        width: 100%;
    }
    .estimate-order-content .p-grid .only-cell {
        width: 100%;
    }
    .estimate-order-content .p-grid > .left-col {
        width: 50%;
    }
    .estimate-order-content .p-grid > .right-col {
        width: 50%;
    }

    .pi-info-circle {
        font-size: 10px;
    }

    .confirm-modal .p-dialog-header .confirm-order-icon {
        right: -10px;
        top: 20px;
        width: 60px;
    }

    /* note this is needed to stop mobile zooming in when entering text */
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}
</style>