<template>
<!-- -->
    <Dialog v-model:visible="displayDialog" :modal="true" :closable="false" class='qm-completion-modal'  @show='renderQmPage'>
        <template #header>
            <div class='logo-header'>
                <img src='../../../public/Invrs-Logo-Green-Blue.png' class="invrs-logo" >
                <img src='../../../public/Handshake-Emoji.png' class="handshake-logo">
                <img src='../../../public/Quotemedia-Logo.png' class="quotemedia-logo"> 
            </div>
            <div class="header-text-container">
                <div>Quote Media is one of our partners and provides market related data for the application and for you.</div>
                <div>In order to complete registration you need to accept the terms and conditions below.</div>
            </div>
        </template>
        <LoadingSpinner :showLoading="displayDialog && !quoteMediaRegistrationPageLoaded "/>
        <div id="qmCompletionPage" v-show="quoteMediaRegistrationPageLoaded" style='height:100%;'></div>
        <template #footer>
            <div>Don't see anything above? Please turn on 3rd party cookies and refresh your browser.</div>
            <div>(You can turn them off again afterwards.)</div>
        </template>
    </Dialog>
</template>

<script>
import {QM_REGISTRATION_URL, QM_COMPLETE_PAGE_URL, QM_COMPLETE_PRODUCT_KEY} from '../../common/config';

import Dialog from 'primevue/dialog';
import LoadingSpinner from '../common/LoadingSpinner';

export default {
    name: 'RegistrationModal',
    props: {
        display: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            quoteMediaRegistrationPageLoaded: false,
            iframe: null,
            displayDialog: this.display,
        }
    },

    watch: {
        display() {
            this.displayDialog = this.display;
        }
    },


    components: {
        Dialog, LoadingSpinner
    },

    mounted() {
        // this.$nextTick( () => {
        //     this.renderQmPage();
        // });
    },
    
    methods: {
        // open() {
        //     this.display = true;
        // },

        // close() {
        //     this.display = false;
        // },

        renderQmPage() {
           
            this.iframe = document.createElement('iframe');
            //console.log("https://client.quotemedia.com/qscreg/invrs/?sid=" + this.$store.state.sid + "&products=" + QM_COMPLETE_PRODUCT_KEY + "&forwardURL=" + QM_COMPLETE_PAGE_URL)
            this.iframe.setAttribute('src', QM_REGISTRATION_URL + "sid="+this.$store.state.sid 
                + "&products=" + QM_COMPLETE_PRODUCT_KEY + "&forwardURL=" + QM_COMPLETE_PAGE_URL+"&iframeName=qscreg" );
            this.iframe.setAttribute('id', 'qmCompletion');
            this.iframe.setAttribute('style', 'width:100%;border:none;height:100%;');
            this.iframe.setAttribute('name', 'qscreg');

            document.getElementById('qmCompletionPage').appendChild(this.iframe);

            document.getElementById('qmCompletion').onload = () => {
               this.quoteMediaRegistrationPageLoaded = true;
               //setTimeout(() => {  this.quoteMediaRegistrationPageLoaded = true; }, 2000);
            };
        
            
        }
    }
}
</script>

<style>
.invrs-logo {
   width: 200px;
   margin-left: 10px;
}

.header-text-container div {
   margin-top: 5px;
}

.handshake-logo {
    width: 35px;
    margin-left: 20px;
}

.quotemedia-logo {
    width: 230px;
}

.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.qm-completion-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 680px;
    height: 630px;
	box-shadow: none;
}

.qm-completion-modal .p-dialog-header {
    padding: 30px 20px 20px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
    flex-direction: column;
}

.qm-completion-modal .p-dialog-footer div {
    padding: 0;
}

.qm-completion-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.qm-completion-modal .logo-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 68px;
}

.qm-completion-modal .p-dialog-content {
	padding: 10px 25px;
    height: 100%;
}

.qm-completion-modal .button-container {
    display: flex;
    justify-content: center;
}
@media (max-width: 760px) {
    .qm-completion-modal {
        width: 100%;
        height: 100%;
    }
    .qm-completion-modal {
        border-radius: 0px;
        width: 100%;
        height: 100%;
        max-height: 100vh !important;
    }
    .qm-completion-modal .p-dialog-header {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
    }  

    .qm-completion-modal .p-dialog-footer {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
    .p-dialog-content {
        padding-top: 0 !important;
        height: 100%;
    }

    .invrs-logo {
        width: 35%;
        margin-left: 10px;
    }

    .handshake-logo {
        width: 10%;
        margin-left: 14px;
    }

    .quotemedia-logo {
        width: 45%;
    }
    /* .qm-completion-modal .p-dialog-content {
        padding: 0;
    } */
}
</style>