<template>
    <ul v-if="items" class="layout-menu" role="menu">
        <template v-for="(item, i) of items">
            <li v-if="visible(item) && !item.separator" :key="item.label || i" :class="[{'layout-root-menuitem': root, 'active-menuitem': activeIndex === i && !item.disabled}, item?.class]" role="menuitem" v-tooltip.right="item.label" >
                <router-link v-if="item.to" :to="item.to" :style="item.style" :class="[item.class, { 'p-disabled': item.disabled, 'active-subroute': isActiveSubRoute(item.to)}]" active-class="active-route" :target="item.target"
                    exact @click="onMenuItemClick($event, item, i)" @mouseenter="onMenuItemMouseEnter(i)">
                    <div  class="layout-menuitem-image">
                        <i v-if="item.icon" :class="['layout-menuitem-icon', item.icon]"></i>
                        <img v-else-if="item.image" :src="item.image"/>
                        <span v-else :class="'material-symbols-outlined' + (item.materialIcon === 'campaign' ? ' motions-menuitem-icon' : '')">{{item.materialIcon}}</span>
                    </div>
                   
                    <i class="pi pi-angle-right menu-active"></i>
                   
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </router-link>
                <a v-if="!item.to" :href="item.url || '#'" :style="item.style" :class="[item.class, { 'p-disabled': item.disabled }]"
                    :target="item.target"  @click="onMenuItemClick($event, item, i)" @mouseenter="onMenuItemMouseEnter(i)">
                    <i :class="['layout-menuitem-icon', item.icon]"></i>
                    <!-- <span class="layout-menuitem-text">{{ item.label }}</span> -->
                    <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
                </a>
                <!-- <div class="layout-root-menuitem" v-if="root && fullMenu()">
                    <div class="layout-menuitem-root-text" style="text-transform: uppercase">{{ item.label }}</div>
                </div> -->
                <!-- <transition name="layout-menu">
                    <appsubmenu v-show="item.items && (root && (!isSlim() || (isSlim() && (mobileMenuActive || activeIndex !== null))) ? true : activeIndex === i)" :items="visible(item) && item.items" :menuActive="menuActive" :layoutMode="layoutMode" :parentMenuItemActive="activeIndex === i" @menuitem-click="$emit('menuitem-click', $event)"></appsubmenu>
                </transition> -->
            </li>
            <li class="menu-separator" :style="item.style" v-if="visible(item) && item.separator" :key="'separator' + i" role="separator"></li>
        </template>
    </ul>
    
</template>

<script>
// import Badge from 'primevue/badge';

import EventBus from './event-bus';
export default {
    name: "appsubmenu",
    components: {
        // Badge
    },
    props: {
        items: Array,
        layoutMode: String,
        menuActive: Boolean,
        mobileMenuActive: Boolean,
        root: {
            type: Boolean,
            default: false,
        },
        parentMenuItemActive: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            activeIndex: null,
        };
    },
    mounted() {
		EventBus.off('reset-active-index');
		EventBus.on('reset-active-index', () => {
			if (this.isSlim()) {
				this.activeIndex = null;
			}
		});
	},

    methods: {
        isActiveSubRoute(url) {
            if(this.$route.matched[0]?.path == '/'){
                return url == '/home';
            }
            return url.startsWith(this.$route.matched[0]?.path);
        },

        onMenuItemClick(event, item, index) {
            if (item.disabled) {
                event.preventDefault();
                return;
            }
            //execute command
            let commandExecuted = false;
            if (item.command) {
                commandExecuted =  item.command({ originalEvent: event, item: item });
                event.preventDefault();
            }
            if(!commandExecuted) {
                if (item.items) {
                    event.preventDefault();
                }
                if (this.root) {
                    EventBus.emit("app-root-menuitem-click", {
                        originalEvent: event,
                        to: item.to,
                    });
                }
                if (item.items) {
                    this.activeIndex = index === this.activeIndex ? null : index;
                }
                EventBus.emit("app-menuitem-click", {
                    originalEvent: event,
                    item: item,
                });
            }
        },
        onMenuItemMouseEnter(index) {
            if (this.root && this.menuActive && this.layoutMode === "slim" && !this.isMobile()) {
                this.activeIndex = index;
            }
        },
        visible(item) {
            return typeof item.visible === "function" ? item.visible() : item.visible !== false;
        },
        isMobile() {
            return window.innerWidth <= 760;//991;
        },
        isSlim() {
            return this.layoutMode === 'slim';
        },

        fullMenu() {
            window.innerWidth > 991;
        }
    }
};
</script>

<style scoped>

::v-deep(.layout-root-menuitem > a) {
    
   justify-content: center;
    display: flex;
    padding: 7px 0;
    cursor: pointer;
    position: relative;
    
}


</style>