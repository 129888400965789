<template>
    <div class="dm-channel-item" @click="onChannelClick()">
        <UserAvatar :imageSrc="dmUser.avatar" size="md" @click="goToProfile($event)" v-tooltip.bottom="'View Profile'"/>

        <div :class="'dm-channel-item-name-container'">
            <div class="dm-channel-item-title-container">
                <span class="dm-channel-title">{{ displayChannelTitle() }}</span>
                <span class="dm-time-posted">{{ getCorrectTime() }}</span>
            </div>
            <span class="dm-channel-full-name" v-if="displayChannelFullName()">{{ displayChannelFullName() }}</span>
        </div>

        <div class="dm-channel-item-counts-container">
            <span class="dm-channel-mention" v-if="mentionCount">
                {{ mentionCount > 0 ? mentionCount + (mentionCount > 1 ? ' mentions' : ' mention') : '' }}
            </span>
            <span class="dm-channel-unread" v-if="unreadCount"> {{ unreadCount }} unread </span>
        </div>

        <div class="dm-channel-item-actions-container" >
            <Button icon='pi pi-eye-slash' class='hide-btn blue-button' @click='hideDmChannel($event)' v-tooltip.bottom="'Hide'"/>
            <Button icon='pi pi-user-minus' class='block-btn red-button__secondary' @click='blockDmChannel($event)' v-tooltip.bottom="'Block User'"/>
            <Button icon='pi pi-trash' class='block-btn red-button__secondary delete-button' @click='deleteDmChannel($event)' v-tooltip.bottom="'Delete'"/>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../profile/UserAvatar';

import TimeUtils from '../../utilities/TimeUtils';
import ChatService from '../../service/ChatService';
import EventBus from '../../event-bus';

export default {
    name: 'DMChannelListItem',
    props: ['channel'],
    emits: ['channel-clicked'],
    components: {
        UserAvatar,
    },

    data() {
        return {
            dmUser: {
                appId: '',
                analystId: '',
                fullName: '',
                username: '',
                avatar: '',
            },
            dmChatData: {
                recipient: {
                    appId: '',
                    analystId: '',
                    fullName: '',
                    username: '',
                    avatar: '',
                },
                requester: {
                    appId: '',
                    analystId: '',
                    fullName: '',
                    username: '',
                    avatar: '',
                }
            },
            isCurrentDmUserRequester: false,
            isCurrentDmUserLeftChat: false,
        };
    },

    mounted() {
        this.initDmChannelData();
        
    },

    computed: {
        unreadCount() {
            // Adding this check, because this indicates you're entering a channel that you're a member of and it would only makes sense to see the read count that way

            if (this.channel.state) {
                if( this.channel.state.unreadCount) {
                    if( this.channel.state.unreadCount == 0 ) {
                        return null;
                    }
                    else if( this.channel.state.unreadCount > 10 ) {
                        return "10+";
                    }
                    else {
                        return this.channel.state.unreadCount;
                    }
                    
                }
                else {
                    return 0;
                }
            }
            else {
                return null;
            }
        },

        mentionCount() {
            if (this.channel['mentionCount']) {
                if (this.channel['mentionCount'] > 10) {
                    return '10+';
                } else {
                    return this.channel['mentionCount'];
                }
                
            }
            else {
                //console.log("no mention count")
                return 0;
            }
        }
    },

    methods:{
        goToProfile(e) {
             e.stopPropagation();
            this.$router.push('/analysts/profile/'+this.dmUser.username)
            
        },
        // channel data that has property 'state' will come from your own channels that you belong to, meaning that you're already a member

        onChannelClick() {
            let channelData = this.channel;
            
           this.$emit('channel-clicked', {'channelTitle': this.displayChannelTitle(), 'channelData': channelData, 'isMember': this.channel.state !== undefined});
        },

        getCorrectTime() {
            if(this.channel.state){
                const timeAgo = TimeUtils.getTimeFromNow(new Date(this.channel.state.last_message_at).toISOString());
                return timeAgo != "53 years ago" ? "| "+ timeAgo : '';
            } else {
                return '';
            }
        },

        displayChannelFullName() {
            return this.dmUser.fullName;
        },

        displayChannelTitle() {
           
            return '@' + this.dmUser.username + (this.isCurrentDmUserLeftChat ? " left the chat" : '');
           

            
        },

        

        initDmChannelData() {
            // console.log("this.channel.data.dmRequest", this.channel.data.dmRequest);
            const dmRequest = this.channel.data.dmRequest;
            this.dmChatData = {
                recipient: {
                    appId: dmRequest.recipientId,
                    analystId: dmRequest.recipientAnalystId,
                    fullName: dmRequest.recipientFirstName + (dmRequest.recipientLastName ? ' ' + dmRequest.recipientLastName : ''),
                    username: dmRequest.recipientName,
                    avatar: dmRequest.recipientAvatar,
                },
                requester: {
                    appId: dmRequest.requesterId,
                    analystId: dmRequest.senderAnalystId,
                    fullName: dmRequest.senderFirstName + (dmRequest.senderLastName ? ' ' + dmRequest.senderLastName : ''),
                    username: dmRequest.senderName,
                    avatar: dmRequest.senderAvatar,
                }
            }
            //console.log("this.dmChatData",this.dmChatData);

            this.isCurrentDmUserRequester = this.dmChatData.requester.appId == this.$store.state.users.user.userId;

            this.dmUser = this.isCurrentDmUserRequester ? this.dmChatData.recipient : this.dmChatData.requester;

            const members = Object.values(this.channel.state.members);
            this.isCurrentDmUserLeftChat = members.length != 2;
        },

         hideDmChannel(e) {
            e.stopPropagation();

            ChatService.hideDMChannel(this.channel).then( () => {
                console.log("dm channel should be hidden")
                this.$store.commit('MARK_CHANNELS_STALE_DM');
                EventBus.emit('refresh-my-dm-channel-list');
            });
        },


        blockDmChannel(e) {
            e.stopPropagation();

            // console.log("blockDmChannel",this.dmUser);
            if(this.dmUser.appId){
                ChatService.blockUser(this.dmUser.appId).then(resp=> {
                    if( resp.data.status === "success") {
                        this.$toast.add({severity:'success', summary: 'The user has been blocked', detail:'', life: 2000, group: 'tc'});
                        ChatService.deleteChannel(this.channel.data.chatChannelId).then(resp=> {// eslint-disable-line
                            this.$store.commit('MARK_CHANNELS_STALE_DM');
                            EventBus.emit('refresh-my-dm-channel-list');
                           
                        
                        });
                    }
                    
                });
            } else {
                // this.$toast.add({severity:'error', summary: 'Error blocking the user', detail:'', life: 2000, group: 'tc'});
                // this.leaveChannel();
            }
        },

        deleteDmChannel(e){
            e.stopPropagation();

            // console.log("blockDmChannel",this.dmUser);
            if(this.dmUser.appId){
                ChatService.deleteChannel(this.channel.data.chatChannelId).then(resp=> {// eslint-disable-line
                  
                    this.$toast.add({severity:'success', summary: 'The channel was deleted.', detail:'', life: 2000, group: 'tc'});
                    this.$store.commit('MARK_CHANNELS_STALE_DM');
                    EventBus.emit('refresh-my-dm-channel-list');
                   
                });
            } else {
                // this.$toast.add({severity:'error', summary: 'Error blocking the user', detail:'', life: 2000, group: 'tc'});
                // this.leaveChannel();
            }
        }

        
    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text  {
    background: white !important;
    color: #32364e !important;
}
</style>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.red-button__secondary.delete-button{
    margin-right: 10px;
}
.dm-channel-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #BFBFBF;
}
.dm-channel-item:hover {
    background: #32364e;
    color: #FFFFFF;
    cursor: pointer;
}
.dm-channel-item:hover .dm-channel-title {
    color: #FFFFFF;
}

.p-avatar {
    min-width: 48px;
}

.dm-channel-unread {
    background-color: #e63e3e;
}
.dm-channel-mention {
    background-color: #693bf5;
}

.dm-channel-unread,
.dm-channel-mention {
    margin: 2px;
    height: 100%;
    color: #fff;
    padding: 4px 8px;
    border-radius: 30px;
    white-space: nowrap;
}
/* .channel-item:hover .channel-unread {
    
} */

.dm-channel-item .dm-channel-title {
    font-weight: bold;
    color: #32364e;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}


.dm-channel-item .dm-channel-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.dm-channel-item .dm-time-posted {
    white-space: nowrap;
    font-size: .8rem;
}

.dm-channel-item .more-options {
    margin-left: auto;
}

.dm-channel-item-name-container {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.dm-channel-item-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.dm-channel-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.dm-channel-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover,
.hide-btn:hover{
    border-color: #FFFFFF !important;
}

.block-btn,
.hide-btn {
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 16px;
}

</style>