<template>
    <Dialog header="Invite user to chat" v-model:visible="display" :modal="true" class="invite-user-modal" :draggable="false" appendTo="#appChatDrawer" @show="onShown">
        <div class="container">
            <div class="invite-user-search-container">
                <span class="input-label">Search for a user:</span>
                <AutoComplete
                    id="inviteUserSearch"
                    ref="inviteUserSearch"
                    style="width: 100%; height: 100%; text-indent: 0rem !important"
                    v-model="selectedItem"
                    :field="getDisplayText"
                    :suggestions="filteredItems"
                    @complete="searchUsers($event)"
                    @item-select="itemSelected($event)"
                    placeholder="Invite user to chat"
                    @click="onInputBarClick"
                    @keydown="onInputKeydown"
                    optionGroupLabel="label"
                    optionGroupChildren="items"
                    scrollHeight="calc(40vh)"
                    class="invite-user-search-bar"
                    panel-class="invite-user-search-panel"
                >
                    <template #item="slotProps">
                        <div style="display: flex; align-items: center">
                            <UserAvatar :imageSrc="slotProps.item.avatar" size="md" style="min-width: 48px" />
                            <div style="margin-left: 2px; display: flex; flex-direction: column">
                                <span v-if="hasFullName(slotProps.item)" class="full-name">{{ getFullName(slotProps.item) }}</span>
                                <span class="symbol">@{{ slotProps.item.name }}</span>
                            </div>
                        </div>
                    </template>
                </AutoComplete>
            </div>
            <div class="channel-desc-textarea-container">
                <span class="input-label">Message:</span>
                <span :class="{ 'p-float-label': true, 'input-error': v$.message.$error }">
                    <Textarea id="messageTextarea" rows="2" cols="30" v-model="v$.message.$model" />
                    <!-- <label for="messageTextarea" class="input-label">Channel description</label> -->
                    <span class="channel-desc-counter" :style="{ color: v$.message.$error ? '#E63E3E' : null }">{{ message ? message.length : 0 }} / {{ messageLimit }} </span>
                </span>
            </div>
        </div>
        <template #footer>
            <Button class="invite-user-btn" label="Invite" @click="inviteUser()" :disabled="disableSubmitButton" />
        </template>
    </Dialog>
</template>

<script>
import UserAvatar from '../profile/UserAvatar';
import Dialog from 'primevue/dialog';
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import useVuelidate from '@vuelidate/core';
import { maxLength } from '@vuelidate/validators';

import UserService from '@/service/UserService.js';
import ChatService from '@/service/ChatService.js';

import Mark from 'mark.js';

export default {
    name: 'InviteUserModal',
    emits: ['user-invited'],
    setup: () => ({ v$: useVuelidate() }),
    data() {
        return {
            display: false,
            message: '',
            messageLimit: 100,
            selectedItem: { displayText: '', analystId: null },
            filteredItems: null,
        };
    },
    components: {
        Dialog,
        AutoComplete,
        Textarea,
        UserAvatar,
    },

    computed: {
        disableSubmitButton() {
            return this.v$.$errors.length > 0 || !this.selectedItem.analystId ? 'disabled' : null;
        },
    },

    methods: {
        resetError(field) {
            this.v$[field].$reset();
        },

        onShown() {
            const dialogElement = document.getElementsByClassName('invite-user-modal')[0];
            dialogElement.parentElement.style.position = 'absolute';
        },

        open(user) {
            this.v$.$reset();
            this.display = true;
            this.message = '';

            if(user && user.username && user.analystId) {
                this.selectedItem = { displayText: user.username, analystId: user.analystId };
            }
        },

        inviteUser() {
            ChatService.getDMChannelIfExists(this.selectedItem.analystId).then((resp) => {
                if (resp.status == 'success') {
                    if(resp.message == 'no such dm'){
                        this.v$.message.$touch();

                        if (this.v$.message.$errors.length == 0) {
                            this.display = false;
                            // console.log(this.selectedItem.displayText, this.selectedItem.analystId);
                            
                            ChatService.createDMRequest(this.selectedItem.analystId, this.message).then(resp => {
                                if( resp.data.status == 'success') {
                                    this.$emit('user-invited');
                                }
                                else {
                                    if( resp.data.message == 'Limit Exceeded') {
                                        this.$toast.add({ severity: 'error', summary: 'DM limit reached.', detail: 'Either delete some direct messaging channels/requests or consider upgrading your subscription.', life: 5000, group: 'tr' });
                                    }
                                }
                            });
                        }
                    } else {
                        this.display = false;
                        this.$toast.add({ severity: 'info', summary: 'Chat already exists', detail: '', life: 3000, group: 'tc' });
                        // console.log("getDMChannelIfExists", resp);
                        let channel = resp.channels[0];
                        channel["type"] = "privateMessaging";
                        channel["channelTitle"] = "@"+this.selectedItem.displayText;
                        this.EventBus.emit('open-dm-channel', channel);
                    }
                } else {
                    this.$toast.add({ severity: 'error', summary: 'Unexpected error during opening conversation', detail: '', life: 3000, group: 'tc' });
                }
            });
        },

        /* AutoComplete methods */
        getDisplayText() {
            return this.selectedItem.displayText ? this.selectedItem.displayText : '';
        },
        onInputKeydown(event) {
            const key = event.which;

            if (key === 13) {
                if (this.filteredItems && this.filteredItems.length == 1) {
                    this.$refs.inviteUserSearch.selectItem(event, this.filteredItems[0]);
                }
                event.preventDefault();
            }
        },
        onInputBarClick() {
            this.clearInput();
        },
        clearInput() {
            this.selectedItem = { displayText: '', analystId: null };
            // this.$refs.inviteUserSearch.focus();
        },
        searchUsers(event) {
            UserService.queryAnalysts(event.query, 10, 0).then((resp) => {
                if (this.searchQuery != event.query) {
                    this.searchQuery = event.query;

                    this.filteredItems = [
                        {
                            items: resp.data.analysts,
                        },
                    ];

                    // Highlighting of matches in results
                    this.$nextTick(() => {
                        this.highlightSearchMatches(event.query);
                    });
                }
            });
        },
        highlightSearchMatches(query) {
            let instance = new Mark(document.querySelectorAll('.invite-user-search-panel .p-autocomplete-item'));

            const regex = new RegExp(`\\b${query}|(?<=[@(])\\b${query}`, 'gi');

            instance.unmark();
            instance.markRegExp(regex, {
                className: 'search-match-highlight',
            });
        },
        itemSelected(event) {
            this.userSelected(event);

            this.$nextTick(() => {
                this.$refs.inviteUserSearch.$el.blur();
            });
        },
        userSelected(event) {
            if (event.value && event.value.name && event.value.analystId) {
                this.selectedItem = { displayText: event.value.name, analystId: event.value.analystId };
            }
        },
        hasFullName(analyst) {
            return analyst.firstName || analyst.lastName;
        },
        getFullName(analyst) {
            let fullName = analyst.firstName;
            if (analyst.middleName != null) {
                fullName += ' ' + analyst.middleName;
            }
            if (analyst.lastName != null) {
                fullName += ' ' + analyst.lastName;
            }
            return fullName;
        },
    },

    validations() {
        return {
            selectedItem: {
            },
            message: {
                maxLength: maxLength(this.messageLimit),
            },
        };
    },
};
</script>

<style>
.invite-user-modal {
    position: static;
    width: 400px;
}
.invite-user-modal .p-dialog-content {
    padding: 0 1.5rem 0 1.5rem !important;
}
.invite-user-search-panel .p-autocomplete-item-group {
    display: none;
}

/* Scrollable table wrapper */
.invite-user-search-panel {
    border-radius: 8px !important;
    overflow: auto;
    overflow-y: scroll;
    scrollbar-color: #bfbfbf #f2f2f2;
    scrollbar-width: thin;
}
.invite-user-search-panel::-webkit-scrollbar {
    width: 10px;
}
.invite-user-search-panel::-webkit-scrollbar-track {
    background-color: #f2f2f2;
    opacity: 1;
    transition: background-color 0.2s;
}
.invite-user-search-panel::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #bfbfbf; /* color of the scroll thumb */
    border-radius: 10px; /* roundness of the scroll thumb */
}
</style>

<style scoped>
.container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3px;
    align-items: center;
}

.input-label{
    margin-top: 8px;
    margin-bottom: 8px;
}

.invite-user-search-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
::v-deep(#inviteUserSearch .p-autocomplete-input) {
    width: 100%;
    font-size: 16px;
    padding: 8px 8px 8px 16px;
    height: 36px;
    color: black;
    background: #f2f4fa;
    border: 2px solid #bfbfbf;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}

.channel-desc-textarea-container .p-inputtextarea {
    font-size: 16px;
    resize: none;
    width: 100%;
    padding: 8px 8px 8px 16px;
    color: black;
    background: #f2f4fa;
    border: 2px solid #bfbfbf;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
}
.channel-desc-textarea-container {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar {
    /* width */
    width: 8px;
}
.channel-desc-textarea-container .p-inputtextarea::-webkit-scrollbar-thumb {
    background-color: #bfbfbf; /* color of the scroll thumb */
    border-radius: 20px; /* roundness of the scroll thumb */
    border: 9px solid #bfbfbf; /* creates padding around scroll thumb */
}
.channel-desc-textarea-container .p-inputtext:enabled:hover {
    border-color: #33cc99;
}
.channel-desc-textarea-container .p-inputtext:enabled:focus {
    border-color: #33cc99;
}
.channel-desc-textarea-container > label {
    font-size: 16px;
    padding-left: 16px;
    color: #bfbfbf;
}
.channel-desc-textarea-container .channel-desc-counter {
    font-size: 12px;
}

.invite-user-btn {
    margin-top: 10px;
    border-radius: 8px;
    width: 168px;
}
</style>