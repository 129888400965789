
import HomeTour from './tours/HomeTour';
import SingleStockPageTour from './tours/SingleStockPageTour.js';
import FundOverviewTour from './tours/FundOverviewTour';
import EquityOverviewTour from './tours/EquityOverviewTour';
import PortfolioOverviewTour from './tours/PortfolioOverviewTour';
import EventBus from '../event-bus';
import { HOME_TOUR_NAME, SINGLE_STOCK_PAGE_TOUR_NAME, EQUITY_OVERVIEW_TOUR_NAME, FUND_OVERVIEW_TOUR_NAME, PORTFOLIO_TOUR_NAME } from '../common/constants';
export default class TourService {
    currentTour = null;

    constructor(name) {

        switch(name) {
            case SINGLE_STOCK_PAGE_TOUR_NAME:
                this.currentTour = new SingleStockPageTour();
                
                break;
            case HOME_TOUR_NAME:
                this.currentTour = new HomeTour();
                break;
            case FUND_OVERVIEW_TOUR_NAME:
                this.currentTour = new FundOverviewTour();
                break;
            case EQUITY_OVERVIEW_TOUR_NAME:
                this.currentTour = new EquityOverviewTour();
                break;
            case PORTFOLIO_TOUR_NAME:
                this.currentTour = new PortfolioOverviewTour();
                break;
                
        }

        EventBus.off('remove-tour');
        EventBus.on('remove-tour', () => {
            this.currentTour.tour.complete();
        });
    }

    start() {
        this.currentTour.tour.addSteps(this.currentTour.steps);
        this.currentTour.tour.start();
    }


    startHelpButtonTour() {
        this.currentTour.tour.addSteps([
            {
                title: 'Help Button',
                attachTo: {element: document.querySelector('.user-bar-container .tour-button'), on: 'left'},
                canClickTarget: false,
                text: "Anytime you want to have a tour about a specific feature or want to create a support ticket, click on this button to do so.",
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                buttons: [
                    {
                        classes: 'green-button',
                        text: 'OK',
                        action: this.currentTour.tour.next
                    }
                ]
            }
        ]);

        this.currentTour.tour.start();
    }
  
  }