<template>
    <div :class="( readOnly ? 'layout-menu read-only' : 'layout-menu')" role="menu">
        <div class="menu-separator" role="separator" style="margin: 0 !important;"></div>
        <router-link v-if="invrsGroup" @click="onGroupItemClick(invrsGroup)" :to="'/groups/profile/' + invrsGroup.groupId" class="layout-menuitem" active-class="active-route" v-tooltip.right="menu.home.label" exact>
            <UserAvatar class="layout-menuitem-image" :imageSrc="menu.home.imageSrc" size="md" shape="roundedSquare" />
            <Badge :value="getGroupNotificationCount(invrsGroup)"  v-if="getGroupNotificationCount(invrsGroup)" class="menuitem-badge"></Badge>
            <i class="pi pi-angle-right menu-active"></i>
        </router-link>
        <infinite-scroll ref="groupsInfiniteScroll" @infinite-scroll="loadMoreGroups" message="" :noResult="noMoreGroups" :style="scrollStyle" :contentStyle="scrollContentStyle">
        <router-link @click="onGroupItemClick(group)" class="layout-menuitem" v-for="group in groups" :key="group.id" :to="'/groups/profile/' + group.groupId" active-class="active-route" v-tooltip.right="group.name" exact>
            <UserAvatar class="layout-menuitem-image" :imageSrc="group.avatar ? group.avatar : generateGroupAvatar(group.name)" size="md" shape="roundedSquare" />
            <Badge :value="getGroupNotificationCount(group)"  v-if="getGroupNotificationCount(group)" class="menuitem-badge"></Badge>
            <!-- <Badge :value="mentionNotifications"  v-if="mentionNotifications" class="mobile-mention-badge"></Badge> -->
            <i class="pi pi-angle-right menu-active"></i>
        </router-link>
        </infinite-scroll>
        <!-- <Badge :value="1" class="not-visible-menuitem-badge"></Badge> -->
        <div class="menu-separator" role="separator" style="margin: 0 !important;"></div>
        <router-link :to="menu.discover.to" class="layout-menuitem" :active-class="$route.path=='/groups' || $route.path=='/groups/create' ?'active-route':''" v-tooltip.right="menu.discover.label" exact>
            <Tag v-if="showScrollToSeeNotificationsTag"  icon="pi pi-arrow-down" class="menuitem-tag"></Tag>
            <div class="layout-menuitem-image">
                <!-- <i class="pi pi-ellipsis-h layout-menuitem-icon"></i> -->
                <span class="material-symbols-outlined layout-menuitem-icon">
                groups
                </span>
            </div>
            <i class="pi pi-angle-right menu-active"></i>
        </router-link>
        <!-- <router-link :to="menu.createGroup.to" class="layout-menuitem" v-tooltip.right="menu.createGroup.label" exact>
            <div class="layout-menuitem-image"><i class="pi pi-plus layout-menuitem-icon"></i></div>
            <i class="pi pi-angle-right menu-active"></i>
        </router-link> -->
    </div>
</template>

<script>
import InfiniteScroll from './components/common/scroll/infinite-scroll-vue3.vue';
import UserAvatar from './components/profile/UserAvatar.vue';
import Badge from 'primevue/badge';
import Tag from 'primevue/tag';
import GroupService from './service/GroupService';
import FeedService from './service/FeedService';
// import BrowserUtils from './utilities/BrowserUtils';
import { USER_TYPES } from './common/constants';
import { SELECTED_FEED, groupSubInfo, generateGroupAvatar } from './components/groups';

import {mapState} from 'vuex';
import EventBus from './event-bus';

const LOAD_GROUPS_LIMIT = 25;

export default {
    name: 'AppGroupMenu',
    components: {
        InfiniteScroll,
        UserAvatar,
        Badge,
        Tag
    },

    data() {
        return {
            //CONSTANTS
            SELECTED_FEED,
            USER_TYPES,
            LOAD_GROUPS_LIMIT,
            
            windowHeight: 0,
            currentText: '',
            groups: [],
            loading: false,
            initLoading: false,
            channelsNotificationsShown: false,
            noMoreGroups: false,
            feedType: SELECTED_FEED['MY_GROUPS'],
            isDefaultInvrsGroupExcluded: false,
            invrsGroup: null,
            showScrollToSeeNotificationsTag: false,

            menu: {
                home: { label: 'INVRS Investment Group', to: '/groups/profile/1', imageSrc: '/Invrs-Symbol-Blue.png' },
                discover: { label: 'Groups', to: '/groups' },
                // createGroup: { label: 'Create Group', to: '/groups?create=true' },
            },
            readOnly: false,
        };
    },
    watch: {
        chatMode(newVal, oldVal) { //eslint-disable-line
            this.readOnly = newVal == 'dm';
        },
    },

    computed: {
         ...mapState(['chatMode']),
         ...mapState(['feedNotificationCounts']),
         ...mapState(['groupChannelsUnreadCounts']),

        scrollStyle() {
            const itemHeight = 54;
            const scrollHeight = itemHeight * this.groups.length;
            const scrollMaxHeight = itemHeight * this.groupsVisibleItemsNumber;
            return `width: 100%; max-height: ${scrollMaxHeight}px; height: ${scrollHeight}px;`;
        },
        groupsVisibleItemsNumber() {
            const itemHeight = 62;
            const max = 800;
            if(this.windowHeight > max){
                return 4;
            } else if(this.windowHeight > max - (itemHeight)){
                return 3;
            } else if(this.windowHeight > max - (itemHeight * 2)) {
                return 2;
            } else if(this.windowHeight > max - (itemHeight * 3)) {
                return 1;
            } else {
                return 0;
            }
        },
        scrollContentStyle() {
            return 'width: 100%; height: 250px;';
        },
        initLoaderMinHeight() {
            return '248px';
        },
    },

    created() {
        EventBus.off('refresh-app-group-menu', this.initLoadGroups);
        EventBus.on('refresh-app-group-menu', this.initLoadGroups);
    },
    mounted() {
        this.initLoadGroups();
        this.$refs.groupsInfiniteScroll.scroller.$el.getElementsByClassName('p-scrollpanel-content')[0].addEventListener("scroll", this.hideNotificationsTag);

        this.windowHeight = window.innerHeight;
        this.$nextTick(() => {
            window.addEventListener('resize', this.onResize);
        })
    },

    beforeUnmount() { 
        window.removeEventListener('resize', this.onResize); 
    },

    methods: {
        groupSubInfo,
        generateGroupAvatar,

        onResize() {
            this.windowHeight = window.innerHeight;
        },

        hideNotificationsTag(){
            if(this.showScrollToSeeNotificationsTag){
                this.showScrollToSeeNotificationsTag = false;
            }
        },

        isGroupOpen(groupId){
            return this.$route.params.groupId == groupId;
        },

        getGroupNotificationCount(group){
           const groupId = group.groupId;
           if(group){
                let count = this.groupChannelsUnreadCounts[groupId]?.unreadCount ? this.groupChannelsUnreadCounts[groupId].unreadCount : 0;

                const groupFeedNotificationCounts = this.feedNotificationCounts?.unseenGroupNotifications[groupId]?.allCount ? this.feedNotificationCounts.unseenGroupNotifications[groupId].allCount : 0;
                if(groupFeedNotificationCounts > 0 && this.isGroupOpen(groupId)){
                    setTimeout(() => {
                        this.resetSeenFeedsNotifications(groupId);
                    }, 2000);
                }

                count += groupFeedNotificationCounts;

                if(count > 10){
                    return "10+";
                }

                return count;
            } else {
                return 0;
            }
        },

        resetSeenFeedsNotifications(groupId){
            if(!groupId && groupId == 1){
                return;
            }

            if(this.feedNotificationCounts.unseenGroupNotifications[groupId]){
                let notificationIds = this.feedNotificationCounts.unseenGroupNotifications[groupId].latestNotificationIds;

                this.feedNotificationCounts.unseenGroupNotifications[groupId].groupFeedNotifications.forEach(groupFeedNotification => {
                    if(groupFeedNotification?.feedNotificationIds && groupFeedNotification.feedNotificationIds.length > 0){
                        notificationIds.push(...groupFeedNotification.feedNotificationIds);
                    }
                });

                if(notificationIds){
                    FeedService.markNotificationsSeenByIds(notificationIds);
                }
                this.$store.commit("REMOVE_GROUP_POST_NOTIFICATIONS_SEEN", groupId);
            }
        },

        onLoading() {
            this.initLoading = true;
            this.groups = [];
        },

        initLoadGroups() {
            //console.log('initLoadGroups');
            this.currentText = '';
            this.groups = [];
            this.initLoading = true;
            this.isDefaultInvrsGroupExcluded = false;
            this.loadGroups(LOAD_GROUPS_LIMIT, 0, '');
        },

        loadGroups(limit, offset, query) {
            GroupService.getMyGroups(offset, limit, query).then((resp) => {
                if (resp.data.status == 'success') {
                    let groups = resp.data.groups;

                    this.initLoading = false;
                    this.loading = false;

                    const indexInvrsGroup = groups.findIndex(group => group.groupId == 1);
                    if(indexInvrsGroup >= 0){
                        groups.splice(indexInvrsGroup, 1);
                        this.isDefaultInvrsGroupExcluded = true;
                    }

                    if (groups.length > 0) {
                        this.groups = this.groups.concat(groups);

                        this.noMoreGroups = false;
                    } else {
                        this.noMoreGroups = true;
                    }
                }

                if(!this.invrsGroup) {
                    GroupService.getGroup(1).then( resp => {
                        if( resp.data.status == "success") {
                            this.invrsGroup = resp.data.group;
                            // this.showChannelsNotifications();
                        }
                    })
                }
            });
        },
        async loadMoreGroups() {
            // if (!this.loading && this.groups.length >= LOAD_GROUPS_LIMIT) {
            if (!this.loading) {
                this.loading = true;

                if (!this.noMoreGroups) {
                    this.loadGroups(
                        LOAD_GROUPS_LIMIT, 
                        this.groups.length + (this.isDefaultInvrsGroupExcluded ? 1 : 0), 
                        this.currentText);
                } else {
                    this.loading = false;
                }
            }
        },

        onGroupItemClick(group) {
            this.resetSeenFeedsNotifications(group.groupId);
        },
    },
};
</script>

<style scoped>
.read-only {
    opacity: .3;
    pointer-events: none;
}
.menuitem-badge {
    top: 6px !important;
    right: 11px !important;
    background-color: #e63e3e !important;
    color: #ffffff !important;
    width: 21px !important;
    height: 21px !important;
    line-height: 1.6rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
    transition: background-color 0.2s !important;
    overflow: hidden !important;
    cursor: pointer !important;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%) !important;
    border-radius: 50% !important;
    transform: rotate(0deg) !important;
    transition: transform 1s !important;
    padding: 0 !important;
}

.menuitem-tag{
	position: absolute;
	top: -15px !important;
	right: 14px !important;
	background-color: #e63e3e !important;
	color: #ffffff !important;
	height: 14px !important;
	line-height: 1.6rem !important;
	font-size: 1.35rem !important;
	text-align: center !important;
	overflow: hidden !important;
	cursor: pointer !important;
	box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%) !important;
	transform: rotate(0deg) !important;
	transition: transform 1s !important;
	z-index: 999;
	width: 65% !important;
	border-radius: 4px 4px 0 0;
	padding: 2px;
}

.not-visible-menuitem-badge {
    top: 673px !important;
    right: 2px !important;
    background-color: #e63e3e !important;
    color: #ffffff !important;
    width: 21px !important;
    height: 21px !important;
    line-height: 1.6rem !important;
    font-size: 0.75rem !important;
    text-align: center !important;
    transition: background-color 0.2s !important;
    overflow: hidden !important;
    cursor: pointer !important;
    box-shadow: -0.25rem 0 1rem rgb(0 0 0 / 15%) !important;
    border-radius: 50% !important;
    transform: rotate(0deg) !important;
    transition: transform 1s !important;
    padding: 0 !important;
}
</style>