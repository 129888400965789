<template>
    <div class="container">
        <TabMenu :model="feedTypeMenuItems" :activeIndex="activeIndex" :scrollable="true">
            <template #item="slotProps">
              

                 <li  :class="'p-tabmenuitem ' + (slotProps.item.index == activeIndex? 'p-highlight ' : '') +(slotProps.item.feedDisabled ? 'disabled-feed' : '')" role="presentation"  @click="(slotProps.item.feedDisabled ? '' : setSelectedFeed(slotProps.item))">
                    <a role="menuitem" class="p-menuitem-link" :aria-label="slotProps.item.label" >
                        <span class="p-menuitem-text">{{slotProps.item.label}}</span></a>
                </li>
            </template>
        </TabMenu>
    </div>
</template>

<script> 
import TabMenu from 'primevue/tabmenu';

import EventBus from '../../event-bus';

import UserUtils from '../../utilities/UserUtils';

export default {
    name: 'FeedTypeMenu',
    components: {
        TabMenu
    },

    data(){
        return {
            
            activeIndex: 0,
        }
    },

    computed : {
        feedTypeMenuItems() {
            let menuItems = [
                { index: 0, label: 'Latest', feedType: 'latest' },
                { index: 1, label: 'Q&A', feedType: 'questions' },
                { index: 2, label: 'Motions', feedType: 'motions' , disabled:(!UserUtils.isFeatureEnabled('collaborativePortfoliosEnabled'))},
               
               
                
                
            ]
            if( this.$store.state.featuredGroupFeed) {
                menuItems.push({ index: 3, label: 'Welcome', feedType: 'featuredFeed'});
                menuItems.push( {  index: 4,  label: 'Following', feedType: 'yourFeed' });
            }
            return menuItems;
        }
    },

    mounted() {
        // this.items = this.feedTypeMenuItems.map((item, index) => {
        //     let obj = item;

        //     obj['index'] = index;

        //     return obj;
        // });

        if(this.$store.state.selectedFeedType && this.$store.state.selectedFeedType != 'latest'){
            switch(this.$store.state.selectedFeedType){
                
                case 'questions':
                    this.setSelectedFeed( this.feedTypeMenuItems[1]);
                    break; 
                case 'motions':
                    this.setSelectedFeed( this.feedTypeMenuItems[2]);
                    break;
                case 'featuredFeed':
                    this.setSelectedFeed( this.feedTypeMenuItems[3]);
                    break;
                case 'yourFeed':
                    this.setSelectedFeed( this.feedTypeMenuItems[4]);
                    break;
                
            }
        }
       
    },

    methods:{
        setSelectedFeed(selectedOption){
            this.activeIndex = selectedOption.index;
            //EventBus.emit('change-feed-type', selectedOption.feedType);
            this.$store.commit("SET_SELECTED_FEED_TYPE", selectedOption.feedType);
            EventBus.emit('update-feed-type-pref');
        },
        refresh() {
            EventBus.emit('refresh-feed');
        },
    }
    
}
</script>

<style scoped>
.container {
    width: 100%;
}

::v-deep(.p-tabmenu-nav) {
    
    text-align: center;
    
    border-width: 1px 0 0 0 !important;
   

}

::v-deep(.p-menuitem-text){
    margin:auto;
}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):not(:hover) .p-menuitem-link) {
    
    border-bottom:none; 
}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem:not(.p-highlight):not(.p-disabled):hover .p-menuitem-link) {
    background: #ffffff;
    border-color: #6c757d;
    color: #6c757d;
    border-width:0 0 2px 0;
}

::v-deep(.p-tabmenuitem) {
    padding-top: 0;
    
}

::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem ){
    margin:auto;
    width: unset;
    padding-top: 0;
    padding-bottom: 0.3rem

}
::v-deep(.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link) {
    padding-bottom: .3rem;
    padding-top: .6rem;
    
}
/*
::v-deep(.p-tabmenu) {
    position: relative;
    height: 26px;
    width: 100%;
    border: none;
    overflow: hidden;
}

::v-deep(.p-tabmenu-nav) {
    height: 100%;
    display: flex;
    border: none;
}


::v-deep(.p-tabmenuitem) {
    width: 50%;
    border: none;
    padding: 0;
}

::v-deep(.p-tabmenuitem > button) {
    display: flex;
    height: 100%;
    width: 100%;
    border: none;
    font-size: 14px;
    color: #32364E;
    font-family: 'Trebuchet MS', 'Verdana';
    background: #fff;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

::v-deep(.p-highlight > button) {
    background: #32364E;
    color: #fff !important;
} */
</style>