export default class SingleStockPageTour {

    defaultOptions = {
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'invrs-onboarding-tour'
        }
    };

    createSteps(tour) {
        // This block of code will determine how the order of the steps shall go depending on what is selected
        let postsEventsNewsSteps = [];

        // If the 'Posts' button is selected
        if (document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child')) {
            postsEventsNewsSteps = [
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
                {
                    title: 'Events',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:nth-child(2)'), on: 'bottom'},
                    text: `Click on 'Events'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    text: "You can find earnings & dividend announcements here!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
                {
                    title: 'News',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:last-child'), on: 'bottom'},
                    text: `Click on 'News'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .news-feed-container')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "Check out the latest headlines and stories pertaining to this company!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
            ]
        // If 'Events' button was selected
        } else if (document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)')) {
            postsEventsNewsSteps = [
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    text: "You can find earnings & dividend announcements here!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
                {
                    title: 'Posts',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:first-child'), on: 'bottom'},
                    text: `Click on 'Posts'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
                {
                    title: 'News',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:last-child'), on: 'bottom'},
                    text: `Click on 'News'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .news-feed-container')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "Check out the latest headlines and stories pertaining to this company!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                }
            ]
        // If 'News' button was selected
        } else if (document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child')) {
            postsEventsNewsSteps = [
                {
                    title: 'News',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.news-feed-container')
                    }, on: 'left'},
                    text: 'Keep up to date with the latest stories!',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'blue-button',
                            text: 'Back',
                            action: tour.back 
                        },
                        {
                            classes: 'blue-button',
                            text: 'Next',
                            action: tour.next   
                        }
                    ]
                },
                {
                    title: 'Posts',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:first-child'), on: 'bottom'},
                    text: `Click on 'Posts'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },                {
                    title: 'Events',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:nth-child(2)'), on: 'bottom'},
                    text: `Click on 'Events'`,
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    text: "You can find earnings & dividend announcements here!",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                         classes: 'blue-button',
                         text: 'Next',
                         action: tour.next   
                        }
                    ]
                },
            ]
        }
        
        return [
        // {
        //     title: 'Searching up a stock',
        //     attachTo: {element: document.querySelector('#secQuickSearch > input'), on: 'bottom'},
        //     advanceOn: {
        //         selector: '#secQuickSearch > input',
        //         event: 'click'
        //     },
        //     text: 'This is the search bar, click into it this area',
        // },
        // {
        //     title: 'Searching up a stock',
        //     attachTo: {element: document.querySelector('#secQuickSearch > input'), on: 'top'},
        //     advanceOn: {
        //         selector: '#secQuickSearch > input',
        //         event: 'select-security'
        //     },
        //     text: `Type out AAPL, this is the symbol for the equity: Apple`
        // },
        // {
        //     title: 'Searching up a stock',
        //     attachTo: {element: () => {
        //         return document.getElementById('secQuickSearch_2');
        //     }, on: 'right'},
        //     advanceOn: {
        //         selector: '#secQuickSearch_2',
        //         event: 'click'
        //     },
        //     text: `Select AAPL`
        // },
        {
            title: "Welcome to the Equity Overview page",
            text: "Anytime you search for a specific Equity, you will be greeted by this page. Now let's look at what's featured:",
            buttons: [
                {
                 classes: 'blue-button',
                 text: 'Next',
                 action: tour.next   
                }
            ]
        },
        {
            title: 'Price Series Chart',
            attachTo: {element: document.querySelector('.security-overview-chart-card'), on: 'right'},
            text: "This is an interactive view of the company's price performance. This tool presents the daily stock price and associated volume in a chosen date range.",
            buttons: [
                {
                 classes: 'blue-button',
                 text: 'Next',
                 action: tour.next   
                }
            ]
        },
        {
            title: 'Analyst Ratings',
            attachTo: {element: document.querySelector('.analyst-ratings-card'), on: 'left'},
            text: "Keep up to date with the expectations given by analysts who cover this company.",
            buttons: [
                {
                 classes: 'blue-button',
                 text: 'Next',
                 action: tour.next   
                }
            ]
        },
        ...postsEventsNewsSteps,
        {
            title: 'EPS History',
            attachTo: {element: document.querySelector('.earnings-container'), on: 'right'},
            text: "Insight into historical Earnings per Share estimates versus what the company reported.",
            scrollTo: {behavior: 'smooth', block: 'center'},
            buttons: [
                {
                 classes: 'blue-button',
                 text: 'Next',
                 action: tour.next   
                }
            ]
        },
        {
            title: 'Summary',
            text: "This page also includes the company's description and an overview of their most important statistics, financials, and valulation metreics. Check them out later!",
            buttons: [
                {
                 classes: 'blue-button',
                 text: 'Next',
                 action: tour.next   
                }
            ]
        }
        ]
    }
  }