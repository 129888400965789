<template>
    <Dialog v-model:visible="display" :modal="true" :draggable="false" header="Investment Calculator" class='investment-calculator-modal' >
        <LoadingSpinner :showLoading="!chartInitialized" minHeight="100px;"/>  
        <div v-show="chartInitialized">
            <div ref="qmodDiv" id="investment-calculator-qtool"
                data-qmod-tool="investmentcalculator"
                :data-qmod-params='investmentCalculatorParams'
                class="qtool investment-calculator-qtool">
            </div>
        </div>
    </Dialog>
  </template>
  
  <script>
  import LoadingSpinner from '../common/LoadingSpinner';
  import Dialog from 'primevue/dialog';
  
  export default {
      name: 'InvestmentCalculator',
     components: {
       LoadingSpinner, Dialog
     },
      
      data() {
          return {
              chartInitialized: false,
              display: false
          }   
      },
      computed : {
          investmentCalculatorParams() {
            return '{"lang":"en","amount":0,"hideSymbolInput":false,"modal":true,"chart":{"enabled":true,"type":"area","height":"250","color":"#33CC99"}}';
          }
      },
  
      mounted() {

      },

      methods: {
        open() {
            this.display = true;

            this.$nextTick(() => {
                this.$refs.qmodDiv.addEventListener('qmod-after-template-bind',  () => {
                    console.debug('investment calculator template bound**************')
                    this.chartInitialized = true;
                }, false) // {once:true}
        
                window.qMod.loadTool('investment-calculator-qtool'); 
            });
        }
      }
     
     
  }
  </script>

<style>
.investment-calculator-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 425px;
}

.investment-calculator-modal .p-dialog-header {
    padding: 16px;
}

.investment-calculator-modal .p-dialog-content {
    padding: 0 16px 16px;
    text-align: center;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

.flatpickr-calendar {
    font-family: "Trebuchet MS", "Verdana";
}
.flatpickr-calendar .flatpickr-day.selected {
    background-color: #32364E;
}
.flatpickr-current-month .numInputWrapper,
.flatpickr-current-month .flatpickr-monthDropdown-months {
    font-weight: bold;
    color: #32364E;
}

@media (max-width: 760px) {
    .investment-calculator-modal {
    
        height: 100vh!important;
        
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    .investment-calculator-modal .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .investment-calculator-modal .p-dialog-content {
        padding: 0 16px 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .investment-calculator-modal .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .flatpickr-months .flatpickr-month {
        height: 60px;
    }

    .flatpickr-current-month .flatpickr-monthDropdown-months {
        height: 40px;
    }

    .flatpickr-current-month .numInputWrapper {
        width: 7ch;
    }
    .numInputWrapper span {
        width: 20px;
    }
    .numInputWrapper span:after {
        left: 30%;
    }

    .flatpickr-current-month input.cur-year {
        height: 40px;
    }

    
    .flatpickr-months .flatpickr-next-month svg, 
    .flatpickr-months .flatpickr-prev-month svg {
        margin-top: 10px;
    }
}
</style>

<style scoped>
::v-deep(h2.qmod-hbg.qmod-heading){
        display:none;
    }
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper .qmod-popup .qmod-popup-titlebar-buttons .qmod-btn) {
    color: #afafaf;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper) {
    left: -150px;
    background: none;
    border-radius: 16px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper .qmod-popup) {
    border-radius: 16px;
    width: 850px;
    max-width: 850px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper .qmod-popup .qmod-popup-title) {
    background-color: #FFFFFF;
    font-size: 20px;
    font-weight: bold;
    color: #32364E;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-block-wrapper) {
    margin: 0;
}
.investment-calculator-qtool ::v-deep(h2.qmod-heading) {
    display: none;
}

.investment-calculator-qtool ::v-deep(.qmood-symbol-input),
.investment-calculator-qtool ::v-deep(.qmod-calendar-control) {
    text-align: left;
}
.investment-calculator-qtool ::v-deep(.qmood-symbol-input label),
.investment-calculator-qtool ::v-deep(.qmod-calendar-control label),
.investment-calculator-qtool ::v-deep(.qmod-checkbox-inline span:first-of-type) {
    font-size: 16px;
    font-weight: bold;
    color: black;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkradlabel) {
    height: 30px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad:checked+label, .qmod-investment-calculator .qmod-chkrad:not(:checked)+label) {
    min-height: 30px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:checked+label:after),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad:checked+label:before),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:checked+label:before),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:not(:checked)+label:before),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad:not(:checked)+label:before) {
    color: #32364E;
}

.investment-calculator-qtool ::v-deep(.inner-addon i:hover) {
    color: #33CC99;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=checkbox]:checked+label:after) {
    color: #32364E
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-adjr) {
    margin-top: 16px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-adjr label) {
    color: black;
    font-size: 16px;
    font-weight: bold;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-btn-go),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-splits-btn),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-dividens-btn),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-calculate) {
    background-color: #33CC99;
    border-radius: 50px;
    font-weight: bold;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-btn-go:hover),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-splits-btn:hover),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-dividens-btn:hover),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-calculate:hover) {
    background: #00C083;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-btn-go:focus),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-splits-btn:focus),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-dividens-btn:focus),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-calculate:focus) {
    background: #00C083;
    outline: none;
}

.investment-calculator-qtool ::v-deep(.qmod-symbollookup) {
    border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
}
.investment-calculator-qtool ::v-deep(.qmod-symbol-lookup-btn btn) {
    border-radius: 50px;
    font-weight: bold;
    background: #33CC99;
}
.investment-calculator-qtool ::v-deep(.qmod-symbol-lookup-btn btn:hover) {
    background: #00C083;
}
.investment-calculator-qtool ::v-deep(.qmod-symbol-lookup-btn btn:focus) {
    background: #00C083;
    outline: none;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-tab-views .qmod-tab) {
    color: #32364e;
    padding: 8px 12px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-tab-views .qmod-tab:hover),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-tab-views .qmod-tab.qmod-tab-active) {
    border-radius: 16px;
    color: #FFFFFF;
    border: 1px solid #32364E;
    background: #32364E;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results) {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow:hidden;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-sersel) {
    display: flex;
    align-items: center;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-sersel .qmod-searchby) {
    margin-right: 8px;
    color: black;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-label) {
    border-color: #32364e;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-label.qmod-active ) {
    background-color: #32364E;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-label.qmod-searchby-name) {
    border-radius: 12px 0 0 12px;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-label.qmod-searchby-symbol) {
    border-radius: 0 12px 12px 0;
}

.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results .qmod-smartlookup-result tr td) {
    padding: 12px;
    text-align: left;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-wrap) {
    margin: 0;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    overflow-x: auto;
}

.investment-calculator-qtool ::v-deep(.highcharts-root) {
    font-family: "Trebuchet MS", "Verdana" !important;
}
.investment-calculator-qtool ::v-deep(.highcharts-series.highcharts-series-0.highcharts-area-series.highcharts-color-0 path:nth-child(2)) {
    stroke: #33CC99 !important;
}
.investment-calculator-qtool ::v-deep(.highcharts-markers.highcharts-series-0.highcharts-area-series.highcharts-color-0.highcharts-tracker path) {
    fill: #33CC99 !important;
}
.investment-calculator-qtool ::v-deep(.highcharts-label.highcharts-tooltip.highcharts-color-0 path:last-of-type) {
    stroke: #33CC99 !important;
}
.investment-calculator-qtool ::v-deep(.highcharts-label.highcharts-tooltip.highcharts-color-0 text tspan:nth-child(2)) {
    fill: #33CC99 !important;
} 
.investment-calculator-qtool ::v-deep(.highcharts-axis-labels.highcharts-xaxis-labels > text) {
    color: black !important;
    fill: black !important;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-final-investment-headers),
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-initial-investment-headers) {
    background-color: #32364E;
    color: #FFFFFF;
    font-weight: bold;
}
.investment-calculator-qtool ::v-deep(.pure-u-sm-1-2 > div) {
    text-align: left;
    color: black;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-split) {
    border-bottom: 1px solid black;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-split:nth-child(odd)) {
    background: none;
}
.investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-split:last-child) {
    border-bottom: 0;
}

@media (max-width: 760px) {
    .investment-calculator-qtool ::v-deep(.qmood-symbol-input label),
    .investment-calculator-qtool ::v-deep(.qmod-calendar-control label),
    .investment-calculator-qtool ::v-deep(.qmod-checkbox-inline span:first-of-type) {
        font-size: 14px;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:checked+label:after),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad:checked+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:checked+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad[type=radio]:not(:checked)+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-chkrad:not(:checked)+label:before) {
        font-size: 20px;
    }
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-checkbox-inline .qmod-chkrad[type=radio]:checked+label:after),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-checkbox-inline .qmod-chkrad:checked+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-checkbox-inline .qmod-chkrad[type=radio]:checked+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-checkbox-inline .qmod-chkrad[type=radio]:not(:checked)+label:before),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-checkbox-inline .qmod-chkrad:not(:checked)+label:before) {
        left: 5px;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-adjr label) {
        font-size: 14px;
    }

    .investment-calculator-qtool ::v-deep(.qmod-symbol-lookup-btn btn),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-btn-go),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-splits-btn),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-dividens-btn),
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-calculate) {
        display: flex;
        align-items: center;
        text-align: center;
        width: unset;
        height: unset;
        min-width: unset;
        padding: 8px 16px;
    }

    .investment-calculator-qtool ::v-deep(.pure-u-sm-1-2.qmod-textr) {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper) {
        left: 0;
        background: none;
        border-radius: 0px;
    }
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper .qmod-popup) {
        border-radius: 0;
        width: 100vw;
        height: 100vh;
    }
    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper .qmod-popup .qmod-popup-title) {
        border-radius: 0;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-wrap) {
        max-height: 100vh;
        min-height: 100vh;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-smartlookup-results) {
        border-radius: 0;
        height: 100vh;
        overflow: visible;
    }

    .investment-calculator-qtool ::v-deep(.qmod-investment-calculator .qmod-popup-wrapper.qmod-investement-modal .qmod-popup .qmod-popup-body) {
        max-height: calc(100vh - 150px);
        height: 100%;
    }

    /* note this is needed to stop mobile zooming in when entering text */
    input:focus {
        font-size: 14px;
        background: #eee;
    }
}
</style>