<template>
    <Sidebar id='appChatDrawer' class='chat-drawer' v-model:visible='display' position='right' :modal='false' @hide='onHide' @show='onShow'>
        <template #header>
            <span class='chat-drawer-header-text'>{{headerTitle}}</span>
            <template v-if="!inDm">
                <span class='icon-container'>
                    <span class="material-icons-outlined refresh-channels-icon" title="Refresh" @click="onRefreshClick()">refresh</span>
                    <span class="material-icons-outlined create-channel-icon" title="Create DM Request" @click="onDMRequestClick()">add_comment</span>
                </span>
            </template>
            <template v-else>
                <span class='icon-container'>
                <span class="material-icons-outlined go-back-icon" @click="deselectChannel()">arrow_back</span>
                </span>
                
            </template>
            
        </template>
         
       
        <Menu ref="menu" :model="channelOptionsMenuItems" :popup="true" v-if='selectedChannel'/>
        <DMSearch @search-input='grabSearchInput'  @hidden-toggled="onHiddenToggled" v-if='!selectedChannel '/>
        <TabView v-if='!selectedChannel'  v-model:activeIndex="activeIndex">
            <TabPanel header="DM's" >
                <DMChannelList  @channel-selected="selectChannel" ref="myDMChannelList" :filter="searchFilter" :includeHidden="includeHidden"/>
            </TabPanel>
            <TabPanel header="Requests">
                <DMRequestList ref="dmRequestList"/>
            </TabPanel>
        </TabView>
<!--             
        <Panel class='dm-channel-list-panel' id="dm-channel-list-panel" v-if='!selectedChannel' >
           
                
            
        </Panel> -->

        
        <DMChannel v-if='selectedChannel' :channelData="selectedChannel" />
    </Sidebar>
    <InviteUserModal @user-invited="onUserInvited()" ref='inviteUserModal' v-if='isMounted'/>
    
</template>

<script>
import Sidebar from 'primevue/sidebar';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';


import DMChannel from './components/chat/DMChannel.vue';
import DMChannelList from './components/chat/DMChannelList.vue'
import DMRequestList from './components/chat/DMRequestList.vue';
//import RecommendedChannelList from './components/chat/RecommendedChannelList.vue';
import InviteUserModal from './components/chat/InviteUserModal.vue';
import DMSearch from './components/chat/DMSearch.vue';
import UserUtils from './utilities/UserUtils';


import EventBus from './event-bus';

export default {
    name: 'AppChatDrawer',
    emits: ['close-chat-drawer'],
    data() {
        return {
            display: false,
            isOpeningDmChat: false,
            selectedChannel: null,
            
            chatClient: null,
            isSearching: false,
            isMounted: false,
            timeoutId: null,
            selectedMenuItem: null,
            headerText: '',
           activeIndex: 0,
            includeHidden: false,
            searchFilter: null,
            inDm: false,
            channelOptionsStyle: 'display: none;',
            headerTitle: 'Direct Messages'
        }
    },
    components: {
        Sidebar, 
        DMChannel, 
        DMChannelList,
        InviteUserModal, 
        DMSearch, 
         TabView, 
        TabPanel, 
        DMRequestList
       
    },


    computed: {
       

       

        channelOptionsMenuItems() {
            return this.getChannelOptionsMenuItems();
        }
    },
    
    created() {
        EventBus.off('deselect-channel');
        EventBus.on('deselect-channel', this.deselectChannel);
    },

    methods: {
        
        onDMRequestClick() {
            this.searchText = '';
                
            this.openInviteUserModal();
        },

         

        onRefreshClick() {
            this.searchText = '';
                
            this.$store.commit('MARK_CHANNELS_STALE_DM');
            
            this.$refs.myDMChannelList.refreshList()
        },

        onHiddenToggled(includeHiddenVal) {
            this.includeHidden = includeHiddenVal;
            this.$store.commit('MARK_CHANNELS_STALE_DM');
            //this.refreshCurrentList();
        },

      
        async handleVisibilityChange() {
            if (this.selectedChannel) {
                if (document.visibilityState === 'hidden' ) {
                    this.timeoutId = setTimeout(()=> { EventBus.emit('chat-pause-required')}, 5000)
                } else {
                    clearTimeout(this.timeoutId);
                   
                    EventBus.emit('chat-reconnect-required');
                }
            }
        },

        grabSearchInput(val) {
            const searching  = val ? true : false;

            this.isSearching = searching;
            this.searchFilter = val;
        },

        open(chatChannel) {
            this.display = true;
            this.selectedChannel = null;
            this.inDm = false;
            
            this.isSearching = false;
            this.isOpeningDmChat = false;

            if(chatChannel){
                this.isOpeningDmChat = true;
                
                this.$nextTick(() => {
                    this.selectChannel(chatChannel);
                })
                //setTimeout(() => this.selectChannel(chatChannel), 0);
            }
        },

        onShow() {
            this.$store.commit('SET_CHAT_MODE', "dm");
            
            this.isMounted = true;
            

           

        },

       

        onHide() {
            this.isMounted = false;
            this.chatClient = null;
            document.removeEventListener('visibilitychange', this.handleVisibilityChange);
           this.$store.commit('SET_CHAT_MODE', "groups");
            this.$emit('close-chat-drawer');
        },

        async selectChannel(channelObj) {
            // console.log("selectChannel",channelObj);
            this.isSearching = false; // important otherwise after searching and selecting channel can't see channel list

            try {
                
                this.selectedChannel = channelObj.channelData ? channelObj.channelData :  channelObj;
                this.headerTitle = channelObj.channelTitle;
                this.inDm = true;

                

                if (this.selectedChannel.type == "privateMessaging" || !channelObj.isMember) {
                    //channelOptionsIcon.style.display = 'none';
                    this.channelOptionsStyle="dispaly:none;";
                } 
                else {
                    this.channelOptionsStyle="";
                }

                

            } catch(err) {
                
                this.selectedChannel = null;
                this.headerTitle = 'Direct Messages';
                this.inDm = false;
                const summary = `Something went wrong with trying to connect to channel "${channelObj.channelTitle}". Contact Support and write a ticket.`;

                console.debug('There was an error:' + err);
                this.$toast.add({severity:'error', summary: summary, life: 5000});
            }
        },

        openInviteUserModal(user) {
            if(user && user.username && user.analystId){
                this.open();
                setTimeout(() => this.$refs.inviteUserModal.open(user), 0);
            } else {
                this.$refs.inviteUserModal.open();
            } 
        },

        onUserInvited() {
            console.log("invite sent refrshing list")
            
            if( this.activeIndex == 1) {
                this.$refs.dmRequestList.refreshList();
            }
            
        },

       

        toggleMenu(event) {
            this.$refs.menu.toggle(event);
        },
        getChannelOptionsMenuItems() {
            let menuItems = [];
            let isCreator = null;

            if (this.selectedChannel.data && this.selectedChannel.data.type == "privateMessaging") {
                return menuItems;
            }

            const userId = this.$store.state.users.user.userId.toString();
            
            if (this.selectedChannel.data) {
                isCreator = userId === this.selectedChannel.data.created_by.id;
            } else {
                isCreator = userId === this.selectedChannel.channelOwnerId;
            }

            // console.log("getChannelOptionsMenuItems", isCreator,UserUtils.userIsChatModerator() );
            
            if (isCreator ) {
                

                menuItems.push({
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.selectedMenuItem = 0;
                        this.headerText = 'Delete Channel?';
                        this.$refs.confirmActionModal.open();
                    }
                })
            } 
            else if( UserUtils.userIsChatModerator() ) {
                 menuItems.push({
                    label: 'Leave',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.selectedMenuItem = 1;
                        this.headerText = 'Leave Channel?';
                        this.$refs.confirmActionModal.open();
                    }
                });

                 menuItems.push({
                    label: 'Delete',
                    icon: 'pi pi-trash',
                    command: () => {
                        this.selectedMenuItem = 0;
                        this.headerText = 'Delete Channel?';
                        this.$refs.confirmActionModal.open();
                    }
                });

            }
            
            else {
                menuItems.push({
                    label: 'Leave',
                    icon: 'pi pi-sign-out',
                    command: () => {
                        this.selectedMenuItem = 1;
                        this.headerText = 'Leave Channel?';
                        this.$refs.confirmActionModal.open();
                    }
                })
            }

            return menuItems;
        },

        

        /* Basically goes back to the main screen by changing the title and removing the back and ellipses icons and adding back the create icon with it's 
        clicking functionality */
        deselectChannel() {
            this.headerText = "Direct Messages";
            this.inDm = false;
            
            
            this.isSearching = false;
            this.selectedChannel = null;
            this.$store.commit('MARK_CHANNELS_STALE_DM');
            //this.$refs.myDMChannelList.refreshList();
        }

        

    }
}
</script>

<style>
.chat-drawer {
    width: 35% !important;
    padding: 16px 0px 0px;
}

.chat-drawer .p-sidebar-header {
    justify-content: flex-start;
    padding: 0px 16px 16px;
}
.chat-drawer  .p-sidebar-header .p-sidebar-header-content {
    width: 100%;
    display: flex;
}
.chat-drawer .icon-container {
    display: flex;
    align-items: center;
    margin-left: auto;
}
.chat-drawer .icon-container .material-icons-outlined {
    color: #32364e;
    
}

#appChatDrawer > div.p-sidebar-header > div > span.material-icons-outlined {
    cursor: pointer;
}
.chat-drawer .icon-container .material-icons-outlined:hover {
    cursor: pointer;
    color: #33cc99;
}
.chat-drawer .icon-container .p-sidebar-close {
    color: #32364e !important;
    display: inline-flex;
}
.chat-drawer .icon-container .p-sidebar-close:hover {
    color: #33cc99 !important;
}
.chat-drawer .icon-container .p-sidebar-close:focus {
    box-shadow: none !important;
}


    #appChatDrawer > div.p-sidebar-header > button.p-sidebar-close {
        color: #32364e !important;
        box-shadow: none !important;


    }

    #appChatDrawer > div.p-sidebar-header > button.p-sidebar-close:hover {
        color: #33cc99 !important;
        box-shadow: none !important;
        background: none;

    }
    #appChatDrawer > div.p-sidebar-header > button.p-sidebar-close:focus {
        box-shadow: none !important;

    }

    #appChatDrawer > div.p-sidebar-header > button.p-sidebar-close > span {
        font-weight: bold;
        font-size: 16px;
    }

.chat-drawer .p-sidebar-header .chat-drawer-header-text {
    font-weight: bold;
    font-size: 24px;
    color: #32364e;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.chat-drawer .p-sidebar-content {
    overflow: hidden;
    height: 100%;
    width: 100%;
}

.chat-drawer .p-sidebar-content .p-tabview .p-tabview-panels {
    border: none;
    color: none;
    padding: 0px;
}
.chat-drawer .p-sidebar-content .p-tabview .p-tabview-nav li {
    width: 50%;
}
.chat-drawer .p-sidebar-content .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    border-color: #BFBFBF;
    border: solid #BFBFBF;
    border-width: 0 0 1px 0;
    color: #32364e;
    padding: 16px;
    border-radius: 0px;
    justify-content: center;
    margin: 0 0 -1px 0;
}
.chat-drawer .p-sidebar-content .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,
.chat-drawer .p-sidebar-content .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: #32364e;
    color: #FFFFFF;
    border-color: #32364e;
}
.chat-drawer .p-sidebar-content .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
}

.refresh-channels-icon {
    margin-right: 5px;
}

@media (max-width: 760px) {
    .chat-drawer {
        width: 100% !important;
    }
}
#dm-channel-list-panel > div > div.p-panel-content{
    padding-top: 0;
    height: 100%;
}

#dm-channel-list-panel > div.p-panel-header {
    display:none;
}
</style>
<style scoped>
*,:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}


</style>

