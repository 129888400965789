import INVRS from '../../main.js';

export default class PortfolioOverviewTour {
    tour = INVRS.app.config.globalProperties.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'invrs-portfolio-tour',
            cancelIcon: {
                'enabled': true
            },
            // scrollTo: {
            //     behavior: 'smooth',
            //     block: 'start'
            // },
        }
    });

    portfoliosOverviewSteps = [
        {
            title: "Portfolios",
            text: "The Portfolios section is where you manage and work with your investment accounts. You can create a Paper account.",
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                }
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Accounts',
            attachTo: { element: '.pill-menu-bar div.optional-buttons-container .account-selector', on: 'left' },
            canClickOnTarget: false,
            text: "We are currently looking at your overall Portfolio. This is an aggregate view of all trading accounts held by you.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Performance chart',
            attachTo: { element: 'div#portfolioOverviewContainer.tab-div div.tab-content div.card.performance-card', on: 'top' },
            canClickOnTarget: false,
            text: "This is a performance chart that shows portfolios returns over the past month.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Allocation chart',
            attachTo: { element: 'div#portfolioOverviewContainer.tab-div div.tab-content div.card.allocation-card', on: 'left' },
            canClickOnTarget: false,
            text: "This is the Allocation chart that shows an overview of your holdings breakdown by weight, sector and asset class.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Holdings table',
            attachTo: { element: 'div#portfolioOverviewContainer.tab-div div.tab-content div.card.overview-table', on: 'top' },
            canClickOnTarget: false,
            text: "This is the Details table which is currently showing all holdings across your investment accounts.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Trading/Performance',
            attachTo: { element: 'div#portfolioOverviewContainer.tab-div div.tab-content div.card.overview-table div.table-header-container div.holdings-view-toggle', on: 'right' },
            canClickOnTarget: false,
            text: "This table has a trading view that shows the current market view for all the holdings and a performance view that shows performance details for you holdings.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Holdings/Transactions',
            attachTo: { element: 'div#portfolioOverviewContainer.tab-div div.tab-content div.card.overview-table div.table-header-container div.overview-table-toggle', on: 'left' },
            canClickOnTarget: false,
            text: "You can also view your account transactions and for Paper Trading accounts your trade orders.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        // {
        //     modalOverlayOpeningRadius: 20,
        //     modalOverlayOpeningPadding: 3,
        //     title: 'Cashflow',
        //     attachTo: { element: 'div.layout-content-wrapper div.layout-content div.pill-menu-bar.main-header div.navigation button:nth-child(2)', on: 'right' },
        //     text: `Let's take a look at Cashflow.
        //         \nClick Cashflow.`,
        //     advanceOn: {
        //         selector: 'div.layout-content-wrapper div.layout-content div.pill-menu-bar.main-header div.navigation button:nth-child(2)',
        //         event: 'click'
        //     },
        // },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Cashflow',
             attachTo: { element: 'div.layout-content-wrapper div.layout-content div.pill-menu-bar.main-header div.navigation button:nth-child(2)', on: 'right' },
        
            canClickOnTarget: false,
            text: "Cashflow is a premium feature that forecasts income from your portfolio.\nUse this to see projected cashflow from dividends & distributions, ranked with our proprietary safety scores.",
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        // {
        //     modalOverlayOpeningRadius: 20,
        //     modalOverlayOpeningPadding: 3,
        //     title: 'Top Performers',
        //     attachTo: { element: 'div.layout-content-wrapper div.layout-content div.pill-menu-bar.main-header div.navigation button:nth-child(3)', on: 'right' },
        //     canClickOnTarget: false,
        //     text: "This highlights 20 users who’s portfolios had the highest daily average return over the past month.",
        //     buttons: [
        //         {
        //             classes: 'red-button',
        //             text: 'Back',
        //             action: this.tour.back
        //         },
        //         {
        //             classes: 'green-button',
        //             text: 'Next',
        //             action: this.tour.next
        //         },
        //     ]
        // },
    ];

    creatingPaperTradingAccountSteps = [
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Add Account',
            attachTo: { element: '.add-account-button', on: 'left' },
            text: `This is where you can create Paper Trading and Manual accounts.
                \nLet's create a Paper Trading Account.
                \nClick Add Account.`,
            classes: 'add-account-button',
            showOn: () => {
                return !document.querySelector('.add-account-options-modal');
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'start'
            },
            advanceOn: {
                selector: '.add-account-button',
                event: 'click'
            },
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Add Account',
            attachTo: { element: '.add-account-options-modal', on: 'right' },
            text: `This dialog allows you to choose which type of account you are going to add.`,
            canClickOnTarget: false,
            classes: 'green-button__secondary',
            scrollTo: {
                behavior: 'smooth',
                block: 'start'
            },
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Manual',
            attachTo: { element: '.add-account-options-modal .buttons-container > div:nth-child(3) > button:nth-child(1)', on: 'right' },
            canClickOnTarget: false,
            text: `Manual accounts are an alternative to connecting your brokerage. You can replicate your portfolio by entering your holdings manually.`,
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Paper Trading',
            attachTo: { element: '.add-account-options-modal .buttons-container > div:nth-child(1) > button:nth-child(1)', on: 'right' },
            text: `Paper Trading accounts allow you to place buy/sell orders for stocks using "Playdough".  The accounts are designed to mimick as much as possible real trading accounts.
                \nClick on Paper Trading.`,
            advanceOn: {
                selector: '.add-account-options-modal .buttons-container > div:nth-child(1) > button:nth-child(1)',
                event: 'click'
            },
        },
        {
            modalOverlayOpeningRadius: 5,
            modalOverlayOpeningPadding: 3,
            title: 'Account name',
            attachTo: { element: 'div.add-paper-account-modal div.p-dialog-content input.account-name-input', on: 'right' },
            text: "Enter a name for the account and click next.",
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 5,
            modalOverlayOpeningPadding: 3,
            title: 'Currency',
            attachTo: { element: 'div.add-paper-account-modal div.p-dialog-content div.currency-type-dropdown', on: 'right' },
            text: `All accounts support a single currency, we currently support USD and CAD.\nRegardless of the account currency you can still trade on both US and Canadian exchanges, however foreign exchange fees will be applied (in Playdough).

                \nClick a Currency Dropdown`,
            beforeShowPromise: () => {
                return new Promise((resolve) => {
                    const accountName = document.querySelector('input.account-name-input').value;
                    if (accountName) {
                        resolve();
                    } else {
                        this.tour.back();
                    }
                });
            },
            showOn: () => {
                return document.querySelector('.add-paper-account-modal');
            },
            advanceOn: {
                selector: 'div.add-paper-account-modal div.p-dialog-content div.currency-type-dropdown',
                event: 'click'
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 5,
            title: 'Select a currency',
            attachTo: { element: 'div.p-dropdown-panel div.p-dropdown-items-wrapper ul.p-dropdown-items', on: 'right' },
            text: `Select a currency`,
            showOn: () => {
                return document.querySelector('.add-paper-account-modal');
            },
            advanceOn: {
                selector: 'div.p-dropdown-panel div.p-dropdown-items-wrapper ul.p-dropdown-items',
                event: 'click'
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Create Paper Account',
            attachTo: { element: 'div.add-paper-account-modal div.p-dialog-footer button.add-account-button', on: 'bottom' },
            text: `Click "Create"`,
            showOn: () => {
                return document.querySelector('.add-paper-account-modal');
            },
            advanceOn: {
                selector: 'div.add-paper-account-modal div.p-dialog-footer button.add-account-button',
                event: 'click'
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Summary',
            attachTo: { element: 'div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card', on: 'top' },
            canClickOnTarget: false,
            text: `Congratulations! You created your first paper trading account.
                \nThis section shows the details about your account.`,
            beforeShowPromise: () => {
                // checks the page every 200ms until finds an element. max time 6s.
                return new Promise((resolve) => {
                    const maxAttempts = 30;
                    let attempts = 0;

                    const interval = window.setInterval(() => {
                        const el = document.querySelector('div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card div.grid-header button');
                        attempts++;
                        if (el || attempts > maxAttempts) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 200);
                });
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'start'
            },
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
    ];

    paperTradingAccountOverview = [
        {
            modalOverlayOpeningRadius: 5,
            title: 'Account Balances',
            attachTo: { element: 'div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card div.p-grid div.right-col table', on: 'left' },
            canClickOnTarget: false,
            text: `Your first US/CAD account is prefunded with $1,000 of Playdough.\nAs you make gains through trading your cash balance will grow.\nAny holdings with dividend payments will also be credited to your account.\nYou can also purchase more Playdough as needed.\nNote that if you dip into your margin or borrow stock (short selling) a daily fee will be applied (in Playdough).`,
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Action',
            attachTo: { element: '.pill-menu-bar div.optional-buttons-container .account-selector', on: 'left' },
            text: `Let's take a look at your Cash Accounts.
                \nClick "Accounts" dropdown.`,
            advanceOn: {
                selector: '.pill-menu-bar div.optional-buttons-container .account-selector',
                event: 'click'
            },
            scrollTo: {
                behavior: 'smooth',
                block: 'start'
            },
        },
        {
            modalOverlayOpeningRadius: 5,
            title: 'Action',
            attachTo: { element: 'div.p-dropdown-panel div.p-dropdown-items-wrapper ul.p-dropdown-items', on: 'left' },
            text: `Select "Cash Accounts (CAD/USD)".`,
            advanceOn: {
                selector: 'div.p-dropdown-panel div.p-dropdown-items-wrapper ul.p-dropdown-items',
                event: 'click'
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
            ]
        },
    ];

    cashAccountsOverview = [
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Account Balances',
            attachTo: { element: 'div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card', on: 'bottom' },
            canClickOnTarget: false,
            text: `These are your USD and CAD cash accounts.  These accounts are how you fund your Paper Trading accounts.\nThey are prefunded with $1,000 in Playdough.`,
            beforeShowPromise: () => {
                // checks the page every 200ms until finds an element. max time 6s.
                return new Promise((resolve) => {
                    const maxAttempts = 30;
                    let attempts = 0;

                    const interval = window.setInterval(() => {
                        const el = document.querySelector('div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card div.p-grid table tr td.right-col button');
                        attempts++;
                        if (el || attempts > maxAttempts) {
                            clearInterval(interval);
                            resolve();
                        }
                    }, 200);
                });
            },
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Buy More',
            attachTo: { element: 'div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card div.p-grid table tr:nth-child(2) td.right-col button:nth-child(2)', on: 'bottom' },
            canClickOnTarget: false,
            text: `You can purchase more Playdough at any time by clicking this button.\nWhen you purchase Playdough it is credited to these accounts.`,
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Transfer Funds',
            attachTo: { element: 'div.layout-content-wrapper div.layout-content div.tab-div div.tab-content div.card.selected-account-card div.p-grid table tr:nth-child(2) td.right-col button:nth-child(1)', on: 'bottom' },
            canClickOnTarget: false,
            text: `Once the cash account is funded you can then transfer the funds to any of your Paper Trading accounts.`,
            buttons: [
                {
                    classes: 'red-button',
                    text: 'Back',
                    action: this.tour.back
                },
                {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next
                },
            ]
        },
        {
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 3,
            title: 'Buy/sell',
            attachTo: { element: 'div.user-bar-container ul.topbar-menu li.profile-item.paper-accounts-trading-item button', on: 'top' },
            text: `Congratulations you have completed the Portfolio Overview Tour!\nClick the Buy/sell button to start trading.
                \nGood luck and have fun!`,
            advanceOn: {
                selector: 'div.user-bar-container ul.topbar-menu li.profile-item.paper-accounts-trading-item button',
                event: 'click'
            },
            buttons: [
                {
                    classes: 'green-button',
                    text: 'Done',
                    action: this.tour.complete
                },
            ]
        },
    ];

    steps = [
        ...this.portfoliosOverviewSteps,
        ...this.creatingPaperTradingAccountSteps,
        ...this.paperTradingAccountOverview,
        ...this.cashAccountsOverview,
    ];
}