export default class AudioUtils {
    constructor() {
        this.audio = null;
    }

    loadAudio(file) {
        this.audio = new Audio(file);
    }

    playAudio() {
        if (this.audio) {
            this.audio.play();
        }
    }

    setCurrentTime(seconds) {
        if (this.audio) {
            this.audio.currentTime = seconds;
        }
    }

}
