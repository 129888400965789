<template> 
    <Dialog :header="header" v-model:visible="display" :modal="true" :closable="true" class='contact-support-modal' @hide="close">
        <div style="margin-bottom:20px;">
        <span class='body-text'>{{topText}}</span>
        </div>
        <div>
            <span >
                <label for="report-type" class='input-label'>Problem Category:</label>
                <Dropdown id="report-type" class="w-full md:w-14rem" :options="reportCategories" optionLabel="name" v-model="reportCategory" placeholder="Select Category"/>  
                
            </span>
            <span class='p-float-label'>
                <Textarea id="ticket-description" v-model="ticketDescription" :autoResize="false" rows="10" cols="100" class="description-input" maxlength="500"/>
                <label for="ticket-description" class='input-label'>Please enter any details</label>
            </span>
        </div>
         <template #footer>
            <div class='button-container'>
                <Button label="Cancel" class='cancel p-button-raised p-button-rounded' @click='close'/>
                <Button label="Submit" class='accept p-button-raised p-button-rounded' @click='submitReport' :loading="sending"/>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import UserService from '../../service/UserService';

export default {
    name: 'SubmitReportModal',
	data() {
		return {
			display: false,
            report: null,
            reportCategory: null,
            ticketDescription: null,
            sending: false,
		}
	},
	components: {
		Dialog, Dropdown,Textarea
	},

    computed: {
        header() {
            if( this.report && this.report.type == 'Post'){
                return "Report a Post";
            }
            else {
                return "Report a User";
            }

        },

        topText() {
            if( this.report && this.report.type == 'Post'){
                return "If you feel a post is misleading or otherise inappropriate, please submit details below and we'll review the post as soon as possible.";
            }
            else {
                return "If you feel a user is making misleading posts or behaving in other inappropriate ways, please submit details below and we'll review their account activity as soon as possible.";
            }
        },
        reportCategories() {
            if( this.report ) {
                if( this.report.type == 'Post') { //PossibleScam, OffensiveOrInappropriate, OffTopic, PossibleScammer, Other
                    return [ 
                        {name:'Possible Scam', code:'PossibleScam' },
                        {name:'Offensive Or Inappropriate', code:'OffensiveOrInappropriate' },
                        {name:'Off Topic', code:'OffTopic' },
                        {name:'Other', code:'Other' },
                        ];
                }
                else {
                    return [
                         {name:'Possible Scammer', code:'PossibleScammer' },
                          {name:'Offensive Or Inappropriate', code:'OffensiveOrInappropriate' },
                        {name:'Other', code:'Other' },
                    ];
                }
            }
            else {
                return [];
            }
        }
    },

	methods: {
        open(report) {
            
            this.display = true;
            this.report = report;
            this.reportCategory = null;
            this.ticketDescription = null;
            this.sending = false;
        },

		close() {
			this.display = false;
             this.report = null;
              this.reportCategory = null;
            this.ticketDescription = null;
            this.sending = false;
		},

       
        submitReport() {
            this.sending = true;
            this.$toast.removeAllGroups()
            if( this.reportCategory == null || this.reportCategory.code.trim() == '' ){
                this.$toast.add({ severity: 'error', summary: 'Please select a category.', life: 5000, group: 'center' });
                this.sending = false;  
                
            }
           
            else {
                this.report['category'] = this.reportCategory.code;
                this.report['details'] = (this.ticketDescription ? this.ticketDescription : '');
               
                UserService.submitReport(this.report).then(resp => {
                    if( resp.data.status == "success") {
                        this.$toast.add({ severity: 'success', summary: ' Thank you, your report has been submitted and will be reviewed as soon as possible. ', life: 10000, group: 'center' });
                        this.close();    
                    }
                    else {
                       console.error("Error sending report "+resp.data.status+" "+ resp.data.message);
                        this.sending = false;
                    }
                }).catch(err => {
                    console.error("Error sending ticket "+err);
                    this.sending = false;
                });
            }
           
                
        },
       
        
	}
}
</script>

<style>
.p-dialog-mask.p-component-overlay {
	background-color: rgba(50,54,78, 0.7);
}

.contact-support-modal {
    font-family: "Trebuchet MS", "Verdana";
    width: 40rem;
	box-shadow: none;
}

.contact-support-modal .p-dialog-header {
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
    color: #32364E;
}

.contact-support-modal .p-dialog-header .p-dialog-title {
	font-size: 25px;
    font-weight: bold;
    color: #32364E;
}

.contact-support-modal .p-dialog-content {
	border-top: 1px solid #F2F2F2;
	border-bottom: 1px solid #F2F2F2;
	padding: 10px 25px;
    overflow-y: visible;
}
.contact-support-modal .body-text {
    font-size: 16px;
    color: #a6a6a6;
}

.contact-support-modal .p-dialog-footer {
	padding-top: 1.5rem;
	border-bottom-left-radius: 30px;
	border-bottom-right-radius: 30px;
}

.contact-support-modal .button-container {
    display: flex;
    justify-content: center;
}
</style>
<style scoped>
.contact-support-modal .subject-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.contact-support-modal .subject-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.contact-support-modal .description-input {
    width: 100%;
    font-size: 20px;
    border-radius: 15px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    padding-left: 20px;
}
.contact-support-modal .description-input:enabled:focus {
    box-shadow: none;
    border-color: #33CC99;
}
.p-float-label {
    margin: 15px 0px 25px;
}
.input-label {
    font-size: 20px;
    padding-left: 20px;
    line-height: 0.8;
}

.contact-support-modal .p-dialog-footer .p-button {
    font-size: 16px;
	background: #FFFFFF;
	font-family: "Trebuchet MS", "Verdana";
	padding: 0.75rem 2.75rem;
	color: #32364E;
	border: 2px solid
 }

 .contact-support-modal .p-dialog-footer .cancel {
	border-color: #32364E;
	margin-right: 10px;
 }

 .contact-support-modal .p-dialog-footer .accept {
    border-color: #33CC99;
    padding: 10px 30px;
 }

.contact-support-modal .p-dialog-footer .accept:hover {
	color: #FFFFFF;
	background: #33CC99;
 }
  .contact-support-modal .p-dialog-footer .accept:focus {
     box-shadow: none;
     border-color: #33CC99;
 }

.contact-support-modal .p-dialog-footer .cancel:hover {
	color: #FFFFFF;
	background: #32364E;
 } 
 .contact-support-modal .p-dialog-footer .cancel:focus {
     box-shadow: none;
     border-color: #32364E;
 }
</style>