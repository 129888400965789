<template>
    <template>
        <Dialog id="createMotionModal" header='Motion to Invest' v-model:visible="display" :modal="true" :draggable="false" :class="`create-motion-modal-${step}`" @hide="onHide">
            <div class="step-header">{{stepHeader}}</div>
            <template v-if="step === 0">
                <div class="p-float-label user-groups-dropdown-container" v-if="userMotionsPage && userGroups">
                    <Dropdown class="collab-portfolio-dropdown dropdown" id="userGroupsDropdown" v-model="userGroup" optionLabel="name" :options="userGroups" panelClass="selection-dropdown-menu" />
                    <label for="userGroupsDropdown" class='input-label'>Group</label>
                </div>
                <div class="p-float-label  portfolio-account-dropdown-container">
                    <Dropdown class="collab-portfolio-dropdown dropdown" id="portfolioAccountDropdown" v-model="selectedPortfolio" optionLabel="labelName" :options="collabPortfolios" panelClass="selection-dropdown-menu" />
                    <label for="portfolioAccountDropdown" class='input-label'>Portfolio Account</label>
                </div>

                <div class='order-section' v-if='selectedAccount'>
                    <div class='header-content'>
                        <template v-if="selectedSecurity?.symbol && position">
                            <div class="position-summary" v-if="!isMobile">
                                <span>Current Position: 
                                <span :class="accountPositionClass">{{position}}</span></span> 
                                <template v-if="position !== 'None'">
                                    <span>Quantity: <b>{{ positionQuantity }}</b></span>  
                                    <span>Avg Cost Per Unit: <b>{{ positionAvgCostPerUnit  }}</b></span>
                                </template>
                            </div>
                            <template v-else>
                                <span>Current Position: 
                                <span :class="accountPositionClass">{{position }}</span></span> 
                                <template v-if="position !== 'None'">
                                    <span>Quantity: <b>{{ positionQuantity }}</b></span>  
                                    <span>Avg Cost Per Unit: <b>{{ positionAvgCostPerUnit }}</b></span>
                                </template>
                            </template>
                        </template>
                        <table>
                            <tr>
                                <td class='left-col'>Currency:</td>
                                <td class='right-col'>{{currency}}</td>
                            </tr>
                            <tr v-if="selectedPortfolio?.collaborationSettings?.tradeCriteria !== undefined">
                                <td class='left-col'>Trade Criteria:</td>
                                <td class='right-col'><span class="trade-criteria-text">{{ selectedPortfolio?.collaborationSettings?.tradeCriteria }}</span></td>
                            </tr>     
                        </table>
                    </div>

                    <div class='form-section'>
                        <!-- Security search bar will only appear if we're creating an order from the actual orders page from Portfolios -->
                        <div :class="`input-section-${step}`">
                            <span class="p-float-label p-input-icon-left security-search-bar-container" v-click-away="onSecuritySearchClickAway">
                                <AutoComplete id="securitySearchBarForTrades" ref='securitySearchBar' placeholder="Select a security" v-model="selectedSecurity" 
                                    :suggestions="securityList" @complete="searchSecurities($event)" field="symbol" optionGroupLabel="label" optionGroupChildren="items"
                                    panelClass="security-search-trade-order-panel" @item-select="onSecuritySelected($event)" >
                                    <template #option="slotProps">

                                        <span class='symbol'>{{slotProps.option.symbol.replace("/", ".")}}</span><br>
                                        <small>
                                            {{(slotProps.option.company ? slotProps.option.company : slotProps.option.name)}}
                                            {{ (slotProps.option.exchangeSymbol ? " - "+securityFormatter.getFormattedExchange(slotProps.option.exchangeSymbol): "")}}
                                        </small>
                                    </template>
                                </AutoComplete>
                                <i class="pi pi-search" />
                                <label for="securitySearchBarForTrades" class='input-label'>Security</label>
                            </span>

                            <div class='quote-data-section' v-if='selectedSecurity'>
                                <QuoteFeedTable :askPrice="askPrice" :askSize="askSize" :bidPrice="bidPrice" :bidSize="bidSize"
                                :highValue="highValue" :lowValue="lowValue" :openValue="openValue" :volume="volume" />
                            </div>
                        </div>



                        <div :class="`input-section-${step}`">
                            <div class="p-float-label">
                                <Dropdown id="orderTypeDropdown" class="order-type-dropdown dropdown" v-model="orderTypeOption" optionLabel="name" :options="orderTypeOptions" 
                                optionDisabled="disabled" panelClass='order-type-dropdown-panel' @change="orderTypeSelected">
                                    <template #option="slotProps">
                                        <div class="order-type-item-row">
                                            <span>{{slotProps.option.name}}</span>
                                            <Button icon='pi pi-info' class='order-type-info-button blue-button' v-if='slotProps.option.description !== undefined' v-tooltip.right="{value: slotProps.option.description}"/>
                                        </div>
                                    </template>
                                </Dropdown>
                                <label for="orderTypeDropdown" class='input-label'>Order Type</label>
                            </div>
                            <div class='selected-order-type-description' v-if="orderTypeOption">{{orderTypeOption.description}}</div>
                        </div>
                        
                        <template v-if='isNonMarketOrderType(orderTypeOption?.name)'>
                            <div :class="`input-section-${step}`" v-if="STOP_ORDER_TYPES.includes(orderTypeOption?.name)">
                                <span class="p-float-label p-input-icon-right">
                                    <i class="pi pi-dollar" />
                                    <InputNumber id="stopPriceInput" class='price-input' v-model="stopPrice" mode="decimal" :minFractionDigits="2" :min="0"/>
                                    <label for="stopPriceInput" class='input-label'>Stop Price</label>
                                </span>
                            </div>
                            <div :class="`input-section-${step}`" v-if="LIMIT_ORDER_TYPES.includes(orderTypeOption?.name)">
                                <span class="p-float-label p-input-icon-right">
                                    <i class="pi pi-dollar" />
                                    <InputNumber id="limitPriceInput" class='price-input' v-model="limitPrice" mode="decimal" :minFractionDigits="2" :min="0"/>
                                    <label for="limitPriceInput" class='input-label'>Limit Price</label>
                                </span>
                            </div>
                        </template>

                        <div :class="`input-section-${step}`">
                            <div class="p-float-label">
                                <InputNumber id='quantityInput' class='quantity-input' v-model="quantity" :min="0"/>
                                <label for="quantityInput" class='input-label'>Quantity</label>
                            </div>
                        </div>  
                        
                        <div :class="`input-section-${step}`">
                            <div class="p-float-label">
                                <Dropdown id='durationTypeDropdown' :class="durationTypeDropdownClass" v-model="durationTypeOption" :options="durationTypeOptions" 
                                optionLabel="name" panelClass="duration-type-dropdown-panel" :disabled="durationTypeDropdownDisabled" :placeholder="durationTypeDropdownPlaceholder"/>
                                <label for="durationTypeDropdown" class='input-label'>Duration</label>
                            </div>
                        </div>  

                        <!-- <div class="popup-checkbox-container">
                            <Checkbox v-model="includeStopLoss" inputId="popup-checkbox" :binary="true" inputClass="green-button__primary"/>
                            <label for="popup-checkbox" class="popup-checkbox-label">Include Stop Loss</label>
                        </div>

                        <template v-if="includeStopLoss">
                            <div :class="`input-section-${step}`">
                                <span class="p-float-label p-input-icon-right">
                                    <i class="pi pi-dollar" />
                                    <InputNumber id="stopPriceInputForStopLoss" class='price-input' v-model="stopPriceForStopLoss" mode="decimal" :minFractionDigits="2" :min="0"/>
                                    <label for="stopPriceInputForStopLoss" class='input-label'>Stop Price</label>
                                </span>
                            </div>
                            
                            <div :class="`input-section-${step}`">
                                <div class="p-float-label">
                                    <InputNumber id='quantityInputForStopLoss' class='quantity-input' v-model="quantityForStopLoss" :min="0" />
                                    <label for="quantityInputForStopLoss" class='input-label'>Quantity</label>
                                </div>
                            </div>  

                            <div :class="`input-section-${step}`">
                                <div class="p-float-label">
                                    <Dropdown id='durationTypeDropdownForStopLoss' :class="durationTypeDropdownClass" v-model="durationTypeOptionForStopLoss" :options="durationTypeOptionsForStopLoss" 
                                    optionLabel="name" panelClass="duration-type-dropdown-panel" :placeholder="durationTypeDropdownPlaceholder"/>
                                    <label for="durationTypeDropdownForStopLoss" class='input-label'>Duration</label>
                                </div>
                            </div>  
                        </template> -->

                    </div>
                </div>
            </template>
            <!-- STEP 2 CONTENT-->
            <div class="enter-motion-details-section" v-else-if="step === 1">
                <div class="summary-help-text">
                    <span class="sub-header">Summary:</span> Provide 3 points that summarize your rationale for <b>{{ orderTypeOption.tradeType === BUY_TRADE_TYPE['ID'] ? 'Buying' : 'Selling' }} {{ selectedSecurity.symbol }}</b>.
                </div>
                <div class="summary-point-section">
                    <div class="input-section">
                        <div class="input-label">Point 1 <span class='required'>*</span></div>
                        <div class="summary-point-container">
                            <InputText type="text" id='summaryPoint1' v-model="v$.summaryPoint1.$model" :class="{'summary-point-input': true, 'input': true, 'input-error': v$.summaryPoint1.$error}"/>
                            <span class='character-counter' :style="{'color': v$.summaryPoint1.$error ? '#E63E3E': null}">{{ (summaryPoint1 ? summaryPoint1.length:0)}} / {{pointCharLimit}} </span>
                        </div>
                    </div>
                    <div class="input-section">
                        <div class="input-label">Point 2 <span class='required'>*</span></div>
                        <div class="summary-point-container">
                            <InputText type="text" id="summaryPoint2" v-model="v$.summaryPoint2.$model" :class="{'summary-point-input': true, 'input': true, 'input-error': v$.summaryPoint2.$error}"/>
                            <span class='character-counter' :style="{'color': v$.summaryPoint2.$error ? '#E63E3E': null}">{{ (summaryPoint2 ? summaryPoint2.length:0)}} / {{pointCharLimit}} </span>
                        </div>
                    </div>
                    <div class="input-section">
                        <div class="input-label">Point 3 <span class='required'>*</span></div>
                        <div class="summary-point-container">
                            <InputText type="text" id="summaryPoint3" v-model="v$.summaryPoint3.$model" :class="{'summary-point-input': true, 'input': true, 'input-error': v$.summaryPoint3.$error}"/>
                            <span class='character-counter' :style="{'color': v$.summaryPoint3.$error ? '#E63E3E': null}">{{ (summaryPoint3 ? summaryPoint3.length:0)}} / {{pointCharLimit}} </span>
                        </div>
                    </div>
                    <!-- <div class="input-section">
                        <div class="input-label">Point 4</div>
                        <div class="summary-point-container">
                            <InputText type="text" id="summaryPoint4" v-model="v$.summaryPoint4.$model" class="summary-point-input input" :disabled="![summaryPoint1, summaryPoint2, summaryPoint3].every(point => point)"/>
                            <span class='character-counter' :style="{'color': v$.summaryPoint4.$error ? '#E63E3E': null}">{{ (summaryPoint4 ? summaryPoint4.length:0)}} / {{pointCharLimit}} </span>
                        </div>
                    </div>
                    <div class="input-section">
                        <div class="input-label">Point 5</div>
                        <div class="summary-point-container">
                            <InputText type="text" id="summaryPoint5" v-model="v$.summaryPoint5.$model" class="summary-point-input input" :disabled="![summaryPoint1, summaryPoint2, summaryPoint3].every(point => point)"/>
                            <span class='character-counter' :style="{'color': v$.summaryPoint5.$error ? '#E63E3E': null}">{{ (summaryPoint5 ? summaryPoint5.length:0)}} / {{pointCharLimit}} </span>
                        </div>
                    </div> -->

                </div>
                
                <div class="input-section">
                    <div class="rationale-help-text"><span class="sub-header">Detailed Rationale:</span> Provide further details behind your rationale (optional).</div>
                    
                    <vue-editor id="motionEditor" ref="motionEditor" v-model="rationale" :editorOptions="createMotionEditorSettings('#createMotionModal')"  
                                :customModules="editorModules" 
                                :placeholder="rationalePlaceholder"
                                />
                    
                </div>

            </div>
            <!-- STEP 3 CONTENT -->
            <div class="review-motion-section" v-else-if="step === 2">
                <div class="trade-details-section">
                    <span class="trade-details-header">Trade Details</span>
                        <div class="trade-details-content">
                        <p>
                            Place <b v-if="MARKET_ORDER_TYPES.includes(orderTypeOption.name)">{{ isBuyingType ? 'Buy' : 'Sell'}}</b>
                            <b v-else>{{orderTypeOption.name}}</b> order for <b>{{ quantity }}</b> units of <b>{{ selectedSecurity.symbol }}</b> <span v-html="mainOrderText"></span><br>
                            <b>Group: {{groupName}}</b><br>
                            <b>Portfolio: {{selectedPortfolio.name}}</b><br>
                            <b>Account: {{selectedAccount.accountName}}</b>
                        </p>
                    </div>
                </div>

                <div class="motion-details-section">
                    <div class="motion-details-header">Motion Details</div>
                    <div class="voting-deadline-section">
                        <span class="sub-header">Voting Deadline:</span> 
                        {{ selectedPortfolio?.collaborationSettings?.defaultDeadline + (selectedPortfolio?.collaborationSettings?.defaultDeadline == 1 ? ' hour' : ' hours')}} ( <b>{{ newDeadlineTime }}</b> Approx.)
                    </div>

                    <div class="summary-section">
                        <span class="sub-header">Summary:</span>
                        <ul>
                            <template v-for="point of [summaryPoint1, summaryPoint2, summaryPoint3, summaryPoint4, summaryPoint5]">
                                <li v-if="point" :key="point">
                                    {{point}}
                                </li>
                            </template>
                        </ul>
                    </div>

                    <div class="rationale-section" v-if="rationale">
                        <span class="sub-header">Detailed Rationale:</span>
                        <div v-if="rationale" v-html="rationale"></div>
                    </div>
                </div>
            </div>

            <template #footer>
                <template v-if="!isMobile || (isMobile && step < 2)">
                    <Button label="Cancel" class="cancel-button red-button__primary" @click="onClose()" />
                    <Button label="Previous" class="previous-button blue-button__primary" @click="step--" v-if="step !== 0"/>
                </template>
                <Button label="Next" class='next-button green-button__primary' @click="nextStep()" v-if="step !== 2" :disabled="disableActionButton"/>

                <div class='buttons-container' v-if="step === 2">
                    <div class='buttons-column' v-if="isMobile">
                        <div class="buttons-row">
                            <Button label="Cancel" class="cancel-button red-button__primary" @click="onClose()" />
                            <Button label="Previous" class="previous-button blue-button__primary" @click="step--" v-if="step !== 0"/>
                        
                            <!-- <Button label="Save as Draft" class="save-draft-button blue-button" @click="saveAsDraft()" :loading="savingAsDraft"/> -->
                            <Button label="Submit" class="submit-button green-button__primary" @click="submit()" :loading="submitting"/>
                        </div>
                    </div>
                    <template v-else>
                        <Button label="Save as Draft" class="save-draft-button blue-button" @click="saveAsDraft()" :loading="savingAsDraft" />
                        <Button label="Submit" class="submit-button green-button__primary" @click="submit()" :loading="submitting"/>
                    </template>
                </div>
            </template>
        </Dialog>
        <ConfirmationModal headerLabel="Confirmation" :bodyText="confirmationBodyText" ref="confirmationModal"/>

    </template>

    
</template>

<script>
import EventBus from '../../event-bus';

import useVuelidate from '@vuelidate/core'
import { maxLength} from '@vuelidate/validators';
// import Checkbox from 'primevue/checkbox';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Dialog from 'primevue/dialog';
import QuoteFeedTable from '../QuoteMedia/QuoteFeedTable.vue';

import { VueEditor } from "vue3-editor";
import { createMotionEditorSettings, motionEditorModules} from "../editor/config.js";

import ConfirmationModal from '../modal/ConfirmationModal.vue';

import {ASSET_TYPES, MARKET_CLOSE_DURATION_ID, UNTIL_CANCELLED_DURATION_ID, FILL_OR_KILL_DURATION_ID, BUY_TRADE_TYPE, SELL_TRADE_TYPE,
MARKET_ORDER_TYPE_ID, LIMIT_ORDER_TYPE_ID, STOP_LOSS_ORDER_TYPE_ID, STOP_ENTRY_ORDER_TYPE_ID, STOP_LIMIT_ORDER_TYPE_ID, US_CURRENCY_TYPE} from '../../common/constants';
import DataService from '../../service/DataService';
import GroupService from '../../service/GroupService';
import MotionService from '../../service/MotionService';
import PortfolioService from '../../service/PortfolioService';
import ResearchService from '../../service/ResearchService';
import SecurityFormatter from '../../common/SecurityFormatter';
import BrowserUtils from '../../utilities/BrowserUtils';
import CurrencyUtils from '../../utilities/CurrencyUtils';
import FormattingUtils from '../../utilities/FormattingUtils';
import NumberUtils from '../../utilities/NumberUtils';
import TimeUtils from '../../utilities/TimeUtils';

const BUY_MARKET_ORDER_NAME = 'Buy Market';
const SELL_MARKET_ORDER_NAME = 'Sell Market';
const BUY_LIMIT_ORDER_NAME = 'Buy Limit';
const SELL_LIMIT_ORDER_NAME = 'Sell Limit';
const BUY_STOP_LOSS_ORDER_NAME = 'Buy Stop Loss';
const SELL_STOP_LOSS_ORDER_NAME = 'Sell Stop Loss';
const BUY_STOP_ENTRY_ORDER_NAME = 'Buy Stop Entry';
const SELL_STOP_ENTRY_ORDER_NAME = 'Sell Stop Entry';
const BUY_STOP_LIMIT_ORDER_NAME = 'Buy Stop Limit';
const SELL_STOP_LIMIT_ORDER_NAME = 'Sell Stop Limit';

const MARKET_ORDER_TYPES = [BUY_MARKET_ORDER_NAME, SELL_MARKET_ORDER_NAME];
const NON_MARKET_ORDER_TYPES = [BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME, BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME,
BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const STOP_ORDER_TYPES = [BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME,
BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const LIMIT_ORDER_TYPES = [BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME, BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];
const STOP_LIMIT_ORDER_TYPES = [BUY_STOP_LIMIT_ORDER_NAME, SELL_STOP_LIMIT_ORDER_NAME];

const TRADE_MOTION_TYPE_ID = 1;

const POLLING_PRICE_INTERVAL = 10000;

const domParser = new DOMParser();

export default {
    setup: () => ({ v$: useVuelidate() }),
    name: 'CreateMotionModal',
    components: {
        // Checkbox,
        Dialog,
        Dropdown,
        InputNumber,
        QuoteFeedTable,
        VueEditor,
        ConfirmationModal
    },

    computed: {
        isMobile() {
            return BrowserUtils.isMobile();
        },

        confirmationBodyText() {
            let baseText = 'You can also view/edit your motion to invest in the Motions section of the app.';

            return this.savedAsDraft ? `Motion to Invest saved as draft!\n\n${baseText}` : `Motion to Invest submitted!\n\n${baseText}`;
        },

        collabPortfolioDropdownPlaceholder() {
            return this.selectedPortfolio ? 'Change Collaboration Portfolio / Account' : 'Select a Collaboration Portfolio / Account';
        },

        accountDropdownPlaceholder() {
            return this.selectedAccount ? 'Change Account' : 'Select an Account';
        },

        stepHeader() {
            const STEP_HEADER_MAP = {
                0: this.editMode ? 'Edit Trade Details' : 'Enter Trade Details',
                1: this.editMode ? 'Edit Motion Details' : 'Enter Motion Details',
                2: this.editMode ? 'Review Edited Motion' :'Review Motion'
            }

            return STEP_HEADER_MAP[this.step];
        },

        accountPositionClass() {
            return {
                'position': true,
                'long': this.position === 'Long',
                'none': this.position === 'None',
                'short': this.position === 'Short'
            }
        },

        durationTypeDropdownClass() {
            return {
                'duration-type-dropdown': true,
                'dropdown': true,
                'market-order-selected': this.marketOrderTypeSelected
            };
        },

        durationTypeDropdownPlaceholder() {
            return !this.marketOrderTypeSelected ? null : 'Fill Or Kill'
        },

        isBuyingType() {
            return this.orderTypeOption?.tradeType === BUY_TRADE_TYPE['ID'];
        },

        mainOrderText() {
            let text = '';

            if (this.step === 2) {
                if (MARKET_ORDER_TYPES.includes(this.orderTypeOption.name)) {
                    text = 'at <b>market price' + this.getLastAskOrBid() + '</b>.';
                } else if ([BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME].includes(this.orderTypeOption.name)) {
                    text = `with a <b>limit price of ${this.formatter.format(this.limitPrice,'$0.00')}</b>.`;
                } else if (STOP_LIMIT_ORDER_TYPES.includes(this.orderTypeOption.name)) {
                    text = `with a <b>limit price of ${this.formatter.format(this.limitPrice,'$0.00')}</b> and <b>stop price of ${this.formatter.format(this.stopPrice,'$0.00')}</b>.`;
                } else if ([SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_ENTRY_ORDER_NAME, BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME].includes(this.orderTypeOption.name)) {
                    text = `with a <b>stop price of ${this.formatter.format(this.stopPrice,'$0.00')}</b>.`;
                }
            }

            return text;
        },

        newDeadlineTime() {
            const hours = this.selectedPortfolio?.collaborationSettings?.defaultDeadline;
            const currentTime = new Date();
            const newTime = TimeUtils.getFormattedTime(TimeUtils.addTime(currentTime, hours, 'hours'), 'YYYY-MM-DD HH:mm');

            return newTime;
        },

        disableActionButton() {
            let value = null;

            if (this.step === 0) {
                const orderName = this.orderTypeOption?.name;

                const baseCondition = !this.selectedSecurity?.symbol || !this.quantity || !this.durationTypeOption || !orderName;
                
                if (!this.selectedPortfolio?.groupPortfolioId) {
                    return true;
                } else if (!orderName) {
                    value = baseCondition;
                } else {
                    if (this.isNonMarketOrderType(orderName)) {
                        if (LIMIT_ORDER_TYPES.includes(orderName)) {
                            const isLimitPriceEmpty = !this.limitPrice;

                            if ([BUY_LIMIT_ORDER_NAME, SELL_LIMIT_ORDER_NAME].includes(orderName)) {
                                value = baseCondition || isLimitPriceEmpty;
                            } else if (STOP_LIMIT_ORDER_TYPES.includes(orderName)) {
                                value = baseCondition || !this.stopPrice || isLimitPriceEmpty;
                            }
                        } else if ([BUY_STOP_ENTRY_ORDER_NAME, SELL_STOP_ENTRY_ORDER_NAME, BUY_STOP_LOSS_ORDER_NAME, SELL_STOP_LOSS_ORDER_NAME].includes(orderName)) {
                            value = baseCondition || !this.stopPrice;
                        }
                    } else {
                        value = baseCondition;
                    }
                }
            } else if (this.step === 1) {
                value = [this.summaryPoint1, this.summaryPoint2, this.summaryPoint3].some(point => !point) || [this.v$.summaryPoint1, this.v$.summaryPoint2, this.v$.summaryPoint3].some(point => point.$error);
            }

            return value;
        },
    },
    watch: {
        selectedPortfolio(portfolio) {
           

            //this.resetOrderSubmission();

            if (portfolio) {
                this.selectedAccount = this.selectedPortfolio.account;
                this.currency = CurrencyUtils.getCurrencyNameViaId(portfolio.account.currencyId);

                if (portfolio.collaborationSettings.motionHints !== undefined) {
                    //let configObj = {...this.editorConfig}

                    //configObj['placeholder'] = portfolio.collaborationSettings.motionHints;

                    this.rationalePlaceholder = portfolio.collaborationSettings.motionHints;// = configObj;
                }
            }
            else {
                 this.selectedAccount = null;
            }
        },

        userGroup(group,oldGroup){
           if(oldGroup && group?.groupId != oldGroup?.groupId){
                //console.log("userGroup", group.groupId, oldGroup?.groupId );
                //this.resetOrderSubmission();
                this.initCollaborativeGroupPortfolios(group.groupId);
           }
        },
    },
    data() {
        return {
            //CONSTANTS
            MARKET_ORDER_TYPES,
            NON_MARKET_ORDER_TYPES,
            STOP_ORDER_TYPES,
            LIMIT_ORDER_TYPES,
            BUY_TRADE_TYPE,
            SELL_TRADE_TYPE,
            BUY_MARKET_ORDER_NAME,
            SELL_MARKET_ORDER_NAME,

            securityFormatter: SecurityFormatter,
            currencyUtils: CurrencyUtils,
            numberUtils: NumberUtils,
            formatter: new FormattingUtils(),

            step: 0,
            display: false,
            editMode: false,
            draftMotion: null,
            userMotionsPage: false,
            userGroups: [],
            userGroup: null,
            displayConfirmation: false,
            submitting: false,
            savingAsDraft: false,
            refetcher: false,
            marketOrderTypeSelected: null,
            savedAsDraft: false,

            groupName: null,
            selectedPortfolio: null,
            collabPortfolios: [],
            selectedAccount: null,
            position: null,
            positionQuantity: null,
            positionAvgCostPerUnit: null,
            currency: null,
            hoursDiff: null,

            securitySearchBarPlaceholder: 'Add a security',
            securityList: [],
            currentSecurity: null,
            selectedSecurity: {
                symbol: "",
                assetTypeId: null,
                assetId: null,
                exchangeSymbol: null,
                companyName: null
            },

            askPrice: null,
            bidPrice: null,
            openValue: null,
            highValue: null,
            lowValue: null,
            volume: null,
            askSize: null,
            bidSize: null,

            actionTypeOption: null,
            actionTypeOptions: ['Buy', 'Sell'],
            actionTypeDropdownPlaceholder: 'Select an Action Type',

            orderTypeOption: null,
            orderTypeOptions: [],
            orderTypeDropdownPlaceholder: 'Select an Order Type',

            stopPrice: null,
            stopPricePlaceholder: 'Enter in value for Stop Price',

            limitPrice: null,
            limitPricePlaceholder: 'Enter in value for Limit Price',

            quantity: null,
            quantityPlaceholder: 'Enter in value for quantity',

            durationTypeOption: null,
            durationTypeOptions: [],
            durationTypeDropdownDisabled: true,

            // includeStopLoss: false,
            // stopPriceForStopLoss: null,
            // quantityForStopLoss: null,
            // durationForStopLoss: null,

            pointCharLimit: 100,
            summaryPoint1: null,
            summaryPoint2: null,
            summaryPoint3: null,
            summaryPoint4: null,
            summaryPoint5: null,
            rationale: null,
            
            createMotionEditorSettings: createMotionEditorSettings,
            editorModules: motionEditorModules,
            
            rationalePlaceholder: "",
        }
    },


    methods: {
       
        getLastAskOrBid(){
            if(this.askPrice || this.bidPrice){
                return this.orderTypeOption.tradeType === BUY_TRADE_TYPE['ID']
                    ? ` (last ask ${this.formatter.format(this.askPrice,'$0.00')})` 
                    : ` (last bid ${this.formatter.format(this.bidPrice,'$0.00')})`;
            } else {
                return '';
            }
        },

        //IN-COMPONENT METHODS

        nextStep() {
            if (this.step === 0) {
                this.step++;
            } else if (this.step === 1) {
                if (![this.v$.summaryPoint1, this.v$.summaryPoint2, this.v$.summaryPoint3].every(point => point.$error)) {
                    this.step++;
                }
            }
        },

        priceValueClass(price) {
            return {
                'price-value': true,
                'positive': NumberUtils.isPositive(price),
                'negative': NumberUtils.isNegative(price)
            }
        },

        open(params) {
            this.savedAsDraft = false;
            this.step = 0;
            this.selectedAccount = null;
            this.selectedPortfolio = null;
            this.collabPortfolios = [];
            this.editMode = false;
            this.draftMotion = null;
            this.userGroups = null;

            let groupId;
            let accountId;

            this.userMotionsPage = params?.userMotionsPage ?? false;
            
            if(this.userMotionsPage){
                if(params?.draftMotion){
                    this.editMode = true;
                    accountId = params.draftMotion.investmentAccountId;
                    groupId = params.draftMotion.groupId;
                    this.groupName = params.draftMotion.groupName;
                } 
            } else {
                accountId = params?.accountId;
                groupId = params?.group?.groupId;
                this.groupName = params?.group?.name;
            }

            accountId = accountId ?? null;
            groupId = groupId ?? 1;
            this.groupName = this.groupName ?? null;

            const callback = () => {
                if(this.editMode){
                    this.initEditModeData(params.draftMotion.motionId);
                }
                else  if (params.security) {
                    this.resetOrderFieldValues();
                    console.log("setting selected security to ",params.security);
                    this.initSelectedSecurity(params.security);
                    this.startPollingPrices();

                    this.fetchAvailableOrderTypes(this.selectedAccount.investmentAccountId).then((types) => {
                        this.orderTypeOptions = types;
                    });
                }
            };

            if(this.userMotionsPage){
                GroupService.getMyGroups(0, 50).then((resp => {
                    if( resp.data.status == "success") {
                        this.userGroups = resp.data.groups;
                        this.userGroup = this.userGroups.find(group => group.groupId==groupId);
                        this.initCollaborativeGroupPortfolios(groupId, accountId, callback);
                    }
                }));
            } else {
                this.initCollaborativeGroupPortfolios(groupId, accountId, callback);
            }

           
        },

        initCollaborativeGroupPortfolios(groupId, accountId, callback){
            GroupService.getCollaborativeGroupPortfolios(groupId).then((resp) => {
                this.collabPortfolios = [];
                this.selectedPortfolio = null;
                if (resp.data.status == "success") {
                    const groupPortfolios = resp.data.groupPortfolios;

                    if(groupPortfolios){
                        groupPortfolios.forEach((portfolio) => {
                            const portfolios = portfolio.accounts.reduce((total, account) => {
                                const labelName = `${portfolio.name} / ${account.accountName}`;

                                let obj = {...portfolio, labelName};

                                obj['account'] = {...account};

                                delete obj.accounts;
                                
                                total.push(obj);

                                return total;
                            }, [])

                            this.collabPortfolios = this.collabPortfolios.concat(portfolios);
                        })
                        
                    }
                } else {
                    console.error("error getting group portfolios :" + resp.data.message)
                }

                if (accountId) {
                    // Default to account that matches accountId
                    for(let i = 0; i < this.collabPortfolios.length; i++) {
                        const portfolio = this.collabPortfolios[i];

                        if (accountId === portfolio?.account?.investmentAccountId) {
                            this.selectedPortfolio = portfolio;
                            break;
                        }
                    }
                } else {
                    // Default to first US account
                    let foundAccount = null;

                    for(let i = 0; i < this.collabPortfolios.length; i++) {
                        const portfolio = this.collabPortfolios[i];

                        if (portfolio.account.currencyId === US_CURRENCY_TYPE['ID']) {
                            foundAccount = portfolio;
                            break;
                        }
                    }

                    this.selectedPortfolio = foundAccount ?? this.collabPortfolios[0];
                }
                this.selectedAccount = this.selectedPortfolio?.account;

                this.display = true;

                if(callback){
                    callback();
                }
            })
        },

        decodeEntities(s) {
            if( s ) {
                let doc = domParser.parseFromString(s, "text/html");
                return doc.documentElement.textContent;
            }
            else {
                return s;
            }
        },
        
 

        initEditModeData(motionId){
            this.resetOrderFieldValues();

            MotionService.getMotion(motionId).then(resp => {
                this.showLoading = false;

                if (resp.data.status == "success") {
                    this.draftMotion = resp.data.motion;
                    
                    const motionTradeOrder = this.draftMotion.motionTradeOrders[0]?.tradeOrder;

                    this.selectedSecurity = {
                        symbol: motionTradeOrder.symbol, 
                        assetTypeId: motionTradeOrder.assetTypeId, 
                        assetId: motionTradeOrder.assetId, 
                        exchangeSymbol: motionTradeOrder.exchangeSymbol, 
                        companyName: motionTradeOrder.symbol // TODO
                    };

                    this.summaryPoint1 = this.decodeEntities(this.draftMotion.summaryPoint1);
                    this.summaryPoint2 = this.decodeEntities(this.draftMotion.summaryPoint2);
                    this.summaryPoint3 = this.decodeEntities(this.draftMotion.summaryPoint3);
                    this.summaryPoint4 = this.decodeEntities(this.draftMotion.summaryPoint4);
                    this.summaryPoint5 = this.decodeEntities(this.draftMotion.summaryPoint5);
                    this.rationale = this.draftMotion.details;

                    this.startPollingPrices();

                    this.fetchAvailableOrderTypes(this.draftMotion.investmentAccountId).then((types) => {
                        this.orderTypeOptions = types;

                        this.orderTypeOption = this.orderTypeOptions.find(option => 
                            option.tradeType == motionTradeOrder.tradeTypeId && option.tradeOrderType == motionTradeOrder.orderTypeId
                        );

                        this.quantity = motionTradeOrder.quantity;
                        this.limitPrice = motionTradeOrder.limitPrice;
                        this.stopPrice = motionTradeOrder.stopPrice;

                        if (this.isNonMarketOrderType(this.orderTypeOption.name)) {
                            this.durationTypeOptions = [
                                {id: UNTIL_CANCELLED_DURATION_ID, name: 'Until Cancelled (Max 30 Days)'},
                                {id: MARKET_CLOSE_DURATION_ID, name: 'Market Close'}
                            ];
                            this.marketOrderTypeSelected = false;
                            this.durationTypeOption = this.durationTypeOptions.find(option => option.id == motionTradeOrder.orderDurationId);
                            this.durationTypeDropdownDisabled = false;
                        } else {
                            this.marketOrderTypeSelected = true;
                            this.durationTypeOptions = [];
                            this.durationTypeOption = {id: FILL_OR_KILL_DURATION_ID, name: 'Fill Or Kill'};
                        }

                    });

                } else {
                    console.error("error getting account motions:" + resp.data.message)
                }
            });
        },

        onClose() {
            this.display = false;
        },

        populateRequest() {
            const mainTradeOrderRequest = {
                assetTypeId: this.selectedSecurity.assetTypeId,
                limitPrice: this.limitPrice,
                durationTypeId: this.durationTypeOption.id,
                orderTypeId: this.orderTypeOption.tradeOrderType,
                quantity: this.quantity,
                stopPrice: this.stopPrice,
                tradeTypeId: this.orderTypeOption.tradeType,
                assetId: this.selectedSecurity.assetId,
                investmentAccountId: this.selectedAccount.investmentAccountId
            };
            const orders = [mainTradeOrderRequest]

            const saveAndSubmitMotionRequest = {
                groupPortfolioId: this.selectedPortfolio.groupPortfolioId,
                investmentAccountId: this.selectedAccount.investmentAccountId,
                motionTypeId: TRADE_MOTION_TYPE_ID,
                deadline: this.selectedPortfolio.collaborationSettings.defaultDeadline,
                summaryPoint1: this.summaryPoint1.trim(),
                summaryPoint2: this.summaryPoint2.trim(),
                summaryPoint3: this.summaryPoint3.trim(),
                details: this.rationale,
                tradeOrders: orders
            }

            return saveAndSubmitMotionRequest
        },

        submit() {
            this.submitting = true;
            const req = this.populateRequest();

            if(this.editMode){
                MotionService.updateMotion(this.draftMotion.motionId, req).then(resp => {
                    if (resp.data.status === 'success') {
                        MotionService.submitMotion(this.draftMotion.motionId).then(resp => {
                            if (resp.data.status === 'success') {
                                //this.$refs.confirmationModal.open();
                                this.$toast.add({ severity: 'success', summary: "Submitted motion!", life: 4000, group: 'center' });
                                this.display = false;
                                EventBus.emit('refresh-motions');
                            } else if(resp.data.message === "Trading Closed") {
                                let msg = 'Your changes were saved but new motions are not currently being accepted for this portfolio.';
                                if(!this.selectedPortfolio.collaborationSettings.suspendTradingFloor) {
                                    const startTime = this.selectedPortfolio.collaborationSettings.tradingStartTime.hour + ':00';
                                    const stopTime = this.selectedPortfolio.collaborationSettings.tradingStopTime.hour + ':00';
                                    msg += ` New motions can be submitted between the hours of ${startTime} and ${stopTime}.`
                                }
                                this.$toast.add({ severity: 'info', summary: msg, life: 6000, group: 'center' });  
                                console.log("Info update and submit motion: Trading Closed");
                            } else {
                                this.$toast.add({ severity: 'error', summary: "Error submitting motion", life: 4000, group: 'center' });
                                console.log("Error update and submit motion");
                            }

                            this.submitting = false;
                        });
                    } else {
                        this.$toast.add({ severity: 'error', summary: "Error updating motion", life: 4000, group: 'center' });
                        this.submitting = false;
                    }
                });
            } else {
                MotionService.saveAndSubmitMotion( req ).then(resp => {
                    if (resp.data.status === 'success') {
                        this.display = false;
                        EventBus.emit('refresh-feed');
                        EventBus.emit('refresh-motions');

                        if(this.userMotionsPage){
                            this.$toast.add({ severity: 'success', summary: "Submitted motion!", life: 4000, group: 'center' });
                        } else {
                            this.$refs.confirmationModal.open();
                        }
                    } else if(resp.data.message === "Trading Closed") {
                        let msg = 'Not currently accepting new Motions for this portfolio.';
                        if(!this.selectedPortfolio.collaborationSettings.suspendTradingFloor) {
                            const startTime = this.selectedPortfolio.collaborationSettings.tradingStartTime.hour + ':00';
                            const stopTime = this.selectedPortfolio.collaborationSettings.tradingStopTime.hour + ':00';
                            msg += ` New motions can be submitted between the hours of ${startTime} and ${stopTime}.`
                        }
                        this.$toast.add({ severity: 'info', summary: msg, life: 6000, group: 'center' });  
                        console.log("Info save and submit motion: Trading Closed");
                    } else {
                        this.$toast.add({ severity: 'error', summary: "Error save and submit motion", life: 4000, group: 'center' });  
                        console.log("Error save and submit motion:");
                    }

                    this.submitting = false;
                });
            }
        },
        
        saveAsDraft() {
            this.savingAsDraft = true;
            const req = this.populateRequest();

            if(this.editMode){
                MotionService.updateMotion(this.draftMotion.motionId, req).then(resp => {
                    if (resp.data.status === 'success') {
                        this.savedAsDraft = true;
                        //this.$refs.confirmationModal.open();
                        this.$toast.add({ severity: 'success', summary: "Updated motion!", life: 4000, group: 'center' });
                        this.display = false;
                        EventBus.emit('refresh-motions');
                    } else {
                        this.$toast.add({ severity: 'error', summary: "Error updating motion", life: 4000, group: 'center' });
                    }

                    this.savingAsDraft = false;
                })
                .catch((err) => {
                    this.$toast.add({ severity: 'error', summary: "Error updating motion", life: 4000, group: 'center' });
                    console.log("Error updating motion: "+err);
                    this.savingAsDraft = false;
                });
            } else {
                MotionService.saveAsDraft(req).then(resp => {
                    if (resp.data.status === 'success') {
                        this.savedAsDraft = true;
                        this.display = false;
                        EventBus.emit('refresh-motions');
                    
                        if(this.userMotionsPage){
                            this.$toast.add({ severity: 'success', summary: "Motion is saved as a draft!", life: 4000, group: 'center' });
                        } else {
                            this.$refs.confirmationModal.open();
                        }
                    } else {
                        this.$toast.add({ severity: 'error', summary: "Error saving motion", life: 4000, group: 'center' });
                    }

                    this.savingAsDraft = false;
                })
                .catch(err => {
                    this.$toast.add({ severity: 'error', summary: "Error saving motion", life: 4000, group: 'center' });
                    console.log("Error saving motion:", err);
                    this.savingAsDraft = false;
                });
            }
        },
        

        onSecuritySearchClickAway(event) {
            if (this.selectedSecurity.symbol === undefined) {
                this.$refs.securitySearchBar.$emit('hide', event);
                this.resetSelectedSecurity();
            }
        },

        resetSelectedSecurity() {
            this.selectedSecurity = {symbol: "", assetTypeId: null, assetId: null, exchangeSymbol: null, companyName: null};
        },

        initSelectedSecurity(security) {
            const isSecurity = security.securityId !== undefined;

            this.selectedSecurity.symbol = security.symbol;
            this.selectedSecurity.assetTypeId = isSecurity ? ASSET_TYPES['EQUITY'] : ASSET_TYPES['FUND'];
            this.selectedSecurity.assetId = isSecurity ? security.securityId : security.fundId;
            this.selectedSecurity.exchangeSymbol = security.exchangeSymbol;
            this.selectedSecurity.companyName = isSecurity ? security.company : security.name;
        },

        onHide() {
            this.placingOrder = false;
            this.savingAsDraft = false;
            this.submitting = false;
            this.currency = null;
            this.groupName = null;

            this.resetOrderSubmission();
        },

        resetOrderSubmission() {
            console.log("resetOrderSubmission")
            this.stopPollingPrices();
            this.resetSelectedSecurity();
            this.resetOrderFieldValues();
        },

        resetOrderFieldValues() {
            this.limitPrice = null;
            this.stopPrice = null;
            this.orderTypeOption = null;
            this.orderTypeOptions = [];
            this.durationTypeOption = null;
            this.durationTypeOptions = [];
            this.durationTypeDropdownDisabled = true;
            this.marketOrderTypeSelected = false;
            this.quantity = null;
            this.position = null;
            this.positionQuantity = null;
            this.positionAvgCostPerUnit = null;

            this.rationale = null;
            this.summaryPoint1 = null;
            this.summaryPoint2 = null;
            this.summaryPoint3 = null;
            this.summaryPoint4 = null;
            this.summaryPoint5 = null;
        },

        resetQuoteDataValues() {
            this.askPrice = null;
            this.bidPrice = null;
            this.openValue = null;
            this.highValue = null;
            this.lowValue = null;
            this.volume = null;
            this.askSize = null;
            this.bidSize = null;
        },

        startPollingPrices() {
            if (this.refetcher) {
                this.resetQuoteDataValues();
                clearInterval(this.refetcher);
            }
            this.fetchPrices();

            this.refetcher = setInterval(() => {
                this.fetchPrices();
            }, POLLING_PRICE_INTERVAL);
        },

        stopPollingPrices(reset = true) {
            if (this.refetcher) {
                clearInterval(this.refetcher);

                if (reset) {
                    this.resetQuoteDataValues();
                }
                this.refetcher = null;
            }
        },

        fetchPrices() {
            DataService.getSnapQuotes(SecurityFormatter.getSymbolViaCdnExchanges(this.selectedSecurity)).then((resp) => {
                const quoteData = resp.data.results.quote[0];
                if( quoteData && quoteData.pricedata) {
                    this.askPrice = quoteData.pricedata.ask;
                    this.bidPrice = quoteData.pricedata.bid;
                    this.openValue = quoteData.pricedata.open;
                    this.highValue = quoteData.pricedata.high;
                    this.lowValue = quoteData.pricedata.low;
                    this.volume = quoteData.pricedata.tradevolume;
                    this.askSize = quoteData.pricedata.asksize;
                    this.bidSize = quoteData.pricedata.bidsize;
                }
            });
        },

        searchSecurities(event){
            ResearchService.queryMarket('All', event.query, 10, 0).then(response => {
				
                let queryResults = response.data;

                this.securityList = [
                {
                    label: 'Equities', code: 'EQ', 
                    items: queryResults.securities
                },
                {
                    label: 'Funds', code: 'F', 
                    items: queryResults.funds
                }];
            })
            .catch(error => {
                this.securityList = []
                
                console.debug('There was an error:', error.response)
            })
        },

        onSecuritySelected(event) {

            this.$refs.securitySearchBar.$el.blur();

            this.resetOrderFieldValues();
            this.initSelectedSecurity(event.value);
            this.startPollingPrices();

            this.fetchAvailableOrderTypes(this.selectedAccount.investmentAccountId).then((types) => {
                this.orderTypeOptions = types;
            });
		},

        async fetchAvailableOrderTypes(investmentAccountId) {
            return PortfolioService.getPosition(investmentAccountId, this.selectedSecurity.assetTypeId, this.selectedSecurity.assetId).then((resp) => {

                if (resp.data.status === 'success') {
                    const positionSummary = resp.data.positionSummary;

                    this.position = positionSummary.position;
                    this.positionQuantity = positionSummary.quantity;
                    this.positionAvgCostPerUnit = positionSummary.avgCostPerUnit

                    const ORDER_TYPES = [
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_MARKET_ORDER_NAME, tradeOrderTypeId: MARKET_ORDER_TYPE_ID}, 
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_MARKET_ORDER_NAME, tradeOrderTypeId: MARKET_ORDER_TYPE_ID}, 
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_LIMIT_ORDER_NAME, tradeOrderTypeId: LIMIT_ORDER_TYPE_ID}, 
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_LIMIT_ORDER_NAME, tradeOrderTypeId: LIMIT_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_LOSS_ORDER_NAME, tradeOrderTypeId: STOP_LOSS_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_LOSS_ORDER_NAME, tradeOrderTypeId: STOP_LOSS_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_ENTRY_ORDER_NAME, tradeOrderTypeId: STOP_ENTRY_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_ENTRY_ORDER_NAME, tradeOrderTypeId: STOP_ENTRY_ORDER_TYPE_ID},
                        {tradeTypeId: BUY_TRADE_TYPE['ID'], name: BUY_STOP_LIMIT_ORDER_NAME, tradeOrderTypeId: STOP_LIMIT_ORDER_TYPE_ID},
                        {tradeTypeId: SELL_TRADE_TYPE['ID'], name: SELL_STOP_LIMIT_ORDER_NAME, tradeOrderTypeId: STOP_LIMIT_ORDER_TYPE_ID}];

                    const SUPPORTED_TRADE_COMBINATIONS_MAP = {
                        'Buy': {
                            'StopEntry': BUY_STOP_ENTRY_ORDER_NAME,
                            'StopLoss': BUY_STOP_LOSS_ORDER_NAME,
                            'Limit': BUY_LIMIT_ORDER_NAME,
                            'StopLimit': BUY_STOP_LIMIT_ORDER_NAME,
                            'Market': BUY_MARKET_ORDER_NAME
                        },
                        'Sell': {
                            'StopEntry': SELL_STOP_ENTRY_ORDER_NAME,
                            'StopLoss': SELL_STOP_LOSS_ORDER_NAME,
                            'Limit': SELL_LIMIT_ORDER_NAME,
                            'StopLimit': SELL_STOP_LIMIT_ORDER_NAME,
                            'Market': SELL_MARKET_ORDER_NAME
                        }
                    };

                    const types = ORDER_TYPES.map((type) => {

                        let obj = {tradeType: type.tradeTypeId, tradeOrderType: type.tradeOrderTypeId, name: type.name, disabled: false};

                        const match = resp.data.supportedTradeCombinations.filter((combo) => {
                            return SUPPORTED_TRADE_COMBINATIONS_MAP[combo.tradeType][combo.tradeOrderType] === type.name
                        })[0];

                        if (match) {
                            obj['description'] = match.description;
                        } else {
                            obj.disabled = true;
                        }

                        return obj;
                    });

                    const nonDisabledTypes = types.filter(type => !type.disabled);
                    const disabledTypes = types.filter(type => type.disabled);
                    const sortedTypes = nonDisabledTypes.concat(disabledTypes);

                    return sortedTypes;

                } else {
                    this.$toast.add({ severity: 'error', summary: 'You are not authorized to get current position.', life: 3000, group: 'center' });
                    console.log("unexpected error getting position: "+resp.data.message);

                    return [];
                }
            })
            .catch((err) => {
                this.$toast.add({ severity: 'error', summary: 'An unexpected error occurred.  Please try again or contact support@invrs.com.', life: 3000, group: 'center' });
                console.log("unexpected error getting position: "+err);

                return [];
            })
        },

        orderTypeSelected(event) {
            this.limitPrice = null;
            this.stopPrice = null;
            this.quantity = null;

            if (this.isNonMarketOrderType(event.value.name)) {
                this.durationTypeOptions = [
                    {id: UNTIL_CANCELLED_DURATION_ID, name: 'Until Cancelled (Max 30 Days)'},
                    {id: MARKET_CLOSE_DURATION_ID, name: 'Market Close'}
                ];
                this.marketOrderTypeSelected = false;
                this.durationTypeOption = null;
                this.durationTypeDropdownDisabled = false;
            } else {
                this.marketOrderTypeSelected = true;
                this.durationTypeOptions = [];
                this.durationTypeOption = {id: FILL_OR_KILL_DURATION_ID, name: 'Fill Or Kill'};
            }
        },

        isNonMarketOrderType(type) {
            return NON_MARKET_ORDER_TYPES.includes(type);
        },

        
    },

    validations() {
      return {
        summaryPoint1: {
            maxLength: maxLength(this.pointCharLimit),
        },
        summaryPoint2: {
            maxLength: maxLength(this.pointCharLimit)
        },
        summaryPoint3: {
            maxLength: maxLength(this.pointCharLimit)
        },
        summaryPoint4: {
            maxLength: maxLength(this.pointCharLimit)
        },
        summaryPoint5: {
            maxLength: maxLength(this.pointCharLimit)
        }
      }
    }

}
</script>

<style>
.create-motion-modal-0 {
    width: 600px;
}
.create-motion-modal-1 {
    width: 800px;
}
.create-motion-modal-2 {
    width: 800px;
}

.motion-confirmation-modal .p-dialog-header,
[class*=create-motion-modal] .p-dialog-header {
    justify-content: center;
    padding: 16px 16px 16px 0;
}
.motion-confirmation-modal .p-dialog-header .p-dialog-title,
[class*=create-motion-modal] .p-dialog-header .p-dialog-title {
    margin: 0px auto;    
}

[class*=create-motion-modal] .p-dialog-content {
    padding: 0 16px 16px;
    overflow-y: visible;
}

.motion-confirmation-modal .p-dialog-content {
    padding: 16px;
    text-align: center;
}

.duration-type-dropdown-panel .p-dropdown-items,
.order-type-dropdown-panel .p-dropdown-items {
    padding: 0px;
    border-radius: 4px;
}

[class*=create-motion-modal] .p-dialog-footer {
    padding: 16px 0px;
    text-align: center;
}
.create-motion-modal-2 .p-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.selection-dropdown-menu .p-dropdown-items {
    padding: 0px;
}
.selection-dropdown-menu .p-dropdown-items .p-dropdown-item {
    padding: 8px;
    width: 100%;
}

.create-motion-modal-1 .ck-editor {
    overflow-x: hidden;
}
.create-motion-modal-1 .ck.ck-toolbar {
    background: #F2F4FA;
    border-top-left-radius: 16px !important;
    border-top-right-radius: 16px !important;
}

.create-motion-modal-1 .ck.ck-content {
    font-size: 16px;
    color: black;
    padding: 0 30px;
    border-bottom-left-radius: 16px !important;
    border-bottom-right-radius: 16px !important;
    min-height: 300px;
    max-height: 300px;
}

.create-motion-modal-1 .ck.ck-reset_all, .create-motion-modal-1 .ck.ck-reset_all * {
    font-family: 'Trebuchet MS', 'Verdana' !important;
}

.create-motion-modal-1 .ck.ck-toolbar.ck-toolbar_grouping > .ck-toolbar__items {
    margin-left: 15px;
}

.create-motion-modal-1 .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_se {
    left: -300px;
}

.create-motion-modal-1 .ck.ck-content a {
    color: #5367FF;
    font-weight: bold;
}

.create-motion-modal-1 .ck-editor__editable_inline::-webkit-scrollbar {
    /* width */
    width: 8px;

}
.create-motion-modal-1 .ck-editor__editable_inline::-webkit-scrollbar-track {
    margin-right: 10px;
}
.create-motion-modal-1 .ck-editor__editable_inline::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background: #BFBFBF;    /* color of the scroll thumb */
    border-radius: 10px;       /* roundness of the scroll thumb */
}


.create-motion-modal-1  .ck-editor__editable_inline.ck-focused {
    border-color: #33CC99 !important;
}

.create-motion-modal-1 .ck .ck-placeholder::before {
    white-space: pre;
}

@media (max-width: 760px) {
    [class*=create-motion-modal] {

        height: 100vh!important;
        
        left: 0!important;
        max-height: 100%;
        top: 0!important;
        transform: none;
        transition: none;
        width: 100vw!important;
        border-radius: 0;
    }

    [class*=create-motion-modal] .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    [class*=create-motion-modal] .p-dialog-content {
        padding: 0 16px 0;
        overflow-y: hidden;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    [class*=create-motion-modal] .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .p-autocomplete-panel.p-component.security-search-trade-order-panel {
        width: 100%;
        border-radius: 0px;
    }

    .create-motion-modal-1 .ck.ck-content {
        min-height: 400px;
        max-height: 400px;
    }
    .motion-confirmation-modal .p-dialog-content {
        padding-top: 16px !important;
    }

    .motion-confirmation-modal .p-dialog-footer {
        padding: 16px;
    }

    .create-motion-modal-1 .ck .ck-placeholder::before {
        white-space: pre-wrap;
    }

}
</style>
<style scoped>
*:not(.pi, .material-symbols-outlined),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.form-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.step-header {
    color: #32364e;
    font-size: 16px;
    margin-bottom: 24px;
    font-weight: bold;
}

.body-text {
    color: black;
    text-align: center;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    font-weight: bold;
}

.sub-header {
    font-weight: bold;
    color: #32364e;
}

.required {
    font-weight: bold;
    color: #E63E3E;
}

.position-summary {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    padding-left: 3px;
    padding-bottom: 2px;
}

.input-error {
    animation-name: shakeError;
    animation-fill-mode: forwards;
    animation-duration: .6s;
    animation-timing-function: ease-in-out;
}
::v-deep(.input.input-error),
::v-deep(.input.input-error:focus-within),
.p-inputnumber.input-error > ::v-deep(.p-inputtext),
.p-inputnumber.input-error > ::v-deep(.p-inputtext:focus-within) {
    border-color: #E63E3E;
} 

.error-message:nth-child(2) {
    padding-top: 12px;
}
.error-message {
    color: #E63E3E;
    padding-left: 8px;
    padding-top: 4px;
}

.motion-confirmation-modal .p-dialog-footer button,
[class*=create-motion-modal] .p-dialog-footer button {
    border-radius: 50px;
    padding: 8px 40px;
    font-size: 16px;
}

[class*=input-section] {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
}
[class*=input-section]:last-of-type {
    margin-bottom: 8px;
}

.input-section-0 {
    width: 75%;
}
.inpur-section-1 {
    width: 100%;
}

.p-float-label .p-inputwrapper-focus ~ label,
.p-float-label .p-inputwrapper-filled ~ label {
    font-size: 12px;
}
.p-float-label ::v-deep(.p-placeholder) {
    opacity: 1;
}

.dropdown {
    background: #F2F4FA;
    border: 2px solid #BFBFBF;
    border-radius: 8px;
    padding: 8px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    width: 100%;
}
.dropdown ::v-deep(.p-inputtext) {
    padding: 0;
}
.dropdown:hover, .dropdown:active {
    border-color: #33CC99;
}
.dropdown:focus, .dropdown:not(.p-disabled).p-focus, .p-inputwrapper-focus {
    border-color: #33CC99;
    box-shadow: none;
}
::v-deep(.p-dropdown-label.p-placeholder) {
    color: #BFBFBF;
    padding: 0px;
}
::v-deep(.p-dropdown-item) {
    color: #32364e;
    border-bottom: 1px solid #333;
}
.dropdown.market-order-selected {
    pointer-events: none;
}
.user-groups-dropdown-container {
    margin-bottom: 22px;
}
.portfolio-account-dropdown-container {
    margin-bottom: 8px;
}

.p-input-icon-right,
.p-inputnumber {
    width: 100%;
}
.p-inputnumber > ::v-deep(.p-inputtext::placeholder) {
    color: #bfbfbf;
}
.p-inputnumber > ::v-deep(.p-inputtext),
.input {
    border-radius: 8px;
    border: 2px solid #BFBFBF;
    background-color: #F2F4FA;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    width: 100%;
    padding: 8px 0px 8px 16px;
    color: black;
}
.p-inputnumber:hover > ::v-deep(.p-inputtext),
.input:hover {
    border-color: #33CC99;
}
.p-inputnumber:focus > ::v-deep(.p-inputtext),
.input:focus {
    box-shadow: none;
    border-color: #33CC99;
}

.security-search-bar-container {
    width: 100%;
    margin-bottom: 12px;
}
.p-autocomplete {
    width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component){
	border-radius: 8px;
    border: 2px solid #bfbfbf;
    background-color: #f2f4fa;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 2px 1px rgba(0, 0, 0, 0.12), 0px 1px 3px rgba(0, 0, 0, 0.2);
    height: 36px;
    padding: 8px 8px 8px 36px;
    color: #32364e;
    font-size: 16px;
	width: 100%;
}
::v-deep(.p-autocomplete-input.p-inputtext.p-component::placeholder) {
    font-size: 16px;
    color: #BFBFBF;
}

.popup-checkbox-container {
    display: flex;
    align-items: center;
    width: 75%;
    margin-bottom: 24px;
}
.popup-checkbox-container .p-checkbox {
    margin-right: 8px;
}
.popup-checkbox-container  .p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
    border-color: #00C083;
    background-color: #00C083;
}
.popup-checkbox-container .popup-checkbox-label {
    font-weight: bold;
    color: black;
}

.order-section {
    overflow-y: auto;
    max-height: calc(100vh - 350px);
}
.order-section::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.order-section::-webkit-scrollbar-track {
	margin-right: 10px;
}
.order-section::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

.header-content {
    font-size: 14px;
    color: black;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
}

.order-value,
.price-value,
.position {
    color: black;
    font-weight: bold;
}
.positive {
    color: #33CC99;
}
.none {
    color: black;
}
.negative {
    color: #E63E3E;
}

.header-content table {
    width: 100%;
}
.header-content table .left-col {
    text-align: left;
    vertical-align: top;
    width: 105px;
}
.header-content table .right-col {
    font-weight: bold;
    text-align: left;
}
.header-content table .trade-criteria-text {
    margin: 0;
}

.quote-data-section {
    padding: 16px 16px 0;
}

.selected-order-type-description {
    color: black;
    margin-top: 12px;
}

.order-type-item-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.order-type-item-row.disabled {
    color: #bfbfbf;
    pointer-events: none;
}
.order-type-info-button {
    border-radius: 50px;
    padding: 2px 8px;
    height: 20px;
    width: 20px;
}

.order-type-item-row > span {
    white-space: normal;
    max-width: 80%;
}

.pi-info-circle {
    vertical-align: super;
}

.enter-motion-details-section {
    padding: 16px 16px 0px 8px;
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}
.enter-motion-details-section .summary-point-section {
    margin-bottom: 32px;
}
.enter-motion-details-section .summary-point-section .input-section {
    margin-bottom: 8px;
}
.enter-motion-details-section .summary-point-section .input-section:last-child {
    margin-bottom: 0;
}
.enter-motion-details-section::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.enter-motion-details-section::-webkit-scrollbar-track {
	margin-right: 10px;
}
.enter-motion-details-section::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}

.deadline-hours-help-text {
    margin-bottom: 8px;
}

.deadline-hours-input {
    width: 100px;
}

.summary-help-text {
    margin-bottom: 24px;
}
.summary-help-text b {
    color: #32364e;
}
.summary-point-container {
    display: flex;
    align-items: center;
}
.summary-point-input {
    margin-bottom: 4px;
    width: 90%;
}
.character-counter {
    margin-left: 10px;
}
.rationale-help-text {
  margin-bottom: 8px;  
}
.rationale-help-text b {
    color: #32364e;
}

.trade-details-section {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
}
.trade-details-section .trade-details-header {
    font-weight:bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.trade-details-section .trade-details-content ::v-deep(b),
.trade-details-section .trade-details-content b {
    color: black;
}

.review-motion-section {
    overflow-y: auto;
    max-height: calc(100vh - 250px);
}
.review-motion-section::-webkit-scrollbar {
	/* width */
	width: 10px;
    
}
.review-motion-section::-webkit-scrollbar-track {
	margin-right: 10px;
}
.review-motion-section::-webkit-scrollbar-thumb {
    background-clip: padding-box;
	background: #BFBFBF;    /* color of the scroll thumb */
	border-radius: 10px;       /* roundness of the scroll thumb */
}
.motion-details-section {
    display: flex;
    flex-direction: column;
}
.motion-details-section .motion-details-header {
    font-weight: bold;
    color: #32364e;
    font-size: 20px;
    margin-bottom: 16px;
}
.motion-details-section .voting-deadline-section {
    margin-bottom: 20px;
}
.motion-details-section .voting-deadline-section ::v-deep(b) {
   color: black; 
}
.motion-details-section .summary-section {
    margin-bottom: 20px;
}
.motion-details-section .summary-section ul {
    word-wrap: break-word;
}
.motion-details-section .rationale-section {
    word-wrap: break-word;
}
.motion-details-section .rationale-section .sub-header {
    margin-bottom: 6px;
}
.motion-details-section .rationale-section ::v-deep(img) {         
    max-width: 100%;
    padding: 8px 0px;
}
.motion-details-section .rationale-section ::v-deep(.image) {
    margin: 0 auto !important;
}
.motion-details-section .rationale-section ::v-deep(.image.image-style-side) {
    float: right;
}
.motion-details-section .rationale-section ::v-deep(.image img) {
    max-width: 100%;
    max-height: 100%;
    width: unset;
    height: unset;
}

.motion-confirmation-modal div {
    font-size: 16px;
    color: black;
    margin-bottom: 12px;
}
.motion-confirmation-modal div:last-child {
    margin-bottom: 0;
}

@media (max-width: 760px) {

    [class*=create-motion-modal] .p-dialog-footer button {
        border-radius: 50px;
        padding: 4px 24px;
        font-size: 14px;
    }
    .create-motion-modal-2 .p-dialog-footer .buttons-container {
        width: 100%;
    }
    .create-motion-modal-2 .p-dialog-footer .buttons-column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .create-motion-modal-2 .p-dialog-footer .buttons-row {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 8px;
    }
    .create-motion-modal-2 .p-dialog-footer .buttons-row:last-child {
        margin-bottom: 0;
    }


    .step-header {
        margin-top: 12px;
    }

    .order-section {
        max-height: calc(100% - 125px);
        padding-bottom: 12px;
    }

    [class*=input-section] {
        width: 100%;
        margin-bottom: 36px;
    }

    .p-float-label .p-inputwrapper-focus ~ label,
    .p-float-label .p-inputwrapper-filled ~ label {
        top: -1rem;
        font-size: 16px;
    }

    .security-search-bar-container {
        margin-bottom: 16px;
    }

    .pi-info-circle {
        font-size: 10px;
    }

    .enter-motion-details-section {
        max-height: calc(100% - 75px);
    }

    .summary-point-input {
        width: 80%;
    }

    .review-motion-section {
        overflow-y: auto;
        height: auto;
        max-height: calc(100% - 75px);
    }

    /* note this is needed to stop mobile zooming in when entering text */
    select:focus,
    textarea:focus,
    input:focus {
        font-size: 16px;
        background: #eee;
    }
}
</style>