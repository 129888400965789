<template>
    <div class="dm-channel-item">
        <UserAvatar :imageSrc="( mode == 'sent' ? request.recipientAvatar : request.senderAvatar)" size="md" @click="goToProfile($event)" v-tooltip.bottom="'View Profile'"/>

        <div :class="'dm-channel-item-name-container'">
            <div class="dm-channel-item-title-container">
                <span class="dm-channel-title">{{ getUsername() }}</span>
                <span class="dm-time-posted">{{ getCorrectTime() }}</span>
            </div>
             <span class="dm-channel-full-name">{{ getFullName() }}</span>
            
        </div>
        <div class="dm-channel-item-counts-container"></div>
       
        <div class="dm-channel-item-actions-container" >
            <Button icon='pi pi-check' class='hide-btn blue-button' @click='acceptRequest($event)' v-tooltip.bottom="'Accept'" v-if="mode == 'received'"/>
            <Button icon='pi pi-times' class='block-btn red-button__secondary' @click='rejectRequest($event)' v-tooltip.bottom="'Decline'" v-if="mode == 'received'"/>
            <Button icon='pi pi-user-minus' class='block-btn red-button__secondary delete-button' @click='blockDmChannel($event)' v-tooltip.bottom="'Block'" v-if="mode == 'received'"/>
            <Button icon='pi pi-trash' class='block-btn red-button__secondary delete-button' @click='deleteRequest($event)' v-tooltip.bottom="'Delete'" v-if="mode == 'sent'"/>
        </div>
    </div>
</template>

<script>
import UserAvatar from '../profile/UserAvatar';

import TimeUtils from '../../utilities/TimeUtils';
import ChatService from '../../service/ChatService';

export default {
    name: 'DMRequestListItem',
    props: ['request', 'mode'],
    emits: ['accept-clicked', 'reject-clicked', 'delete-clicked'],
    components: {
        UserAvatar,
    },

    data() {
        return {
           
        };
    },

    mounted() {
        
        
    },

    

    methods:{
        goToProfile() {
            
            if( this.mode == 'sent') {
                this.$router.push('/analysts/profile/'+this.request.recipientName)
            }
            else {
                this.$router.push('/analysts/profile/'+this.request.senderName)
            }
        },
        acceptRequest() {
            this.$emit('accept-clicked', this.request);

        },
        rejectRequest() {
            this.$emit('reject-clicked', this.request);
            
        },
        deleteRequest() {
            this.$emit('delete-clicked', this.request);
            
        },
       
        blockDmChannel() {
            
            
            
            ChatService.blockUser(this.request.requesterId).then(resp=> {
                if( resp.data.status === "success") {
                    this.$toast.add({severity:'success', summary: 'The user has been blocked', detail:'', life: 5000, group: 'tc'});
                    this.$emit('reject-clicked', this.request);
                }
                
            });
            
        },

        getCorrectTime() {
            
            let theDate = this.request.createdDate;
            let prefix = (this.mode == 'sent' ? 'Sent ' : 'Received ');
            if( this.request.dateAccepted) {
                theDate = this.request.dateAccepted;
                prefix = 'Accepted '
            }
            if( this.request.dateDeclined) {
                theDate= this.request.dateDeclined;
                prefix = 'Declined '
            }
            const timeAgo = TimeUtils.getTimeFromNow(new Date(theDate).toISOString());
            return timeAgo != "53 years ago" ? "| "+ prefix +timeAgo : '';
            
        },

        
        getUsername() {
           if( this.mode == 'sent') {
                return '@' + this.request.recipientName ;
           }
           else {
               return '@' + this.request.senderName ;
           }
           
        },

        getFullName() {
           if(this.mode == 'sent') {
                const firstName = (this.request.recipientfirstName) ? this.request.recipientfirstName : '';
            
                const lastName = (this.request.recipientLastName) ? this.request.recipientLastName : '';

                return `${firstName} ${lastName}`
           }
           else {
                const firstName = (this.request.senderfirstName) ? this.request.senderfirstName : '';
            
                const lastName = (this.request.senderLastName) ? this.request.senderLastName : '';

                return `${firstName} ${lastName}`
           }
           
        },

       

        
      
        
    }
}
</script>

<style>
.p-tooltip.p-component.p-tooltip-bottom .p-tooltip-text  {
    background: white !important;
    color: #32364e !important;
}
</style>

<style scoped>
::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

.red-button__secondary.delete-button{
    margin-right: 10px;
}
.dm-channel-item {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px;
    border-bottom: 1px solid #BFBFBF;
}
.dm-channel-item:hover {
    background: #32364e;
    cursor: pointer;
    color: #FFFFFF;
}
.dm-channel-item:hover .dm-channel-title {
    color: #FFFFFF;
}

.p-avatar {
    min-width: 48px;
}

.dm-channel-unread {
    background-color: #e63e3e;
}
.dm-channel-mention {
    background-color: #693bf5;
}

.dm-channel-unread,
.dm-channel-mention {
    margin: 2px;
    height: 100%;
    color: #fff;
    padding: 4px 8px;
    border-radius: 30px;
    white-space: nowrap;
}
/* .channel-item:hover .channel-unread {
    
} */

.dm-channel-item .dm-channel-title {
    font-weight: bold;
    color: #32364e;
    font-size: 1rem;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
    margin-right: 4px;
}

.dm-channel-item .dm-channel-item-title-container {
    display: flex;
    flex-wrap: wrap;
}

.dm-channel-item .dm-time-posted {
    white-space: nowrap;
    font-size: .8rem;
}

.dm-channel-item .more-options {
    margin-left: auto;
}

.dm-channel-item-name-container {
    display: flex;
    flex-direction: column;
    max-width: 70%;
}

.dm-channel-item-name-container {
    display: flex;
    flex-direction: column;
    max-width: 50%;
}

.dm-channel-item-counts-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: right;
    margin-left: 8px;
    flex-grow: 4;
}

.dm-channel-item-actions-container {
    width: fit-content;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
}

.block-btn:hover,
.hide-btn:hover{
    border-color: #FFFFFF !important;
}

.block-btn,
.hide-btn {
    margin-left: 8px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 16px;
}

</style>