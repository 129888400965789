import APIService from '@/common/APIService';
import {APP_STATUS_URL} from '@/common/config';

class AdminServiceImpl {

    rescheduleJobs() {
        return APIService.post(`/admin/rescheduleJobs`, {});
    }

    getServerStatus() {
        return APIService.getExternal(APP_STATUS_URL);
    }

}

const AdminService = new AdminServiceImpl();

export default AdminService;