<template>
  <img v-if="bannerAd" :src="bannerAd.imageUrl" @click="bannerAdClick()" style="width:100%;object-fit:contain;cursor:pointer;" />
</template>

<script>
import AdService from '../../service/AdService';


export default {
    name: 'BannerAd',
    emits: ['banner-ad-ready'],
    data() {
        return {
            bannerAd: null,
        }
    },

    mounted() {
        AdService.getRandomChanceBannerAd().then(resp => {
            if( resp.data.status == 'success'){
                if( resp.data.bannerAd){
                    this.bannerAd = resp.data.bannerAd
                    console.log("got banner ad ", this.bannerAd)
                }
                else {
                    console.log("no banner ad");
                }
            }
            else {
                console.log("unexpected error getting banner ad", resp.data.message);
            }
            this.onReady();
        }).catch(err => {
            console.log("unexpected error getting banner ad", err)
            this.onReady();
        });
    },

    methods: {
        bannerAdClick(){
            if( this.bannerAd ){
                window.open(this.bannerAd.clickUrl,'_blank');
            }
        },

        onReady() {
            console.log('emitting banner ad ready')
            this.$emit('banner-ad-ready');
        }
    },
}
</script>

<style scoped>

.ease-in{
   
 /* opacity: 0; */
    
 /* Add animation properties */
 animation: fadeIn 2s ease-in; 

}


@keyframes fadeIn {  
   from {  
       opacity:0;  
   }  

   to {  
       opacity:1;  
   }  
}
</style>