import INVRS from '../../main.js';

export default class EquityOverviewTour {
    tour = INVRS.app.config.globalProperties.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'invrs-onboarding-tour',
            cancelIcon: {
                'enabled': true
            }
        }
    });
    
    steps = this.createSteps();

    createSteps() {
        // This block of code will determine how the order of the steps shall go depending on what is selected
        let postsEventsNewsSteps = [];

        // If the 'Posts' button is selected
        if (document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child')) {
            postsEventsNewsSteps = [
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:first-child'), on: 'bottom'},
                    text: `Click on 'Posts'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:nth-child(2)'), on: 'bottom'},
                    text: `Click on 'Events'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    text: "You can find earnings & dividend announcements here.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:last-child'), on: 'bottom'},
                    text: `Click on 'News'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .news-feed-container')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "Check out the latest headlines and stories pertaining to this company.",
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
            ]
        // If 'Events' button was selected
        } else if (document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)')) {
            postsEventsNewsSteps = [
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:nth-child(2)'), on: 'bottom'},
                    text: `Click on 'Events'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    text: "You can find earnings & dividend announcements here.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:first-child'), on: 'bottom'},
                    text: `Click on 'Posts'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:last-child'), on: 'bottom'},
                    text: `Click on 'News'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .news-feed-container')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    text: "Check out the latest headlines and stories pertaining to this company.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                }
            ]
        // If 'News' button was selected
        } else if (document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child')) {
            postsEventsNewsSteps = [
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:last-child'), on: 'bottom'},
                    text: `Click on 'News'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .news-feed-container')
                    }, on: 'left'},
                    text: 'Keep up to date with the latest stories.',
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:last-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:first-child'), on: 'bottom'},
                    text: `Click on 'Posts'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Posts',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .social-feed')}
                        , on: 'left'},
                    canClickOnTarget: false,
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:first-child');
                    },
                    text: "This is an aggregate view of all community posts that feature this company. Be sure to check this section if you want to keep on top of the latest conversations.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },                {
                    title: 'Action',
                    attachTo: {element: document.querySelector('.equity-overview-container .feed-header-menu button:nth-child(2)'), on: 'bottom'},
                    text: `Click on 'Events'`,
                    classes: 'feed-header-menu-button',
                    showOn: () => {
                        return !document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.equity-overview-container .feed-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Events',
                    attachTo: {element: () => {
                        return document.querySelector('.equity-overview-container .corporate-events-container')
                    }, on: 'left'},
                    canClickOnTarget: false,
                    showOn: () => {
                        return document.querySelector('.equity-overview-container .feed-header-menu button.active:nth-child(2)');
                    },
                    text: "You can find earnings & dividend announcements here.",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: 'Back',
                            action: this.tour.back   
                        },
                        {
                            classes: 'green-button',
                            text: 'Next',
                            action: this.tour.next   
                        },
                    ]
                },
            ]
        }
        
        return [
            {
                title: "Welcome to the Equity Overview page",
                text: "Anytime you search for a specific Equity, you will be greeted by this page. Now let's look at what's featured:",
                buttons: [
                    {
                    classes: 'green-button',
                    text: 'Next',
                    action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Price Series Chart',
                attachTo: {element: document.querySelector('.security-overview-chart-card'), on: 'right'},
                text: "This is an interactive view of the company's price performance. This tool presents the daily stock price and associated volume in a chosen date range.",
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Analyst Ratings',
                attachTo: {element: document.querySelector('.analyst-ratings-card'), on: 'left'},
                text: "Keep up to date with the expectations given by analysts who cover this company.",
                scrollTo: {behavior: 'smooth', block: 'start'},
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            ...postsEventsNewsSteps,
            {
                title: 'EPS History',
                attachTo: {element: document.querySelector('.earnings-container'), on: 'right'},
                text: "Insight into historical Earnings per Share estimates versus what the company reported.",
                scrollTo: {behavior: 'smooth', block: 'center'},
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Financials Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(2) button'), on: 'bottom'},
                text: "This is where you can access 5 years of historical financial statements and visualize the data in a matter of seconds.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                scrollTo: {behavior: 'smooth', block: 'center'},
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Compare Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(3) button'), on: 'bottom'},
                text: "The Compare tool provides insight into the company's key metrics and ratios.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Analysts Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(4) button'), on: 'bottom'},
                text: "This tab highlights various recommendations and estimates provided by analysts that cover this company.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Insiders Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(5) button'), on: 'bottom'},
                text: "Use this tab to gain insight into the internal trading patterns of public company executives.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Charting Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(6) button'), on: 'bottom'},
                text: "This Advanced Chart contains all the most popular upper & lower indicators, as well as other complementary functions to enhance your technical analysis.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Dividends Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(7) button'), on: 'bottom'},
                text: "This tab tracks all historical dividends declared by a company and provides insight into future dividends once they have been formally declared.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Posts Tab',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(8) button'), on: 'bottom'},
                text: "This tab contains an aggregate view of all the posts shared on INVRS that include this company.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Trades Tab (Premium)',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(9) button'), on: 'bottom'},
                text: "This tab provides botha trade summary and a tame-and-sales view of a given symbol's recent trade activity.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Performance Tab (Premium)',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:nth-child(10) button'), on: 'bottom'},
                text: "This tab provides a detailed display of a stock's movement over time, including high-low values over several time periods, volume metrics, and institutional holdings.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Filings Tab (Premium)',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .navigationCarousel .p-carousel-items-container .p-carousel-item:last-child button'), on: 'bottom'},
                text: "This tab provides access to all of a company's financial reports, as well as a host of other filings; all of which are available for download.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Subscribe Alerts Button (Premium)',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .optional-buttons-container button:first-child'), on: 'bottom'},
                text: "Keep up to date with custom triggers and alerts.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Follow Equity Button',
                attachTo: {element: document.querySelector('.pill-menu-bar.main-header .optional-buttons-container button:last-child'), on: 'bottom'},
                text: "Make sure you get relevant news by Following your favorite companies.",
                classes: 'pill-menu-bar-button',
                canClickOnTarget: false,
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'Next',
                        action: this.tour.next   
                    },
                ]
            },
            {
                title: 'Summary',
                text: "This page also includes the company's description and an overview of their most important statistics, financials, and valulation metreics. Check them out later!",
                buttons: [
                    {
                        classes: 'red-button',
                        text: 'Back',
                        action: this.tour.back   
                    },
                    {
                        classes: 'green-button',
                        text: 'OK',
                        action: this.tour.complete   
                    },
                ]
            }
        ]
    }
}