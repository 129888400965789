<template>
    <Dialog :header="headerLabel" :modal="true" :draggable="false" v-model:visible="display" class='order-confirmation-popup' @hide="onHide">
        <div class='body-text ease-in' >
            <template v-if="delayedTrade">
                <div class='order-description' v-if="orderType === MARKET_ORDER_TYPE_ID" >
                    <span >Your order has been placed but is currently pending. Your order will be filled (if possible) on or after {{estFillDate}}.</span>
                </div>
                <div class='order-description' v-else-if="orderType === LIMIT_ORDER_TYPE_ID">
                    <span >You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Limit Order</b> with a <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'drops below': 'rises above'}} <b>{{formatter.format(limitPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    
                </div>
                <div class='order-description' v-else-if="orderType === STOP_LOSS_ORDER_TYPE_ID">
                    <span>You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Loss Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>Ask Price</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises above': 'drops below'}} <b>{{formatter.format(stopPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    
                </div>
                <div class='order-description' v-else-if="orderType === STOP_ENTRY_ORDER_TYPE_ID">
                    <span >You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Entry Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises above': 'drops below'}} <b>{{formatter.format(stopPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    
                </div>
                <div class='order-description' v-else>
                    <span >You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Limit Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> and a <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises to or above': 'drops to or below'}} the <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> but {{tradeType === BUY_TRADE_TYPE['ID'] ? 'does not exceed': 'is not below'}} the <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b>.</span>
                    
                </div>

            </template>
            <template v-else>

                <div class='order-description' v-if="orderType === MARKET_ORDER_TYPE_ID" >
                    <span v-if='executionDate'>You {{tradeType === BUY_TRADE_TYPE['ID'] ? 'bought': 'sold'}} {{quantity}} shares of {{security.symbol}} ({{security.companyName}}) at {{formatter.format(executedPrice, '$0.00')}} {{currency}}</span>
                    <span v-else>Your order has been placed but is currently pending due to the market being closed. Your order will be filled (if possible) when the market next opens.</span>
                </div>
                <div class='order-description' v-else-if="orderType === LIMIT_ORDER_TYPE_ID">
                    <span v-if="!executionDate">You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Limit Order</b> with a <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'drops below': 'rises above'}} <b>{{formatter.format(limitPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    <span v-else>You {{tradeType === BUY_TRADE_TYPE['ID'] ? 'bought': 'sold'}} {{quantity}} shares of {{security.symbol}} ({{security.companyName}}) at {{formatter.format(executedPrice, '$0.00')}} {{currency}}</span>
                </div>
                <div class='order-description' v-else-if="orderType === STOP_LOSS_ORDER_TYPE_ID">
                    <span v-if="!executionDate">You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Loss Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>Ask Price</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises above': 'drops below'}} <b>{{formatter.format(stopPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    <span v-else>You {{tradeType === BUY_TRADE_TYPE['ID'] ? 'bought': 'sold'}} {{quantity}} shares of {{security.symbol}} ({{security.companyName}}) at {{formatter.format(executedPrice, '$0.00')}} {{currency}}</span>
                </div>
                <div class='order-description' v-else-if="orderType === STOP_ENTRY_ORDER_TYPE_ID">
                    <span v-if="!executionDate">You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Entry Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> equals or {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises above': 'drops below'}} <b>{{formatter.format(stopPrice, '$0.00')}}</b> before {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.</span>
                    <span v-else>You {{tradeType === BUY_TRADE_TYPE['ID'] ? 'bought': 'sold'}} {{quantity}} shares of {{security.symbol}} ({{security.companyName}}) at {{formatter.format(executedPrice, '$0.00')}} {{currency}}</span>
                </div>
                <div class='order-description' v-else>
                    <span v-if="!executionDate">You have placed a <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Buy': 'Sell'}} Stop Limit Order</b> with a <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> and a <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b> to be filled by {{durationType === MARKET_CLOSE_DURATION_ID ? 'market close' : '30 days'}}.
                    The order will be executed if the <b>{{tradeType === BUY_TRADE_TYPE['ID'] ? 'Ask Price': 'Bid Price'}}</b> {{tradeType === BUY_TRADE_TYPE['ID'] ? 'rises to or above': 'drops to or below'}} the <b>Stop Price of {{formatter.format(stopPrice, '$0.00')}}</b> but {{tradeType === BUY_TRADE_TYPE['ID'] ? 'does not exceed': 'is not below'}} the <b>Limit Price of {{formatter.format(limitPrice, '$0.00')}}</b>.</span>
                    <span v-else>You {{tradeType === BUY_TRADE_TYPE['ID'] ? 'bought': 'sold'}} {{quantity}} shares of {{security.symbol}} ({{security.companyName}}) at {{formatter.format(executedPrice, '$0.00')}} {{currency}}</span>
                </div>

                <div class='p-grid balance' v-if='executionDate'>
                    <div class='p-sm-12 header-cell'><b>Your Account Balance</b></div>
                    <div class='p-sm-6 left-col'>
                        <span>Total Value:</span>
                        <span>Total Cash:</span>
                        <span>Total Borrowed:</span>
                        <span>Buying Power:</span>
                    </div>
                    <div class='p-sm-6 right-col'>
                        <b>{{formatter.format(totalValue, '$0.00')}} {{accountCurrency}}</b>
                        <b>{{formatter.format(totalCash, '$0.00')}} {{accountCurrency}}</b>
                        <b>{{formatter.format(totalBorrowed, '$0.00')}} {{accountCurrency}}</b>
                        <b>{{formatter.format(buyingPower, '$0.00')}} {{accountCurrency}}</b>
                    </div>
                </div>
            </template>
            <BannerAd @banner-ad-ready="onBannerAdReady"/>
            
        </div>
        
        <template #footer>
            
            <Button label='Close' class='close-button green-button__primary' @click="onClose"/>
            <Button label='Go to Portfolio' class="go-to-portfolio-button green-button__primary" @click="onGoToPortfoliosButtonClick" v-if="isNotOnAccount"/>
        </template>
    </Dialog>
</template>

<script>

import Dialog from 'primevue/dialog';

import {getTotalCash} from '../';
import EventBus from '../../../event-bus';
import {MARKET_CLOSE_DURATION_ID, MARKET_ORDER_TYPE_ID, LIMIT_ORDER_TYPE_ID, STOP_LOSS_ORDER_TYPE_ID, STOP_ENTRY_ORDER_TYPE_ID, BUY_TRADE_TYPE} from '../../../common/constants';
import CurrencyUtils from '../../../utilities/CurrencyUtils';
import FormattingUtils from '../../../utilities/FormattingUtils';
import TimeUtils from '../../../utilities/TimeUtils';

import BannerAd from '../../advertising/BannerAd.vue';

export default {
    name: 'OrderConfirmationPopup',
    components: {
        Dialog,BannerAd
    },

    props: {
        // Checks if user is on account profile in portfolios page
        isNotOnAccount: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        headerLabel() {
            return this.executionDate ? 'Order Filled' : 'Order Placed';
        },
        estFillDate() {
            return TimeUtils.getFormattedTime(this.effectiveDate, "YYYY/MM/DD HH:mm");
        }
    },
    data() {
        return {
            //CONSTANTS
            BUY_TRADE_TYPE,
            MARKET_ORDER_TYPE_ID,
            LIMIT_ORDER_TYPE_ID,
            STOP_LOSS_ORDER_TYPE_ID,
            STOP_ENTRY_ORDER_TYPE_ID,
            MARKET_CLOSE_DURATION_ID,

            display: false,
            onConfirmOrderScreen: false,
            orderIsPlaced: false,

            security: null,
            askPrice: null,
            stopPrice: null,
            limitPrice: null,
            quantity: null,
            tradeType: null,
            orderType: null,
            durationType: null,
            executionDate: null,
            effectiveDate: null,
            currency: null,
            accountCurrency: null,
            totalCost: null,

            selectedAccount: null,

            formatter: new FormattingUtils(),
            delayedTrade: false,
            ready: false,
        }
    },


    methods: {
       
        open(data) {
            this.ready = false;
            this.display = true;
            
            this.security = data.security;
            this.stopPrice = data?.stopPrice;
            this.limitPrice = data?.limitPrice;
            this.tradeType = data.tradeType;
            this.orderType = data.orderType;
            this.durationType = data.durationType;
            this.executionDate = data?.executionDate;
            this.executedPrice = data?.executedPrice;
            this.effectiveDate = data.effectiveDate;
            this.currency = data.currency;
            this.quantity = data.quantity;
            
            this.selectedAccount = data?.updatedAccount;

            this.totalValue = this.selectedAccount.totalValue;
            this.totalCash = getTotalCash(this.selectedAccount.cashPositions);
            this.totalBorrowed = this.selectedAccount.totalBorrowed;
            this.buyingPower = this.selectedAccount.currentBuyingPower;
            this.accountCurrency = CurrencyUtils.getCurrencySymbolViaId(this.selectedAccount.currencyId);
            console.log("date returned is ", data)
            if( !this.executionDate && data.createdDate == data.lastChecked && data.tradingDelay > 0) {
                this.delayedTrade = true;
            }
            else {
                this.deletedtrade = false;
            }

            

        },

        onBannerAdReady() {
            console.log("banner ad ready");
            this.ready = true;
        },

        onGoToPortfoliosButtonClick() {
            this.onClose();

            if (this.$route.path.startsWith('/portfolios')) {
                EventBus.emit('select-account', this.selectedAccount);
            } else {
                let type = "";

                let portfolioName = this.selectedAccount.name;
                switch(this.selectedAccount.accountTypeId){
                    case 74:
                        type = "Paper Trading ";
                        portfolioName = this.selectedAccount.name+`(${type}${this.accountCurrency})`;
                        break;
                    default:
                        portfolioName = this.selectedAccount.name+` (${this.accountCurrency})`;
                    
                }

                this.$store.commit('SET_RECENTLY_VIEWED_PORTFOLIO', {
                    portfolioData: this.selectedAccount,
                    portfolioName: portfolioName,
                })

                this.$router.push('/portfolios');
            }
        },

        onClose() {
            this.display = false;
        },

        onHide() {
            this.security = null;
            this.askPrice = null;
            this.stopPrice = null;
            this.limitPrice = null;
            this.tradeType = null;
            this.orderType = null;
            this.durationType = null;
            this.executionDate = null;
            this.quantity = null;
            this.currency = null;
            this.totalValue = null;
            this.totalCash = null;
            this.totalBorrowing = null;
            this.buyingPower = null;
            this.accountCurrency = null;
            this.delayedTrade = false;
            this.effectiveDate = null;
        }
    }
}
</script>

<style>
.order-confirmation-popup {
    width: 600px;
}

.order-confirmation-popup .p-dialog-header {
    padding: 16px;
}

.order-confirmation-popup .p-dialog-content {
    padding: 0px 16px;
    overflow-y: visible;
    text-align: center;
}

.order-confirmation-popup .p-dialog-footer {
    padding: 16px;
    text-align: center;
}

@media (max-width: 760px) {
    .order-confirmation-popup {
        width: 100%;
    }

    .order-confirmation-popup .p-dialog-header {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
    }

    .order-confirmation-popup .p-dialog-content {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .order-confirmation-popup .p-dialog-footer {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}
</style>
<style scoped>
*:not(.pi),::v-deep(.p-component) {
	font-family: "Trebuchet MS", "Verdana";
}

.p-dialog-header .title {
    font-weight: bold;
    color: #32364e;
    font-size: 24px;
}

.body-text {
    color: black;
    font-size: 16px;
}

.order-description {
    margin-bottom: 15px;
}

.balance {
    margin-bottom: 15px;
}

.p-grid {
    padding: 0px 96px 0px;
}
.p-grid > div {
    display: flex;
    flex-direction: column;
}
.p-grid .left-col {
    align-items: flex-start;
}
.p-grid .right-col {
    align-items: flex-end;
}

button {
    border-radius: 50px;
    padding: 8px 64px;
    font-size: 18px;
}

@media (max-width: 760px) {
    .p-grid {
        padding: 0px 36px 0px;
    }   
    .p-grid .header-cell {
        width: 100%;
    }
    .p-grid > .left-col {
        width: 50%;
    }
    .p-grid > .right-col {
        width: 50%;
    }

    button {
        padding: 8px 32px;
        font-size: 14px;
    }
}

.ease-in{
   
 /* opacity: 0; */
    
 /* Add animation properties */
 animation: fadeIn 2s ease-in; 

}


@keyframes fadeIn {  
   from {  
       opacity:0;  
   }  

   to {  
       opacity:1;  
   }  
}

</style>