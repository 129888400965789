import EventBus from '../../event-bus';
import INVRS from '../../main.js';
export default class HomeTour {
    tour = INVRS.app.config.globalProperties.$shepherd({
        useModalOverlay: true,
        defaultStepOptions: {
            classes: 'invrs-onboarding-tour',
            cancelIcon: {
                'enabled': true
            }
        }
    });
    
    steps = this.createSteps();

    createTextPara(text) {
        const para = document.createElement("p");
        para.innerHTML = text;

        return para;
    }

    createTextDiv(texts) {
        const textDiv = document.createElement("div");

        texts.forEach((text) => {
            textDiv.appendChild(this.createTextPara(text));
        })

        return textDiv
    }

    returnSearchResultsHandler(query) {
        if (query === 'aapl' || query === 'AAPL') {
            this.tour.next();
        }
    }


    createSteps() { 
        EventBus.on("return-search-results", this.returnSearchResultsHandler.bind(this));

        this.tour.on('inactive', ()=>{
            EventBus.off('return-search-results', this.returnSearchResultsHandler.bind(this));
        });
       
        let marketDiscoverNewsSteps = [];

        if (document.querySelector('.markets-discover-header-menu button.active:first-child')) {
            marketDiscoverNewsSteps = [
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:first-child'), on: 'bottom'},
                    classes: 'markets-discover-news-menu-button',
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    text: "Click on 'Markets'",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Markets',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.markets-container');
                    }, on: 'left'},
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    text: 'Near real time data on North American indicies, sectors and factor performance.',
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:nth-child(2)'), on: 'bottom'},
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:nth-child(2)');
                    },
                    text: "Click on 'Discover'",
                    classes: 'markets-discover-news-menu-button',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Discover',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.discover-container')
                    }, on: 'left-start'},
                    text: 'Check out trending Notes, assets, and investors to follow.',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:nth-child(2)');
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:last-child'), on: 'bottom'},
                    classes: 'markets-discover-news-menu-button',
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:last-child');
                    },
                    text: "Click on 'News'",
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.news-feed-container')
                    }, on: 'left'},
                    text: 'Keep up to date with the latest stories.',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
            ]
        } else if (document.querySelector('.markets-discover-header-menu button.active:nth-child(2)')) {
            marketDiscoverNewsSteps = [
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:nth-child(2)'), on: 'bottom'},
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:nth-child(2)');
                    },
                    text: "Click on 'Discover'",
                    classes: 'markets-discover-news-menu-button',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Discover',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.discover-container')
                    }, on: 'left-start'},
                    text: 'Check out trending Notes, assets, and investors to follow.',
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:nth-child(2)');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:first-child'), on: 'bottom'},
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    text:  "Click on 'Markets'",
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Markets',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.markets-container');
                    }, on: 'left'},
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    text: 'Near real time data on North American indicies, sectors and factor performance.',
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: () => {
                        return document.querySelector('.markets-discover-header-menu button:last-child');
                    }, on: 'bottom'},
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:last-child');
                    },
                    text: "Click on 'News'",
                    classes: 'markets-discover-news-menu-button',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.news-feed-container')
                    }, on: 'left'},
                    text: 'Keep up to date with the latest stories.',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
            ]
        } else if (document.querySelector('.markets-discover-header-menu button.active:last-child')) {
            marketDiscoverNewsSteps = [
                {
                    title: "Action",
                    attachTo: {element: () => {
                        return document.querySelector('.markets-discover-header-menu button:last-child');
                    }, on: 'bottom'},
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:last-child');
                    },
                    text: "Click on 'News'",
                    classes: 'markets-discover-news-menu-button',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:last-child',
                        event: 'click'
                    }
                },
                {
                    title: 'News',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.news-feed-container')
                    }, on: 'left'},
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:last-child');
                    },
                    text: 'Keep up to date with the latest stories.',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:first-child'), on: 'bottom'},
                    classes: 'markets-discover-news-menu-button',
                    text: "Click on 'Markets'",
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:first-child',
                        event: 'click'
                    }
                },
                {
                    title: 'Markets',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.markets-container');
                    }, on: 'left'},
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    showOn: () => {
                        return document.querySelector('.markets-discover-header-menu button.active:first-child');
                    },
                    text: 'Near real time data on North American indicies, sectors and factor performance.',
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
                {
                    title: "Action",
                    attachTo: {element: document.querySelector('.markets-discover-header-menu button:nth-child(2)'), on: 'bottom'},
                    classes: 'markets-discover-news-menu-button',
                    text: "Click on 'Discover'",
                    showOn: () => {
                        return !document.querySelector('.markets-discover-header-menu button.active:nth-child(2)');
                    },
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'start'
                    },
                    advanceOn: {
                        selector: '.markets-discover-header-menu button:nth-child(2)',
                        event: 'click'
                    }
                },
                {
                    title: 'Discover',
                    canClickTarget: false,
                    attachTo: {element: () => {
                        return document.querySelector('.discover-container')
                    }, on: 'left-start'},
                    text: 'Check out trending Notes, assets, and investors to follow.',
                    scrollTo: {
                        behavior: 'smooth',
                        block: 'center'
                    },
                    buttons: [
                        {
                            classes: 'red-button',
                            text: `<i class="pi pi-angle-left"></i>Back`,
                            action: this.tour.back 
                        },
                        {
                            classes: 'green-button',
                            text: `Next<i class="pi pi-angle-right"></i>`,
                            action: this.tour.next   
                        }
                    ]
                },
            ]
        }


        return [
            {
                title: 'Welcome to INVRS!',
                text: this.createTextDiv(["Welcome to the INVRS Home Page!", "This section of our app highlights the main Feed and acts as a hub for you to access everything else on our platform! Let’s take a look around:"]),
                buttons: [
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Navigation Column',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar > .navigation-menu'), on: 'right'},
                text: 'The left side of your screen highlights shortcuts to all major sections of the platform.',
                classes: 'app-navigation-column',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Home',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:first-child'), on: 'right'},
                text: 'This is where we are right now.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Contests',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:nth-child(2)'), on: 'right'},
                text: 'Contests range from Investment Trivia to Portfolio competitions with real world challenges.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Education',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:nth-child(3)'), on: 'right'},
                text: 'Here you can find a curated list of Investment and Financial literacy courses available within the application.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Portfolio',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:nth-child(4)'), on: 'right'},
                text: 'Create Paper Trading Accounts or manually enter your current accounts.  Then make trades and track your performance, allocation and cash flow statistics.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Tools',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:nth-child(5)'), on: 'right'},
                text: 'Create Screeners, Lists and Alerts and view Earnings, Dividends, Currencies, Futures, Rates and Indices.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Notes',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:first-child li.layout-root-menuitem:last-child'), on: 'right'},
                text: 'Keep track of all your research Notes here.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'INVRS Group',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:last-child .layout-menu > .layout-menuitem:first-of-type'), on: 'right'},
                text: 'This is the INVRS Community group. Your favourite groups will float here for easy access.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Groups Hub',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-sidebar .layout-menu-container:last-child .layout-menu > .layout-menuitem:last-of-type'), on: 'right'},
                text: 'Create and discover more groups here.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Search Bar',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-topbar > .topbar-left'), on: 'bottom'},
                text: this.createTextDiv(['The search bar helps you find Equities, Funds, Users, Tags and Indices', `Can't find something on the platform? Try searching for it. (More on this later!)`]),
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Playdough Balances',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.playdough-balances-container'), on: 'bottom'},
                text: 'The current balance of your Playdough accounts.  Playdough is the in-app currency you can earn or buy and can be used to make paper trades and purchase other value add services.',
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Notifications',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#notification-bell > div'), on: 'left'},
                text: 'Keep track of your platform activity, requests, and triggered alerts.',
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'User Menu',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.topbar-right > .user-bar-container .topbar-menu:last-of-type > .profile-item > div'), on: 'left'},
                text: 'This is where you can access your profile, settings, and billing.',
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Chat',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.chat-floating-button'), on: 'left'},
                text: 'Access your personal messages or DMs here.',
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: `Now, back to the Feed`,
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-content-wrapper.home-page .layout-content .tab-content2'), on: 'top'},
                text: 'This is an aggregate view of community content, portfolios, markets, news, and more.',
                classes: 'home-main-view',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Tags',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-content-wrapper.home-page .layout-content > .custom-carousel'), on: 'bottom'},
                text: `Check out the platform's hottest topics.`,
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Create',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#statusFormContainer'), on: 'bottom'},
                text: 'Share your thoughts with a Post or create a long-form research report with a Note.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Post Button',
                classes: 'create-post-button',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#statusFormContainer .status-form-top-row button:first-of-type'), on: 'bottom'},
                text: 'Click on this to create a short form post. You can create longer posts called Notes by clicking on the Notes <i class="pi pi-pencil"></i> button on the left menu bar.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Ask Button',
                classes: 'create-question-button',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#statusFormContainer .status-form-top-row button:nth-of-type(2)'), on: 'bottom'},
                text: 'Click on this to ask a general question or a poll. Polls can be yes/no or multiple choice and members can answer on the feed.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Motion Button',
                classes: 'create-motion-button',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#statusFormContainer .status-form-top-row button:nth-of-type(3)'), on: 'bottom'},
                text: 'Click on this to create a Motion to Invest. This is a suggested stock trade for one of the INVRS collaborative portfolios. Anyone can make a Motion to Invest and anyone can vote on it. If the motion passes then the suggested trade is executed.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Refresh Button',
                classes: 'refresh-button',
                canClickTarget: false,
                attachTo: {element: document.querySelector('#statusFormContainer .status-form-top-row .status-form-row button:nth-child(2)'), on: 'bottom'},
                text: 'Click on this to refresh the feed.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            // {
            //     title: 'Notes Only Button',
            //     classes: 'notes-only-button',
            //     canClickTarget: false,
            //     attachTo: {element: document.querySelector('#statusFormContainer .status-form-top-row .status-form-row button:nth-child(3)'), on: 'bottom'},
            //     text: 'Click on this to filter the feed to only show Notes (Long form posts). Be aware that this will also hide any Questions/Polls posted.',
            //     buttons: [
            //         {
            //             classes: 'red-button',
            //             text: `<i class="pi pi-angle-left"></i>Back`,
            //             action: this.tour.back 
            //         },
            //         {
            //             classes: 'green-button',
            //             text: `Next<i class="pi pi-angle-right"></i>`,
            //             action: this.tour.next   
            //         }
            //     ]
            // },
            {
                title: 'Main Feed',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-content-wrapper.home-page .layout-content .p-scrollpanel-content .activity-cards-container'), on: 'right-start'},
                text: 'Checkout posts created and shared by people you follow, as well as some additional content that aligns with your defined interests.',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            {
                title: 'Performance',
                canClickTarget: false,
                attachTo: {element: document.querySelector('.layout-content-wrapper.home-page .layout-content .home-portfolio-container'), on: 'left'},
                text: 'Keep an eye on portfolio performance.',
                showOn: () => {
                    return document.querySelector('.layout-content-wrapper.home-page .layout-content .home-portfolio-container');
                },
                scrollTo: {
                    behavior: 'smooth',
                    block: 'start'
                },
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next   
                    }
                ]
            },
            ...marketDiscoverNewsSteps,
            {
                title: 'Help Button',
                attachTo: {element: document.querySelector('.user-bar-container .tour-button'), on: 'left'},
                canClickTarget: false,
                text: "Anytime you want to have a tour about a specific feature or want to create a support ticket, click on this button to do so.",
                modalOverlayOpeningRadius: 20,
                modalOverlayOpeningPadding: 3,
                scrollTo: {
                    behavior: 'smooth',
                    block: 'start'
                },
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: `Next<i class="pi pi-angle-right"></i>`,
                        action: this.tour.next
                    }
                ]
            },
            {
                title: 'Congratulations! Now you know the basics.',
                attachTo: {element: document.querySelector('#secQuickSearch'), on: 'bottom'},
                text: 'Try searching for a company (Ex. AAPL) using the Search bar to checkout Equities Overview!',
                buttons: [
                    {
                        classes: 'red-button',
                        text: `<i class="pi pi-angle-left"></i>Back`,
                        action: this.tour.back 
                    },
                    {
                        classes: 'green-button',
                        text: 'Done',
                        action: this.tour.complete
                    }
                ]
            },
            {
                title: 'Action',
                attachTo: {element: () => {
                    return document.querySelector('#secQuickSearch_2')
                }, on: 'right'},
                beforeShowPromise: () => {
                    return new Promise((resolve) => {
                        if (document.querySelector('.app-search-panel')) {
                            resolve();
                        }
                    })
                },
                advanceOn: {selector: '#secQuickSearch_2', event: 'click'},
                text: 'Select AAPL',
            }
        ]
    }
  }