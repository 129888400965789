<template>
<Dialog header="Login Required" v-model:visible="display" @hide="onHide"  :modal="true">
    <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle p-mr-3" style="font-size: 2rem" />
        <span>Your session has expired, please login again.</span>
    </div>
    <template #footer >
        <div style="width:100%;justify-content: center; align-items: center;display: flex;">
        <Button label="OK"  @click="closeModal" />
        </div>
    </template>
</Dialog>


</template>

<script>
import Dialog from 'primevue/dialog';
import AuthService from '../../service/AuthService';
import EventBus from '../../event-bus';

export default {
   
    components : {
        Dialog,
       
    },
    created() {
        EventBus.on('login-required', this.loginRequired);
        
    },

    data() {
      return {
           display : false,
          
      }
    },
    
    methods: {
        loginRequired() {
            this.display = true;
        },
        onHide() {
            AuthService.logout();
        },

        closeModal() {
            this.display = false;
        }

       
    }
   
}
</script>

<style scoped>



</style>