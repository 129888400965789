<template>
   
    <div class="switch-container">
        <div class='radio-container'>
            <h4 class="switch-title">Sent</h4>
            <RadioButton v-model="requestType" value="sent"  />
        </div>
        <div class='radio-container'>
            <h4 class="switch-title">Recieved</h4>
            <RadioButton v-model="requestType" value="received" />
        </div>
    </div>
     <LoadingSpinner :showLoading="loading"/> 
    <infinite-scroll @infinite-scroll="loadMoreRequests" message="" :noResult="noMoreResults" :style='scrollStyle' v-if="!initLoading ">
        
        <DMRequestListItem v-for='request in requests' :key='request.dm_request_id' :request="request" :mode='requestType'
            @accept-clicked="onAcceptClicked" 
            @reject-clicked="onRejectClicked" 
            @delete-clicked="onDeleteClicked"/>
        
    </infinite-scroll>
</template>

<script>
import ChatService from '../../service/ChatService';
import InfiniteScroll from '../common/scroll/infinite-scroll-vue3.vue';
import RadioButton from 'primevue/radiobutton';
import LoadingSpinner from '../common/LoadingSpinner.vue';
import DMRequestListItem from './DMRequestListItem.vue';

import EventBus from '../../event-bus';

export default {
    name: 'DMRequestList',
   
    props: {
        
    },
    data() {
        return {
            loading: false,
            initLoading: false,
            requests: [],
            noMoreResults: false,
            
            header: '',
           
            scrollStyle: "width: 100%; max-height: 100%; height: calc(100vh - 200px);",
           
            requestType: 'sent',
        }
    },
    components: {
        InfiniteScroll, LoadingSpinner, 
        DMRequestListItem,RadioButton
    },

    errorCaptured(err, vm, info) {// eslint-disable-line
        console.log("capturing error : "+err)
        return false;
    },
   watch: {
        requestType(newVal, oldVal) {
            console.log("request type changed to " + newVal +" from "+oldVal)
            if( newVal != oldVal) {
                this.refreshList();
            }
        },

        
   },

    mounted() {
        this.refreshList();

    },

   
   

    methods: {
        onAcceptClicked( req ) {
            ChatService.acceptOrRejectDMRequest(req.dmRequestId, true).then( resp => { //eslint-disable-line
                this.refreshList();
                this.$store.commit('MARK_CHANNELS_STALE_DM');
                EventBus.emit('refresh-my-dm-channel-list');

            })
        },

        onRejectClicked( req) {
            ChatService.acceptOrRejectDMRequest(req.dmRequestId, false).then( resp => {//eslint-disable-line
                  this.refreshList();

            })
        },

        onDeleteClicked( req) {
            ChatService.deleteDMRequest(req.dmRequestId).then( resp => { //eslint-disable-line
            console.log("deleting dm request");
                  this.refreshList();

            })
        },
       
       refreshList() {
            this.loading = true;
            this.requests = [];
            this.refreshRequestList(20, 0);
       },

        refreshRequestList(limit, offset) {
            if( this.requestType == 'sent') {
                console.log("getting dm requests sent")
                ChatService.getMyDMRequestsSent(limit, offset).then( resp => {
                    this.loading = false;
                    if( resp.data.status == 'success') {
                        this.requests = this.requests.concat(resp.data.requests);
                    }
                    else {
                        console.log("error getting sent dm requests "+ resp.data.message);
                    }
                    

                });
            }
            else {
                 console.log("getting dm requests receive")
                 ChatService.getMyDMRequestsReceived(limit, offset).then( resp => {
                    this.loading = false;
                    if( resp.data.status == 'success') {
                        this.requests = this.requests.concat(resp.data.requests);
                    }
                    else {
                        console.log("error getting received dm requests "+ resp.data.message);
                    }

                });
            }
        },

        
       


        async loadMoreRequests() {
            if (!this.loading && this.requests.length >= 20) {
                this.loading = true;

                if (!this.noMoreResults) {
                    this.refreshRequestList(20, this.requests.length);
                } else {
                    this.loading = false;
                }
            }
            
        },

        

    }
}
</script>

<style scoped>
.switch-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.switch-title {
  margin: 0 16px 0 0;
  font-size: 18px;
  font-weight: bold;
  color: #32364e;
}

.radio-container {
  display: flex;
  margin-right: 16px;
}
.radio-container:last-child {
  margin-right: 0px;
}


::v-deep(*),:not(::v-deep(.pi), [class*=material-icon]) {
    font-family: 'Trebuchet MS', 'Verdana';
}

::v-deep(.p-scrollpanel-content) {
    padding: 0px !important;
}

/* 
::v-deep(.channel-item) {
    display: flex;
    padding: 16px;
    border-bottom: 1px solid #BFBFBF;
}
::v-deep(.channel-item:hover) {
    background: #32364e;
}
::v-deep(.channel-item:hover .channel-title) {
    color: #FFFFFF;
}

::v-deep(.channel-item .channel-title) {
    font-weight: bold;
    color: #32364e;
    font-size: 16px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
::v-deep(.channel-item .more-options) {
    margin-left: auto;
} */
</style>